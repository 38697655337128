import React, { useCallback } from "react";
// material
import { Box, makeStyles } from "@material-ui/core";
// react-router-dom
import { useHistory } from "react-router-dom";
// css
import "./special-event.css";
import { useAlert } from "../../../../component/Alert";
import AdaptiveImage from "../../../../component/AdaptiveImage";
// zustand
import useTokenStore from "../../../../zustand/useTokenStore";

const switchLists = [
  {
    label: "遊戲說明",
    href: "/special-event/information",
  },
  {
    label: "購買",
    href: "/special-event/purchase",
  },
  {
    label: "我的育成",
    href: "/special-event/my-cultivation",
  },
  {
    label: "圖鑑與活動",
    href: "/special-event/illustrate",
  },
];

const swtichContainerStyle = {
  display: "flex",
  justifyContent: "center",
  "& .tab-item": {
    fontSize: "18px",
    fontWeight: 900,
    color: "#fff",
    position: "relative",
    "&::after": {
      content: '""',
      width: "1.5px",
      height: "16px",
      backgroundColor: "#fff",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
    "&:last-child::after": {
      width: 0,
    },
  },
  "& .tab-container": {},
  "& .tab-item.tab-selected": {
    color: "#658c9a",
  },
};

export default function SwitchNavTabs() {
  const { alert } = useAlert();
  const history = useHistory();
  const useStyles = makeStyles({
    swtichContainer: swtichContainerStyle,
  });
  const classes = useStyles();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  return (
    <AdaptiveImage ratio="10%">
      <Box className={classes.swtichContainer}>
        {switchLists.map((item, index) =>
          _renderLists(item, index, history, memberToken, alert, classes)
        )}
      </Box>
    </AdaptiveImage>
  );
}

const _renderLists = ({ label, href }, index, history, memberToken, alert) => {
  const isCurrentPath = window.location.pathname.startsWith(href);
  return (
    <Box
      key={index}
      px={1}
      className={
        isCurrentPath
          ? "tab-container tab-selected tab-item"
          : "tab-item tab-container"
      }
      onClick={() => {
        if (label === "我的育成") {
          if (memberToken) {
            history.push(href);
          } else {
            return alert("通知", "尚未登入，請先登入才可查看我的育成。", [
              {
                text: "登入",
                onPress: () =>
                  history.push("/login", {
                    goToPage: "/special-event/my-cultivation",
                  }),
                type: "ok",
              },
              {
                text: "取消",
                type: "cancel",
              },
            ]);
          }
        } else {
          history.push(href);
        }
      }}
    >
      {label}
    </Box>
  );
};
