import React from "react";
import { Button, Dialog, DialogActions } from "@material-ui/core";

export default function RuleDialog({ onClose = () => {}, open = false }) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <img
        src={require("../images/到貨規則.jpg").default}
        style={{ width: "100%" }}
        alt=""
      />
      <DialogActions>
        <Button onClick={onClose}>關閉</Button>
      </DialogActions>
    </Dialog>
  );
}
