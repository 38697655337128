import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import "./special-event.css";

export default function AreaInformation({ title, titleStyle }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Box className="area-title-container" mt="32px">
        <Typography
          className="area-title"
          style={{ fontSize: isMobile ? "2em" : "3em", ...titleStyle }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
