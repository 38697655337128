import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

export default function PageItem({ data = [], page, onChangePage = () => {} }) {
  const theme = useTheme();
  const deviceSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    pageButton: {
      minWidth: theme.spacing(5),
      margin: theme.spacing(0.5),
    },
  });
  const classes = useStyles();
  const [newData, setNewData] = useState([]);
  useEffect(() => {
    const dataLength = data.length;
    const dataInteger = Math.floor(dataLength / 10);
    const dataRemainder = dataLength % 10;
    const pageInteger = Math.floor(page / 10) + 1;
    const pageRemainder = page % 10;

    if (dataInteger === 0) {
      setNewData(data.slice(dataInteger, dataLength));
    } else if (pageInteger - 1 < dataInteger && pageRemainder === 0) {
      setNewData(data.slice((pageInteger - 2) * 10, (pageInteger - 1) * 10));
    } else if (pageInteger - 1 < dataInteger) {
      setNewData(data.slice((pageInteger - 1) * 10, pageInteger * 10));
    } else if (pageInteger - 1 === dataInteger && pageRemainder === 0) {
      setNewData(data.slice((pageInteger - 2) * 10, (pageInteger - 1) * 10));
    } else if (pageInteger - 1 === dataInteger && pageRemainder !== 0) {
      setNewData(
        data.slice(
          (pageInteger - 1) * 10,
          (pageInteger - 1) * 10 + dataRemainder
        )
      );
    }
  }, [data, page]);
  function NextButton(props) {
    return (
      <Button
        classes={{ root: classes.pageButton }}
        onClick={() => onChangePage(page + 1)}
        disabled={page === data.length || !Boolean(data[0])}
        {...props}
      >
        <KeyboardArrowRight />
      </Button>
    );
  }
  function FirstButton(props) {
    return (
      <Button
        classes={{ root: classes.pageButton }}
        onClick={() => onChangePage(1)}
        disabled={page === 1 || !Boolean(data[0])}
        {...props}
      >
        <FirstPageIcon />
      </Button>
    );
  }
  function PreviousButton(props) {
    return (
      <Button
        classes={{ root: classes.pageButton }}
        onClick={() => onChangePage(page - 1)}
        disabled={page === 1 || !Boolean(data[0])}
        {...props}
      >
        <KeyboardArrowLeft />
      </Button>
    );
  }
  function LastButton(props) {
    return (
      <Button
        classes={{ root: classes.pageButton }}
        onClick={() => onChangePage(data.length)}
        disabled={page === data.length || !Boolean(data[0])}
        {...props}
      >
        <LastPageIcon />
      </Button>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection={deviceSize ? "column" : "row"}
      alignSelf="center"
      justifyContent="center"
      padding={1}
      width="100%"
    >
      {!deviceSize && (
        <>
          <FirstButton />
          <PreviousButton />
        </>
      )}
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {newData.map((item, index) => {
          return (
            <Button
              key={index}
              classes={{ root: classes.pageButton }}
              variant={page === newData[index] ? "contained" : "text"}
              color={page === newData[index] ? "secondary" : "default"}
              onClick={() => onChangePage(parseInt(newData[index], 10))}
            >
              {item}
            </Button>
          );
        })}
      </Box>
      {!deviceSize && (
        <>
          <NextButton />
          <LastButton />
        </>
      )}
      {deviceSize && (
        <Box display="flex">
          <FirstButton style={{ flex: 1 }} />
          <PreviousButton style={{ flex: 1 }} />
          <NextButton style={{ flex: 1 }} />
          <LastButton style={{ flex: 1 }} />
        </Box>
      )}
    </Box>
  );
}
