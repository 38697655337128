import React, { useMemo, useState } from "react";
import { Grid, TextField, Button, makeStyles } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Controller, useFormContext } from "react-hook-form";

import { AutocompleteSelect } from "./Form";
import { useAlert } from "./Alert";
import LoadingModal from "./LoadingModal";
import countryCodes from "../countryCodes.json";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode(
    $countryCallingCode: String!
    $mobile: String!
    $needCheck: Boolean
  ) {
    sendVerificationCode(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
      needCheck: $needCheck
    )
  }
`;

export default function PhoneVerifyHookForm({ forget }) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  });
  const classes = useStyles();
  const { control, getValues, watch } = useFormContext();
  const [disabledMobile, setDisabledMobile] = useState(false);
  function getVerificationCode() {
    setDisabledMobile(true);
    _sendVerificationCode();
  }

  const newCountryCodes = useMemo(() => {
    const newValue = [];
    countryCodes.forEach((item) => {
      newValue.push({
        label: `${item.countryName}\u2002(${item.phoneCode})`,
        value: item.phoneCode,
      });
    });
    return newValue;
  }, []);

  const [_sendVerificationCode, { loading: sendVerificationCodeLoading }] =
    useMutation(SEND_VERIFICATIONCODE, {
      variables: {
        countryCallingCode: getValues("countryCallingCodeObject")?.value,
        mobile: getValues("mobile"),
        needCheck: forget ? true : false,
      },
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          Alert.alert("通知", "已發送驗證碼。", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("通知", "發送驗證碼失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.alert("通知", `${error.message.replace("GraphQL error: ", "")}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    });
  return (
    <Grid item container spacing={1}>
      <LoadingModal loading={sendVerificationCodeLoading} />
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name="countryCallingCodeObject"
          rules={{
            required: "必填欄位",
          }}
          render={({
            field: { onChange, ...otherFields },
            fieldState: { error },
          }) => (
            <AutocompleteSelect
              {...otherFields}
              label={"選擇國碼"}
              items={newCountryCodes}
              renderOption={(option) => {
                return (
                  <React.Fragment>
                    <div className={classes.text}>{option.label}</div>
                  </React.Fragment>
                );
              }}
              onChange={(e, value) => onChange(value)}
              error={error}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          control={control}
          name="mobile"
          rules={{
            required: "必填欄位",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="手機／同登入帳號"
              error={error}
              helperText={error?.message}
              fullWidth
              disabled={disabledMobile}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          style={{ height: "100%" }}
          variant="contained"
          color="primary"
          onClick={getVerificationCode}
          fullWidth
          disabled={
            watch("mobile").length < 6 ||
            !Boolean(watch("countryCallingCodeObject")) ||
            disabledMobile
          }
        >
          取得驗證碼
        </Button>
      </Grid>
      <Grid item container>
        <Controller
          control={control}
          name="verificationCode"
          rules={{
            required: "必填欄位",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="手機驗證碼"
              error={error}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
