/**
 * @type {StringToFloatProps}
 */
/**
 * @callback StringToFloatProps
 * @param {string | number | null | undefined} value - 值
 * @returns {string | number | null | undefined}
 */
/** @type {StringToFloatProps} */
export default function stringToFloat(value) {
  if (value) {
    return parseFloat(value);
  }
  return value;
}
