import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
  Paper,
} from "@material-ui/core";

export default function Table({
  paper = false,
  data = [],
  header = [],
  columns = [],
  columnWidths = [],
  alignItems = [],
  alignHeaders = [],
  tableWidth = 1,
  onPress,
  cellMaxWidth = "auto",
  emptyItem,
  minWidth,
  isDoubleClick = false,
}) {
  const tableList = [320, 600, 960, 1280, 1920];
  const useStyles = makeStyles({
    table: {
      minWidth: minWidth || tableList[changeTableWith()],
    },
    tableRow: {
      cursor: onPress && "pointer",
    },
  });
  const classes = useStyles();
  function changeColumns(data, item, rowIndex) {
    if (typeof item === "string") {
      return data[item];
    } else if (Array.isArray(item)) {
      let newData = Object.assign({}, data);
      for (let i = 0, len = item.length; i < len; i++) {
        newData = newData[item[i]];
      }
      return newData;
    } else if (typeof item === "function") {
      return item(data, rowIndex);
    } else {
      return item;
    }
  }
  function changeTableWith() {
    return Math.max(Math.min(tableWidth, 4), 0);
  }
  return (
    <TableContainer component={paper ? Paper : "div"}>
      <MuiTable className={classes.table}>
        <TableHead>
          <TableRow>
            {header.map((item, index) => (
              <TableCell
                key={index}
                align={alignHeaders[index] || "inherit"}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                hover={Boolean(onPress)}
                className={classes.tableRow}
                onDoubleClick={() => {
                  if (isDoubleClick) {
                    onPress(row, rowIndex);
                  }
                }}
                onClick={() => {
                  if (onPress && !isDoubleClick) {
                    onPress(row, rowIndex);
                  }
                }}
              >
                {columns.map((item, index) => (
                  <TableCell
                    key={index}
                    width={columnWidths[index]}
                    align={alignItems[index] || "inherit"}
                    style={{ maxWidth: cellMaxWidth, wordBreak: "break-all" }}
                  >
                    {changeColumns(row, item, rowIndex)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : header.length && emptyItem ? (
            <TableRow>
              <TableCell colSpan={header.length} {...emptyItem} />
            </TableRow>
          ) : null}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
