import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

/** - 擴展面板 */
export default function CustomAccordion({ header = "標題", content = "" }) {
  const classes = useStyles();
  const _renderHeader = useCallback(() => {
    if (typeof header === "function") {
      return { header };
    }
    return <Typography className={classes.heading}>{header}</Typography>;
  }, [classes, header]);
  const _renderContent = useCallback(() => {
    if (typeof content === "function") {
      return { content };
    }
    return <Typography>{content}</Typography>;
  }, [content]);
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {_renderHeader()}
        </AccordionSummary>
        <AccordionDetails>{_renderContent()}</AccordionDetails>
      </Accordion>
    </div>
  );
}
