import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Controller, FormProvider, useForm } from "react-hook-form";
// NOTE 組件
import { useAlert } from "./Alert";
import Button from "./Button";
import Text from "./Text";
import Grid from "./Grid";
import { FieldCache } from "./Form";

const attentionArray = [
  {
    label:
      "參與團體戰視同『有購買意願』此活動之商品，活動結束後可至會員中心查看是否中選，如有中選請於期限內繳費完成，如未繳費，未來將有可能無法繼續購買官方網站之商品，參加團體戰前請確認購買之意願。",
  },
  {
    label:
      "其他團體戰之規章請參考網站分頁：常見問題。此同意書為同意網站常見問題中之全部內容。",
  },
  {
    label: "參與團體戰活動如有變更或修改，以現場公佈為主。",
  },
];
const useStylesTable = makeStyles({
  dialogPaper: {
    maxHeight: `calc(90% - 16px)`,
    maxWidth: `calc(85% - 16px)`,
    width: `calc(100% - 16px)`,
    margin: 8,
  },
  Container: {
    display: "flex",
    justifyContent: "center",
    color: "red",
  },
  divider: {
    height: 30,
  },
  checked: {
    color: "#0072E3",
  },
});
export default memo(
  forwardRef(function TermsDialog({ handleEvent }, ref) {
    const Alert = useAlert();
    const classesTable = useStylesTable();
    const [open, setOpen] = useState(false);
    const form = useForm({
      defaultValues: {
        checked: false,
      },
    });
    const { reset } = form;
    const handleFn = useRef({
      handleEvent,
    });
    useEffect(() => {
      handleFn.current.handleEvent = handleEvent;
    }, [handleEvent]);
    const _close = useCallback(
      (checked) => {
        if (typeof checked === "boolean" && checked) {
          handleFn.current.handleEvent?.();
        }
        setOpen(false);
        setTimeout(() => {
          reset();
        }, 300);
      },
      [reset]
    );
    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          setOpen(true);
        },
        close: () => {
          _close();
        },
      }),
      [_close]
    );
    return (
      <FormProvider {...form}>
        <Dialog
          open={open}
          fullWidth
          maxWidth={false}
          style={{ marginBottom: "5em" }}
          classes={{ paper: classesTable.dialogPaper }}
        >
          <DialogTitle classes={{ root: classesTable.Container }}>
            團體戰的同意說明書
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={1}>
              {attentionArray.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex">
                    <Text type="h6">{index + 1}.&nbsp;&nbsp;</Text>
                    <Text type="h6">{item.label}</Text>
                  </Box>
                </Grid>
              ))}
              <Grid container item xs={12} justify="center">
                <Controller
                  name="checked"
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      className={classesTable.checked}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                          style={{
                            color: "#2894FF",
                          }}
                        />
                      }
                      label={"我已詳閱以上內容，並同意繼續。"}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="center">
              <Grid item>
                <FieldCache
                  name="checked"
                  render={(checked) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() => {
                        if (!checked) {
                          Alert.notice("請詳閱注意事項並勾選同意書即可繼續！");
                        } else {
                          _close(checked);
                        }
                      }}
                    >
                      同意
                    </Button>
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={() => _close()}
                >
                  取消
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </FormProvider>
    );
  })
);
