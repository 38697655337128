import { useCallback, useEffect, useMemo, memo, useRef } from "react";
// mui
import {
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  ButtonBase,
  ButtonGroup,
  Button,
  Container,
  CircularProgress,
} from "@material-ui/core";
// icon
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import { Controller, useFormContext, useWatch } from "react-hook-form";
// moment
import moment from "moment";

// components
import { useAlert } from "../../../component/Alert";
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import Image from "../../../component/Image";
import { FieldCache } from "../../../component/FieldCache";
import {
  OptionBuyDialog,
  BuyTicketSuccessDialog,
  RulesDialog,
  SuccessfullyExchangedEggsDialog,
} from "../../../component/special-event/Alerts";
import VideoPlayerDialog from "../../../component/special-event/VideoPlayerDialog";
import LoadingModal from "../../../component/LoadingModal";
import AdaptiveImage from "../../../component/AdaptiveImage";
import PageIsland from "./component/PageIsland";
import SwitchNavTabs from "../../../component/special-event/SwitchNavTabs";
import CopyrightRender from "./component/CopyrightRender";
// zustand
import useTokenStore from "../../../zustand/useTokenStore";
import useHatchingStore from "../../../zustand/useHatchingStore";
import useHatchingOrderIdStore from "../../../zustand/useHatchingOrderIdStore";

// SECTION apollo
// NOTE 購買券
const BUY_TICKETS = gql`
  mutation buyTickets($quantity: Int!) {
    buyTickets(quantity: $quantity) {
      # "成功"
      success
      # "訊息"
      message
      # "訂單"
      order {
        id
        # "訂單編號"
        serialNumber
        # "付款網址"
        paymentUrl
      }
    }
  }
`;
// NOTE 抽蛋
const DRAW_EGGS = gql`
  mutation drawEggs($quantity: Int!) {
    drawEggs(quantity: $quantity) {
      # "成功"
      success
      # "訊息"
      message
      # "蛋"
      memberEgg {
        id
        # "蛋種"
        eggType {
          id
          # "蛋gif"
          gifUrl
          # "抽蛋影片"
          drawLotsVideoUrl
        }
      }
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function SpecialEventPurchasePage() {
  return (
    <BackgroundContainer>
      <PageIsland />
      <PurChaseContent />
    </BackgroundContainer>
  );
}

export function useGetMarginTop() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const is900 = useMediaQuery("(max-width:900px)");
  const is800 = useMediaQuery("(max-width:800px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const is600 = useMediaQuery("(max-width:600px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const is400 = useMediaQuery("(max-width:400px)");
  const marginTop = useMemo(() => {
    if (is400) {
      return -180;
    } else if (isDownMobile) {
      return -180;
    } else if (is600) {
      return -250;
    } else if (isMobile) {
      return -300;
    } else if (is800) {
      return -350;
    } else if (is900) {
      return -350;
    } else if (isTable) {
      return -450;
    } else if (isDownPC) {
      return -450;
    } else if (isPC) {
      return -450;
    }
    return -450;
  }, [
    isPC,
    isDownPC,
    isTable,
    is900,
    is800,
    isMobile,
    is600,
    isDownMobile,
    is400,
  ]);
  return marginTop;
}

// ANCHOR 購買
function PurChaseContent() {
  const marginTop = useGetMarginTop();
  return (
    <Box style={{ marginTop }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BannerArea />
        </Grid>
        <Grid item xs={6} style={{ zIndex: 1 }}>
          <LuyaoTicketArea />
        </Grid>
        <Grid item xs={6} style={{ zIndex: 1 }}>
          <EggArea />
        </Grid>
      </Grid>
      <Box>
        <PurchaseArea />
      </Box>
      <CopyrightRender />
      <SwitchNavTabs />
    </Box>
  );
}

// ANCHOR 路遙券按鈕
const LuyaoTicketArea = memo(function LuyaoTicketArea() {
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="clickButton"
        render={(clickButton) => {
          if (clickButton === "egg") {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/crystal-palace/育成券未按.png"}
                onPress={() => {
                  if (clickButton !== "ticket") {
                    setValue("quantity", 1);
                    setValue("clickButton", "ticket");
                  }
                }}
              />
            );
          } else {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/crystal-palace/育成券按.png"}
                resizeMode="contain"
              />
            );
          }
        }}
      />
    </Box>
  );
});
// ANCHOR 路遙蛋按鈕
const EggArea = memo(function EggArea() {
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="clickButton"
        render={(clickButton) => {
          if (clickButton === "ticket") {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/crystal-palace/育成蛋未按.png"}
                onPress={() => {
                  if (clickButton !== "egg") {
                    setValue("selectDrawEgg", null);
                    setValue("clickButton", "egg");
                  }
                }}
              />
            );
          } else {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/crystal-palace/育成蛋按.png"}
                resizeMode="contain"
              />
            );
          }
        }}
      />
    </Box>
  );
});

// ANCHOR Banner
const BannerArea = memo(function BannerArea() {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: isDownMd ? "60%" : "50%",
        mx: "auto",
      }}
    >
      <AdaptiveImage ratio="37.93%">
        <FieldCache
          name="clickButton"
          render={(clickButton) => {
            if (clickButton === "ticket") {
              return (
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={"/img/special/crystal-palace/由蛋而生.png"}
                  resizeMode="contain"
                />
              );
            } else {
              return (
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={"/img/special/crystal-palace/孕育而生.png"}
                  resizeMode="contain"
                />
              );
            }
          }}
        />
      </AdaptiveImage>
    </Box>
  );
});

// ANCHOR 內容區域
const PurchaseArea = memo(function PurchaseArea() {
  const { notice } = useAlert();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // NOTE 這裡控制下方路遙卷數量
  const useStyles = makeStyles({
    areaContainer: {
      minHeight: "140px",
      height: "100%",
      position: "relative",
      backgroundColor: "rgb(246, 245, 241, 0.65)",
      marginBottom: "100px",
      padding: isDownMd ? "16px 16px 64px" : "16px 16px 32px",
      // border: "1px solid",
      // borderColor: "#fff",
      borderRadius: "999px",
      "& .MuiTypography-root": {
        color: "#fff",
        fontWeight: 900,
        fontSize: "32px",
      },
      boxShadow: "0 0 4px rgb(1, 155, 201, .35)",
    },

    purchaseButton: {
      position: "absolute",
      bottom: "-24px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "#fff",
      borderRadius: "2px",
      height: "44px",
      boxShadow: "0 0 4px rgb(1, 155, 201, .35)",
      transition: "box-shadow .2s",
      "& .MuiTypography-root": {
        color: "#0099c7",
        fontWeight: 900,
        fontSize: "20px",
      },
      "&:hover": {
        boxShadow: "0 0 12px rgba(0,0,0,.55)",
      },
    },
  });
  const classes = useStyles();

  const { setValue, getValues } = useFormContext();

  const optionBuyRef = useRef();
  const rulesRef = useRef();
  const successfullyExchangedEggsRef = useRef();
  const videoPlayerDialogRef = useRef();

  const [buyTicketsFn, { loading: buyTicketsLoading }] = useMutation(
    BUY_TICKETS,
    {
      onCompleted({ buyTickets: { message, order } }) {
        if (message) {
          notice(message);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
          } else {
            setValue("eventRefetch", true);
          }
        } else if (order) {
          setValue("jumpingToPayment", true);
          useHatchingStore
            .getState()
            .setDirectedToPayment(true)
            .then((result) => {
              if (result) {
                useHatchingOrderIdStore
                  .getState()
                  .setHatchingOrderId(order.id)
                  .then((result2) => {
                    if (result2) {
                      window.location = order.paymentUrl;
                    }
                  });
              }
            });
        }
      },
      onError() {
        setValue("eventRefetch", true);
        return null;
      },
    }
  );

  const [drawEggsFn, { loading: drawEggsLoading }] = useMutation(DRAW_EGGS, {
    onCompleted({ drawEggs: { message, memberEgg } }) {
      if (message) {
        notice(message);
        if (message.search("登入") > -1) {
          useTokenStore.getState().cleanToken();
        } else {
          setValue("eventRefetch", true);
        }
      } else {
        setValue("memberRefetch", true);
        setValue("memberEggsRefetch", true);
        setValue("getMemberEggs", memberEgg);
        successfullyExchangedEggsRef?.current?.open(memberEgg);
      }
    },
    onError() {
      setValue("eventRefetch", true);
      return null;
    },
  });

  const _optionBuyFn = useCallback(
    (quantity) => {
      buyTicketsFn({ variables: { quantity } });
    },
    [buyTicketsFn]
  );

  const _rulesFn = useCallback(
    (selectDrawEgg) => {
      if (selectDrawEgg?.ticket) {
        drawEggsFn({ variables: { quantity: selectDrawEgg.ticket } });
      }
    },
    [drawEggsFn]
  );
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  return (
    <Box className={classes.areaContainer}>
      <LoadingModal loading={buyTicketsLoading || drawEggsLoading} />
      <BuyTicketCallBack />
      <OptionBuyDialog ref={optionBuyRef} onSubmit={_optionBuyFn} />
      <RulesDialog ref={rulesRef} onSubmit={_rulesFn} />
      <SuccessfullyExchangedEggsDialog
        ref={successfullyExchangedEggsRef}
        onPlayVideo={(e) => {
          videoPlayerDialogRef?.current?.open(e);
        }}
        onClose={() => {
          setValue("getMemberEggs", []);
        }}
      />
      <VideoPlayerDialog
        ref={videoPlayerDialogRef}
        onBack={(e) => {
          const getMemberEggs = getValues("getMemberEggs");
          getMemberEggs.forEach((item) => {
            if (item.id === e.id) {
              item.disassembled = true;
            }
          });
          setValue("getMemberEggs", getMemberEggs);
          setTimeout(() => {
            successfullyExchangedEggsRef?.current?.open(getMemberEggs);
          }, 0);
        }}
      />
      <FieldCache
        name="clickButton"
        render={(clickButton) => {
          if (clickButton === "ticket") {
            return (
              <Box>
                <Container maxWidth="sm">
                  <DisplayMyTickets />
                  <CountAdjust />
                </Container>
              </Box>
            );
          } else {
            return (
              <Box>
                <ExchangeEgg />
              </Box>
            );
          }
        }}
      />

      {/* Button */}
      <FieldCache
        name={["clickButton", "jumpingToPayment", "selectDrawEgg", "event"]}
        render={(valueArray) => {
          const [clickButton, jumpingToPayment, selectDrawEgg, event] =
            valueArray;
          const eventIsClose = Boolean(
            event?.endTime && moment(event.endTime).isBefore()
          );
          if (clickButton === "ticket") {
            return (
              <ButtonBase
                className={classes.purchaseButton}
                onClick={() => optionBuyRef?.current?.open()}
                disabled={jumpingToPayment || !memberToken || eventIsClose}
                style={{
                  // backgroundColor:
                  //   (jumpingToPayment || !memberToken || eventIsClose) &&
                  //   "#d6e6e8",
                  width: jumpingToPayment ? "180px" : "120px",
                }}
              >
                {jumpingToPayment && (
                  <CircularProgress color="secondary" size="20px" />
                )}
                <Typography>
                  {jumpingToPayment ? "付款轉跳中..." : "購買"}
                </Typography>
              </ButtonBase>
            );
          } else {
            return (
              <ButtonBase
                className={classes.purchaseButton}
                onClick={() => rulesRef?.current?.open()}
                disabled={!selectDrawEgg || eventIsClose}
                style={{
                  width: "120px",
                }}
              >
                <Typography>兌換</Typography>
              </ButtonBase>
            );
          }
        }}
      />
    </Box>
  );
});
// ANCHOR 金流callBack動作
function BuyTicketCallBack() {
  const order = useWatch({ name: "order" });
  const buyTicketSuccessRef = useRef();
  useEffect(() => {
    if (order?.id) {
      buyTicketSuccessRef?.current?.open();
    }
  }, [order]);
  return (
    <>
      <BuyTicketSuccessDialog ref={buyTicketSuccessRef} />
    </>
  );
}
// ANCHOR 顯示擁有的路遙券
function DisplayMyTickets() {
  const myTickets = useWatch({ name: "myTickets" });
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  return (
    <Typography
      style={{
        fontSize: "18px",
        fontWeight: 900,
        textAlign: "center",
        color: "#0099c7",
      }}
      gutterBottom
    >
      {memberToken ? `目前擁有的路遙券：${myTickets}` : "請先登入才可購買"}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  countButton: {
    minWidth: 40,
    minHeight: 40,
    padding: theme.spacing(0.5),
    backgroundColor: "#fff",

    "&:hover": {
      backgroundColor: "#fff",
    },
    "& svg": {
      color: "#017a90",
    },
  },
}));
// ANCHOR 數量選擇
function CountAdjust() {
  const { control } = useFormContext();

  const classes = useStyles();
  const event = useWatch({ name: "event" });
  const limit = useMemo(
    () => ({
      maxNumberOfTicket: event?.maxNumberOfTicket || 999,
      maxNumberOfTicketPerPerson: event?.maxNumberOfTicketPerPerson || 999,
    }),
    [event]
  );
  return (
    <Controller
      control={control}
      name="quantity"
      render={({ field: { onChange, value } }) => (
        <ButtonGroup style={{ display: "flex", flex: 1 }}>
          <Button
            onClick={() => onChange(Math.max(1, value - 1))}
            className={classes.countButton}
          >
            <RemoveIcon />
          </Button>
          <Button style={{ flex: 1 }} className={classes.countButton}>
            {value}
          </Button>
          <Button
            onClick={() =>
              onChange(
                Math.max(
                  value,
                  Math.min(
                    value + 1,
                    Math.min(
                      limit.maxNumberOfTicket,
                      limit.maxNumberOfTicketPerPerson
                    )
                  )
                )
              )
            }
            className={classes.countButton}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
      )}
    />
  );
}
// ANCHOR 兌換路遙蛋區域
function ExchangeEgg() {
  const drawLotsPlans = useWatch({ name: "drawLotsPlans" });
  const selectDrawEgg = useWatch({ name: "selectDrawEgg" });
  const myTickets = useWatch({ name: "myTickets" });
  const _renderDrawEgg = useCallback(
    (item) => {
      const selected = item.id === selectDrawEgg?.id;
      const disabled = myTickets < item.ticket;
      return (
        <Grid item>
          <PriceCard {...item} selected={selected} disabled={disabled} />
        </Grid>
      );
    },
    [selectDrawEgg, myTickets]
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DisplayMyTickets />
      </Grid>
      {drawLotsPlans.map(_renderDrawEgg)}
    </Grid>
  );
}
// ANCHOR 路遙蛋選項按鈕
const PriceCard = memo(function PriceCard({
  id,
  times,
  ticket,
  selected = false,
  disabled = false,
}) {
  const { setValue } = useFormContext();
  const selectedColor = {
    backgroundColor: "#84A8B2",
    "& .MuiTypography-root.title": {
      color: "#fff",
    },
    "& .MuiTypography-root.subtitle": {
      fontSize: "18px",
      color: "#fff",
      fontWeight: 300,
    },
  };

  const useStyles = makeStyles({
    areaContainer: {
      width: "120px",
      height: "44px",
      borderRadius: "2px",
      backgroundColor: "#fff",
      padding: "4px 8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",

      cursor: !disabled && "pointer",
      "& .MuiTypography-root.title": {
        color: "#326379",
        fontWeight: 900,
        fontSize: "22px",
      },
      "& .MuiTypography-root.subtitle": {
        color: "#898989",
        fontSize: "18px",
        fontWeight: 300,
      },
      "&:hover": !disabled && selectedColor,
      //
      ...(selected ? selectedColor : {}),
    },
  });
  const classes = useStyles();
  return (
    <Box
      className={classes.areaContainer}
      onClick={() => {
        if (!disabled) {
          setValue("selectDrawEgg", { id, ticket, times });
        }
      }}
    >
      <Typography align="center" className="title">
        {ticket}抽
      </Typography>
      {times > 0 && (
        <Typography align="center" className="subtitle">
          送{times}抽
        </Typography>
      )}
    </Box>
  );
});
