import {
  memo,
  useEffect,
  useRef,
  useState,
  Fragment,
  createElement,
} from "react";
import {
  Box,
  Typography,
  makeStyles,
  LinearProgress,
  Divider,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { batch, useDispatch, useSelector } from "react-redux";
import "./index.css";

import {
  setLoadInitialValue,
  setRemainingTime,
  restRaidSetting,
  setStatus,
  setNumberOfPlayer,
  setEndTime,
  setStartTime,
  setCurrentHealthPoint,
  setScore,
  setJoined,
  setShowBoard,
} from "../../redux/raid";
import Qrcode from "./components/Qrcode";
import { useAlert } from "../../component/Alert";
// import useIosDevice from "../hooks/iosDevice";

//團體戰Query
const GET_EVENT_RAID = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      ... on RaidEvent {
        monster
        maxHealthPoint
        damagePerAttack
        quota
        joined
        players {
          id
        }
        startTime
        endTime
        status
      }
    }
  }
`;

// 團體戰遊戲中內容
const GET_RAID = gql`
  query raid($id: Int!) {
    raid(eventId: $id) {
      numberOfPlayer
      score
      status
      currentHealthPoint
    }
  }
`;

// 發動攻擊
const PLAYER_ATTACK = gql`
  mutation launchAttack($eventId: Int!, $attackPoint: Int!) {
    launchAttack(eventId: $eventId, attackPoint: $attackPoint) {
      success
      message
    }
  }
`;

const useTextStyles = makeStyles({
  serifText: {
    fontFamily: "Noto Serif TC",
  },
});

// ANCHOR 主要組件
export default function GameUI() {
  return (
    <>
      <FetchGameInitialValue />
      <GameLayout>
        <InformationBoard />
        <Box
          display="flex"
          flex={1}
          color="white"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <GameContentSwitch />
        </Box>
        <Typography variant="caption" color="inherit" align="center">
          @2022 Luyao Design Co.Ltd
        </Typography>
      </GameLayout>
    </>
  );
}
// ANCHOR 遊戲外框
function GameLayout({ children }) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    outside: {
      display: "flex",
      justifyContent: "center",
    },
    bg: {
      height: "100vh",
      width: "100%",
      display: "block",
      objectFit: "cover",
    },
    inside: {
      position: "relative",
      flex: 1,
      maxWidth: 600,
    },
    children: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      color: "white",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`,
      "& > *": {
        margin: `${theme.spacing(0.5)}px 0px`,
      },
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    return () => dispatch(restRaidSetting());
  }, [dispatch]);
  return (
    <Box className={`${classes.outside} game-area`}>
      <Box className={classes.inside}>
        <Box
          component="img"
          src={require("./assets/background.jpg").default}
          className={classes.bg}
        />
        <Box className={classes.children}>{children}</Box>
      </Box>
    </Box>
  );
}
// ANCHOR 資訊板
function InformationBoard() {
  const showBoard = useSelector((state) => state.raid.showBoard);
  if (showBoard) {
    return (
      <>
        <StatusBoard />
        {/* <ProgressBar /> */}
      </>
    );
  } else {
    return null;
  }
}
// ANCHOR 狀態板
function StatusBoard() {
  // const currentHealthPoint = useSelector(
  //   (state) => state.raid.currentHealthPoint
  // );
  const score = useSelector((state) => state.raid.score);
  // const remainingTime = useSelector((state) => state.raid.remainingTime);
  const boardData = [
    // { label: "HP", value: currentHealthPoint },
    // { label: "TIME", value: `${remainingTime}s` },
    { label: "SCORE", value: String(score).padStart(5, "0") },
  ];
  return (
    <Box display="flex" width="100%">
      {boardData.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Separate orientation="vertical" />}
          <Box flex={1} flexDirection="column">
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {item.label}
            </Typography>
            <Separate variant="middle" />
            <Typography variant="h6" align="center">
              {item.value}
            </Typography>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
}
// ANCHOR 進度條
function ProgressBar() {
  const maxHealthPoint = useSelector((state) => state.raid.maxHealthPoint);
  const currentHealthPoint = useSelector(
    (state) => state.raid.currentHealthPoint
  );
  const useStyles = makeStyles({
    bar: {
      height: 12,
      border: `1px solid white`,
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "white",
      },
    },
  });
  const classes = useStyles();
  return (
    <LinearProgress
      className={classes.bar}
      variant="determinate"
      color="primary"
      value={creatProgress(currentHealthPoint, maxHealthPoint)}
    />
  );
}
// ANCHOR 獲得遊戲內容
function FetchGameInitialValue() {
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  // NOTE 只抓第一次當預設值
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          dispatch(
            setLoadInitialValue({
              joined: event.joined,
              status: event.status,
              maxHealthPoint: event.maxHealthPoint,
              numberOfPlayer: event.players ? event.players.length : 0,
              damagePerAttack: event.damagePerAttack,
              startTime: event.startTime,
              endTime: event.endTime,
              remainingTime: convertRemaining(event.endTime, event.startTime),
            })
          );
        }, 0);
      }
    },
    onError(error) {
      // Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      dispatch(
        setLoadInitialValue({
          status: undefined,
        })
      );
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
    }
  }, [id, getEvent]);
  useEventEffect();
  useRaidEffect();
  useRemainingTimeEffect();
  return null;
}
// ANCHOR 遊戲內容切換
const GameContentSwitch = memo(() => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.raid.status);
  const loaded = useSelector((state) => state.raid.loaded);
  const joined = useSelector((state) => state.raid.joined);
  // const currentHealthPoint = useSelector(
  //   (state) => state.raid.currentHealthPoint
  // );
  if (loaded) {
    if (typeof status === "undefined") {
      return <Typography variant="h5">找不到團體戰！</Typography>;
    }
    if (joined) {
      // if (currentHealthPoint <= 0) {
      //   return <ResultContent result="VICTORY" />;
      // }
      switch (status) {
        case "READY_TO_START":
          // 開始前
          return <PrepareScenes />;
        case "DURING_THE_GAME":
          // 進行中
          dispatch(setShowBoard(true));
          return <AttackScenes />;
        case "VICTORY":
          // 勝利
          dispatch(setShowBoard(true));
          return <ResultContent result="VICTORY" />;
        case "DEFEAT":
          // 失敗
          dispatch(setShowBoard(true));
          return <ResultContent result="DEFEAT" />;
        default:
          return <CircularProgress color="inherit" />;
      }
    } else {
      return <Typography variant="h5">請先加入團體戰！</Typography>;
    }
  } else {
    return <CircularProgress color="inherit" />;
  }
});
// ANCHOR 結果畫面
function ResultContent({ result = "DEFEAT" }) {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(1),
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  const textClasses = useTextStyles();
  const defeatText = [
    "｜請再接再厲！｜",
    "感謝所有馬力參與這次的團體戰，可惜沒有挑戰成功，下次再戰吧！",
  ];
  const victoryText = [
    "｜恭喜獲得抽選資格！｜",
    "稍後請至\n「會員中心」>「待付款訂單」\n查看抽選結果",
    "中選者商品將直接匯入，務必於時間內完成繳款，未中選者一律不另行通知。",
  ];
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        {(result === "DEFEAT" ? defeatText : victoryText).map((i, index) => (
          <Fragment key={index}>
            {index === 0 ? (
              <>
                <ShadowBox>
                  <Typography variant="h3" className={textClasses.serifText}>
                    {result}
                  </Typography>
                </ShadowBox>
                <Box marginBottom={3}>
                  <Typography
                    align="center"
                    variant="h6"
                    className={textClasses.serifText}
                  >
                    {i}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                paragraph
                className={textClasses.serifText}
              >
                {i}
              </Typography>
            )}
          </Fragment>
        ))}
        {createElement(
          Button,
          {
            variant: "contained",
            color: "default",
            size: "large",
            ...(result === "DEFEAT"
              ? { onClick: () => history.replace("/") }
              : { onClick: () => history.replace("/member") }),
          },
          result === "DEFEAT" ? "返回首頁" : "返回會員中心"
        )}
      </Box>
      <Grid container spacing={1} justifyContent="center">
        <Grid item container justifyContent="center">
          <img
            src={require("./assets/title.png").default}
            style={{ width: "75%" }}
            alt=""
          />
        </Grid>
        <TotalPeople />
      </Grid>
    </Box>
  );
}
// ANCHOR 影子盒
function ShadowBox({ children }) {
  const useStyles = makeStyles({
    shadow: {
      position: "absolute",
      bottom: 4,
      right: 0,
      left: 0,
      background: `linear-gradient(to right, transparent, rgba(255, 255, 255), transparent)`,
      filter: `blur(1px)`,
      height: 4,
      borderRadius: "50%",
    },
  });
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.shadow} />
      {children}
    </Box>
  );
}
// ANCHOR 分隔線
function Separate(props) {
  const useStyles = makeStyles({
    hr: {
      paddingTop: 1,
      background: `linear-gradient(to ${
        props.orientation === "vertical" ? "bottom" : "right"
      }, transparent, white, transparent)`,
      filter: `blur(0.5px)`,
    },
  });
  const classes = useStyles();
  return <Divider className={classes.hr} flexItem {...props} />;
}
// ANCHOR 分隔線箱子
function SeparateBox({ children, ...props }) {
  return (
    <Box {...props}>
      <Separate />
      <Box
        marginY={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
      <Separate />
    </Box>
  );
}

// ANCHOR 開始前畫面
function PrepareScenes() {
  const timingRef = useRef(null);
  const minute = 60;
  const startTime = useSelector((state) => state.raid.startTime);
  const endTime = useSelector((state) => state.raid.endTime);
  const [startTimeSubtract, setStartTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (startTime) {
        setStartTimeSubtract(
          Math.max(
            moment(startTime)
              .tz("Asia/Taipei")
              .diff(moment().tz("Asia/Taipei"), "seconds")
          )
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [startTime, endTime]);

  // NOTE 轉圈圈
  if (startTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  }
  // NOTE 開始前10分鐘
  if (startTimeSubtract >= minute * 10) {
    return <Typography variant="h5">團體戰尚未開始！</Typography>;
  }

  // NOTE 開始倒數10分鐘且大於10秒
  if (startTimeSubtract < minute * 10 && startTimeSubtract > 10) {
    return <BeforeRaid />;
  }

  //NOTE 倒數前10秒
  if (startTimeSubtract <= 10) {
    return <PrepareText />;
  }
}

// ANCHOR 團體賽開始前
function BeforeRaid() {
  const textClasses = useTextStyles();
  const startTime = useSelector((state) => state.raid.startTime);
  return (
    <Box padding={1}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item container justifyContent="center">
          <img
            src={require("./assets/title.png").default}
            style={{ width: "75%" }}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            className={textClasses.serifText}
          >
            遊戲開始
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SeparateBox width="100%">
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {moment(startTime).tz("Asia/Taipei").format("HH：mm")}
            </Typography>
          </SeparateBox>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={textClasses.serifText}>
            抽選團體戰活動即將開跑，
          </Typography>
          <Typography align="center" className={textClasses.serifText}>
            請以打倒遊戲角色為目標。
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            variant="caption"
            align="center"
            className={textClasses.serifText}
          >
            歡迎分享QRcode邀請朋友來參戰！
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Qrcode size={96} />
        </Grid>
        <Grid item>
          <img
            src={require("./assets/sword.png").default}
            style={{ width: 72 }}
            alt=""
          />
        </Grid>
        <TotalPeople />
      </Grid>
    </Box>
  );
}
// ANCHOR 參加人數
const TotalPeople = memo(() => {
  const numberOfPlayer = useSelector((state) => state.raid.numberOfPlayer);
  const labelArray = String(numberOfPlayer).padStart(4, "0").split("");
  return (
    <>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h6"
          style={{ lineHeight: 1, fontFamily: "Noto Serif TC" }}
        >
          目前參戰人數
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Box padding={1}>
          <Grid container spacing={1} wrap="nowrap">
            {labelArray.map((i, index) => (
              <Grid item key={`${i}-${index}`}>
                <Typography
                  variant="h3"
                  style={{ lineHeight: 1, textDecoration: "underline" }}
                >
                  {i}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  );
});
// ANCHOR 攻擊畫面
function AttackScenes() {
  const attack = useAttack();
  return (
    <Box display="flex" flex={1} flexDirection="column" padding={1}>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <AttackButton onClick={attack} />
        <Typography variant="h6" align="center">
          請點擊中間attack按鈕進行攻擊
        </Typography>
      </Box>
      <Grid container spacing={1} justifyContent="center">
        <Grid item container justifyContent="center">
          <img
            src={require("./assets/title.png").default}
            style={{ width: "75%" }}
            alt=""
          />
        </Grid>
        <TotalPeople />
      </Grid>
    </Box>
  );
}
// ANCHOR 攻擊按鈕
function AttackButton(props) {
  const useStyles = makeStyles({
    buttonBackground: {
      width: 128,
      height: 128,
    },
    button: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: 128,
      height: 128,
      opacity: 0,
      "&:active": {
        opacity: 1,
      },
    },
  });
  const classes = useStyles();
  return (
    <Box {...props} position="relative">
      <Box
        component="img"
        src={require("./assets/attack.png").default}
        className={classes.buttonBackground}
      />
      <Box
        component="img"
        src={require("./assets/attack_active.png").default}
        className={classes.button}
      />
    </Box>
  );
}
// ANCHOR 即將開始文字
const PrepareText = memo(() => {
  const useStyles = makeStyles((theme) => ({
    prepareText: {
      fontWeight: "bold",
      animation: `$prepare 250ms ${theme.transitions.duration.standard}`,
    },
    "@keyframes prepare": {
      "0%": { color: "white" },
      "100%": { color: "red" },
    },
  }));
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.prepareText}>
      對戰即將開始
    </Typography>
  );
});

// SECTION 資料處理相關
// ANCHOR 建立進度條
/**
 * @param {number} molecular 分子
 * @param {number} denominator 分母
 * @returns {number}
 */
function creatProgress(molecular, denominator = 100) {
  return Math.floor((molecular / denominator) * 100);
}
// ANCHOR 自動刷新遊戲資料
function useEventEffect() {
  const params = useParams();
  const id = Number(params?.id);
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.raid.loaded);
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          batch(() => {
            dispatch(setStatus(event.status));
            dispatch(
              setNumberOfPlayer(event.players ? event.players.length : 0)
            );
            dispatch(setStartTime(event.startTime));
            dispatch(setEndTime(event.endTime));
            dispatch(setJoined(event.joined));
          });
        }, 0);
      }
    },
    // onError(error) {
    //   Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    // },
  });
  useEffect(() => {
    if (loaded) {
      if (id) {
        getEvent({ variables: { id } });
      }
    }
  }, [id, loaded, getEvent]);
}
// ANCHOR 取得怪物資料
function useRaidEffect() {
  const dispatch = useDispatch();
  const { id } = useParams();
  useQuery(GET_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    variables: {
      id: Number(id),
    },
    onCompleted({ raid }) {
      if (raid) {
        setTimeout(() => {
          batch(() => {
            dispatch(setCurrentHealthPoint(raid.currentHealthPoint));
            dispatch(setScore(raid.score));
          });
        }, 0);
      }
    },
  });
}
// ANCHOR 取得遊戲剩餘時間
function useRemainingTimeEffect() {
  const dispatch = useDispatch();
  const endTime = useSelector((state) => state.raid.endTime);
  const status = useSelector((state) => state.raid.status);
  const currentHealthPoint = useSelector(
    (state) => state.raid.currentHealthPoint
  );
  const timerRef = useRef(null);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (currentHealthPoint > 0) {
        if (endTime && status === "DURING_THE_GAME") {
          // NOTE 血量變0時將剩餘秒數歸0
          dispatch(setRemainingTime(convertRemaining(endTime, undefined)));
        }
      } else {
        dispatch(setRemainingTime(0));
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [endTime, status, currentHealthPoint, dispatch]);
}
// ANCHOR 換算遊戲剩餘時間
function convertRemaining(endTime, startTime) {
  return Math.max(
    Math.min(
      moment(endTime)
        .tz("Asia/Taipei")
        .diff(moment(startTime).tz("Asia/Taipei"), "seconds"),
      30
    ),
    0
  );
}
// ANCHOR 攻擊事件
/**
 * @param {number} sendInterval 送出間隔幾秒
 * @param {number} maxAttackTimes 每秒攻擊次數上限
 */
function useAttack(sendInterval = 2, maxAttackTimes = 8) {
  const Alert = useAlert();
  const history = useHistory();
  const seconds = 1000;
  const timerRef = useRef(null);
  const params = useParams();
  const id = Number(params?.id);
  const countRef = useRef(0);
  const damagePerAttack = useSelector((state) => state.raid.damagePerAttack);
  const [attackMonster] = useMutation(PLAYER_ATTACK, {
    onCompleted({ launchAttack }) {
      if (launchAttack.message) {
        if (launchAttack.message.search("登入") > -1) {
          // return Alert.alert("", launchAttack.message, [
          //   {
          //     text: "回首頁",
          //     onPress: () => history.replace("/"),
          //     type: "ok",
          //   },
          //   {
          //     text: "登入",
          //     onPress: () => history.replace("/login"),
          //     type: "cancel",
          //   },
          // ]);
        } else {
          // return Alert.notice(launchAttack.message);
        }
      }
    },
  });
  useEffect(() => {
    if (id && damagePerAttack) {
      timerRef.current = setInterval(() => {
        if (countRef.current > 0) {
          const maxCount = Math.min(
            countRef.current,
            sendInterval * maxAttackTimes
          );
          attackMonster({
            variables: {
              eventId: id,
              attackPoint: maxCount * damagePerAttack,
            },
          });
          countRef.current = countRef.current - Math.max(0, maxCount);
        }
      }, sendInterval * seconds);
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [sendInterval, maxAttackTimes, damagePerAttack, id, attackMonster]);
  function attack() {
    countRef.current = countRef.current + 1;
  }
  return attack;
}
// !SECTION
