import React, { Fragment, useEffect, useRef } from "react";
// material
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

// utils
import shuffle from "../../../../utils/shuffle";
import Image from "../../../../component/Image";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

// NOTE 產生陣列裡面是數字1-100
const randomNumberArray = Array.from({ length: 100 }).map((_, index) => index);
export default function RandomToy({ data = [] }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const useStyles = makeStyles({
    randomPanel: {
      // paddingRight: "60px",
      height: "100%",
      width: "100%",
      position: "relative",
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
    },
  });
  const classes = useStyles();
  const randomImgRef = useRef();

  useEffect(() => {
    if (data.length > 0) {
      let toyImages = document.getElementsByClassName("toyImage");
      const randomBox = document.getElementById("randomBox");
      Object.values(toyImages).forEach((toyImage) => {
        const reducePercent = isMobile ? 65 : 65;
        const reduceTop = isMobile ? 65 : 100;
        let positionLeftOrRightRandom = getRandomInt(
          randomBox?.offsetWidth - reducePercent || 0
        );
        let positionTopOrBottomRandom = getRandomInt(
          randomBox?.offsetHeight - reduceTop || 0
        );
        toyImage.setAttribute(
          "style",
          `position: absolute;
          width: ${isMobile ? "55px" : `78px`};
          height: ${isMobile ? "55px" : `78px`};
          left: ${positionLeftOrRightRandom}px;
          top: ${positionTopOrBottomRandom}px;
          transition: all 1s ease-in;`
        );
        toyImage.setAttribute("opacity", 1);
      });

      randomImgRef.current = setInterval(() => {
        let toyImages = document.getElementsByClassName("toyImage");
        const randomBox = document.getElementById("randomBox");
        Object.values(toyImages).forEach((toyImage) => {
          const reducePercent = isMobile ? 65 : 65;
          const reduceTop = isMobile ? 65 : 100;
          let positionLeftOrRightRandom = getRandomInt(
            randomBox?.offsetWidth - reducePercent || 0
          );
          let positionTopOrBottomRandom = getRandomInt(
            randomBox?.offsetHeight - reduceTop || 0
          );
          let transitionSecond = shuffle([3, 4, 5, 3, 4, 5])[0];
          const whetherToMove = shuffle(randomNumberArray)[0] > 20;
          if (whetherToMove) {
            toyImage.setAttribute(
              "style",
              `position: absolute;
              width: ${isMobile ? "55px" : `78px`};
              height: ${isMobile ? "55px" : `78px`};
              left: ${positionLeftOrRightRandom}px;
              top: ${positionTopOrBottomRandom}px;
              transition: all ${transitionSecond}s ease-in;`
            );
          }
        });
      }, 8000);
    }
    return () => {
      if (randomImgRef.current) {
        clearInterval(randomImgRef.current);
      }
    };
  }, [data, isMobile]);

  if (data.length > 0) {
    return (
      <Box id="randomBox" className={classes.randomPanel}>
        {data.map((item) => (
          <Fragment key={item.id}>
            <Image
              alt=""
              className={"toyImage game-area"}
              style={{
                opacity: 0,
              }}
              src={item.toy.gifUrl}
              resizeMode="contain"
            />
          </Fragment>
        ))}
      </Box>
    );
  } else {
    return null;
  }
}
