import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, CircularProgress, Typography } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useAlert } from "../component/Alert";
import TabView from "../component/TabView";
import ConsoleProductTable from "./component/ConsoleProductTable";
import ConsoleProductForm from "./component/ConsoleProductForm";
import LoadingModal from "../component/LoadingModal";
import { useLocalStorage } from "../component/LocalStorageContext";
import { Select } from "../component/Form";
import { CardContent } from "../component/Card";

const GET_PRODUCTS = gql`
  query products($latest: Boolean, $pageSize: Int, $page: Int) {
    products(latest: $latest, pageSize: $pageSize, page: $page) {
      pageCount
      productCount
      products {
        id
        images {
          filename
          mimetype
          encoding
          location
        }
        preorder
        name
        size
        material
        limit
        stock
        price
        startTime
        endTime
        details {
          type
          body
        }
        memberTierLimit
        createdBy {
          id
          fullName
        }
        updatedBy {
          id
          fullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_PRODUCTS_ALL = gql`
  query products {
    products {
      products {
        id
        name
      }
    }
  }
`;

const HIDE_PRODUCT = gql`
  mutation hideProduct($id: Int!) {
    hideProduct(id: $id) {
      success
      message
    }
  }
`;

export default function ProductPage() {
  const Alert = useAlert();
  const LocalStorage = useLocalStorage();
  const [tabViewData, setTabViewData] = useState(["列表", "新增"]);
  const [select, setSelect] = useState(0);
  const [dataTestIndex, setDataTestIndex] = useState(undefined);
  const [simulationQuery, setSimulationQuery] = useState(undefined);
  const [page, setPage] = useState(
    Boolean(LocalStorage.getSearchProductPage())
      ? JSON.parse(LocalStorage.getSearchProductPage())
      : 1
  );
  const [pageSize, setPageSize] = useState(
    Boolean(LocalStorage.getSearchProductPageSize())
      ? JSON.parse(LocalStorage.getSearchProductPageSize())
      : 10
  );
  const [pageMax, setPageMax] = useState(null);
  const [pageArray, setPageArray] = useState([]);
  const renderFirst = useRef(false);
  useEffect(() => {
    if (renderFirst.current && typeof dataTestIndex === "number") {
      setTabViewData((e) => [...e, "編輯"]);
      setSelect(2);
    }
    renderFirst.current = true;
  }, [dataTestIndex]);
  useEffect(() => {
    if (renderFirst.current && select !== 2) {
      tabViewData.splice(2, 1);
      setSimulationQuery(undefined);
      setDataTestIndex(undefined);
    }
  }, [select, tabViewData]);
  const { data, refetch, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
      pageSize,
      page,
    },
    onCompleted({ products }) {
      setTimeout(() => {
        setPageMax(products.pageCount);
      }, 0);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);
  useEffect(() => {
    if (data) {
      if (!data.products.products[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  const { data: productsAllData, loading: productsAllLoading } = useQuery(
    GET_PRODUCTS_ALL,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  function _delete(value) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        onPress: () => hideProduct({ variables: { id: Number(value.id) } }),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }

  const [hideProduct, { loading: hideProductLoading }] = useMutation(
    HIDE_PRODUCT,
    {
      onCompleted({ hideProduct }) {
        if (hideProduct.success) {
          return Alert.alert("", "刪除成功！", [
            {
              text: "確定",
              onPress: refetch,
              type: "ok",
            },
          ]);
        } else {
          return Alert.alert("", "刪除失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  if (loading || productsAllLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <>
        <LoadingModal loading={hideProductLoading} />
        <CardContent>
          <Typography>
            商品數量：{data && data.products.productCount}
          </Typography>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Select
                label={"每頁顯示"}
                items={[
                  { label: "10", value: 10 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                value={pageSize}
                onChange={(value) => {
                  LocalStorage.clearSearchProductPage();
                  setPageSize(() => {
                    LocalStorage.setSearchProductPageSize(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <TabView
          paper
          tabs={tabViewData}
          index={select}
          onChange={(e) => {
            if (select === 2) {
              Alert.alert("", "確定離開編輯？", [
                {
                  text: "確定",
                  onPress: () => {
                    setSelect(e);
                  },
                  type: "ok",
                },
                { text: "取消", type: "cancel" },
              ]);
            } else {
              setSelect(e);
            }
          }}
        >
          <ConsoleProductTable
            dataTest={data ? data.products.products : []}
            onChangeSelect={(index, item) => {
              setSimulationQuery(item);
              setDataTestIndex(index);
            }}
            pageArray={pageArray}
            page={page}
            onChangePage={(e) =>
              setPage(() => {
                LocalStorage.setSearchMemberPage(`${e}`);
                return e;
              })
            }
            _delete={_delete}
            onRefetch={refetch}
          />
          <ConsoleProductForm
            onGoBackList={() => {
              setSelect(0);
              refetch();
            }}
          />
          <ConsoleProductForm
            data={simulationQuery}
            onGoBackList={() => {
              setSelect(0);
              refetch();
            }}
            productsAllData={
              productsAllData &&
              productsAllData.products.products.map((item) => ({
                label: item.name,
                value: item.id,
              }))
            }
          />
        </TabView>
      </>
    );
  }
}
