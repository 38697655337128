import { produce } from "immer";
import create from "zustand";
import { persist } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} avatars - avatars
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  avatars: [],
};
/**
 * @typedef ChangeStateType
 * @property {() => void} writeAvatar - 寫入avatar
 * @property {() => void} clearAvatar - 清除avatar
 * @property {() => void} clearAll - 清除全部
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  writeAvatar: (avatar) => {
    return new Promise((resolve, reject) => {
      if (typeof avatar === "object" && avatar) {
        const avatars = get().avatars;
        const newAvatars = produce(avatars, (drafts) => {
          const avatarIndex = drafts.findIndex((i) => i.id === avatar.id);
          if (avatarIndex > -1) {
            drafts[avatarIndex].token = avatar.token;
          } else {
            drafts.push(avatar);
          }
        });
        set({ avatars: newAvatars });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  clearAvatar: (avatar) => {
    return new Promise((resolve, reject) => {
      if (typeof avatar === "object" && avatar) {
        const avatars = get().avatars;
        const newAvatars = avatars.filter((i) => i.id !== avatar.id);
        set({ avatars: newAvatars });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  clearAll: () => {
    return new Promise((resolve) => {
      set({ avatars: [] });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useAvatarsStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@luyaoAvatars",
    }
  )
);
export default useAvatarsStore;
