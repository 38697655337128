import React from "react";
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";

export default function TitleBox({ title, children, titleAdornment }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    divider: {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex">
          <Box
            width={8}
            height={36}
            marginRight={1}
            bgcolor={theme.palette.primary.main}
          />
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box>{titleAdornment}</Box>
      </Box>
      <Divider className={classes.divider} />
      <Box margin={`${theme.spacing(1)}px 0`}>{children}</Box>
      <Divider className={classes.divider} />
    </Box>
  );
}
