import React from "react";
// mui
import { Box } from "@material-ui/core";

export default function AdaptiveImage({
  ratio = "56.25%",
  width = "100%",
  children,
}) {
  return (
    <Box
      style={{
        position: "relative",
        height: 0,
        width,
        overflow: "hidden",
        paddingTop: ratio,
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
