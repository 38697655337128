import { useCallback, useRef, useState } from "react";
// mui
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import { Controller, useFormContext } from "react-hook-form";

// icon
import { UploadIcon } from "./CustomIcons";
import CircularStatic from "./CircularStatic";
// component
import { useAlert } from "./Alert";
// utils
import fetch from "../utils/fetch";

// SECTION
// NOTE 上傳圖片
const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      # "名稱"
      filename
      # "類型"
      mimetype
      # "編碼"
      encoding
      # "位置"
      location
    }
  }
`;
// NOTE 上傳影片
const UPLOAD_VIDEO = gql`
  mutation uploadVideo {
    uploadVideo {
      # "上傳網址"
      uploadUrl
      # "影片網址"
      videoUrl
    }
  }
`;
// !SECTION

// NOTE CustomUploadFiles
export default function CustomUploadFiles({
  name = "",
  onChange,
  accept,
  render,
  disabled = false,
  uploadIconStyle,
  type = "image",
}) {
  const { notice } = useAlert();
  const { setValue } = useFormContext();
  const uploadInputName = name + "uploadInput";
  const [progress, setProgress] = useState(0);
  const useStyles = makeStyles({
    outerLayer: {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    uploadFile: {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": !disabled && {
        cursor: "pointer",
      },
    },
  });
  const classes = useStyles();

  const onProgress = useCallback(({ loaded, total }) => {
    setProgress(parseFloat((loaded / total) * 100).toFixed(2));
  }, []);
  const onVideoProgress = useCallback((loaded, total) => {
    setProgress(parseFloat((loaded / total) * 100).toFixed(2));
  }, []);

  const numberOfAttemptsRef = useRef(1);
  const uploadFileValueRef = useRef();
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [uploadImageFn] = useMutation(UPLOAD_IMAGE, {
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress,
        // onAbortPossible: abortHandler => {
        //   abortHandlerRef.current = abortHandler;
        // },
      },
    },
    onError(error) {
      console.log("error", error.message);
      numberOfAttemptsRef.current = numberOfAttemptsRef.current += 1;
      if (numberOfAttemptsRef.current <= 3) {
        _uploadFile(null, [uploadFileValueRef.current]);
      } else {
        notice("發生錯誤");
        numberOfAttemptsRef.current = 1;
        uploadFileValueRef.current = null;
        setUploadImageLoading(false);
      }
      return null;
    },
  });
  const oldS3LocationRef = useRef();
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const [uploadVideoApolloFn] = useMutation(UPLOAD_VIDEO, {
    onError() {
      numberOfAttemptsRef.current = 1;
      uploadFileValueRef.current = null;
      oldS3LocationRef.current = null;
      setUploadVideoLoading(false);
      return null;
    },
  });
  const _uploadVideo = useCallback(
    (uploadVideo, videoData) => {
      fetch(
        uploadVideo.uploadUrl,
        {
          method: "PUT",
          headers: { "Content-Type": "video/mp4" },
          body: videoData,
        },
        onVideoProgress
      )
        .then((res2) => {
          if (res2.status === 200) {
            numberOfAttemptsRef.current = 1;
            uploadFileValueRef.current = null;
            oldS3LocationRef.current = null;
            const { videoUrl } = uploadVideo;
            if (onChange) {
              onChange({ location: videoUrl });
            } else {
              setValue(name, { location: videoUrl });
            }
            setUploadVideoLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error.message);
          numberOfAttemptsRef.current = numberOfAttemptsRef.current += 1;
          if (numberOfAttemptsRef.current <= 3) {
            _uploadFile(
              null,
              [uploadFileValueRef.current],
              oldS3LocationRef.current
            );
          } else {
            notice("發生錯誤");
            numberOfAttemptsRef.current = 1;
            uploadFileValueRef.current = null;
            oldS3LocationRef.current = null;
            setUploadVideoLoading(false);
          }
          return null;
        });
    },
    [setValue, onChange, name, notice, onVideoProgress]
  );
  const _uploadFile = useCallback(
    async (e, params, oldS3Location) => {
      for (const iterator of params) {
        try {
          uploadFileValueRef.current = iterator;
          if (type === "image") {
            setUploadImageLoading(true);
            const { data } = await uploadImageFn({
              variables: { image: iterator },
            });
            if (data?.uploadImage) {
              numberOfAttemptsRef.current = 1;
              uploadFileValueRef.current = null;
              const { __typename, ...otherItems } = data.uploadImage;
              if (onChange) {
                onChange(otherItems);
              } else {
                setValue(name, otherItems);
              }
              setUploadImageLoading(false);
            } else {
              setUploadImageLoading(false);
            }
          } else {
            if (oldS3Location) {
              _uploadVideo(oldS3Location, iterator);
            } else {
              const {
                data: { uploadVideo },
              } = await uploadVideoApolloFn();
              if (uploadVideo) {
                setUploadVideoLoading(true);
                oldS3LocationRef.current = uploadVideo;
                _uploadVideo(uploadVideo, iterator);
              } else {
                setUploadVideoLoading(false);
              }
            }
          }
        } catch (error) {
          console.log("catchError", error);
        }
      }
      if (e?.target?.value) {
        e.target.value = "";
      }
    },
    [uploadImageFn, setValue, onChange, uploadVideoApolloFn, type, name]
  );
  return (
    <>
      <Controller
        key={name}
        name={name}
        render={({ field }) => {
          if (
            (uploadImageLoading || uploadVideoLoading) &&
            progress &&
            progress < 100
          ) {
            return (
              <Box className={classes.outerLayer}>
                <CircularStatic progress={progress} />
              </Box>
            );
          } else if (uploadImageLoading || uploadVideoLoading) {
            return (
              <Box className={classes.outerLayer}>
                <CircularProgress />
              </Box>
            );
          } else if (field.value) {
            if (render) {
              return (
                <Box
                  className={classes.uploadFile}
                  onClick={() => {
                    if (!disabled) {
                      const uploadInput =
                        document.getElementById(uploadInputName);
                      uploadInput.click();
                    }
                  }}
                >
                  {render(field.value)}
                </Box>
              );
            } else {
              return (
                <>
                  <></>
                </>
              );
            }
          } else {
            return (
              <>
                <Box
                  className={classes.uploadFile}
                  onClick={() => {
                    if (!disabled) {
                      const uploadInput =
                        document.getElementById(uploadInputName);
                      uploadInput.click();
                    }
                  }}
                  style={{ ...uploadIconStyle }}
                >
                  <UploadIcon disabled={true} />
                </Box>
              </>
            );
          }
        }}
      />
      <input
        type="file"
        accept={accept ? accept : "image/*"}
        id={uploadInputName}
        style={{ display: "none" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const uploadFiles = e.target.files;
          const uploadArray = [];
          for (const key in uploadFiles) {
            if (Number(key) || Number(key) === 0) {
              const eachFile = uploadFiles[key];
              uploadArray.push(eachFile);
            }
          }
          if (uploadArray.length) {
            // 上傳後的file array
            _uploadFile(e, uploadArray);
          }
        }}
      />
    </>
  );
}
