export default function intlNumberFormat(price, needSymbol = false) {
  const newPrice =
    typeof price === "number"
      ? price
      : typeof price === "string" && !isNaN(price)
      ? Number(price)
      : null;
  const variables = {
    currency: "TWD",
    // currencyDisplay: "code",
  };
  if (needSymbol) {
    variables.style = "currency";
  }
  return new Intl.NumberFormat("zh-TW", variables).format(newPrice);
}
