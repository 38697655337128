import create from "zustand";
import { persist } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} memberId - memberId
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  memberId: null,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} setMemberId - 設定setMemberId
 * @property {() => void} cleanMemberId - 清除cleanMemberId
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  setMemberId: (memberId) => {
    return new Promise((resolve, reject) => {
      if (memberId && typeof memberId === "number") {
        set({ memberId });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  cleanMemberId: () => {
    return new Promise((resolve) => {
      set({ memberId: null });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useMemberIdStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@luyaoMemberId",
    }
  )
);
export default useMemberIdStore;
