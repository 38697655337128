import { createSlice } from "@reduxjs/toolkit";

// ANCHOR 預設值
const initialState = {
  // "會員級別參加限制"
  memberTierLimit: undefined,
};
// ANCHOR 主要組件
export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    writeMemberAllValue: (state, action) => {
      const object = action.payload;
      for (const key in object) {
        state[key] = object[key];
      }
    },
    setMemberAllValue: (state, action) => {
      const object = action.payload;
      for (const key in object) {
        state[key] = object[key];
      }
      saveLocalStorage(state);
    },
    cleanMemberTierLimit: (state) => {
      state.memberTierLimit = initialState["memberTierLimit"];
      saveLocalStorage(state);
    },
  },
});

export const { writeMemberAllValue, setMemberAllValue, cleanMemberTierLimit } =
  memberSlice.actions;

export const memberSliceState = (state) => state.member;

export default memberSlice.reducer;

// ANCHOR 存入本地
function saveLocalStorage(value) {
  localStorage.setItem("@LuyaoMember", JSON.stringify(value));
}
