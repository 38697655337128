import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

export default function useSearchParams() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const searchParamsString = searchParams.toString();
  const searchParamsObject = useMemo(
    () => qs.parse(searchParamsString),
    [searchParamsString]
  );
  const createQueryString = useCallback(
    (data, clearAll = false) => {
      const params = new URLSearchParams(
        clearAll ? "" : searchParamsString.toString()
      );
      Object.keys(data).forEach((key) => {
        if (data[key] || typeof data[key] === "boolean") {
          params.set(key, data[key].toString());
        }
        // 移除沒有值的 key
        if (
          typeof data[key] === "object" ||
          (typeof data[key] !== "boolean" && !data[key])
        ) {
          params.delete(key);
        }
      });
      const paramString = params.toString();
      history.push({
        pathname: location.pathname,
        search: `?${paramString}`,
      });
    },
    [history, searchParamsString, location.pathname]
  );
  return { searchParamsObject, createQueryString };
}
