import React, { useCallback, useEffect, useReducer } from "react";
// material
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  FormHelperText,
  TextField,
} from "@material-ui/core";
// icon
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
// react-player
import ReactPlayer from "react-player";
// date-fns
import { format, parseISO } from "date-fns";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import { InputTitle, AutocompleteSelect } from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import AdaptiveImage from "../../component/AdaptiveImage";
import Image from "../../component/Image";
import CustomUploadFiles from "../../component/CustomUploadFiles";
// utils
import emptyArray from "../../utils/emptyArray";

// SECTION apollo
// NOTE 商品列表
const GET_PRODUCTS = gql`
  query products {
    products(hidden: false, latest: true) {
      products {
        value: id
        label: name
      }
    }
  }
`;
// NOTE 玩具列表
const GET_TOYS = gql`
  query toys($eventId: Int!) {
    toys(eventId: $eventId, latest: true) {
      # "總數"
      count
      # "總頁數"
      pageCount
      # "玩具"
      contents {
        id
        # "商品"
        product {
          id
          # "名稱"
          name
        }
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 玩具
const GET_TOY = gql`
  query toy($id: Int!) {
    toy(id: $id) {
      id
      # "商品"
      product {
        id
        # "名稱"
        name
      }
      # "玩具gif"
      gifUrl
      # "孵化影片(後)"
      hatchingVideoUrl
      # "簡介"
      introduction
    }
  }
`;
// NOTE 儲存玩具
const SAVE_TOY = gql`
  mutation saveToy($toyInput: ToyInput!) {
    saveToy(toyInput: $toyInput) {
      success
      message
    }
  }
`;
// NOTE 隱藏玩具
const HIDE_TOY = gql`
  mutation hideToy($id: Int!) {
    hideToy(id: $id) {
      success
      message
    }
  }
`;
// !SECTION
// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "edit":
      return action.data;
    default:
      return null;
  }
}
// ANCHOR 主要組件
export default function ToyManage({ eventId }) {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  const { control, setValue } = useForm({
    defaultValues: {
      listRefetch: false,
      contentRefetch: false,
    },
  });

  switch (swtichContent?.swtichType) {
    case "add":
      return (
        <ToyForm
          swtichContentDispatch={swtichContentDispatch}
          formSetValue={setValue}
          formControl={control}
          eventId={eventId}
        />
      );
    case "edit":
      return swtichContent?.id ? (
        <ToyForm
          swtichContent={swtichContent}
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      ) : null;
    default:
      return (
        <ToyList
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      );
  }
}
// ANCHOR 列表
function ToyList({
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const listRefetch = useWatch({
    control: formControl,
    name: "listRefetch",
  });
  const { alert, notice } = useAlert();

  const {
    data: toysData,
    loading: toysLoading,
    refetch,
  } = useQuery(GET_TOYS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onCompleted({ toys }) {
      if (toys) {
        setTimeout(() => {
          formSetValue("listRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (listRefetch) {
      refetch();
    }
  }, [listRefetch, refetch]);

  const [hideToyFn, { loading: hideToyLoading }] = useMutation(HIDE_TOY, {
    onCompleted({ hideToy: { message } }) {
      if (message) {
        notice(message);
      } else {
        notice("刪除成功");
        refetch();
      }
    },
    onError() {
      return null;
    },
  });

  const _hideToy = useCallback(
    ({ id, product }) => {
      alert("", `確定要刪除玩具「${product?.name}」？`, [
        {
          text: "確定",
          onPress: () => {
            hideToyFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, hideToyFn]
  );

  if (toysLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={hideToyLoading} />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>玩具數量：{toysData?.toys?.count || 0}</Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() =>
                swtichContentDispatch({
                  type: "add",
                  data: {
                    swtichType: "add",
                  },
                })
              }
            >
              新增玩具
            </Button>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            data={emptyArray(toysData?.toys?.contents)}
            header={["商品", "建立時間", "最後更新時間", "操作"]}
            tableWidth={2}
            columns={[
              (item) => item?.product?.name || "",
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.updatedAt &&
                format(parseISO(item.updatedAt), "yyyy/LL/dd HH:mm"),
              (item) => (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onPress={(e) => {
                        e.stopPropagation();
                        _hideToy(item);
                      }}
                    >
                      刪除
                    </Button>
                  </Grid>
                </Grid>
              ),
            ]}
            onPress={(item) => {
              swtichContentDispatch({
                type: "edit",
                data: {
                  swtichType: "edit",
                  id: item.id,
                  name: item.product.name,
                },
              });
            }}
          />
        </CardContent>
      </Card>
    );
  }
}
// ANCHOR form
function ToyForm({
  swtichContent,
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const contentRefetch = useWatch({
    control: formControl,
    name: "contentRefetch",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const form = useForm({
    defaultValues: {
      product: "",
      gifUrl: "",
      hatchingVideoUrl: "",
      introduction: "",
    },
  });
  const { reset, setValue } = form;

  useEffect(() => {
    if (eventId) {
      setValue("eventId", eventId);
    }
  }, [eventId, setValue]);

  const { data: productsData, loading: productsLoading } = useQuery(
    GET_PRODUCTS,
    {
      fetchPolicy: "network-only",
      onError() {
        return null;
      },
    }
  );

  const [getToy, { loading: toyLoading, refetch }] = useLazyQuery(GET_TOY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ toy }) {
      if (toy) {
        setTimeout(() => {
          Object.keys(toy).forEach((item) => {
            if (item !== "__typename") {
              if (item === "product") {
                setValue(item, {
                  value: toy[item].id,
                  label: toy[item].name,
                });
              } else {
                setValue(item, toy[item]);
              }
            }
          });
          formSetValue("contentRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (contentRefetch && refetch) {
      refetch();
    }
  }, [contentRefetch, refetch]);

  useEffect(() => {
    if (swtichContent?.id) {
      getToy({ variables: { id: swtichContent.id } });
    }
  }, [swtichContent, getToy]);

  const _goBack = useCallback(() => {
    swtichContentDispatch({
      type: "list",
    });
    reset();
  }, [swtichContentDispatch, reset]);

  if (toyLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <FormProvider {...form}>
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>
              {swtichContent?.name || "新增玩具"}
            </Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="product"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="商品" required />
                    <AutocompleteSelect
                      {...field}
                      items={emptyArray(productsData?.products?.products)}
                      noOptionsText={`找不到商品`}
                      loading={productsLoading}
                      loadingText="載入中..."
                      renderOption={(props) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: "12px",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                        >
                          {props.label}
                        </Box>
                      )}
                      onChange={(e, value) => field.onChange(value)}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={9}
              style={{ display: isMobile ? "none" : "block" }}
            />
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="gifUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="孵蛋：玩具動畫(GIF)" required />
                    <AdaptiveImage ratio="100%">
                      <CustomUploadFiles
                        name="gifUrl"
                        multiple={false}
                        render={() => (
                          <Image
                            src={field?.value}
                            style={{ width: "100%", height: "100%" }}
                            resizeMode={"contain"}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="hatchingVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="孵蛋：孵化動畫後半段(影片)" required />
                    <AdaptiveImage ratio="177.77%">
                      <CustomUploadFiles
                        name="hatchingVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            playing
                            width={"100%"}
                            height="100%"
                            controls
                            // config={{
                            //   file: {
                            //     forceHLS: true,
                            //   },
                            // }}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="introduction"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="簡介" required />
                    <TextField
                      {...field}
                      rows={5}
                      multiline
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ToolButton _goBack={_goBack} formSetValue={formSetValue} />
        </CardActions>
      </FormProvider>
    </Card>
  );
}
// ANCHOR 按鈕
function ToolButton({ _goBack = () => {}, formSetValue }) {
  const { notice } = useAlert();
  const { handleSubmit } = useFormContext();

  const [saveToyFn, { loading: saveToyLoading }] = useMutation(SAVE_TOY, {
    onCompleted({ saveToy: { message } }) {
      if (message) {
        notice(message);
      } else {
        formSetValue("listRefetch", true);
        formSetValue("contentRefetch", true);
        notice("成功");
        _goBack();
      }
    },
    onError() {
      return null;
    },
  });

  const _submit = useCallback(
    ({ product, ...otherDatas }) => {
      const toyInput = { ...otherDatas, productId: product.value };
      saveToyFn({ variables: { toyInput } });
    },
    [saveToyFn]
  );
  const _onCancel = useCallback(() => {
    _goBack();
  }, [_goBack]);
  return (
    <>
      <LoadingModal loading={saveToyLoading} />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onPress={handleSubmit(_submit)}
          >
            儲存
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onPress={_onCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
