import React from "react";
// mui
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";

// component
import Image from "../../../../component/Image";
import AreaInformation from "./AreaInformation";

export default function CopyrightRender() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box pb={3} mb={3} borderBottom="2px solid #93b6c5">
        {/* logo */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mx="auto"
          mb={1}
        >
          <AreaInformation
            title="LUYAODES!GN"
            titleStyle={{ color: "#93b6c5" }}
          />
        </Box>
        {/* copyright */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mx="auto"
          mb={1}
        >
          <Typography variant={"subtitle2"} style={{ color: "#96b7c6" }}>
            © 2023 Luyao design Co. Ltd Cultivation Program
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box pb={3} mb={3}>
      <Box mx="auto" mb={1}>
        <Image
          src={"/img/special/parallel-universe/下方字.png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"contain"}
        />
      </Box>
    </Box>
  );
}
