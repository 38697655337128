import { useMemo } from "react";

import countryCodes from "../countryCodes.json";

export default function useCountryCallingCode() {
  const countryCodesArray = useMemo(() => {
    const newValue = [];
    countryCodes.forEach((item) => {
      newValue.push({
        label: `${item.countryName}\u2002(${item.phoneCode})`,
        value: item.phoneCode,
      });
    });
    return newValue;
  }, []);

  return { countryCodesArray };
}
