import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
// mui
import {
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
} from "@material-ui/core";
// react-hook-form
import { useFormContext, useWatch } from "react-hook-form";
// react-router-dom
import { useHistory } from "react-router-dom";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

// components
import { useAlert } from "../../../component/Alert";
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import Image from "../../../component/Image";
import { FieldCache } from "../../../component/Form";
import {
  CheckMyEggDialog,
  CheckMyToyDialog,
  ChangeNameDialog,
  EnterCodeDialog,
} from "../../../component/special-event/Alerts";
import ToysRandomPosition from "./component/ToysRandomPosition";
import AdaptiveImage from "../../../component/AdaptiveImage";
import CreateOrderFloatingLayer from "../../../component/CreateOrderFloatingLayer";
import SwitchNavTabs from "./component/SwitchNavTabs";
import PageIsland, { useGetGap } from "./component/PageIsland";
import VideoPlayerDialog from "../../../component/special-event/VideoPlayerDialog";
import LoadingFloating from "../../../component/LoadingFloating";
import CopyrightRender from "./component/CopyrightRender";
// zustand
import useTokenStore from "../../../zustand/useTokenStore";

// SECTION apollo
// NOTE 建立育成訂單
const CREATE_RAISING_ORDER = gql`
  mutation createRaisingOrder(
    $memberEggId: Int!
    $shipmentInput: ShipmentInput!
  ) {
    createRaisingOrder(
      memberEggId: $memberEggId
      shipmentInput: $shipmentInput
    ) {
      success
      message
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function CultivationPage() {
  return (
    <BackgroundContainer>
      <CultivationContent />
    </BackgroundContainer>
  );
}

// ANCHOR 我的育成
function CultivationContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <CheckToken />
      <Grid container spacing={isMobile ? 2 : 6}>
        <Grid
          item
          xs={12}
          style={{
            position: "relative",
          }}
          id="gridContainer"
        >
          <PageIsland />
          <ToysDisplay />
        </Grid>
        <Grid item xs={12}>
          {/* 下方選擇開始 */}
          <Box
            style={{
              left: 0,
              display: "flex",
              flexDirection: "row",
              marginTop: isMobile ? "-20px" : "-50px",
            }}
          >
            <Grid container spacing={isMobile ? 2 : 6}>
              <Grid item xs={6} style={{ position: "relative", zIndex: 100 }}>
                <MyEggArea />
              </Grid>
              <Grid item xs={6} style={{ position: "relative", zIndex: 100 }}>
                <MyToyArea />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <MyEgg />
      <Box pt={"50px"}>
        <CopyrightRender />
      </Box>
      <SwitchNavTabs />
    </Box>
  );
}

// ANCHOR 檢查token
function CheckToken() {
  const { notice } = useAlert();
  const history = useHistory();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  useEffect(() => {
    if (!memberToken) {
      history.push("/login");
      notice("登入時效已過，請重新登入。");
    }
  }, [memberToken, history, notice]);
  return null;
}

// ANCHOR 玩具顯示外框
function ToysDisplay() {
  const gap = useGetGap();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        gap,
      }}
    >
      <AdaptiveImage ratio="28%">
        {/* NOTE 跑步框框預留空位 */}
        <Box height={"100%"} />
      </AdaptiveImage>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AdaptiveImage ratio="18%" width="70%">
          <ToysRandomPositionComponent />
        </AdaptiveImage>
      </Box>
    </Box>
  );
}

// ANCHOR 玩具顯示
const ToysRandomPositionComponent = memo(
  function ToysRandomPositionComponent() {
    const memberToys = useWatch({ name: "memberToys" });
    return <ToysRandomPosition data={memberToys} />;
  }
);

// ANCHOR 我的玩具按鈕
const MyToyArea = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      paddingTop: isMobile && "20px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="myCultivationClickButton"
        render={(myCultivationClickButton) => {
          if (myCultivationClickButton === "hatchingEggs") {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/parallel-universe/我的玩具未按.png"}
                onPress={() => {
                  if (myCultivationClickButton !== "hatchingMyToys") {
                    setValue("myCultivationClickButton", "hatchingMyToys");
                  }
                }}
              />
            );
          }
          return (
            <Image
              style={{
                width: "85%",
                height: "85%",
              }}
              src={"/img/special/parallel-universe/我的玩具按.png"}
            />
          );
        }}
      />
    </Box>
  );
};
// ANCHOR 路遙蛋按鈕
const MyEggArea = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      paddingTop: isMobile && "20px",
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="myCultivationClickButton"
        render={(myCultivationClickButton) => {
          if (myCultivationClickButton === "hatchingMyToys") {
            return (
              <Image
                style={{
                  width: "85%",
                  height: "85%",
                }}
                src={"/img/special/parallel-universe/我的蛋蛋未按.png"}
                onPress={() => {
                  if (myCultivationClickButton !== "hatchingEggs") {
                    setValue("myCultivationClickButton", "hatchingEggs");
                  }
                }}
              />
            );
          }
          return (
            <Image
              style={{
                width: "85%",
                height: "85%",
              }}
              src={"/img/special/parallel-universe/我的蛋蛋按.png"}
            />
          );
        }}
      />
    </Box>
  );
};

// ANCHOR 內容區域
const MyEgg = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    toyContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      gap: "24px",

      "& > *+*": {
        flexShrink: 0,
      },
      "& .MuiTypography-root": {
        color: "#4D626B",
        fontSize: "20px",
        height: "40%",
      },
      "& .toyDetailButton": {
        color: "#326379",
        // fontWeight: 900,
        fontSize: "16px",
        backgroundColor: "#fff",
        borderRadius: "2px",
        textAlign: "center",
        padding: "2px 3px",
        // width: "50px",
      },
    },
    logoImageContainer: {
      width: 60,
      height: 60,
      "& img": {
        maxWidth: "100%",
        objectFit: "contain",
      },
    },
    toyImageContainer: {
      // width: "100%",
      // paddingBottom: "46.66%",
      width: 175,
      height: 175,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
      "& img": {
        display: "inline-block",
        position: "absolute",
        width: "100%",
        height: "100%",
        inset: 0,
        objectFit: "contain",
      },
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const { getValues, setValue } = useFormContext();
  const checkMyEggDialogRef = useRef();
  const videoPlayerDialogRef = useRef();
  const checkMyToyDialogRef = useRef();
  const changeNameDialogRef = useRef();
  const enterCodeDialogRef = useRef();
  const createOrderDialogRef = useRef();

  useEffect(() => {
    const luyaoHatchingSelectStoreRandomCode = localStorage.getItem(
      "@luyaoHatchingSelectStoreRandomCode"
    );
    const luyaoHatchingSelectStoreMemberEggId = localStorage.getItem(
      "@luyaoHatchingSelectStoreMemberEggId"
    );
    if (
      luyaoHatchingSelectStoreRandomCode &&
      luyaoHatchingSelectStoreMemberEggId
    ) {
      createOrderDialogRef?.current?.open(
        "hatchingMyToys",
        Number(luyaoHatchingSelectStoreMemberEggId)
      );
    }
  }, []);

  return (
    <Box pt={"40px"}>
      {/* 蛋蛋孵化區域 */}
      <CheckMyEggDialog
        ref={checkMyEggDialogRef}
        onChangeName={(e) => changeNameDialogRef?.current?.open(e)}
        onFeeding={(e) => enterCodeDialogRef?.current?.open(e)}
        onIncubation={(e) => videoPlayerDialogRef?.current?.open(e)}
      />
      <VideoPlayerDialog
        ref={videoPlayerDialogRef}
        onBack={() => {
          history.push("/special-event/my-cultivation");
          setValue("memberEggsRefetch", true);
        }}
        onGoTo={() => {
          setValue("memberEggsRefetch", true);
          setValue("myCultivationClickButton", "hatchingMyToys");
        }}
      />
      <CheckMyToyDialog
        ref={checkMyToyDialogRef}
        onChangeName={(e) => changeNameDialogRef?.current?.open(e)}
        onFeeding={(e) => enterCodeDialogRef?.current?.open(e)}
        onCreateRaisingOrder={(e) =>
          createOrderDialogRef?.current?.open(null, e)
        }
      />
      <ChangeNameDialog ref={changeNameDialogRef} />
      <EnterCodeDialog
        ref={enterCodeDialogRef}
        onIncubation={(e) => videoPlayerDialogRef?.current?.open(e)}
        onFeeding={(e) => videoPlayerDialogRef?.current?.open(e)}
      />
      <CreateOrderDialog ref={createOrderDialogRef} />
      <Grid
        container
        spacing={4}
        justifyContent={isDownSm ? "center" : "space-between"}
      >
        {/* 補位 */}
        <Grid item sm={3} />
        {/* 上方提示當前區域 */}
        <Grid item xs={10} sm={7} />
        {/* 左邊區域 */}
        <Grid item xs={10} sm={3}>
          <FieldCache
            name="selectItems"
            render={(selectItems) => (
              <Box className={classes.toyContainer}>
                <FieldCache
                  name="myCultivationClickButton"
                  render={(myCultivationClickButton) => (
                    <Box
                      sx={{
                        maxWidth: "401px",
                        "& img": {
                          maxWidth: "150px",
                          height: "40px",
                        },
                      }}
                    >
                      <Image
                        src={
                          myCultivationClickButton === "hatchingEggs"
                            ? "/img/special/parallel-universe/我的蛋蛋.png"
                            : "/img/special/parallel-universe/我的玩具.png"
                        }
                        resizeMode="contain"
                        alt=""
                      />
                    </Box>
                  )}
                />

                {/* 選中的寶貝 */}
                <Box py={2} flex={1}>
                  {selectItems?.eggType?.gifUrl || selectItems?.toy?.gifUrl ? (
                    <Box className={classes.toyImageContainer}>
                      <Image
                        src={
                          selectItems?.eggType?.gifUrl ||
                          selectItems?.toy?.gifUrl
                        }
                        resizeMode="contain"
                        alt="toy"
                      />
                    </Box>
                  ) : (
                    <Box className={classes.toyImageContainer}>
                      <Image
                        src={require("../../../images/dark-egg.png").default}
                        resizeMode="contain"
                        alt="empty-toy"
                      />
                    </Box>
                  )}
                </Box>

                {/* 名稱 & 介紹 */}
                <Box
                  width={isDownSm ? "100%" : "100px"}
                  style={{
                    display: "flex",
                    flexDirection: isDownSm ? "row" : "column",
                    alignItems: isDownSm && "center",
                    justifyContent: "space-between",
                    marginTop: isDownSm && "10px",
                    gap: "24px",
                  }}
                >
                  <Typography
                    style={{
                      margin: isDownSm && "0px 0px 0px",
                      fontSize: "15px",
                      textAlign: "center",
                      color: "#96b7c6",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectItems?.name ? selectItems?.name : "請選擇物品"}
                  </Typography>
                  <Box
                    className={"toyDetailButton"}
                    onClick={() => {
                      if (selectItems) {
                        if (
                          getValues("myCultivationClickButton") ===
                          "hatchingEggs"
                        ) {
                          checkMyEggDialogRef?.current?.open(selectItems);
                        } else {
                          checkMyToyDialogRef?.current?.open(selectItems);
                        }
                      }
                    }}
                    // style={{ backgroundColor: !selectItems && "#326379" }}
                  >
                    <FieldCache
                      name="myCultivationClickButton"
                      render={(myCultivationClickButton) =>
                        myCultivationClickButton === "hatchingEggs"
                          ? "詳情 / 孵化"
                          : "詳情 / 出貨"
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
          />
        </Grid>
        {/* 中間divider */}
        <Grid xs={0} sm={1}>
          <Box
            sx={{
              width: "1px",
              bgcolor: "#fff",
              height: "100%",
              mx: "auto",
            }}
          />
        </Grid>
        {/* 右邊寶寶區域 */}
        {/* swiper of babies */}
        <Grid item xs={10} sm={7}>
          <Box height="100%">
            <SwiperOfBabies />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateOrderDialog = forwardRef(function CreateOrderDialog(props, ref) {
  const { notice } = useAlert();
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [memberEggId, setMemberEggId] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (type, id) => {
        if (typeof type === "string" && type === "hatchingMyToys") {
          setValue("myCultivationClickButton", "hatchingMyToys");
        }
        if (id && typeof id === "number") {
          setMemberEggId(id);
        }
        setOpen(true);
      },
    }),
    [setValue]
  );
  const [createRaisingOrderFn, { loading: createRaisingOrderLoading }] =
    useMutation(CREATE_RAISING_ORDER, {
      onCompleted({ createRaisingOrder }) {
        if (createRaisingOrder.success) {
          localStorage.removeItem("@luyaoHatchingSelectStoreMemberEggId");
          setValue("memberEggsRefetch", true);
          notice("成功");
        } else if (createRaisingOrder.message) {
          notice(createRaisingOrder.message);
        }
      },
      onError() {
        return null;
      },
    });
  return (
    <>
      <LoadingFloating loading={createRaisingOrderLoading} />
      <CreateOrderFloatingLayer
        open={open}
        memberEggId={memberEggId}
        content={"育成玩具出貨"}
        type="Hatching"
        noPaymentRequired
        onChangeFloatingwindowClose={(value, _, shipmentInput) => {
          setOpen(false);
          if (value === "mutation") {
            createRaisingOrderFn({
              variables: {
                memberEggId,
                shipmentInput,
              },
            });
          }
        }}
      />
    </>
  );
});

// ANCHOR 整理路遙蛋&玩具hook
function useGetMemberEggsOrToys() {
  const memberEggs = useWatch({ name: "memberEggs" });
  const memberToys = useWatch({ name: "memberToys" });
  // 每6個一page
  const newMemberEggs = useMemo(() => {
    const newData = [];
    if (memberEggs.length > 0) {
      for (let i = 0; i < memberEggs.length; i++) {
        if (Math.floor(memberEggs.length / 6) === i) {
          let newFakeBabyLists = [];
          const remainingAmount = memberEggs.length - i * 6;
          const notEnoughQuantity = 6 - remainingAmount;
          newFakeBabyLists = memberEggs.slice(i * 6, i * 6 + remainingAmount);
          for (let j = 0; j < notEnoughQuantity; j++) {
            newFakeBabyLists.push({
              name: "",
              imageUrl: "/img/special/dark-egg.png",
            });
          }
          newData.push(newFakeBabyLists);
          break;
        } else {
          newData.push(memberEggs.slice(i * 6, (i + 1) * 6));
        }
      }
    } else {
      const newFakeBabyLists = new Array(6).fill({ name: "" });
      newData.push(newFakeBabyLists);
    }
    return newData;
  }, [memberEggs]);
  // 每6個一page
  const newMemberToys = useMemo(() => {
    const newData = [];
    if (memberToys.length > 0) {
      for (let i = 0; i < memberToys.length; i++) {
        if (Math.floor(memberToys.length / 6) === i) {
          let newFakeBabyLists = [];
          const remainingAmount = memberToys.length - i * 6;
          const notEnoughQuantity = 6 - remainingAmount;
          newFakeBabyLists = memberToys.slice(i * 6, i * 6 + remainingAmount);
          for (let j = 0; j < notEnoughQuantity; j++) {
            newFakeBabyLists.push({
              name: "",
            });
          }
          newData.push(newFakeBabyLists);
          break;
        } else {
          newData.push(memberToys.slice(i * 6, (i + 1) * 6));
        }
      }
    } else {
      const newFakeBabyLists = new Array(6).fill({ name: "" });
      newData.push(newFakeBabyLists);
    }
    return newData;
  }, [memberToys]);
  return { memberEggs: newMemberEggs, memberToys: newMemberToys };
}

// ANCHOR 瀏覽物品區域
const SwiperOfBabies = () => {
  const { setValue } = useFormContext();
  const [pageIndex, setPageIndex] = useState(0);
  const memberEggsOrToys = useGetMemberEggsOrToys();
  const myCultivationClickButton = useWatch({
    name: "myCultivationClickButton",
  });
  const newImagesArray = useMemo(() => {
    if (myCultivationClickButton === "hatchingEggs") {
      setTimeout(() => {
        setValue(
          "selectItems",
          memberEggsOrToys.memberEggs[0][0]?.name
            ? memberEggsOrToys.memberEggs[0][0]
            : null
        );
      }, 500);
      return memberEggsOrToys.memberEggs;
    } else {
      setTimeout(() => {
        setValue(
          "selectItems",
          memberEggsOrToys.memberToys[0][0]?.name
            ? memberEggsOrToys.memberToys[0][0]
            : null
        );
      }, 500);
      return memberEggsOrToys.memberToys;
    }
  }, [memberEggsOrToys, myCultivationClickButton, setValue]);

  useEffect(() => {
    setPageIndex(0);
  }, [myCultivationClickButton]);

  const handleNext = useCallback(() => {
    if (pageIndex < newImagesArray.length - 1) {
      setPageIndex((e) => e + 1);
    } else {
      setPageIndex(0);
    }
  }, [pageIndex, newImagesArray]);
  const handlePrev = useCallback(() => {
    if (pageIndex < 1) {
      setPageIndex(newImagesArray.length - 1);
    } else {
      setPageIndex((e) => e - 1);
    }
  }, [pageIndex, newImagesArray]);

  //

  const useStyles = makeStyles({
    swiperContainer: {
      height: "100%",
      // padding: "16px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
      "& .pagination": {
        height: "20px",
        width: "20px",
        mt: "10px",
        dispaly: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#658c9a",
        textAlign: "center",
        margin: "10px auto",
      },
    },

    // container 含有 select
    toyContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      flex: 1,
      width: "100%",
      paddingBottom: "100%",
      cursor: "pointer",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
      "& img": {
        maxWidth: "100%",
        position: "absolute",
        inset: 0,
        height: "100%",
        objectFit: "contain",
      },
      "& .bottom-select": {
        position: "absolute",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        backgroundImage: 'url("/img/special/buttonSelect.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "200%",
        backgroundPosition: "center",
      },
    },
    // arrows
    leftArrowContainer: {
      position: "absolute",
      left: "-12px",
      top: "50%",
      transform: "translate(-25%,-50%)",
      cursor: "pointer",
      "& img": {
        width: "25px",
        height: "25px",
      },
    },
    rightArrowContainer: {
      position: "absolute",
      right: "-12px",
      top: "50%",
      transform: "translate(25%,-50%)",
      cursor: "pointer",
      "& img": {
        width: "25px",
        height: "25px",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box className={classes.swiperContainer}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        style={{ minHeight: "188.66px", overflow: "hidden" }}
      >
        {newImagesArray[pageIndex]?.map((item, index) => {
          return (
            <Grid item xs={4} key={item?.id || index}>
              <FieldCache
                name="selectItems"
                render={(selectItems) => {
                  const selectItem = item?.id && item?.id === selectItems?.id;
                  return (
                    <Box
                      className={
                        selectItem
                          ? `${classes.toyContainer} breathing`
                          : classes.toyContainer
                      }
                      onClick={() => {
                        if (item?.id) {
                          setValue("selectItems", item);
                        }
                      }}
                      style={{ cursor: !item?.id && "default" }}
                    >
                      <Image
                        alt="toy"
                        src={
                          item?.eggType?.gifUrl ||
                          item?.toy?.gifUrl ||
                          "/img/special/dark-egg.png"
                        }
                        style={{ opacity: !item?.id && 0.3 }}
                        resizeMode="contain"
                      />
                      {/* select 框 */}
                      {selectItem && (
                        <>
                          <div className="bottom-select" />
                        </>
                      )}
                    </Box>
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box>
        <Box className="pagination">{`(${pageIndex + 1})`}</Box>
      </Box>

      {/* arrow */}
      <Box className={classes.leftArrowContainer} onClick={handlePrev}>
        <Image
          src={"/img/special/parallel-universe/左按鈕.png"}
          className="button-zoom"
          resizeMode="contain"
          alt=""
        />
      </Box>
      <Box className={classes.rightArrowContainer} onClick={handleNext}>
        <Image
          src={"/img/special/parallel-universe/右按鈕.png"}
          className="button-zoom"
          resizeMode="contain"
          alt=""
        />
      </Box>
    </Box>
  );
};
