import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { AutocompleteSelect } from "./Form";
import { useAlert } from "./Alert";
import LoadingModal from "./LoadingModal";
import countryCodes from "../countryCodes.json";
import { alphanumericOnlyPasswordRegex } from "./regex";

const RESET_MEMBERPASSWORDFROMMEMBERPAGE = gql`
  mutation resetMemberPasswordFromMemberPage(
    $oldPassword: String!
    $newPassword: String!
  ) {
    resetMemberPasswordFromMemberPage(
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode(
    $countryCallingCode: String!
    $mobile: String!
  ) {
    sendVerificationCode(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
    )
  }
`;

export default function ResetMemberPasswordFromMemberPage({
  open = false,
  onClose = () => {},
}) {
  const { notice } = useAlert();
  const Alert = useAlert();
  const useStyles = makeStyles({
    inputLabel: {
      fontSize: "0.8em",
    },
  });
  const classes = useStyles();

  // useform
  const { handleSubmit, control, getValues, reset } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      checkPassword: "",
    },
  });

  function _onClose() {
    reset();
    onClose();
  }

  const [
    resetMemberPasswordFromMemberPage,
    { loading: resetMemberPasswordFromMemberPageLoading },
  ] = useMutation(RESET_MEMBERPASSWORDFROMMEMBERPAGE, {
    onCompleted({ resetMemberPasswordFromMemberPage }) {
      if (resetMemberPasswordFromMemberPage.success) {
        _onClose();
        notice("更改密碼成功！");
      } else if (resetMemberPasswordFromMemberPage.message) {
        notice(resetMemberPasswordFromMemberPage.message);
      } else {
        notice("更改密碼失敗，請重新嘗試！");
      }
    },
  });

  function submit(data) {
    const { oldPassword, newPassword } = data;
    resetMemberPasswordFromMemberPage({
      variables: { oldPassword, newPassword },
    });
  }

  // 發送驗證碼

  const [_sendVerificationCode] = useMutation(SEND_VERIFICATIONCODE, {
    onCompleted({ sendVerificationCode }) {
      if (sendVerificationCode) {
        Alert.notice("已發送驗證碼。");
      } else {
        Alert.notice("發送驗證碼失敗，請重新嘗試！");
      }
    },
    onError(error) {
      Alert.notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  // country code
  const [countryCodesArray, setCountryCodesArray] = useState([]);

  useEffect(() => {
    const newCountryCodesArray = [
      {
        label: "全部",
        value: "ALL",
      },
    ];
    countryCodes.forEach((item) => {
      newCountryCodesArray.push({
        label: item.countryName,
        value: item.phoneCode,
      });
    });
    setCountryCodesArray(newCountryCodesArray);
  }, []);

  function getVerificationCode() {
    // onDisabledMobile();
    _sendVerificationCode({
      variables: {
        countryCallingCode: getValues("countryCallingCodeObject").value,
        mobile: getValues("mobile"),
      },
    });
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingModal loading={resetMemberPasswordFromMemberPageLoading} />
      <DialogTitle>重設密碼</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* TODO 新增驗證碼 */}
          {false && (
            <Grid item xs={12}>
              {/* 驗證碼 */}
              <Grid container item spacing={1}>
                <Typography variant="body1" className={classes.inputLabel}>
                  連絡電話
                </Typography>
                <Grid container item spacing={1}>
                  <Grid item xs={6} sm={3}>
                    <Controller
                      control={control}
                      name="countryCallingCodeObject"
                      rules={{
                        required: "必填欄位",
                      }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <AutocompleteSelect
                          placeholder="選擇國碼"
                          items={countryCodesArray}
                          value={value}
                          onChange={(e, value) => onChange(value)}
                          error={error}
                          helperText={error?.message}
                          // disabled={disabledMobile}
                          disableClearable
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="mobile"
                      rules={{
                        required: "必填欄位",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          placeholder="請輸入手機號碼"
                          error={error}
                          helperText={error?.message}
                          // disabled={disabledMobile}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getVerificationCode}
                      // disabled={
                      //   watch("mobile").length < 6 ||
                      //   !Boolean(watch("countryCallingCodeObject")) ||
                      //   disabledMobile
                      // }
                      fullWidth
                    >
                      取得驗證碼
                    </Button>
                  </Grid>
                </Grid>
                {/* {needVerify && (
                <Grid container item>
                  <Controller
                    control={control}
                    name="verificationCode"
                    rules={{
                      required: "必填欄位",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        placeholder="請輸入手機驗證碼"
                        error={error}
                        helperText={error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )} */}
              </Grid>
            </Grid>
          )}
          {/* 密碼區域 */}
          <Grid item xs={12}>
            <Controller
              control={control}
              name="oldPassword"
              rules={{
                required: "必填欄位",
                pattern: {
                  value: alphanumericOnlyPasswordRegex,
                  message: "密碼至少八碼或格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Typography variant="body1" className={classes.inputLabel}>
                    舊密碼
                  </Typography>
                  <TextField
                    {...field}
                    placeholder={"請輸入舊密碼"}
                    fullWidth
                    size="small"
                    error={Boolean(error)}
                    helperText={error?.message}
                    type="password"
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: "必填欄位",
                pattern: {
                  value: alphanumericOnlyPasswordRegex,
                  message: "密碼至少八碼或格式不正確！",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Typography variant="body1" className={classes.inputLabel}>
                    新密碼
                  </Typography>
                  <TextField
                    {...field}
                    placeholder={"請輸入新密碼"}
                    fullWidth
                    size="small"
                    error={Boolean(error)}
                    helperText={error?.message}
                    type="password"
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="checkPassword"
              rules={{
                required: "必填欄位",
                validate: (e) => {
                  if (e !== getValues("newPassword")) {
                    return "新密碼與再次輸入密碼不符合！";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Typography variant="body1" className={classes.inputLabel}>
                    再次輸入密碼
                  </Typography>
                  <TextField
                    {...field}
                    placeholder={"請輸入密碼"}
                    fullWidth
                    size="small"
                    error={Boolean(error)}
                    helperText={error?.message}
                    type="password"
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={_onClose}>
              取消
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(submit)}
            >
              儲存
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
