// mui
import { Box, Grid, useMediaQuery } from "@material-ui/core";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import AdaptiveImage from "../../../component/AdaptiveImage";
import Image from "../../../component/Image";
import CopyrightRender from "./component/CopyrightRender";
import PageIsland from "./component/PageIsland";
import SwitchNavTabs from "./component/SwitchNavTabs";
import { useMemo } from "react";

// ANCHOR 主要組件
export default function IllustratePage() {
  return (
    <BackgroundContainer>
      <PageIsland />
      <IllustrateContent />
    </BackgroundContainer>
  );
}

export function useGetMarginTop() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const is900 = useMediaQuery("(max-width:900px)");
  const is800 = useMediaQuery("(max-width:800px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const is600 = useMediaQuery("(max-width:600px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const is400 = useMediaQuery("(max-width:400px)");
  const marginTop = useMemo(() => {
    if (is400) {
      return -230;
    } else if (isDownMobile) {
      return -270;
    } else if (is600) {
      return -330;
    } else if (isMobile) {
      return -400;
    } else if (is800) {
      return -470;
    } else if (is900) {
      return -530;
    } else if (isTable) {
      return -600;
    } else if (isDownPC) {
      return -600;
    } else if (isPC) {
      return -600;
    }
    return -600;
  }, [
    isPC,
    isDownPC,
    isTable,
    is900,
    is800,
    isMobile,
    is600,
    isDownMobile,
    is400,
  ]);
  return marginTop;
}

// ANCHOR 內容
function IllustrateContent() {
  const marginTop = useGetMarginTop();
  return (
    <Box style={{ marginTop }}>
      <Grid container spacing={3} style={{ marginTop: "40px" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ zIndex: 1 }}
        >
          <Image
            src={"/img/special/radish-island/育成系統台灣數量.png"}
            style={{ width: "20%", height: "100%" }}
            resizeMode={"contain"}
          />
        </Grid>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/radish-island/圖鑑.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
      </Grid>
      <CopyrightRender />
      <SwitchNavTabs />
    </Box>
  );
}
