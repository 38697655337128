import "./App.css";
import React, { useLayoutEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { zhTW } from "date-fns/locale";
import ReactGA from "react-ga4";
import { AlertProvider } from "./component/Alert";
import { LocalStorageContextProvider } from "./component/LocalStorageContext";
import Routes from "./Routes";
import {
  ReduxProvider,
  ThemeProvider,
  ApolloProvider,
  AuthTokenProvider,
  FetchDataProvider,
} from "./provider";

export default function App() {
  useLayoutEffect(() => {
    window.FB?.CustomerChat.hideDialog();
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host.search(".s3-") === -1
    ) {
      ReactGA.initialize("GTM-MVSX398M");
    }
  }, []);
  return (
    <ReduxProvider>
      <FetchDataProvider>
        <ApolloProvider>
          <LocalStorageContextProvider>
            <AuthTokenProvider>
              <ThemeProvider>
                <CssBaseline />
                <AlertProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTW}>
                    <Routes />
                  </MuiPickersUtilsProvider>
                </AlertProvider>
              </ThemeProvider>
            </AuthTokenProvider>
          </LocalStorageContextProvider>
        </ApolloProvider>
      </FetchDataProvider>
    </ReduxProvider>
  );
}
