import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Helmet } from "react-helmet";

import { ReactComponent as LogoIcon } from "../../logo.svg";
import Events from "../../component/Events";

export default function SpecialEventListPage() {
  const theme = useTheme();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
  });
  const classes = useStyles();
  const isPC = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div style={{ minHeight: 700 }}>
      <Helmet>
        <title>育成專區｜路遙圓創</title>
      </Helmet>

      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={isPC ? "column" : "row"}
              justifyContent="space-between"
              padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
            >
              <Box>
                <Box display="flex" flexDirection={isPC ? "column" : "row"}>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    Luyao&nbsp;Design&nbsp;
                  </Typography>
                  <LogoIcon style={{ height: 56, width: 56 }} />
                </Box>
                <Box margin={`${theme.spacing(3)}px 0`}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    育成專區
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  TAIWAN
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  DESIGN TOYS
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Events eventType={"raising_event"} />
    </div>
  );
}
