import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  makeStyles,
  Grid,
  Card,
  useTheme,
  Typography,
  Divider,
  ButtonBase,
  CircularProgress,
  colors,
} from "@material-ui/core";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory, useLocation } from "react-router-dom";

import SquareBox from "./SquareBox";
import PageItem from "./PageItem";
import DateCountdown from "./DateCountdown";
import PasswordDialog from "./PasswordDialog";
// NOTE zustand
import useProductPasswordStore from "../zustand/useProductPasswordStore";

// SECTION apollo
const GET_PRODUCTS = gql`
  query products(
    $type: ProductCategory
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    products(
      type: $type
      enabled: true
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      productCount
      products {
        id
        slug
        images {
          filename
          mimetype
          encoding
          location
        }
        name
        size
        material
        stock
        limit
        limitText
        price
        depositSize
        startTime
        endTime
        # "有無密碼"
        hasPassword
      }
    }
  }
`;
// !SECTION

export default function Products({ type }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(null);
  const [pageArray, setPageArray] = useState([]);
  const passwordDialogRef = useRef();

  const [getProducts, { data, loading }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ products }) {
      setTimeout(() => {
        setPageMax(products.pageCount);
      }, 0);
    },
    onError() {
      return null;
    },
  });

  const variables = useMemo(() => {
    return {
      latest: true,
      pageSize: pathname !== "/product" ? 10 : undefined,
      page: 1,
    };
  }, [pathname]);

  const exclusive = pathname === "/" || pathname.includes("exclusive");
  const preorder = pathname.includes("preorder");

  const checkPathname = useCallback(
    (value) => {
      if (exclusive) {
        variables.type = "EXCLUSIVE";
      } else if (preorder) {
        variables.type = "PREORDER";
      } else {
        variables.type = "NORMAL";
      }

      if (value) {
        variables.page = value;
      }
      if (type) {
        variables.type = type;
      }

      getProducts({ variables });
    },
    [exclusive, getProducts, preorder, variables, type]
  );

  useEffect(() => {
    checkPathname();
  }, [checkPathname]);

  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);

  useEffect(() => {
    if (data) {
      if (!data.products.products[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  const _handlePassword = useCallback((productSlug) => {
    passwordDialogRef.current?.open(productSlug);
  }, []);

  const _handleEvent = useCallback(
    ({ slug, password }) => {
      useProductPasswordStore.getState().setProductPassword(password);
      history.push(`/product/${slug}`);
    },
    [history]
  );

  if (loading) {
    return (
      <Box
        display="flex"
        height="548px"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <PasswordDialog ref={passwordDialogRef} handleEvent={_handleEvent} />
      {Boolean(data) &&
        data.products.products.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <ProductItem
              data={item}
              handlePassword={_handlePassword}
              handleEvent={_handleEvent}
            />
          </Grid>
        ))}
      {Boolean(pageArray[0]) && pathname !== "/product" && (
        <Grid container item>
          <PageItem
            data={pageArray}
            page={page}
            onChangePage={(e) =>
              setPage(() => {
                checkPathname(e);
                return e;
              })
            }
          />
        </Grid>
      )}
    </>
  );
}

function ProductItem({ data, handlePassword }) {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    contentArea: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        margin: `${theme.spacing(1)}px 0`,
      },
    },
    divider: {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
    titleText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: 3 /* number of lines to show */,
      boxOrient: "vertical",
      height: 96,
    },
    infoText: {
      textAlign: "left",
      color: theme.palette.text.hint,
    },
    itemButton: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& > *": {
        width: "100%",
      },
    },
    countdownText: {
      textAlign: "center",
      color: theme.palette.text.hint,
    },
  }));
  const classes = useStyles();
  const handleRef = useRef({
    handlePassword,
  });
  useEffect(() => {
    handleRef.current.handlePassword = handlePassword;
  }, [handlePassword]);
  const _goToProduct = useCallback(() => {
    if (data.slug) {
      if (data.hasPassword) {
        handleRef.current.handlePassword?.(data.slug);
        return null;
      }
      history.push(`/product/${data.slug}`);
    }
  }, [data, history]);
  return (
    <ButtonBase className={classes.itemButton} onClick={_goToProduct}>
      <Card variant="outlined">
        <SquareBox>
          <img
            src={data.images[0]?.location}
            className={classes.image}
            alt=""
          />
        </SquareBox>
      </Card>
      <Box className={classes.contentArea}>
        <div>
          <Typography variant="h6" align="left" className={classes.titleText}>
            {data.name}
          </Typography>
          <Divider className={classes.divider} />
        </div>
        <Box
          height={72}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography className={classes.infoText}>
            尺寸&ensp;|&ensp;{data.size}
          </Typography>
          <Typography className={classes.infoText}>
            材質&ensp;|&ensp;{data.material}
          </Typography>
          {data.limitText || data.limit ? (
            <Typography className={classes.infoText}>
              限量&ensp;|&ensp;{data.limitText ?? `${data.limit}個`}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <PriceBrand
        price={data.price}
        depositSize={data.depositSize}
        soldOut={Boolean(moment(data.endTime).isBefore() || data.stock < 1)}
      />
      {data.startTime && moment().diff(data.startTime) < 0 ? (
        <DateCountdown start={moment(data.startTime)} />
      ) : (
        <DateCountdown end={moment(data.endTime)} />
      )}
    </ButtonBase>
  );
}
function PriceBrand({ price = 0, count = 1, depositSize = 0, soldOut }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const exclusive = pathname === "/" || pathname.includes("exclusive");
  const preorder = pathname.includes("preorder");
  function thousandsSeparator(number) {
    return number.toLocaleString("en-US");
  }
  return (
    <Box
      bgcolor={
        soldOut
          ? preorder || exclusive
            ? theme.palette.primary.main
            : colors.red["700"]
          : theme.palette.primary.main
      }
      padding={`0 ${theme.spacing(2)}px`}
      color={theme.palette.primary.contrastText}
    >
      <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
        {soldOut
          ? preorder || exclusive
            ? "已結束"
            : "SOLD OUT"
          : `${depositSize < 1 ? "訂金" : ""}
          \u2002TWD\u2002
          ${thousandsSeparator(price * depositSize * count)}`}
      </Typography>
    </Box>
  );
}
