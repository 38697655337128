import React, { useState, createElement } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../component/Alert";
import TitleBox from "../component/TitleBox";
import PhoneVerify from "../component/PhoneVerify";
import LoadingModal from "../component/LoadingModal";

const CHECK_VERIFICATION_CODE = gql`
  mutation CheckVerificationCode(
    $countryCallingCode: String
    $mobile: String!
    $verificationCode: String!
  ) {
    checkVerificationCode(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
      verificationCode: $verificationCode
    )
  }
`;

const RESET_MEMBER_PASSWORD = gql`
  mutation ResetMemberPassword(
    $countryCallingCode: String!
    $mobile: String!
    $verificationCode: String!
    $password: String!
  ) {
    resetMemberPassword(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
      verificationCode: $verificationCode
      password: $password
    ) {
      success
      message
    }
  }
`;

export default function ForgePasswordPage() {
  const history = useHistory();
  const Alert = useAlert();
  const [mobile, setMobile] = useState("");
  const [countryCallingCode, setCountryCallingCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rePassword, setRePassword] = useState("");
  const [showRePassword, setShowRePassword] = useState(false);

  function _resetMemberPassword() {
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;

    if (!passwordRegex.test(password)) {
      return Alert.notice("新密碼格式錯誤，需包含英文以及數字，且長度為八碼！");
    }

    if (password !== rePassword) {
      return Alert.notice("新密碼與再次輸入密碼不相同！");
    }

    resetMemberPassword();
  }

  const [resetMemberPassword, { loading }] = useMutation(
    RESET_MEMBER_PASSWORD,
    {
      variables: {
        mobile,
        countryCallingCode,
        verificationCode,
        password,
      },
      onCompleted({ resetMemberPassword }) {
        if (resetMemberPassword.success) {
          Alert.alert(
            "變更成功",
            "密碼已設置完成，請使用您的新密碼重新登入。",
            [{ text: "確定", onPress: () => history.push("/login") }]
          );
        } else {
          Alert.alert("通知", `${resetMemberPassword.message}`);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  const [checkVerificationCode] = useMutation(CHECK_VERIFICATION_CODE, {
    variables: {
      mobile,
      countryCallingCode,
      verificationCode,
    },
    onCompleted({ checkVerificationCode }) {
      if (checkVerificationCode) {
        setEditPassword(true);
      } else {
        Alert.alert(
          "驗證碼錯誤",
          "您的手機驗證碼輸入錯誤，請重新接收驗證碼並再次嘗試。",
          [{ text: "確定", onPress: () => setVerificationCode("") }]
        );
      }
    },
  });
  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>忘記密碼｜路遙圓創</title>
      </Helmet>
      <LoadingModal loading={loading} />
      {editPassword ? (
        <TitleBox title="修改密碼">
          <Grid container spacing={1}>
            <Grid container item>
              <TextField
                label="請輸入新密碼"
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => setShowPassword((e) => !e)}
                        size="small"
                      >
                        {createElement(
                          showPassword ? VisibilityIcon : VisibilityOffIcon
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container item>
              <TextField
                label="再次輸入密碼"
                fullWidth
                type={showRePassword ? "text" : "password"}
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => setShowRePassword((e) => !e)}
                        size="small"
                      >
                        {createElement(
                          showRePassword ? VisibilityIcon : VisibilityOffIcon
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={_resetMemberPassword}
              >
                確認修改密碼
              </Button>
            </Grid>
          </Grid>
        </TitleBox>
      ) : (
        <TitleBox title="忘記密碼">
          <Grid container spacing={1}>
            <PhoneVerify
              forget
              mobile={mobile}
              setMobile={setMobile}
              countryCallingCode={countryCallingCode}
              setCountryCallingCode={setCountryCallingCode}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
            />
            <Grid container item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={checkVerificationCode}
                disabled={
                  !Boolean(mobile) ||
                  !Boolean(countryCallingCode) ||
                  verificationCode.length < 6
                }
              >
                送出
              </Button>
            </Grid>
          </Grid>
        </TitleBox>
      )}
    </Container>
  );
}
