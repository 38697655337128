import { useLocation } from "react-router-dom";

export function splitArray(array = [], number) {
  let newArray = [];
  let index = 0;
  while (index < array.length) {
    newArray.push(array.slice(index, (index += number)));
  }
  return newArray;
}
export function addThousandSeparator(number) {
  const reg = /(\d)(?=(?:\d{3})+$)/g;
  let newNumber = number.split(".");
  return (
    newNumber[0].replace(reg, "$1,") +
    (typeof newNumber[1] !== "undefined" ? "." + newNumber[1] : "")
  );
}
export function removeThousandSeparator(number) {
  return String(number).replace(/,/g, "");
}
export function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

export function thousandsSeparator(number) {
  if (typeof number === "number" || typeof number === "string") {
    return number.toLocaleString("en-US");
  } else {
    return null;
  }
}

export function stringHiddenInTheMiddle(string = "", firstLen = 1, endLen = 1) {
  if (string.length >= 3) {
    let len = string.length - firstLen - endLen;
    let xing = "";
    for (let i = 0; i < len; i++) {
      xing += "O";
    }
    return (
      string.slice(0, firstLen) +
      xing +
      string.slice(string.length - endLen, string.length)
    );
  } else if (string.length === 2) {
    return string.slice(0, 1) + "O";
  } else {
    return string;
  }
}

export function getMemberTier(value) {
  switch (value) {
    case "HORSE_POWER":
      return "小馬力";
    case "SUPER_HORSE_POWER":
      return "超級馬力";
    case "COLLECTOR_HORSE_POWER":
      return "藏家馬力";
    default:
      return "無";
  }
}

export function checkMemberTierLimit(fromMember, fromData) {
  if (fromData === "HORSE_POWER" && !fromMember) return true;
  else if (
    fromData === "SUPER_HORSE_POWER" &&
    (!fromMember || fromMember === "HORSE_POWER")
  )
    return true;
  else if (
    fromData === "COLLECTOR_HORSE_POWER" &&
    (!fromMember ||
      fromMember === "HORSE_POWER" ||
      fromMember === "SUPER_HORSE_POWER")
  )
    return true;
  else return false;
}

export function checkImageSize(value) {
  if (value < 1048576) return false;
  else {
    const imageSize = Math.floor((value / 1048576).toFixed(1) * 10) / 10;
    if (imageSize > 4.4) return true;
    else return false;
  }
}

// ANCHOR 產生邀請碼
export function getInvitationCode(length) {
  const randomChars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
  let invitationCode = "";
  for (let i = 0; i < length; i++) {
    invitationCode += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return invitationCode;
}

// ANCHOR 陣列最大值
export function getMaxOfArray(numArray) {
  if (Array.isArray(numArray) && numArray.length > 0) {
    return Math.max.apply(null, numArray);
  } else return 0;
}
// ANCHOR 計算字串位元數
export function halfShapeAndFullForm(data) {
  let totalNumber = 0;
  function matchRule(rule) {
    return data.match(rule) ? data.match(rule).length : 0;
  }
  if (data) {
    totalNumber =
      matchRule(/[\u4e00-\u9fa5]/g) * 2 +
      matchRule(/[\u0000-\u00ff]/g) +
      matchRule(/[\uff00-\uffff]/g) * 2;
  }
  return totalNumber;
}
