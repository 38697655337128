import React, { memo } from "react";
// mui
import { Box, Typography } from "@material-ui/core";

// component
import AreaInformation from "./AreaInformation";

export default memo(function CopyrightRender() {
  return (
    <Box pb={3} mb={3} borderBottom="2px solid #fff">
      {/* logo */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mx="auto"
        mb={1}
      >
        <AreaInformation title="LUYAODES!GN" />
      </Box>
      {/* copyright */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mx="auto"
        mb={1}
      >
        <Typography
          variant={"subtitle2"}
          style={{ fontSize: "0.8em", color: "#fff" }}
        >
          © 2024 Luyao design Co. Ltd Cultivation Program
        </Typography>
      </Box>
    </Box>
  );
});
