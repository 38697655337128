import React, { useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

import { useAlert } from "../../component/Alert";
import Table from "../../component/Table";
import Text from "../../component/Text";
import { Card, CardActions } from "../../component/Card";
import PageItem from "../../component/PageItem";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import { getMemberTier } from "../../component/utils";

const MOVE_PRODUCTPRIORITY = gql`
  mutation moveProductPriority(
    $sourceProductId: Int!
    $position: ProductPosition!
  ) {
    moveProductPriority(
      sourceProductId: $sourceProductId
      position: $position
    ) {
      success
      message
    }
  }
`;

export default function ConsoleProductTable({
  dataTest,
  onChangeSelect = () => {},
  pageArray,
  page = 1,
  onChangePage = () => {},
  _delete = () => {},
  onRefetch = () => {},
}) {
  const Alert = useAlert();
  const [data, setData] = useState(dataTest);
  const indexRef = useRef();
  const moveRef = useRef();
  function thousandsSeparator(number) {
    return number.toLocaleString("en-US");
  }
  function swapItems(index1, index2) {
    data[index1] = data.splice(index2, 1, data[index1])[0];
    setData((e) => [...e]);
    indexRef.current = undefined;
    moveRef.current = undefined;
  }
  const [moveProductPriority, { loading }] = useMutation(MOVE_PRODUCTPRIORITY, {
    onCompleted({ moveProductPriority }) {
      if (moveProductPriority.success) {
        if (
          (indexRef.current === 0 && moveRef.current === "UP") ||
          (indexRef.current === data.length - 1 && moveRef.current === "DOWN")
        ) {
          indexRef.current = undefined;
          moveRef.current = undefined;
          onRefetch();
        } else {
          if (moveRef.current === "UP") {
            swapItems(indexRef.current, indexRef.current - 1);
          } else {
            swapItems(indexRef.current, indexRef.current + 1);
          }
        }
      } else {
        Alert.alert("", "移動失敗，請再次嘗試。", [
          { text: "確定", type: "ok" },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  return (
    <Card>
      <LoadingModal loading={loading} />
      <Table
        data={data}
        tableWidth={3}
        columnWidths={[200]}
        header={[
          "名稱",
          "預購",
          "尺寸",
          "材質",
          "價錢",
          "會員級別購買限制",
          "開始時間",
          "結束時間",
          "排序",
          "刪除",
        ]}
        columns={[
          "name",
          (item) => <Text>{item.preorder ? "是" : "否"}</Text>,
          "size",
          "material",
          (item) => <Text>NT${thousandsSeparator(item.price)}</Text>,
          (item) => getMemberTier(item.memberTierLimit),
          (item) => (
            <Text>
              {Boolean(item.startTime)
                ? moment(item.startTime).format("YYYY/MM/DD HH:mm")
                : "無"}
            </Text>
          ),
          (item) => (
            <Text>
              {Boolean(item.endTime)
                ? moment(item.endTime).format("YYYY/MM/DD HH:mm")
                : "無"}
            </Text>
          ),
          (item, index) => {
            return (
              <Grid container direction={"row"}>
                <Grid item sm={6}>
                  <Button
                    fullWidth
                    onPress={(event) => {
                      event.stopPropagation();
                      indexRef.current = index;
                      moveRef.current = "UP";
                      moveProductPriority({
                        variables: { sourceProductId: item.id, position: "UP" },
                      });
                    }}
                    disabled={page === 1 && index === 0}
                  >
                    <ArrowUpward />
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    fullWidth
                    onPress={(event) => {
                      event.stopPropagation();
                      indexRef.current = index;
                      moveRef.current = "DOWN";
                      moveProductPriority({
                        variables: {
                          sourceProductId: item.id,
                          position: "DOWN",
                        },
                      });
                    }}
                    disabled={
                      page === pageArray.length && index === data.length - 1
                    }
                  >
                    <ArrowDownward />
                  </Button>
                </Grid>
              </Grid>
            );
          },
          (item) => {
            return (
              <Button
                variant="outlined"
                color="primary"
                onPress={(event) => {
                  event.stopPropagation();
                  _delete(item);
                }}
              >
                刪除
              </Button>
            );
          },
        ]}
        onPress={(item, index) => onChangeSelect(index, item)}
      />
      <CardActions>
        <PageItem data={pageArray} page={page} onChangePage={onChangePage} />
      </CardActions>
    </Card>
  );
}
