import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Controller, useFormContext } from "react-hook-form";

import { useAlert } from "./Alert";
import { AutocompleteSelect } from "./Form";
import LoadingModal from "./LoadingModal";
import ResetMemberPasswordFromMemberPage from "./ResetMemberPasswordFromMemberPage";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode(
    $countryCallingCode: String!
    $mobile: String!
  ) {
    sendVerificationCode(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
    )
  }
`;

export default function MemberForm({
  needVerify = false,
  divisionKey,
  subdivisionsKey,
  countryCodesArray,
  disabledMobile,
  onDisabledMobile = () => {},
}) {
  const Alert = useAlert();

  const { control, setValue, getValues, watch } = useFormContext();

  const [resetUserPasswordOpen, setResetUserPasswordOpen] = useState(false);

  function getVerificationCode() {
    onDisabledMobile();
    _sendVerificationCode({
      variables: {
        countryCallingCode: getValues("countryCallingCodeObject").value,
        mobile: getValues("mobile"),
      },
    });
  }

  const [_sendVerificationCode, { loading: sendVerificationCodeLoading }] =
    useMutation(SEND_VERIFICATIONCODE, {
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          Alert.notice("已發送驗證碼。");
        } else {
          Alert.notice("發送驗證碼失敗，請重新嘗試！");
        }
      },
      onError(error) {
        Alert.notice(error.message.replace("GraphQL error: ", ""));
      },
    });

  return (
    <Box padding={1}>
      <LoadingModal loading={sendVerificationCodeLoading} />
      <ResetMemberPasswordFromMemberPage
        open={resetUserPasswordOpen}
        onClose={() => setResetUserPasswordOpen(false)}
      />
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TitleLabel subTitle="Name" title="收件人名字" />
          </Box>
          <Controller
            control={control}
            name="fullName"
            rules={{
              required: "必填欄位",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="請輸入收件人名字"
                error={error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <TitleLabel subTitle="Nickname" title="暱稱" />
          <Controller
            control={control}
            name="nickname"
            rules={{
              required: "必填欄位",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="請輸入暱稱"
                error={error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <TitleLabel subTitle="Address" title="寄送地址" />
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
              <Controller
                control={control}
                name="district"
                rules={{
                  required: "必填欄位",
                }}
                render={({
                  field: { value: district, onChange },
                  fieldState: { error },
                }) => (
                  <AutocompleteSelect
                    placeholder="選擇縣市"
                    items={divisionKey}
                    value={district}
                    onChange={(e, value) => {
                      setValue("subdistrict", null);
                      onChange(value);
                    }}
                    error={error}
                    helperText={error?.message}
                    disableClearable
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Controller
                control={control}
                name="subdistrict"
                rules={{
                  required: "必填欄位",
                }}
                render={({
                  field: { value: subdistrict, onChange },
                  fieldState: { error },
                }) => (
                  <AutocompleteSelect
                    placeholder="選擇地區"
                    items={subdivisionsKey}
                    value={subdistrict}
                    onChange={(e, value) => onChange(value)}
                    error={error}
                    helperText={error?.message}
                    disableClearable
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    placeholder="請輸入地址"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <TitleLabel subTitle="Phone" title="連絡電話" />
          <Grid container item spacing={1}>
            <Grid item xs={6} sm={3}>
              <Controller
                control={control}
                name="countryCallingCodeObject"
                rules={{
                  required: "必填欄位",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <AutocompleteSelect
                    placeholder="選擇國碼"
                    items={countryCodesArray}
                    value={value}
                    onChange={(e, value) => onChange(value)}
                    error={error}
                    helperText={error?.message}
                    disabled={disabledMobile}
                    disableClearable
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name="mobile"
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    placeholder="請輸入手機號碼"
                    error={error}
                    helperText={error?.message}
                    disabled={disabledMobile}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {needVerify && (
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getVerificationCode}
                  disabled={
                    watch("mobile").length < 6 ||
                    !Boolean(watch("countryCallingCodeObject")) ||
                    disabledMobile
                  }
                  fullWidth
                >
                  取得驗證碼
                </Button>
              </Grid>
            )}
          </Grid>
          {needVerify && (
            <Grid container item>
              <Controller
                control={control}
                name="verificationCode"
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    placeholder="請輸入手機驗證碼"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <TitleLabel subTitle="Email" title="聯絡信箱" />
          <Controller
            control={control}
            name="email"
            rules={{
              required: "必填欄位",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="請輸入email"
                error={error}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        {/* 重設密碼 */}
        <Grid item>
          <Button onClick={() => setResetUserPasswordOpen(true)}>
            <Typography>修改密碼</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function TitleLabel({ title, subTitle }) {
  const useStyles = makeStyles((theme) => ({
    titleLabel: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      marginRight: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  }));
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" marginBottom={0.5}>
      <Box className={classes.titleLabel}>
        <Typography>{subTitle}</Typography>
      </Box>
      <Typography color="primary">{title}</Typography>
    </Box>
  );
}
