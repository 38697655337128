import React, { useState, memo } from "react";
import { Box, makeStyles, Grid, ButtonBase, useTheme } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
// auto play
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default memo(function SwipeableViewsComponent({ images = [] }) {
  const theme = useTheme();
  const [imageIndex, setImageIndex] = useState(0);
  const useStyles = makeStyles({
    imageOutsideBox: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      paddingTop: "56.25%",
      "&:hover": {
        cursor: images[imageIndex]?.link && "pointer",
      },
    },
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <AutoPlaySwipeableViews
        index={imageIndex}
        onChangeIndex={(e) => setImageIndex(e)}
        enableMouseEvents
        slideStyle={{
          overflow: "hidden",
        }}
      >
        {images.map((item) => (
          <Box
            className={classes.imageOutsideBox}
            onClick={() => {
              if (item.link) {
                const newWindow = window.open(item.link);
                newWindow.opener = null;
              }
            }}
          >
            <img src={item.image.location} className={classes.image} alt="" />
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <Grid container justify="center">
        {images.map((item, index) => (
          <Grid key={item.id} item>
            <ButtonBase onClick={() => setImageIndex(index)}>
              <Box
                height={8}
                width={8}
                borderRadius={4}
                bgcolor={
                  index === imageIndex
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }
                style={{ marginRight: "5px" }}
              />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
});
