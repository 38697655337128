import { useEffect } from "react";
import { useFormState } from "react-hook-form";

export default function FormScrollToError() {
  const { errors, isSubmitting, isSubmitted } = useFormState();
  useEffect(() => {
    const elements = Object.keys(errors);
    if (elements.length > 0 && (isSubmitting || isSubmitted)) {
      const elements2 = elements
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements2.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );
      if (elements2.length > 0) {
        let errorElement = elements2[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus({ preventScroll: true });
      }
    }
  }, [errors, isSubmitting, isSubmitted]);
  return null;
}
