import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
// material
import {
  Dialog,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  TextField,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import { Controller, useFormContext, useWatch } from "react-hook-form";
// date-fns
import { format, parseISO, addMinutes, isBefore } from "date-fns";
// react-router-dom
import { useHistory } from "react-router-dom";

// icon
import { ReactComponent as CloseIcon } from "../../svgs/special/CloseIcon.svg";
// component
import { useAlert } from "../Alert";
import LoadingModal from "../LoadingModal";
import { halfShapeAndFullForm } from "../../component/utils";
import DateCountdown from "../../component/DateCountdown";
// utils
import { checkSpecialSymbols } from "../../utils/checkRegExp";
import emptyArray from "../../utils/emptyArray";
import dateIsBefore from "../../utils/dateIsBefore";
// zustand
import useTokenStore from "../../zustand/useTokenStore";
import useHatchingOrderIdStore from "../../zustand/useHatchingOrderIdStore";

// SECTION apollo
// NOTE 更新蛋名
const UPDATE_MEMBER_EGG_NAME = gql`
  mutation updateMemberEggName($id: Int!, $name: String!) {
    updateMemberEggName(id: $id, name: $name) {
      success
      message
    }
  }
`;
// NOTE 使用代碼
const FEEDING_CODE = gql`
  mutation useCode($eggId: Int!, $type: CodeType!, $code: String!) {
    feedingCode: useCode(eggId: $eggId, type: $type, code: $code) {
      success
      message
      # "玩具"
      toy {
        id
        # "孵化影片(後)"
        hatchingVideoUrl
      }
    }
  }
`;
// NOTE 變更蛋孵化狀態
const CHANGE_EGG_HATCHED = gql`
  mutation changeEggHatched($eggIds: [Int]!, $feedingSuccess: Boolean) {
    changeEggHatched(eggIds: $eggIds, feedingSuccess: $feedingSuccess) {
      success
      message
      # "蛋種"
      eggType {
        # "ID"
        id
        # "孵化影片(前)"
        hatchingVideoUrl
      }
      # "玩具"
      toy {
        # "ID"
        id
        # "孵化影片(後)"
        hatchingVideoUrl
      }
    }
  }
`;
// NOTE 餵食蛋
const FEEDING_EGG = gql`
  mutation feedingEgg($memberEggId: Int!) {
    feedingEgg(memberEggId: $memberEggId) {
      # "成功"
      success
      # "訊息"
      message
      # "已孵化"
      hatched
      # "動畫"
      video
    }
  }
`;
// !SECTION

const customDialogStyles = {
  padding: "16px 32px",
};

const DialogCloseIcon = ({ onClick = () => {} }) => {
  return (
    <Box display="flex" justifyContent="flex-end" height="32px">
      <CloseIcon
        onClick={onClick}
        width={15}
        height={15}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};

// ANCHOR 使用規範彈跳視窗路遙券兌換蛋
function RulesDialogRef({ onSubmit = () => {} }, ref) {
  const { handleSubmit, setValue, clearErrors } = useFormContext();
  const selectDrawEgg = useWatch({ name: "selectDrawEgg" });
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
    }),
    []
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    setValue("acceptRules", false);
    clearErrors("acceptRules");
  }, [setValue, clearErrors]);
  const _submit = useCallback(() => {
    onSubmit(selectDrawEgg);
    handleClose();
  }, [handleClose, onSubmit, selectDrawEgg]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box style={customDialogStyles}>
        <Typography
          color="error"
          paragraph
          style={{ fontWeight: 700, fontSize: "20px" }}
        >
          注意
        </Typography>
        <Typography paragraph variant="body2">
          每款玩具皆不同機率，總數量為固定數量，可於分頁中『圖鑑與活動』分頁查看，每次抽蛋系統將檢查庫存與機率後進行，若抽蛋過程中因環境網路不佳，可能產生無法觀看動畫影片問題，但不影響機率結果，可直接點選略過並於『我的玩具』查看結果，購買育成系統路遙蛋需等待育成時間完成後，點選『孵化』即可孵出我的玩具，出貨時間依官方設定公告時間後5-8天（不含假日）出貨。
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {/* 同意框框 */}
          <Controller
            name="acceptRules"
            rules={{
              required: "* 請同意使用規則",
            }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"acceptRules"}
                      checked={field.value}
                      onChange={(_, v) => field.onChange(v)}
                      color="primary"
                    />
                  }
                  label={"我同意路遙券使用規範"}
                />
                {error && (
                  <Typography variant="caption" component={"div"} color="error">
                    {" "}
                    {error.message}{" "}
                  </Typography>
                )}
              </div>
            )}
          />
          {/* Button */}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit(_submit)}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
export const RulesDialog = forwardRef(RulesDialogRef);

// ANCHOR 兌換蛋成功
function SuccessfullyExchangedEggsDialogRef(
  { onPlayVideo = () => {}, onClose = () => {} },
  ref
) {
  const history = useHistory();
  const useStyles = makeStyles({
    lookAtTheResult: {
      marginTop: "10px",
      border: "1px solid",
      textAlign: "center",
      "&:hover": {
        backgroundColor: "#f1f1f1",
      },
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [incomingData, setIncomingData] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setOpen(true);
      },
    }),
    []
  );
  const handleClose = useCallback(
    (e) => {
      if (e === "goto") {
        history.push("/special-event/my-cultivation");
      }
      setOpen(false);
      setIncomingData(null);
      onClose();
    },
    [onClose, history]
  );
  const findEggPumpingIndex = emptyArray(incomingData).findIndex(
    (item) => !Boolean(item.disassembled)
  );
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box style={customDialogStyles}>
        <Typography
          paragraph
          style={{ fontWeight: 700, fontSize: "16px", textAlign: "center" }}
        >
          請點選開獎，查看抽蛋結果。
          <br />
          (若黑屏無法顯示，可能與環境網路有關，可略過直接至我的育成查看。)
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {emptyArray(incomingData).map((item, index) => (
            <Grid key={item.id || index} item xs={6} sm={2}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: item.disassembled ? "default" : "pointer",
                }}
                onClick={() => {
                  if (!item.disassembled) {
                    onPlayVideo(item);
                    setOpen(false);
                  }
                }}
              >
                <img
                  src={
                    (item.disassembled && item.eggType?.gifUrl) ||
                    "/img/special/white-egg.png"
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                  className="shake"
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Box
            className={classes.lookAtTheResult}
            style={{
              height: "22.5px",
              width: findEggPumpingIndex > -1 ? "50px" : "120px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (findEggPumpingIndex > -1) {
                onPlayVideo(incomingData[findEggPumpingIndex]);
                setOpen(false);
              } else {
                handleClose("goto");
              }
            }}
          >
            <Typography paragraph style={{ fontWeight: 700, fontSize: "15px" }}>
              {findEggPumpingIndex > -1 ? "開獎" : "前往我的育成"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
export const SuccessfullyExchangedEggsDialog = forwardRef(
  SuccessfullyExchangedEggsDialogRef
);

// ANCHOR 查看我的蛋蛋
function CheckMyEggDialogRef(
  { onChangeName = () => {}, onFeeding = () => {}, onIncubation = () => {} },
  ref
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { notice } = useAlert();
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [hatchable, setHatchable] = useState(false);
  const [feedable, setFeedable] = useState(false);
  const event = useWatch({ name: "event" });
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setOpen(true);
      },
    }),
    []
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    setIncomingData(null);
  }, []);
  const hatchingTime = useMemo(() => {
    if (incomingData && event) {
      return addMinutes(parseISO(incomingData.createdAt), event.hatchingTime);
    }
  }, [incomingData, event]);
  const feedingTime = useMemo(() => {
    if (incomingData?.fedAt && event?.feedingCoolDownTime) {
      return addMinutes(
        parseISO(incomingData.fedAt),
        event.feedingCoolDownTime
      );
    }
  }, [incomingData, event]);
  useEffect(() => {
    if (hatchingTime) {
      setHatchable(isBefore(hatchingTime, new Date()));
    }
  }, [hatchingTime]);
  useEffect(() => {
    if (feedingTime) {
      setFeedable(isBefore(feedingTime, new Date()));
    }
  }, [feedingTime]);
  const [changeEggHatchedFn, { loading: changeEggHatchedLoading }] =
    useMutation(CHANGE_EGG_HATCHED, {
      onCompleted({ changeEggHatched: { message } }) {
        if (message) {
          setOpen(false);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
            return;
          }
          if (message) {
            setValue("memberEggsRefetch", true);
            notice(message);
          }
        } else {
          setOpen(false);
          onIncubation(incomingData);
        }
      },
      onError() {
        return null;
      },
    });
  const [feedingEggFn, { loading: feedingEggLoading }] = useMutation(
    FEEDING_EGG,
    {
      onCompleted({ feedingEgg: { success, message, hatched, video } }) {
        if (message) {
          setOpen(false);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
            return;
          }
          if (video) {
            setOpen(false);
            setValue("memberEggsRefetch", true);
            onIncubation({
              id: incomingData?.id,
              feeding: {
                message: "可愛的育成蛋蛋吃得很飽呢！",
                video,
              },
            });
            return;
          }
          if (message) {
            setValue("memberEggsRefetch", true);
            notice(message);
          }
        } else if (success) {
          if (hatched) {
            setOpen(false);
            onIncubation({
              id: incomingData?.id,
              feeding: {
                message: "可愛的育成蛋蛋餵食之後…好像孵化了！",
                video,
              },
            });
            return;
          }
        }
      },
      onError() {
        return null;
      },
    }
  );
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <LoadingModal loading={changeEggHatchedLoading || feedingEggLoading} />
      <Box style={customDialogStyles}>
        {/* header */}
        <DialogCloseIcon onClick={handleClose} />
        {/* content */}
        <Box paddingBottom="14px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  width={150}
                  height={150}
                  src={
                    incomingData?.eggType?.gifUrl ||
                    require("../../images/_luyao2019-removebg-preview.png")
                      .default
                  }
                  style={{
                    objectFit: "contain",
                    paddingBottom: !isMobile && "20px",
                  }}
                  alt="egg"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography paragraph>
                {incomingData?.name || "請選擇物品"}
              </Typography>
              <Typography paragraph variant="body2">
                {incomingData?.eggType?.introduction}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                size="small"
                flexWrap={"wrap"}
                style={{ gap: "8px" }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    setOpen(false);
                    onChangeName(incomingData);
                  }}
                >
                  重新命名
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    feedingEggFn({
                      variables: { memberEggId: incomingData?.id },
                    });
                  }}
                  disabled={feedingTime && !feedable}
                >
                  餵食
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    if (hatchable) {
                      changeEggHatchedFn({
                        variables: { eggIds: [incomingData.id] },
                      });
                    } else {
                      setOpen(false);
                      onFeeding({ ...incomingData, type: "HATCHING" });
                    }
                  }}
                >
                  {hatchable ? "立即孵化" : "輸入孵化代碼"}
                </Button>
                {feedingTime && !feedable && (
                  <DateCountdown
                    end={feedingTime}
                    onEnd={() => setFeedable(true)}
                    render={({
                      days,
                      hours = "0",
                      minutes = "0",
                      seconds = "0",
                    }) => {
                      const newHours = days > 0 ? days * 24 + hours : hours;
                      return (
                        <Typography>
                          餵食倒數&nbsp;|&nbsp;
                          {`${newHours}:${String(minutes).padStart(
                            2,
                            "0"
                          )}:${String(seconds).padStart(2, "0")}`}
                        </Typography>
                      );
                    }}
                  />
                )}
                {hatchingTime && !hatchable && (
                  <DateCountdown
                    end={hatchingTime}
                    onEnd={() => setHatchable(true)}
                    render={({
                      days,
                      hours = "0",
                      minutes = "0",
                      seconds = "0",
                    }) => {
                      const newHours = days > 0 ? days * 24 + hours : hours;
                      return (
                        <Typography>
                          孵化倒數&nbsp;|&nbsp;
                          {`${newHours}:${String(minutes).padStart(
                            2,
                            "0"
                          )}:${String(seconds).padStart(2, "0")}`}
                        </Typography>
                      );
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}
export const CheckMyEggDialog = forwardRef(CheckMyEggDialogRef);

// ANCHOR 查看我的玩具
function CheckMyToyDialogRef(
  {
    onChangeName = () => {},
    onFeeding = () => {},
    onCreateRaisingOrder = () => {},
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const event = useWatch({ name: "event" });
  const [incomingData, setIncomingData] = useState();
  const [dateOfShippedDisabled, setDateOfShippedDisabled] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setOpen(true);
      },
    }),
    []
  );
  useEffect(() => {
    if (open) {
      if (event?.dateOfShipped) {
        try {
          setDateOfShippedDisabled(
            dateIsBefore(new Date(), event.dateOfShipped, "minute")
          );
        } catch (error) {
          console.log("error", error);
          return null;
        }
      }
    }
  }, [event, open]);
  const handleClose = useCallback(() => {
    setOpen(false);
    setIncomingData(null);
  }, []);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box style={customDialogStyles}>
        {/* header */}
        <DialogCloseIcon onClick={handleClose} />
        {/* content */}
        <Box paddingBottom="14px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  width={150}
                  height={150}
                  src={
                    incomingData?.toy?.gifUrl ||
                    require("../../images/_luyao2019-removebg-preview.png")
                      .default
                  }
                  style={{ objectFit: "contain" }}
                  alt="toy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography paragraph>
                {incomingData?.name || "請選擇物品"}
              </Typography>
              <Typography paragraph variant="body2">
                {incomingData?.toy?.introduction}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                size="small"
                flexWrap={"wrap"}
                style={{ gap: "8px" }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    setOpen(false);
                    onChangeName(incomingData);
                  }}
                >
                  重新命名
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    setOpen(false);
                    onFeeding({ ...incomingData, type: "FEEDING" });
                  }}
                >
                  進化
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ minHeight: "28px" }}
                  onClick={() => {
                    setOpen(false);
                    onCreateRaisingOrder(incomingData?.id);
                  }}
                  disabled={dateOfShippedDisabled || incomingData?.createdOrder}
                >
                  {!incomingData?.createdOrder
                    ? event?.dateOfShipped
                      ? dateOfShippedDisabled
                        ? `
                    可出貨時間 | 
                    ${format(
                      parseISO(event?.dateOfShipped),
                      "yyyy/LL/dd HH:mm"
                    )}`
                        : "出貨"
                      : "出貨"
                    : "已建立出貨單"}
                </Button>
                {incomingData?.hatchedAt && (
                  <Typography varinat="body2">
                    孵化日期&nbsp;|&nbsp;
                    {format(parseISO(incomingData.hatchedAt), "yyyy/LL/dd")}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}
export const CheckMyToyDialog = forwardRef(CheckMyToyDialogRef);

// ANCHOR 選購彈跳視窗 - 路遙券
function OptionBuyDialogRef({ onSubmit = () => {} }, ref) {
  const event = useWatch({ name: "event" });
  const quantity = useWatch({ name: "quantity" });
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
    }),
    []
  );
  const handleClose = useCallback(() => setOpen(false), []);
  const payThePrice = useMemo(
    () => (event?.ticketFare || 0) * quantity,
    [event, quantity]
  );
  const _submit = useCallback(() => {
    onSubmit(quantity);
    handleClose();
  }, [handleClose, onSubmit, quantity]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box style={customDialogStyles}>
        <Typography
          color="error"
          paragraph
          style={{ fontWeight: 700, fontSize: "20px" }}
        >
          注意
        </Typography>
        <Typography color="#4C6069">
          你的選擇為{quantity}
          數量路遙券購買(新台幣{payThePrice}
          金額)，本人符合法定年齡18歲以上並同意官方公布『圖鑑與活動』與『遊戲說明』分頁說明機率之結果與規章。
          <br />
          購買路遙券為了避免損失權益，請於金流介面一次完成繳費，避免遺失訂單與名額，進入金流後，購買訂單若當機、跳出、遺失等情況，順序內之名額帳號，可至會員中心&nbsp;&gt;&nbsp;代付款區查看訂單，並於5分鐘內繳費完成避免取消訂單，若名額外則無訂單。
          <br />
          購買完成之路遙券同擁有購買育成蛋之名額，可參與育成蛋抽獎，路遙券請務必於該場育成活動結束前使用，若未於該場次結束前使用完畢，系統將依照官方公告之機率『自動轉換為育成玩具』後出貨。
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "20px",
          }}
        >
          {/* Button */}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={_submit}
          >
            確定
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            取消
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
export const OptionBuyDialog = forwardRef(OptionBuyDialogRef);

// ANCHOR 購買路遙券成功彈跳
function BuyTicketSuccessDialogRef(props, ref) {
  const history = useHistory();
  const { setValue } = useFormContext();
  const order = useWatch({ name: "order" });
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
    }),
    []
  );
  const handleClose = useCallback(
    (e) => {
      useHatchingOrderIdStore.getState().cleanHatchingOrderId();
      setValue("order", null);
      if (e === "goto") {
        history.push("/member");
      }
      setOpen(false);
    },
    [setValue, history]
  );
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box style={customDialogStyles}>
        <Typography
          color="error"
          paragraph
          style={{ fontWeight: 700, fontSize: "20px" }}
        >
          注意
        </Typography>
        <Box minHeight="100px" display="flex" alignItems="center">
          <Typography color="#4C6069">
            {order?.items[0]?.amount}張路遙券購買成功。
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          {/* Button */}

          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleClose("goto")}
          >
            返回會員中心
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
export const BuyTicketSuccessDialog = forwardRef(BuyTicketSuccessDialogRef);

// ANCHOR 輸入餵食/孵化代碼
function EnterCodeDialogRef(
  { onIncubation = () => {}, onFeeding = () => {} },
  ref
) {
  const { notice } = useAlert();
  const { handleSubmit, setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [incomingData, setIncomingData] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setOpen(true);
      },
    }),
    []
  );
  const [changeEggHatchedFn, { loading: changeEggHatchedLoading }] =
    useMutation(CHANGE_EGG_HATCHED, {
      onCompleted({ changeEggHatched: { message, eggType, toy } }) {
        if (message) {
          notice(message);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
          }
        } else {
          onIncubation({
            id: incomingData?.id,
            eggType,
            toy,
          });
        }
      },
      onError() {
        return null;
      },
    });
  const [feedingCodeFn, { loading: feedingCodeLoading }] = useMutation(
    FEEDING_CODE,
    {
      onCompleted({ feedingCode: { message, toy } }) {
        if (message) {
          notice(message);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
          }
        } else {
          setValue("enterCode", "");
          setOpen(false);
          if (incomingData?.type === "HATCHING") {
            changeEggHatchedFn({
              variables: { eggIds: [incomingData.id] },
            });
          } else if (toy) {
            onFeeding({ toy });
          }
        }
      },
      onError() {
        return null;
      },
    }
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    setIncomingData(null);
    setValue("enterCode", "");
  }, [setValue]);
  const onSubmit = useCallback(
    ({ enterCode }) => {
      feedingCodeFn({
        variables: {
          eggId: incomingData?.id,
          type: incomingData?.type,
          code: enterCode,
        },
      });
    },
    [feedingCodeFn, incomingData]
  );
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <LoadingModal loading={feedingCodeLoading || changeEggHatchedLoading} />
      <Box style={customDialogStyles}>
        <Typography
          color="error"
          paragraph
          style={{ fontWeight: 700, fontSize: "20px" }}
        >
          輸入{incomingData?.type === "FEEDING" ? "進化" : "孵化"}代碼
        </Typography>
        <Box minHeight="100px" display="flex" alignItems="center">
          <Controller
            name="enterCode"
            rules={{
              required: "* 請輸入代碼",
            }}
            render={({ field, fieldState: { error } }) => (
              <div style={{ width: "100%" }}>
                <TextField
                  {...field}
                  fullWidth
                  placeholder="請輸入代碼"
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              </div>
            )}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          {/* Button */}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            確定使用
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            取消
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
export const EnterCodeDialog = forwardRef(EnterCodeDialogRef);

// ANCHOR 改名浮層
function ChangeNameDialogRef(props, ref) {
  const { notice } = useAlert();
  const { handleSubmit, setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [incomingData, setIncomingData] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setValue("changeNameText", value?.name);
        setOpen(true);
      },
    }),
    [setValue]
  );

  const [updateMemberEggNameFn, { loading: updateMemberEggNameLoading }] =
    useMutation(UPDATE_MEMBER_EGG_NAME, {
      onCompleted({ updateMemberEggName: { message } }) {
        if (message) {
          notice(message);
          if (message.search("登入") > -1) {
            useTokenStore.getState().cleanToken();
          }
        } else {
          setValue("memberEggsRefetch", true);
          setOpen(false);
          setIncomingData(null);
          setValue("changeNameText", "");
        }
      },
      onError() {
        return null;
      },
    });

  const handleClose = useCallback(() => {
    setOpen(false);
    setIncomingData(null);
    setValue("changeNameText", "");
  }, [setValue]);
  const onSubmit = useCallback(
    ({ changeNameText }) => {
      updateMemberEggNameFn({
        variables: { id: incomingData?.id, name: changeNameText },
      });
    },
    [updateMemberEggNameFn, incomingData]
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <LoadingModal loading={updateMemberEggNameLoading} />
      <Box style={customDialogStyles}>
        {/* header */}
        <DialogCloseIcon onClick={handleClose} />
        {/* content */}
        <Box paddingBottom="14px">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  width={150}
                  height={150}
                  src={
                    incomingData?.eggType?.gifUrl ||
                    incomingData?.toy?.gifUrl ||
                    require("../../images/_luyao2019-removebg-preview.png")
                      .default
                  }
                  style={{ objectFit: "contain" }}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography paragraph> 輸入修改名稱 </Typography>
              <Box minHeight="100px" alignItems="center" display="flex">
                <Controller
                  name="changeNameText"
                  rules={{
                    required: "* 請輸入名稱",
                    validate: (e) => {
                      if (
                        e &&
                        (checkSpecialSymbols.test(e) ||
                          halfShapeAndFullForm(e) > 10)
                      ) {
                        return "名稱須為10個字元以內(不可含特殊符號)";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <div style={{ width: "100%" }}>
                      <TextField
                        {...field}
                        fullWidth
                        placeholder="請輸入名稱"
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    </div>
                  )}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ gap: "8px" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  確定修改
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  取消
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}
export const ChangeNameDialog = forwardRef(ChangeNameDialogRef);
