import { produce } from "immer";

/** - 處理推入字串陣列 */
export default function stringArrayPick(options, item) {
  return produce(options, (draft) => {
    const findIndex = draft.findIndex((i) => i.value === item.value);
    if (findIndex === -1) {
      draft.push(item);
    } else {
      draft.splice(findIndex, 1);
    }
  });
}
