import React from "react";
import { useForm, Controller } from "react-hook-form";

// component
import Grid from "../component/Grid";
import TabView from "../component/TabView";
import HatchingManage from "./component/HatchingManage";

const tabViewData = ["育成活動列表"];
export default function HatchingListPage() {
  const { control } = useForm({
    defaultValues: {
      select: 0,
    },
  });

  return (
    <Grid>
      <Controller
        control={control}
        name="select"
        render={({ field }) => (
          <TabView
            paper
            tabs={tabViewData}
            index={field.value}
            onChange={(e) => {
              field.onChange(e);
            }}
          >
            <HatchingManage />
          </TabView>
        )}
      />
    </Grid>
  );
}
