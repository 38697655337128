import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaded: false,
  showBoard: false,
  playMusic: true,
  numberOfPlayer: 0,
  maxHealthPoint: 0,
  currentHealthPoint: 0,
  // "海盜分數"
  pirateScore: 0,
  // "探險家分數"
  adventurerScore: 0,
  // "獲勝隊伍"
  winningTeam: null,
  remainingTime: 0,
  score: 0,
  /**
   * @type { "READY_TO_START" | "DURING_THE_GAME" | "VICTORY" | "DEFEAT"}
   */
  status: null,
  damagePerAttack: 0,
  /**
   * @type {Date}
   */
  startTime: null,
  /**
   * @type {Date}
   */
  endTime: null,
  showResult: false,
};

export const raidMonster = createSlice({
  name: "raidMonster",
  initialState,
  reducers: {
    // SECTION 初始值相關
    /**
     * @param {{payload: initialState}} action
     */
    setLoadInitialValue: (state, action) => {
      for (const key in action.payload) {
        if (key !== "loaded") {
          state[key] = action.payload[key];
        }
      }
      state.loaded = true;
    },
    restRaidSetting: (state) => {
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    // !SECTION
    // SECTION 設定參數
    // NOTE 剩餘時間
    /**
     * @param {{payload: initialState['remainingTime']}} action
     */
    setRemainingTime: (state, action) => {
      state.remainingTime = action.payload;
    },
    // NOTE 音樂
    /**
     * @param {{payload: initialState['playMusic']}} action
     */
    setPlayMusic: (state, action) => {
      state.playMusic = action.payload;
    },
    // NOTE 玩家人數
    /**
     * @param {{payload: initialState['numberOfPlayer']}} action
     */
    setNumberOfPlayer: (state, action) => {
      state.numberOfPlayer = action.payload;
    },
    // NOTE 當前血量
    /**
     * @param {{payload: initialState['currentHealthPoint']}} action
     */
    setCurrentHealthPoint: (state, action) => {
      state.currentHealthPoint = action.payload;
    },
    // NOTE 分數
    /**
     * @param {{payload: initialState['score']}} action
     */
    setScore: (state, action) => {
      state.score = action.payload;
    },
    // NOTE 海盜分數
    /**
     * @param {{payload: initialState['pirateScore']}} action
     */
    setPirateScore: (state, action) => {
      state.pirateScore = action.payload;
    },
    // NOTE 探險家分數
    /**
     * @param {{payload: initialState['adventurerScore']}} action
     */
    setAdventurerScore: (state, action) => {
      state.adventurerScore = action.payload;
    },
    // NOTE 獲勝隊伍
    /**
     * @param {{payload: initialState['winningTeam']}} action
     */
    setWinningTeam: (state, action) => {
      state.winningTeam = action.payload;
    },
    // NOTE 遊戲狀態
    /**
     * @param {{payload: initialState['status']}} action
     */
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    // NOTE 開始時間
    /**
     * @param {{payload: initialState['startTime']}} action
     */
    setStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    // NOTE 結束時間
    /**
     * @param {{payload: initialState['endTime']}} action
     */
    setEndTime: (state, action) => {
      state.endTime = action.payload;
    },
    // NOTE 顯示資訊
    /**
     * @param {{payload: initialState['showBoard']}} action
     */
    setShowBoard: (state, action) => {
      state.showBoard = Boolean(action.payload);
    },
    // NOTE 顯示結果
    /**
     * @param {{payload: initialState['showBoard']}} action
     */
    setShowResult: (state, action) => {
      state.showResult = Boolean(action.payload);
    },
    // !SECTION
  },
});

export const {
  setLoadInitialValue,
  restRaidSetting,
  setRemainingTime,
  setPlayMusic,
  setNumberOfPlayer,
  setCurrentHealthPoint,
  setScore,
  setPirateScore,
  setAdventurerScore,
  setWinningTeam,
  setStatus,
  setStartTime,
  setEndTime,
  setShowBoard,
  setShowResult,
} = raidMonster.actions;

export default raidMonster.reducer;
