import create from "zustand";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} fetchMemberTicketsPreviousToken - fetchMemberTicketsPreviousToken
 * @property {string} fetchMemberEggsPreviousToken - fetchMemberEggsPreviousToken
 * @property {boolean} directedToPayment - directedToPayment
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  fetchMemberTicketsPreviousToken: null,
  fetchMemberEggsPreviousToken: null,
  directedToPayment: false,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} setFetchMemberTicketsPreviousToken - setFetchMemberTicketsPreviousToken
 * @property {() => void} setFetchMemberEggsPreviousToken - setFetchMemberEggsPreviousToken
 * @property {() => void} setDirectedToPayment - setDirectedToPayment
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  setFetchMemberTicketsPreviousToken: (fetchMemberTicketsPreviousToken) => {
    return new Promise((resolve, reject) => {
      if (
        fetchMemberTicketsPreviousToken &&
        typeof fetchMemberTicketsPreviousToken === "string"
      ) {
        set({ fetchMemberTicketsPreviousToken });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  setFetchMemberEggsPreviousToken: (fetchMemberEggsPreviousToken) => {
    return new Promise((resolve, reject) => {
      if (
        fetchMemberEggsPreviousToken &&
        typeof fetchMemberEggsPreviousToken === "string"
      ) {
        set({ fetchMemberEggsPreviousToken });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  setDirectedToPayment: (directedToPayment) => {
    return new Promise((resolve, reject) => {
      if (directedToPayment && typeof directedToPayment === "boolean") {
        set({ directedToPayment });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useHatchingStore = create((set, get) => ({
  ...initialState,
  ...changeState(set, get),
}));
export default useHatchingStore;
