import React from "react";
import PropTypes from "prop-types";

export default function Image({
  source,
  resizeMode = "stretch",
  style = {},
  onPress = () => {},
  ...props
}) {
  const resizeModeList = {
    cover: "cover",
    contain: "contain",
    stretch: "fill",
    repeat: "none",
    center: "scale-down",
  };
  return (
    <img
      className="game-area"
      src={source}
      style={{ objectFit: resizeModeList[resizeMode], ...style }}
      {...props}
      onClick={onPress}
      alt=""
    />
  );
}
Image.propTypes = {
  source: PropTypes.string,
  resizeMode: PropTypes.oneOf([
    "cover",
    "contain",
    "stretch",
    "repeat",
    "center",
  ]),
  style: PropTypes.object,
  onPress: PropTypes.func,
};
