// mui
import { Box, Grid, useMediaQuery } from "@material-ui/core";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import AdaptiveImage from "../../../component/AdaptiveImage";
import Image from "../../../component/Image";
import CopyrightRender from "./component/CopyrightRender";
import PageIsland from "./component/PageIsland";
import SwitchNavTabs from "./component/SwitchNavTabs";
import { useMemo } from "react";

// ANCHOR 主要組件
export default function IllustratePage() {
  return (
    <BackgroundContainer>
      <PageIsland />
      <IllustrateContent />
    </BackgroundContainer>
  );
}

export function useGetMarginTop() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const is900 = useMediaQuery("(max-width:900px)");
  const is800 = useMediaQuery("(max-width:800px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const is600 = useMediaQuery("(max-width:600px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const is400 = useMediaQuery("(max-width:400px)");
  const marginTop = useMemo(() => {
    if (is400) {
      return -130;
    } else if (isDownMobile) {
      return -150;
    } else if (is600) {
      return -180;
    } else if (isMobile) {
      return -200;
    } else if (is800) {
      return -200;
    } else if (is900) {
      return -250;
    } else if (isTable) {
      return -250;
    } else if (isDownPC) {
      return -250;
    } else if (isPC) {
      return -250;
    }
    return -250;
  }, [
    isPC,
    isDownPC,
    isTable,
    is900,
    is800,
    isMobile,
    is600,
    isDownMobile,
    is400,
  ]);
  return marginTop;
}

// ANCHOR 內容
function IllustrateContent() {
  const marginTop = useGetMarginTop();
  return (
    <Box style={{ marginTop }}>
      <Grid container spacing={3} style={{ marginTop: "40px" }}>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/crystal-palace/圖鑑.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
      </Grid>
      <CopyrightRender />
      <SwitchNavTabs />
    </Box>
  );
}
