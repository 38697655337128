import { createElement } from "react";
import { Provider } from "jotai";

/** - `jotai`的`hoc` */
export default function withJotai(component, store) {
  return (props) => {
    const customRef = props.customRef;
    return (
      <Provider store={store}>
        {createElement(component, { ...props, ref: customRef })}
      </Provider>
    );
  };
}
