import { useMemo } from "react";
import divisions from "../divisions.json";

export default function useDivision(initState = "") {
  const divisionKey = useMemo(() => {
    const newDivisions = [];
    Object.keys(divisions).forEach((item) => {
      newDivisions.push({
        label: divisions[item].zhTW,
        value: item,
      });
    });
    return newDivisions;
  }, []);

  const subdivisionsKey = useMemo(() => {
    const newSubdistrict = [];
    if (initState) {
      Object.keys(divisions[initState].subdivisions).forEach((item) => {
        newSubdistrict.push({
          label: divisions[initState].subdivisions[item].zhTW,
          value: item,
        });
      });
    }
    return newSubdistrict;
  }, [initState]);

  return { divisionKey, subdivisionsKey };
}
