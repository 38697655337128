import React, {
  createElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { deepmerge } from "@material-ui/utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import ImageIcon from "@material-ui/icons/Image";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PropTypes from "prop-types";
import { useAlert } from "./Alert";
import Text from "./Text";
// react-number-format
import { NumericFormat } from "react-number-format";
// hookform
import { useWatch, useFormContext } from "react-hook-form";

function checkValueUndfined(
  newValue,
  onChange = () => {},
  setValue,
  error = false
) {
  let temp = onChange(newValue, error);
  if (typeof temp !== "undefined") {
    return setValue(temp);
  }
}
export function TextInput({
  value,
  length,
  fullWidth,
  minLength,
  maxLength,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  ...props
}) {
  const [internalValue, setInternalValue] = useState("");
  function error() {
    const valueLength = internalValue.length;
    if (internalValue) {
      if (length) {
        return valueLength !== length;
      } else {
        return valueLength < minLength || valueLength > maxLength;
      }
    } else {
      return false;
    }
  }
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  return (
    <TextField
      {...props}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value)}
      onBlur={(e) => {
        const newValue = e.target.value;
        checkValueUndfined(newValue, onChange, setInternalValue);
        onBlur(newValue);
      }}
      onFocus={onFocus}
      error={error()}
      fullWidth={fullWidth}
      helperText={error() && "格式錯誤"}
    />
  );
}
TextInput.prototype = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  length: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  fullWidth: PropTypes.bool,
};

// textfield
export function CustomTextField({ style, size = "small", rows = 1, ...props }) {
  const fieldHeight = (() => {
    switch (size) {
      case "small":
        return 38;
      default:
        return 40;
    }
  })();

  // styles for input 、 textfield
  const textfieldStyles = {
    minWidth: "80px",
    borderRadius: "5px",
    "& .MuiFormHelperText-root": {
      ml: 0,
      fontSize: "12px",
      color: props.error ? "error.main" : "#212121",
    },
    ".MuiOutlinedInput-root": {
      minHeight: rows !== 1 ? rows * fieldHeight : fieldHeight,
      bgcolor: "#fff",
      fontSize: "15px",
      backgroundColor: "#fffcf6",
    },
    "& .Mui-disabled": {
      bgcolor: "#F7F7F7",
      borderRadius: "5px",
    },
    ...style,
  };

  return (
    <TextField
      fullWidth
      size={size}
      // styles
      {...deepmerge(
        {
          inputProps: {
            style: {
              input: {
                height: 38,
              },
            },
          },
        },
        props
      )}
      style={textfieldStyles}
    />
  );
}

// ANCHOR custom number input
export function CustomNumberInput({ ...props }) {
  return (
    <NumericFormat decimalScale={2} {...props} customInput={CustomTextField} />
  );
}

export function TelInput({
  value,
  fullWidth,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) {
  const [internalValue, setInternalValue] = useState("");
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  function error() {
    if (internalValue) {
      const regTel = new RegExp("^[0-9+#]*$");
      return !regTel.test(internalValue);
    } else {
      return false;
    }
  }
  return (
    <TextField
      {...props}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value)}
      onBlur={(e) => {
        const newValue = e.target.value;
        checkValueUndfined(newValue, onChange, setInternalValue);
        onBlur(newValue);
      }}
      error={error()}
      fullWidth={fullWidth}
      helperText={error() && "格式錯誤"}
    />
  );
}
TelInput.prototype = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fullWidth: PropTypes.bool,
};
export function NumberInput({
  value,
  fullWidth,
  onChange = () => {},
  onBlur = () => {},
  thousandSeparator,
  percentageValueAllowed,
  ...props
}) {
  const [internalValue, setInternalValue] = useState("");
  useEffect(() => {
    setInternalValue(
      typeof value === "number" || typeof value === "string"
        ? String(value)
        : "" || ""
    );
  }, [value]);
  function error() {
    if (internalValue) {
      const regNum = percentageValueAllowed
        ? new RegExp("^[0-9.+-.%]*$")
        : new RegExp("^[0-9.+-]*$");
      return !regNum.test(internalValue);
    } else {
      return false;
    }
  }
  function addThousandSeparator(number) {
    const reg = /(\d)(?=(?:\d{3})+$)/g;
    let newNumber = number.split(".");
    return (
      newNumber[0].replace(reg, "$1,") +
      (typeof newNumber[1] !== "undefined" ? "." + newNumber[1] : "")
    );
  }
  function removeThousandSeparator(number) {
    return String(number).replace(/,/g, "");
  }
  return (
    <TextField
      {...props}
      value={
        thousandSeparator ? addThousandSeparator(internalValue) : internalValue
      }
      onChange={(e) => {
        const newE = e.target.value;
        setTimeout(() => {
          setInternalValue(removeThousandSeparator(newE));
        }, 0);
      }}
      onBlur={(e) => {
        const newValue = e.target.value;
        checkValueUndfined(
          removeThousandSeparator(newValue),
          onChange,
          setInternalValue,
          error()
        );
        onBlur(newValue);
      }}
      error={error()}
      fullWidth={fullWidth}
      helperText={error() && "格式錯誤"}
    />
  );
}
NumberInput.prototype = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fullWidth: PropTypes.bool,
  thousandSeparator: PropTypes.bool,
};

export function EmailInput({
  value,
  fullWidth,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) {
  const [internalValue, setInternalValue] = useState("");
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  function error() {
    if (internalValue) {
      const regMail = new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
      );
      return !regMail.test(internalValue);
    } else {
      return false;
    }
  }
  return (
    <TextField
      {...props}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value)}
      onBlur={(e) => {
        const newValue = e.target.value;
        checkValueUndfined(newValue, onChange, setInternalValue);
        onBlur(newValue);
      }}
      error={error()}
      fullWidth={fullWidth}
      helperText={error() && "格式錯誤"}
    />
  );
}
EmailInput.prototype = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export function PasswordWordInput({
  value,
  length,
  minLength,
  maxLength,
  fullWidth,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) {
  const [internalValue, setInternalValue] = useState("");
  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  const [showPassword, setShowPassword] = useState(false);
  function error() {
    if (internalValue) {
      const valueLength = internalValue.length;
      if (length) {
        return valueLength !== length;
      } else {
        return valueLength < minLength || valueLength > maxLength;
      }
    } else {
      return false;
    }
  }
  return (
    <TextField
      {...props}
      value={internalValue}
      onChange={(e) => setInternalValue(e.target.value)}
      onBlur={(e) => {
        const newValue = e.target.value;
        checkValueUndfined(newValue, onChange, setInternalValue);
        onBlur(newValue);
      }}
      error={error()}
      helperText={error() && "格式錯誤"}
      type={showPassword ? "text" : "password"}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setShowPassword((e) => !e)}>
              {createElement(showPassword ? VisibilityIcon : VisibilityOffIcon)}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
PasswordWordInput.prototype = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  length: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  fullWidth: PropTypes.bool,
};
export function SingleFileUpload({
  value,
  label,
  fullWidth,
  onChange = () => {},
  ...props
}) {
  const Alert = useAlert();
  const inputRef = useRef(() => {});
  const [lockInput, setLockInput] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  function _delete() {
    Alert.alert("", "確認刪除?", [
      { text: "取消", type: "cancel" },
      { text: "確認", onPress: () => onChange(), type: "ok" },
    ]);
  }
  function _closeList() {
    setOpenFile(false);
  }
  return (
    <>
      <TextField
        {...props}
        fullWidth={fullWidth}
        label={label}
        value={value || ""}
        onClick={() => {
          if (!lockInput) {
            if (value) {
              setOpenFile(true);
            } else {
              inputRef.current.click();
            }
          }
        }}
        InputProps={{
          endAdornment: value && (
            <InputAdornment>
              <IconButton
                size="small"
                onClick={_delete}
                onMouseDown={() => setLockInput(true)}
              >
                <DeleteIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={openFile} onClose={_closeList} fullWidth>
        <DialogContent>
          <ListItem>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText>{value && value.name}</ListItemText>
          </ListItem>
        </DialogContent>
        <DialogActions>
          <Button onClick={_closeList}>關閉</Button>
        </DialogActions>
      </Dialog>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </>
  );
}
SingleFileUpload.prototype = {
  label: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export function MultiFilesUpload({
  value = [],
  label,
  fullWidth,
  onChange = () => {},
  ...props
}) {
  const Alert = useAlert();
  const inputRef = useRef(() => {});
  const checkArray = Array.isArray(value);
  const initialized = checkArray && value.length > 0;
  const [openFile, setOpenFile] = useState(false);
  function _delete(index, name) {
    Alert.alert("", `確認刪除${name}？`, [
      { text: "取消", type: "cancel" },
      {
        text: "確認",
        onClick: () =>
          onChange(value.filter((item2, index2) => index !== index2)),
        type: "ok",
      },
    ]);
  }
  function _upload() {
    inputRef.current.click();
  }
  function _closeFlie() {
    setOpenFile(false);
  }
  useEffect(() => {
    if (!initialized) {
      setOpenFile(false);
    }
  }, [value, initialized]);
  return (
    <>
      <TextField
        {...props}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onClick={() => {
          initialized ? setOpenFile(true) : _upload();
        }}
        InputProps={{
          endAdornment: (
            <>
              {initialized ? (
                <InputAdornment>
                  <Text>共&ensp;{value.length}&ensp;個</Text>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
      />
      <Dialog open={openFile} onClose={_closeFlie} fullWidth>
        <DialogContent>
          {checkArray &&
            value.map((item, index) => (
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <Typography
                  style={{ display: "flex", flex: 1 }}
                  variant="inherit"
                  noWrap
                >
                  {item.name}
                </Typography>
                <IconButton onClick={() => _delete(index, item.name)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={_upload}>
            上傳更多
          </Button>
          <Button color="primary" variant="outlined" onClick={_closeFlie}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      <input
        ref={inputRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={(e) =>
          onChange((orgin) => {
            if (orgin) {
              return [...orgin, ...e.target.files];
            } else {
              return [...e.target.files];
            }
          })
        }
      />
    </>
  );
}
MultiFilesUpload.prototype = {
  label: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export function SingleImageUpload({
  value,
  label,
  fullWidth,
  onChange = () => {},
  ...props
}) {
  const Alert = useAlert();
  const inputRef = useRef(() => {});
  const [openImage, setOpenImage] = useState(false);
  const [lockInput, setLockInput] = useState(false);
  function _delete() {
    Alert.alert("", "確認刪除?", [
      { text: "取消", onPress: unLockInput, type: "cancel" },
      {
        text: "確認",
        onPress: () => {
          onChange(undefined);
          unLockInput();
        },
        type: "ok",
      },
    ]);
  }
  function unLockInput() {
    setLockInput(false);
  }
  function _closeImage() {
    setOpenImage(false);
    unLockInput();
  }
  useEffect(() => {
    console.log(value);
  });
  return (
    <>
      <TextField
        {...props}
        fullWidth={fullWidth}
        label={label}
        value={value || ""}
        onClick={() => {
          if (!lockInput) {
            if (value) {
              setOpenImage(true);
            } else {
              inputRef.current.click();
            }
          }
        }}
        InputProps={{
          endAdornment: value && (
            <InputAdornment>
              <IconButton
                size="small"
                onClick={_delete}
                onMouseDown={() => setLockInput(true)}
              >
                <DeleteIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={openImage} onClose={_closeImage} fullWidth>
        <DialogContent>
          {value && (
            <img
              src={value}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={_closeImage}>關閉</Button>
        </DialogActions>
      </Dialog>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </>
  );
}
SingleImageUpload.prototype = {
  label: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export function MultiImagesUpload({
  value = [],
  label,
  fullWidth,
  onChange = () => {},
  ...props
}) {
  const Alert = useAlert();
  const inputRef = useRef();
  const checkArray = Array.isArray(value);
  const initialized = checkArray && value.length > 0;
  const [openList, setOpenList] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  function _delete(index, name) {
    Alert.alert("", `確認刪除${name}？`, [
      { text: "取消", type: "cancel" },
      {
        text: "確認",
        onPress: () =>
          onChange(value.filter((item2, index2) => index !== index2)),
        type: "ok",
      },
    ]);
  }
  function _upload() {
    inputRef.current.click();
  }
  function _closeList() {
    setOpenList(false);
  }
  function _closeImage() {
    setOpenImage(false);
  }
  useEffect(() => {
    if (!initialized) {
      setOpenList(false);
    }
  }, [value, initialized]);
  return (
    <>
      <TextField
        {...props}
        fullWidth={fullWidth}
        label={label}
        value={value}
        onClick={() => {
          initialized ? setOpenList(true) : _upload();
        }}
        InputProps={{
          endAdornment: (
            <>
              {initialized ? (
                <InputAdornment>
                  <Text>共&ensp;{value.length}&ensp;個</Text>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
      />
      <Dialog open={openList} onClose={_closeList} fullWidth>
        <DialogContent>
          {checkArray &&
            value.map((item, index) => (
              <ListItem>
                <ListItemIcon>
                  <ImageIcon />
                </ListItemIcon>
                <Typography
                  style={{ display: "flex", flex: 1 }}
                  variant="inherit"
                  noWrap
                >
                  {item.name}
                </Typography>
                <IconButton onClick={() => setOpenImage(index)}>
                  <ImageSearchIcon />
                </IconButton>
                <IconButton onClick={() => _delete(index, item.name)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={_upload}>
            上傳更多
          </Button>
          <Button color="primary" variant="outlined" onClick={_closeList}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={typeof openImage === "number"}
        onClose={_closeImage}
        fullWidth
      >
        <DialogContent>
          {checkArray && (
            <img
              src={value[openImage]}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={_closeImage}>關閉</Button>
        </DialogActions>
      </Dialog>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={(e) =>
          onChange((orgin) => {
            if (orgin) {
              return [...orgin, ...e.target.files];
            } else {
              return [...e.target.files];
            }
          })
        }
      />
    </>
  );
}
MultiImagesUpload.prototype = {
  label: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export function Select({
  value,
  label,
  fullWidth,
  onChange = () => {},
  items = [],
  disabled,
  ...props
}) {
  //{label:標題(固定),value:值(固定)}
  return (
    <FormControl variant="outlined" size="small" fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...props}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        disabled={disabled}
      >
        <MenuItem value={undefined} disabled>
          請選擇
        </MenuItem>
        {items.length > 0 &&
          items.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
}

Select.prototype = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  items: PropTypes.array,
};

export function AutocompleteSelect({
  placeholder,
  value,
  label,
  fullWidth,
  disabled,
  onChange = () => {},
  items = [],
  error,
  ...props
}) {
  return (
    <Autocomplete
      {...props}
      options={items}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) =>
        value && option.value === value.value
      }
      ListboxProps={{ style: { maxHeight: "14.5rem" } }}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={onChange}
      value={value ? value : null}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          error={error}
          helperText={error?.message}
        />
      )}
    />
  );
}

export function AutocompleteFocusSelect({
  open = false,
  label,
  items = [],
  onInputChange = () => {},
  value,
  onChange = () => {},
  renderOption = () => {},
  fullWidth,
  disabled,
  loading = false,
  ...props
}) {
  return (
    <Autocomplete
      {...props}
      open={open}
      options={items}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) =>
        value && option.value === value.value
      }
      ListboxProps={{ style: { maxHeight: "14.5rem" } }}
      value={value ? value : null}
      onChange={onChange}
      renderOption={renderOption}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      noOptionsText={"暫無資料"}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
export function DateInput({
  value,
  label = "",
  format = "",
  openTo,
  views,
  fullWidth,
  minDate,
  onChange = () => {},
}) {
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      format={format}
      openTo={openTo}
      minDate={minDate}
      label={label}
      views={views}
      fullWidth={fullWidth}
    />
  );
}
DateInput.prototype = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  format: PropTypes.string,
  openTo: PropTypes.oneOf(["date", "year", "month"]),
  views: PropTypes.array,
};

// CustomDateSelect
export function CustomDateSelect({ style, value, ...props }) {
  const [existingDate, setExistingDate] = useState(null);

  useEffect(() => {
    if (typeof value !== "undefined") {
      setExistingDate(value);
    }
  }, [value]);
  return (
    <DateTimePicker
      {...props}
      ampm={false}
      value={existingDate}
      onChange={setExistingDate}
      format="yyyy年MM月dd日 HH點mm分"
      showToolbar={false}
      renderInput={(params) => (
        <TextField
          style={style}
          error={props?.error}
          helperText={props?.error?.message}
          disabled={Boolean(props?.readOnly)}
          {...params}
          {...props}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" size="small">
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

//InputTitle
export const InputTitle = ({
  label,
  color = "primary",
  required = false,
  style,
  children,
  ...props
}) => {
  const labelStyles = {
    color,
    marginBottom: "7px",
    minHeight: "14px",
    lineHeight: "1em",
    ...style,
  };
  return (
    <Box
      display="flex"
      justifyContent={children ? "space-between" : "flex-start"}
    >
      <Typography style={labelStyles} fontSize="14px" {...props}>
        {label}
        {required && (
          <Typography
            color="red"
            component={"span"}
            style={{ lineHeight: "1em" }}
          >
            &nbsp;*
          </Typography>
        )}
      </Typography>
      {children}
    </Box>
  );
};

/**
 * @typedef FieldCacheProps
 * @property {import('react-hook-form').Control} control
 * @property {string} name
 * @property {(callback: string) => void} render
 */
/**
 * @param {FieldCacheProps} param0
 */
export function FieldCache(props) {
  const methods = useFormContext();
  const { control = methods.control, name, render } = props;
  const value = useWatch({ control, name });
  return useMemo(() => render?.(value) || null, [value, render]);
}
