import React, { useEffect, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  CircularProgress,
  TextField,
  Select as Select2,
  MenuItem,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useAlert } from "./Alert";
import Grid from "./Grid";
import CustomerAttentionFloatingLayer from "./CustomerAttentionFloatingLayer";
import { FieldCache } from "./FieldCache";
import {
  getInvitationCode,
  getMaxOfArray,
  halfShapeAndFullForm,
} from "./utils";

import { countiesList, districtsList } from "../utils/countiesDistricts";
import { shipmentMethodList } from "../utils/localData";
import emptyArray from "../utils/emptyArray";
import { checkSpecialSymbolsAndNumber } from "../utils/checkRegExp";

// ANCHOR 抓取會員資料
const Get_Member = gql`
  query member {
    member {
      id
      fullName
      mobile
      address {
        district
        subdistrict
        address
      }
    }
  }
`;

// ANCHOR 門市電子地圖
const GO_TO_LOGISTICSMAP = gql`
  query logisticsMap($randomCode: String!, $returnUrl: String!) {
    logisticsMap(randomCode: $randomCode, returnUrl: $returnUrl)
  }
`;

// ANCHOR 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

export default function CreateOrderFloatingLayer({
  orderControl,
  open,
  content,
  count,
  type = "Product",
  orderId,
  memberEggId,
  onChangeFloatingwindowClose = () => {},
  noPaymentRequired = false,
  isFlashSale = false,
  paymentMethods = [],
}) {
  const Alert = useAlert();
  const { location } = useHistory();
  const useStylesTable = makeStyles({
    QuestionsContainer: {
      flexDirection: "column",
    },
  });
  const selectedOrderItems = useWatch({
    control: orderControl,
    name: "selectedOrderItems",
  });
  const selectedOrderItemsDeliveryFee = useWatch({
    control: orderControl,
    name: "selectedOrderItemsDeliveryFee",
  });

  const { handleSubmit, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      paymentMethod: null,
      recipient: "",
      recipientPhone: "",
      shipmentMethod: null,
      cvsStoreId: "",
      cvsStoreName: "",
      district: null,
      subdistrict: null,
      address: "",
      note: "",
      deliveryFee: 0,
    },
  });

  const [openAttention, setOpenAttention] = useState(false);
  const [selectStoreDisabled, setSelectStoreDisabled] = useState(false);
  const classesTable = useStylesTable();

  const [getMember, { loading }] = useLazyQuery(Get_Member, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ member }) {
      if (member) {
        setTimeout(() => {
          const { fullName, mobile, address } = member;
          setValue("recipient", fullName);
          setValue("recipientPhone", mobile);
          if (address) {
            const { district, subdistrict } = address;
            setValue("district", district);
            setValue("subdistrict", subdistrict);
            setValue("address", address.address);
          }
          const luyaoSelectStoreDeliveryFee = localStorage.getItem(
            `@luyao${type}SelectStoreDeliveryFee`
          );
          if (luyaoSelectStoreDeliveryFee) {
            setValue("deliveryFee", Number(luyaoSelectStoreDeliveryFee));
          } else {
            const orderItemsDeliveryFee = emptyArray(
              selectedOrderItemsDeliveryFee
            ).map((item) => item.deliveryFee);
            const getMaxValue = getMaxOfArray(orderItemsDeliveryFee);
            setValue("deliveryFee", getMaxValue);
          }
          const luyaoSelectStoreRandomCode = localStorage.getItem(
            `@luyao${type}SelectStoreRandomCode`
          );
          if (luyaoSelectStoreRandomCode) {
            getLogisticsMapData({
              variables: { randomCode: luyaoSelectStoreRandomCode },
            });
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error}`);
    },
  });

  function getOldData() {
    const luyaoSelectStoreGetValues = localStorage.getItem(
      `@luyao${type}SelectStoreGetValues`
    );
    if (luyaoSelectStoreGetValues) {
      const { paymentMethod, recipient, recipientPhone, note } = JSON.parse(
        luyaoSelectStoreGetValues
      );
      setValue("paymentMethod", paymentMethod);
      setValue("recipient", recipient);
      setValue("recipientPhone", recipientPhone);
      setValue("shipmentMethod", "CONVENIENCE_STORE");
      setValue("note", note);
      setTimeout(() => {
        localStorage.removeItem(`@luyao${type}SelectStoreCount`);
        localStorage.removeItem(`@luyao${type}SelectStoreRandomCode`);
        localStorage.removeItem(`@luyao${type}SelectStoreGetValues`);
      }, 0);
    }
  }

  const [getLogisticsMapData, { loading: logisticsMapDataLoading }] =
    useLazyQuery(GET_LOGISTICSMAPDATA, {
      onCompleted({ logisticsMapData }) {
        if (logisticsMapData) {
          setTimeout(() => {
            const { cvsStoreId, cvsStoreName } = logisticsMapData;
            setValue("cvsStoreId", cvsStoreId);
            setValue("cvsStoreName", cvsStoreName);
            getOldData();
          }, 0);
        }
      },
      onError() {
        getOldData();
      },
    });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        getMember();
      }, 1000);
    }
  }, [open, getMember]);

  const [goToLogisticsMap] = useLazyQuery(GO_TO_LOGISTICSMAP, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ logisticsMap }) {
      if (logisticsMap) {
        setTimeout(() => {
          window.location = logisticsMap;
        }, 0);
      }
    },
  });

  const chooseStore = async () => {
    setSelectStoreDisabled(true);
    const randomCode = getInvitationCode(7);
    if (count) {
      localStorage.setItem(`@luyao${type}SelectStoreCount`, String(count));
    }
    if (orderId) {
      localStorage.setItem(`@luyao${type}SelectStoreOrderId`, String(orderId));
    }
    if (memberEggId) {
      localStorage.setItem(
        `@luyao${type}SelectStoreMemberEggId`,
        String(memberEggId)
      );
    }
    if (selectedOrderItemsDeliveryFee) {
      localStorage.setItem(
        `@luyao${type}SelectStoreDeliveryFee`,
        String(getValues("deliveryFee"))
      );
    }
    if (selectedOrderItems) {
      localStorage.setItem(
        `@luyao${type}SelectStoreSelectedOrderItems`,
        JSON.stringify(selectedOrderItems)
      );
    }
    localStorage.setItem(
      `@luyao${type}SelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@luyao${type}SelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };

  function initialization(value, paymentMethod, shipmentInput, otherValue) {
    onChangeFloatingwindowClose(
      value,
      paymentMethod,
      shipmentInput,
      otherValue
    );
    if (value !== "mutation") {
      localStorage.removeItem(`@luyao${type}SelectStoreCount`);
      localStorage.removeItem(`@luyao${type}SelectStoreOrderId`);
      localStorage.removeItem(`@luyao${type}SelectStoreMemberEggId`);
      localStorage.removeItem(`@luyao${type}SelectStoreDeliveryFee`);
      localStorage.removeItem(`@luyao${type}SelectStoreSelectedOrderItems`);
      localStorage.removeItem(`@luyao${type}SelectStoreRandomCode`);
      localStorage.removeItem(`@luyao${type}SelectStoreGetValues`);
      reset();
    }
  }

  function _createShipment() {
    setOpenAttention(true);
  }

  return (
    <Dialog
      style={{ marginBottom: "5em" }}
      open={open}
      fullWidth
      disableScrollLock
    >
      <CustomerAttentionFloatingLayer
        open={openAttention}
        onChangeFloatingwindowClose={(value) => {
          setOpenAttention(false);
          if (typeof value === "boolean") {
            const shipmentInput = {
              recipient: getValues("recipient"),
              recipientPhone: getValues("recipientPhone"),
              shipmentMethod: getValues("shipmentMethod"),
              note: getValues("note"),
            };
            if (getValues("shipmentMethod") === "CONVENIENCE_STORE") {
              shipmentInput.shipmentSubType = "SEVEN_ELEVEN";
              shipmentInput.cvsStoreId = String(getValues("cvsStoreId"));
              shipmentInput.convenienceStore = getValues("cvsStoreName");
            } else {
              shipmentInput.shipmentSubType = "OTHER";
              const address = {
                district: getValues("district"),
                subdistrict: getValues("subdistrict"),
                address: getValues("address"),
              };
              shipmentInput.address = address;
            }
            const otherValue = orderId
              ? orderId
              : selectedOrderItems
              ? selectedOrderItems
              : undefined;
            initialization(
              "mutation",
              getValues("paymentMethod"),
              shipmentInput,
              otherValue
            );
          }
        }}
      />
      <DialogTitle>{content ?? "通知"}</DialogTitle>
      {loading || logisticsMapDataLoading ? (
        <DialogContent>
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Grid container direction="column" spacing={1}>
              {!noPaymentRequired && (
                <Controller
                  control={control}
                  name="paymentMethod"
                  rules={{
                    required: "請選擇付款方式",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Grid item>
                      <Typography color="primary">選擇付款方式：</Typography>
                      <Select2 {...field} fullWidth error={error} displayEmpty>
                        <MenuItem value={null} disabled>
                          請選擇付款方式
                        </MenuItem>
                        {(paymentMethods.length > 0
                          ? paymentMethods
                          : [
                              {
                                label: "信用卡",
                                value: "Credit",
                              },
                              {
                                label: "ATM 櫃員機",
                                value: "ATM",
                              },
                            ]
                        )
                          .filter((i) =>
                            isFlashSale ? i.value !== "ATM" : true
                          )
                          .map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Select2>
                      {error && (
                        <FormHelperText error={error}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                />
              )}
              <Grid
                container
                item
                spacing={1}
                classes={{ root: classesTable.QuestionsContainer }}
              >
                <Controller
                  control={control}
                  name="recipient"
                  rules={{
                    required: "請輸入收件人姓名",
                    validate: (e) => {
                      if (
                        e &&
                        (checkSpecialSymbolsAndNumber.test(e) ||
                          halfShapeAndFullForm(e) > 10)
                      ) {
                        return "收件人姓名請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Grid item>
                      <Typography color="primary">收件人姓名</Typography>
                      <TextField
                        {...field}
                        placeholder={"收件人姓名"}
                        fullWidth
                        error={error}
                        helperText={error?.message}
                      />
                    </Grid>
                  )}
                />
                <Controller
                  control={control}
                  name="recipientPhone"
                  rules={{
                    required: "請輸入收件人手機",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Grid item>
                      <Typography color="primary">收件人手機</Typography>
                      <TextField
                        {...field}
                        placeholder={"收件人手機"}
                        fullWidth
                        error={error}
                        helperText={error?.message}
                      />
                    </Grid>
                  )}
                />
                <Controller
                  control={control}
                  name="shipmentMethod"
                  rules={{
                    required: "請選擇運送方式",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Grid item>
                      <Typography color="primary">運送方式：</Typography>
                      <Select2 {...field} fullWidth error={error} displayEmpty>
                        <MenuItem value={null} disabled>
                          請選擇運送方式
                        </MenuItem>
                        {shipmentMethodList
                          .filter(
                            (i) =>
                              i.value !== "ALL" && i.value !== "SELF_PICKUP"
                          )
                          .map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Select2>
                      {error && (
                        <FormHelperText error={error}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                />
                <FieldCache
                  control={control}
                  name="shipmentMethod"
                  render={(shipmentMethod) => {
                    if (shipmentMethod === "HOME_DELIVERY") {
                      return (
                        <Grid item>
                          <Typography color="primary">收件人地址</Typography>
                          <Grid container spacing={1}>
                            <Controller
                              control={control}
                              name="district"
                              rules={{
                                required: "請選擇縣市",
                              }}
                              render={({
                                field: { onChange, ...otherFields },
                                fieldState: { error },
                              }) => (
                                <Grid item xs={6} sm={3}>
                                  <Select2
                                    {...otherFields}
                                    fullWidth
                                    onChange={(e) => {
                                      onChange(e);
                                      setValue("subdistrict", null);
                                    }}
                                    error={error}
                                    displayEmpty
                                  >
                                    <MenuItem value={null} disabled>
                                      請選擇縣市
                                    </MenuItem>
                                    {countiesList("zhTW").map((item) => (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select2>
                                  {error && (
                                    <FormHelperText error={error}>
                                      {error.message}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              )}
                            />
                            <FieldCache
                              control={control}
                              name="district"
                              render={(district) => (
                                <Controller
                                  control={control}
                                  name="subdistrict"
                                  rules={{
                                    required: "請選擇地區",
                                  }}
                                  render={({
                                    field,
                                    fieldState: { error },
                                  }) => (
                                    <Grid item xs={6} sm={3}>
                                      <Select2
                                        {...field}
                                        fullWidth
                                        displayEmpty
                                        error={error}
                                      >
                                        <MenuItem value={null} disabled>
                                          請選擇地區
                                        </MenuItem>
                                        {districtsList(district).map((item) => (
                                          <MenuItem
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </Select2>
                                      {error && (
                                        <FormHelperText error={error}>
                                          {error.message}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  )}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="address"
                              rules={{
                                required: "請輸入地址",
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    {...field}
                                    fullWidth
                                    error={error}
                                    helperText={error?.message}
                                  />
                                </Grid>
                              )}
                            />
                          </Grid>
                        </Grid>
                      );
                    } else if (shipmentMethod === "CONVENIENCE_STORE") {
                      return (
                        <Controller
                          control={control}
                          name="cvsStoreName"
                          rules={{
                            required: "請選擇超商名稱",
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <Grid item>
                              <Typography color="primary">超商名稱</Typography>
                              <TextField
                                {...field}
                                id="cvsStoreNameTextField"
                                fullWidth
                                error={error}
                                helperText={error?.message}
                                onClick={chooseStore}
                                inputProps={{ readOnly: true }}
                              />
                            </Grid>
                          )}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />
                <Controller
                  control={control}
                  name="note"
                  render={({ field }) => (
                    <Grid item>
                      <Typography color="primary">備註</Typography>
                      <TextField {...field} fullWidth />
                    </Grid>
                  )}
                />
                {type === "CreateShipment" && (
                  <Controller
                    control={control}
                    name="deliveryFee"
                    render={({ field }) => (
                      <Grid item>
                        <Typography color="primary">運費</Typography>
                        <TextField {...field} fullWidth disabled />
                      </Grid>
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(_createShipment)}
                  disabled={selectStoreDisabled}
                >
                  確定
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={initialization}
                  disabled={selectStoreDisabled}
                >
                  留在此頁
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
