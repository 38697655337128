import create from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} eventType - 活動類別
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  eventType: null,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} setEventType - 設定活動類別
 * @property {() => void} clearEventType - 清除活動類別
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  setEventType: (eventType) => {
    return new Promise((resolve, reject) => {
      if (eventType) {
        set({ eventType });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  clearEventType: () => {
    return new Promise((resolve) => {
      set({ eventType: initialState.eventType });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useEventTypeStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@luyaoEventType",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
export default useEventTypeStore;
