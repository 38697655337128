// ANCHOR 陣列洗牌亂數
export default function shuffle(array) {
  if (Array.isArray(array)) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  return [];
}
