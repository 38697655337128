export default function removeDuplicates(array, key = "id") {
  const seenIds = new Set();
  return array.filter((item) => {
    if (seenIds.has(item[key])) {
      return false;
    } else {
      seenIds.add(item[key]);
      return true;
    }
  });
}
