import { useCallback, useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

// zustand
import useTokenStore from "../zustand/useTokenStore";

const GET_MEMBER = gql`
  query member {
    member {
      id
    }
  }
`;

export function useRefreshToken() {
  const [creating, setCreating] = useState(false);
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const consoleToken = useTokenStore(
    useCallback((state) => state.consoleToken, [])
  );

  const [getMember] = useLazyQuery(GET_MEMBER, {
    fetchPolicy: "network-only",
    onCompleted({ member }) {
      if (!member) {
        setTimeout(() => {
          if (window.location.pathname.includes("console")) {
            useTokenStore.getState().cleanConsoleToken();
          } else {
            useTokenStore.getState().cleanToken();
          }
        }, 0);
      }
    },
  });

  useEffect(() => {
    if ((memberToken || consoleToken) && !creating) {
      setCreating(true);
      getMember();
    }
  }, [getMember, creating, memberToken, consoleToken]);

  return null;
}
