import { Box, useTheme } from "@material-ui/core";
import { createElement } from "react";
import QRCode from "react-qr-code";

export default function Qrcode(props) {
  const theme = useTheme();
  const spacing = 0.5;
  const size = props.size + theme.spacing(spacing * 2);
  return (
    <Box height={size} width={size} p={spacing} bgcolor="white">
      {createElement(QRCode, {
        value: `${window.location.protocol}//${window.location.host}/event`,
        ...props,
      })}
    </Box>
  );
}
