import React from "react";
import moment from "moment";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";

const GET_FAQS = gql`
  query faqs {
    faqs {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

const DELETE_FAQ = gql`
  mutation deleteFAQ($id: Int!) {
    deleteFAQ(id: $id) {
      success
      message
    }
  }
`;

export default function FaqTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();

  const { data, refetch, loading } = useQuery(GET_FAQS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [deleteFAQ, { loading: deleteFAQLoading }] = useMutation(DELETE_FAQ, {
    onCompleted({ deleteFAQ }) {
      if (deleteFAQ.success) {
        return Alert.alert("", "刪除成功！", [
          {
            text: "確定",
            onPress: () => refetch(),
            type: "ok",
          },
        ]);
      } else {
        return Alert.alert("", "刪除失敗，請重新嘗試！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid>
        <LoadingModal loading={deleteFAQLoading} />
        <Table
          paper
          data={data ? data.faqs : []}
          header={["問題", "答案", "建立時間", "最後更新時間", "操作"]}
          tableWidth={2}
          columns={[
            "question",
            (item) => {
              const newAnswer =
                item.answer.split("").length > 16
                  ? item.answer.slice(0, 16) + "..."
                  : item.answer;
              return newAnswer;
            },
            (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
            (item) => moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
            (item) => {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={(event) => {
                    event.stopPropagation();
                    Alert.alert("通知", "確定要刪除？", [
                      {
                        text: "確定",
                        onPress: () =>
                          deleteFAQ({ variables: { id: item.id } }),
                        type: "ok",
                      },
                      {
                        text: "取消",
                        type: "cancel",
                      },
                    ]);
                  }}
                >
                  刪除
                </Button>
              );
            },
          ]}
          onPress={(item) => onEditSelect(item)}
        />
      </Grid>
    );
  }
}
