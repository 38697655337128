import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Controller, FormProvider, useForm } from "react-hook-form";
// NOTE 組件
import LoadingModal from "./LoadingModal";
import { CustomTextField } from "./Form";
import { useAlert } from "./Alert";

// SECTION apollo
// NOTE 確認商品密碼
/** - 確認商品密碼 */
const CHECK_PRODUCT_PASSWORD = gql`
  mutation checkProductPassword($slug: String!, $password: String) {
    checkProductPassword(slug: $slug, password: $password) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// NOTE 確認遊戲密碼
/** - 確認遊戲密碼 */
const CHECK_GAME_PASSWORD = gql`
  mutation checkGamePassword($password: String) {
    checkGamePassword(password: $password) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

function PasswordDialog({ handleEvent }, ref) {
  const { notice } = useAlert();
  const form = useForm({
    defaultValues: {
      password: "",
    },
  });
  const { handleSubmit, getValues, reset } = form;
  const [open, setOpen] = useState(false);
  const productSlugRef = useRef();
  const handleFn = useRef({
    handleEvent,
  });
  useEffect(() => {
    handleFn.current.handleEvent = handleEvent;
  }, [handleEvent]);
  const _close = useCallback(() => {
    productSlugRef.current = null;
    setOpen(false);
    setTimeout(() => {
      reset();
    }, 300);
  }, [reset]);
  useImperativeHandle(
    ref,
    () => ({
      open: (productSlug) => {
        productSlugRef.current = productSlug;
        setTimeout(() => {
          setOpen(true);
        }, 0);
      },
      close: () => {
        _close();
      },
    }),
    [_close]
  );
  const [checkProductPasswordFn, { loading: checkProductPasswordLoading }] =
    useMutation(CHECK_PRODUCT_PASSWORD, {
      onCompleted({ checkProductPassword: { success, message } }) {
        if (success) {
          const password = getValues("password");
          handleFn.current.handleEvent?.({
            slug: productSlugRef.current,
            password,
          });
          _close();
        } else if (message) {
          notice(message);
        }
      },
      onError() {
        productSlugRef.current = null;
        return null;
      },
    });
  const [checkMiniGamePasswordFn, { loading: checkMiniGamePasswordLoading }] =
    useMutation(CHECK_GAME_PASSWORD, {
      onCompleted({ checkGamePassword: { success, message } }) {
        if (success) {
          const password = getValues("password");
          handleFn.current.handleEvent?.({
            password,
          });
          _close();
        } else if (message) {
          notice(message);
        }
      },
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
        return null;
      },
    });
  const _checkProductPassword = useCallback(
    ({ password }) => {
      if (productSlugRef.current) {
        checkProductPasswordFn({
          variables: { slug: productSlugRef.current, password },
        });
        return;
      }
      checkMiniGamePasswordFn({
        variables: { password },
      });
    },
    [checkProductPasswordFn, checkMiniGamePasswordFn]
  );
  return (
    <Dialog
      style={{ marginBottom: "5em" }}
      open={open}
      fullWidth
      disableScrollLock
    >
      <LoadingModal
        loading={checkProductPasswordLoading || checkMiniGamePasswordLoading}
      />
      <DialogTitle>專屬密碼</DialogTitle>
      <FormProvider {...form}>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography color="primary">密碼</Typography>
              <Controller
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_checkProductPassword)}
              >
                確定
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={_close}>
                關閉
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default memo(forwardRef(PasswordDialog));
