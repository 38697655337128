import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
// react-router-dom
import { useHistory } from "react-router-dom";
// material-ui
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// moment
import moment from "moment";
// react-hook-form
import { useForm, Controller } from "react-hook-form";
import { setHours, setMinutes, subMonths } from "date-fns";

// component
import Table from "../component/Table";
import { useAlert } from "../component/Alert";
import PageItem from "../component/PageItem";
import Button from "../component/Button";
import { CardContent } from "../component/Card";
import LoadingModal from "../component/LoadingModal";
import { Select, TextInput } from "../component/Form";
import { useLocalStorage } from "../component/LocalStorageContext";
import Text from "../component/Text";
import { excelDownload } from "../component/ExcelDownload";
import { halfShapeAndFullForm } from "../component/utils";
// utils
import { checkSpecialSymbolsAndNumber } from "../utils/checkRegExp";
import {
  shipmentStatusEnum,
  shipmentStatusList,
  shipmentMethodEnum,
  shipmentMethodList,
} from "../utils/localData";
import { getCounty, getDistrict } from "../utils/countiesDistricts";
import divisions from "../divisions.json";

const GET_PRODUCTS = gql`
  query products($latest: Boolean) {
    products(latest: $latest) {
      products {
        id
        name
      }
    }
  }
`;

const GET_SHIPMENTS = gql`
  query shipments(
    $shipmentMethod: ShipmentMethod
    $status: ShipmentStatus
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $productId: Int
    $combinedDelivery: Boolean
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    shipments(
      shipmentMethod: $shipmentMethod
      status: $status
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      productId: $productId
      combinedDelivery: $combinedDelivery
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      shipmentCount
      orderItemCount
      pageCount
      shipments {
        id
        member {
          id
          fullName
          mobile
        }
        serialNumber
        ecpayLogisticsId
        recipient
        recipientPhone
        shipmentMethod
        convenienceStore
        address {
          district
          subdistrict
          address
        }
        note
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_EXCEL_SHIPMENTs = gql`
  query shipments(
    $shipmentMethod: ShipmentMethod
    $status: ShipmentStatus
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $productId: Int
    $combinedDelivery: Boolean
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    shipments(
      shipmentMethod: $shipmentMethod
      status: $status
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      productId: $productId
      combinedDelivery: $combinedDelivery
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      shipmentCount
      pageCount
      shipments {
        id
        member {
          id
          fullName
          mobile
        }
        serialNumber
        ecpayLogisticsId
        recipient
        recipientPhone
        shipmentMethod
        convenienceStore
        address {
          district
          subdistrict
          address
        }
        note
        orderItems {
          product {
            name
          }
          amount
        }
        status
        totalPayment
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_SHIPMENT = gql`
  mutation deleteShipment($id: Int!) {
    deleteShipment(id: $id) {
      success
      message
    }
  }
`;

const UPDATE_SHIPMENTORDERITEMSTATUS = gql`
  mutation updateShipmentOrderItemStatus($shipmentIds: [Int!]!) {
    updateShipmentOrderItemStatus(shipmentIds: $shipmentIds) {
      success
      message
    }
  }
`;

const SET_SHIPMENTANDORDERITEMSTATUSTOREADY = gql`
  mutation setShipmentAndOrderItemStatusToReady($shipmentIds: [Int!]!) {
    setShipmentAndOrderItemStatusToReady(shipmentIds: $shipmentIds) {
      success
      message
    }
  }
`;

// ANCHOR 物流資訊設定
const GET_LOGISTICSINFO = gql`
  query logisticsInfo {
    logisticsInfo {
      id
      senderName
      senderPhone
      senderCellPhone
    }
  }
`;

// ANCHOR 儲存物流資訊設定
const SAVE_LOGISTICSINFO = gql`
  mutation saveLogisticsInfo($logisticsInfoInput: LogisticsInfoInput!) {
    saveLogisticsInfo(logisticsInfoInput: $logisticsInfoInput) {
      success
      message
    }
  }
`;

export default function ShipmentListPage() {
  const Alert = useAlert();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  const [status, setStatus] = useState(
    Boolean(LocalStorage.getSearchShipmentStatus())
      ? LocalStorage.getSearchShipmentStatus()
      : "ALL"
  );
  const [productId, setProductId] = useState(
    Boolean(LocalStorage.getSearchShipmentProductId())
      ? LocalStorage.getSearchShipmentProductId() === "ALL"
        ? LocalStorage.getSearchShipmentProductId()
        : JSON.parse(LocalStorage.getSearchShipmentProductId())
      : "ALL"
  );
  const [shipmentMethod, setShipmentMethod] = useState(
    Boolean(LocalStorage.getSearchShipmentShipmentMethod())
      ? LocalStorage.getSearchShipmentShipmentMethod()
      : "ALL"
  );
  const [productArray, setProductArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    Boolean(LocalStorage.getSearchShipmentSearchTerm())
      ? LocalStorage.getSearchShipmentSearchTerm()
      : ""
  );
  const [latest, setLatest] = useState(
    Boolean(LocalStorage.getSearchShipmentLatest())
      ? JSON.parse(LocalStorage.getSearchShipmentLatest())
      : true
  );
  const [page, setPage] = useState(
    Boolean(LocalStorage.getSearchShipmentPage())
      ? JSON.parse(LocalStorage.getSearchShipmentPage())
      : 1
  );
  const [pageMax, setPageMax] = useState(null);
  const [pageSize, setPageSize] = useState(
    Boolean(LocalStorage.getSearchShipmentPageSize())
      ? JSON.parse(LocalStorage.getSearchShipmentPageSize())
      : 10
  );
  const [pageArray, setPageArray] = useState([1]);
  const [startTime, setStartTime] = useState(
    Boolean(LocalStorage.getSearchShipmentStartTime())
      ? JSON.parse(LocalStorage.getSearchShipmentStartTime())
      : setMinutes(setHours(subMonths(new Date(), 1), 0), 0)
  );
  const [endTime, setEndTime] = useState(
    Boolean(LocalStorage.getSearchShipmentEndTime())
      ? JSON.parse(LocalStorage.getSearchShipmentEndTime())
      : null
  );
  const shipmentCount = useRef(0);
  const [excelLoading, setExcelLoading] = useState(false);

  const [openChoose, setOpenChoose] = useState(false);
  const [shipmentIds, setShipmentIds] = useState([]);

  const [combinedDelivery, setCombinedDelivery] = useState(
    Boolean(LocalStorage.getSearchShipmentCombinedDelivery())
      ? JSON.parse(LocalStorage.getSearchShipmentCombinedDelivery())
      : false
  );

  function _gotoShipment(shipmentId) {
    return history.push(`/console/shipment/${shipmentId}`);
  }
  function _delete(id) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        onPress: () => deleteShipment({ variables: { id } }),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }

  const { loading: productsLoading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
    },
    onCompleted({ products }) {
      setTimeout(() => {
        const newProductArray = [
          {
            label: "全部",
            value: "ALL",
          },
        ];
        products.products.forEach((item) => {
          newProductArray.push({
            label: item.name,
            value: item.id,
          });
        });
        setProductArray(newProductArray);
      }, 0);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const {
    data,
    refetch,
    loading: shipmentsLoading,
  } = useQuery(GET_SHIPMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      shipmentMethod: shipmentMethod === "ALL" ? undefined : shipmentMethod,
      status: status === "ALL" ? undefined : status,
      searchTerm: searchTerm || undefined,
      startTime,
      endTime,
      productId: productId === "ALL" ? undefined : productId,
      combinedDelivery,
      latest,
      pageSize,
      page,
    },
    onCompleted({ shipments }) {
      if (shipments) {
        setTimeout(() => {
          shipmentCount.current = shipments.shipmentCount;
          setPageMax(shipments.pageCount);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const addressProcessing = useCallback((value) => {
    if (
      value.shipmentMethod === "CONVENIENCE_STORE" &&
      value.convenienceStore
    ) {
      return value.convenienceStore;
    } else if (
      value.shipmentMethod === "HOME_DELIVERY" &&
      value.address?.district &&
      value.address?.subdistrict &&
      value.address?.address
    ) {
      return (
        getCounty(value.address.district) +
        getDistrict(value.address.district, value.address.subdistrict) +
        value.address.address
      );
    } else {
      return "";
    }
  }, []);

  const [getExcelShipments] = useLazyQuery(GET_EXCEL_SHIPMENTs, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ shipments }) => {
      if (shipments) {
        setTimeout(async () => {
          const excelData = [];
          const entozh = {
            serialNumber: "貨物編號",
            fullName: "會員姓名",
            mobile: "會員手機",
            recipient: "收件人",
            recipientPhone: "收件人電話",
            orderItems: "品項",
            shipmentMethod: "運送方式",
            address: "地址 / 超商店名",
            status: "出貨狀態",
            note: "備註",
            totalPayment: "付款金額",
            createdAt: "建立日期",
            updatedAt: "更新日期",
          };
          shipments.shipments.forEach((item) => {
            excelData.push({
              serialNumber: item.serialNumber,
              fullName: item.member.fullName,
              mobile: item.member.mobile,
              recipient: item.recipient,
              recipientPhone: item.recipientPhone,
              orderItems: item.orderItems
                .map(
                  (orderItem) =>
                    `${orderItem.product.name} × ${orderItem.amount}`
                )
                .join("\n"),
              shipmentMethod: shipmentMethodEnum[item.shipmentMethod],
              address: addressProcessing(item),
              status: shipmentStatusEnum[item.status],
              note: item.note,
              totalPayment: item.totalPayment,
              createdAt: moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              updatedAt: moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
            });
          });
          const excelFinish = await excelDownload(
            entozh,
            excelData,
            `${moment().format("YYYY-MM-DD")} 路遙圓創出貨清單`
          );
          if (excelFinish === "Finish") {
            setExcelLoading(false);
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);
  useEffect(() => {
    if (data) {
      if (!data.shipments.shipments[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  const [deleteShipment, { loading: deleteShipmentLoading }] = useMutation(
    DELETE_SHIPMENT,
    {
      onCompleted({ deleteShipment }) {
        if (deleteShipment.success) {
          return Alert.alert("", "刪除成功！", [
            {
              text: "確定",
              onPress: refetch,
              type: "ok",
            },
          ]);
        } else {
          return Alert.alert("", "刪除失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  const [
    updateShipmentOrderItemStatus,
    { loading: updateShipmentOrderItemStatusLoading },
  ] = useMutation(UPDATE_SHIPMENTORDERITEMSTATUS, {
    onCompleted({ updateShipmentOrderItemStatus }) {
      if (updateShipmentOrderItemStatus.success) {
        return Alert.alert("", "更改狀態成功！", [
          {
            text: "確定",
            onPress: () => {
              refetch();
              setShipmentIds([]);
              setOpenChoose((e) => !e);
            },
            type: "ok",
          },
        ]);
      } else {
        return Alert.alert("", "更改狀態失敗！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [
    setShipmentAndOrderItemStatusToReady,
    { loading: setShipmentAndOrderItemStatusToReadyLoading },
  ] = useMutation(SET_SHIPMENTANDORDERITEMSTATUSTOREADY, {
    onCompleted({ setShipmentAndOrderItemStatusToReady }) {
      if (setShipmentAndOrderItemStatusToReady.success) {
        return Alert.alert("", "更改狀態成功！", [
          {
            text: "確定",
            onPress: () => {
              refetch();
              setShipmentIds([]);
              setOpenChoose((e) => !e);
            },
            type: "ok",
          },
        ]);
      } else {
        return Alert.alert("", "更改狀態失敗！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const allCheck = useMemo(() => {
    let hasAllChoose = false;
    const hasAllChooseArray = [];
    const pushAllChooseArray = [];
    if (Boolean(data)) {
      data.shipments.shipments.forEach((item) => {
        shipmentIds.forEach((item2) => {
          if (item2 === item.id) {
            hasAllChooseArray.push(item.id);
          }
        });
        pushAllChooseArray.push(item.id);
      });
    }
    if (hasAllChooseArray.length === pushAllChooseArray.length) {
      hasAllChoose = true;
    } else {
      hasAllChoose = false;
    }
    return { hasAllChoose, hasAllChooseArray, pushAllChooseArray };
  }, [data, shipmentIds]);

  if (shipmentsLoading || productsLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid container direction="column">
        <Grid container item>
          <LoadingModal
            loading={
              excelLoading ||
              deleteShipmentLoading ||
              updateShipmentOrderItemStatusLoading ||
              setShipmentAndOrderItemStatusToReadyLoading
            }
          />
          <CardContent>
            <Typography>
              <spam>出貨總數：{data && data.shipments.shipmentCount}</spam>
            </Typography>
            <Typography>
              <spam>貨物總數：{data && data.shipments.orderItemCount}</spam>
            </Typography>
          </CardContent>
          <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"出貨狀態"}
                  items={shipmentStatusList}
                  value={status}
                  onChange={(v) => {
                    LocalStorage.clearSearchShipmentPage();
                    setStatus(() => {
                      LocalStorage.setSearchShipmentStatus(v);
                      setPage(1);
                      return v;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"商品"}
                  items={productArray}
                  value={productId}
                  onChange={(v) => {
                    LocalStorage.clearSearchShipmentPage();
                    setProductId(() => {
                      if (typeof v === "number") {
                        LocalStorage.setSearchShipmentProductId(`${v}`);
                      } else {
                        LocalStorage.setSearchShipmentProductId(v);
                        LocalStorage.clearSearchShipmentCombinedDelivery();
                        setCombinedDelivery(false);
                      }
                      setPage(1);
                      return v;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              {typeof productId === "number" && (
                <Grid item xs={12} sm={2}>
                  <Select
                    label={"合併訂單"}
                    items={[
                      { label: "是", value: true },
                      { label: "否", value: false },
                    ]}
                    value={combinedDelivery}
                    onChange={(value) => {
                      LocalStorage.clearSearchShipmentPage();
                      setCombinedDelivery(() => {
                        LocalStorage.setSearchShipmentCombinedDelivery(value);
                        setPage(1);
                        return value;
                      });
                    }}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={2}>
                <Select
                  label={"運送方式"}
                  items={shipmentMethodList}
                  value={shipmentMethod}
                  onChange={(v) => {
                    LocalStorage.clearSearchShipmentPage();
                    setShipmentMethod(() => {
                      LocalStorage.setSearchShipmentShipmentMethod(v);
                      setPage(1);
                      return v;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextInput
                  label={"搜尋"}
                  value={searchTerm}
                  onChange={(value) => {
                    LocalStorage.clearSearchShipmentPage();
                    setSearchTerm(() => {
                      LocalStorage.setSearchShipmentSearchTerm(value);
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"每頁顯示"}
                  items={[
                    { label: "10", value: 10 },
                    { label: "50", value: 50 },
                    { label: "100", value: 100 },
                  ]}
                  value={pageSize}
                  onChange={(value) => {
                    LocalStorage.clearSearchShipmentPage();
                    setPageSize(() => {
                      LocalStorage.setSearchShipmentPageSize(`${value}`);
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"排序"}
                  items={[
                    { label: "從新到舊", value: true },
                    { label: "從舊到新", value: false },
                  ]}
                  value={latest}
                  onChange={(value) => {
                    setLatest(() => {
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  ampm={false}
                  label={"開始時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  value={startTime}
                  onChange={(v) => {
                    LocalStorage.clearSearchShipmentPage();
                    LocalStorage.setSearchShipmentStartTime(JSON.stringify(v));
                    setStartTime(v);
                    setPage(1);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  ampm={false}
                  label={"結束時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  minDate={startTime}
                  value={endTime}
                  onChange={(v) => {
                    LocalStorage.clearSearchShipmentPage();
                    LocalStorage.setSearchShipmentEndTime(JSON.stringify(v));
                    setEndTime(v);
                    setPage(1);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    if (openChoose) {
                      setShipmentIds([]);
                    }
                    setOpenChoose((e) => !e);
                  }}
                  fullWidth
                >
                  {!openChoose ? "選擇出貨單" : "取消選擇"}
                </Button>
              </Grid>
              {openChoose && Boolean(shipmentIds[0]) && (
                <>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() =>
                        setShipmentAndOrderItemStatusToReady({
                          variables: { shipmentIds },
                        })
                      }
                      fullWidth
                    >
                      更改出貨單狀態為待出貨
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() =>
                        updateShipmentOrderItemStatus({
                          variables: { shipmentIds },
                        })
                      }
                      fullWidth
                    >
                      更改出貨單狀態為已出貨
                    </Button>
                  </Grid>
                </>
              )}
              <LogisticsInfoDialog />
            </Grid>
          </Box>
          <Table
            paper
            data={data && data.shipments.shipments}
            header={[
              Boolean(openChoose) && (
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justify="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allCheck.hasAllChoose}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (allCheck.hasAllChoose) {
                            shipmentIds.forEach((item) => {
                              allCheck.hasAllChooseArray.forEach((item2) => {
                                if (item === item2) {
                                  setShipmentIds((e) =>
                                    e.filter((i) => i !== item)
                                  );
                                }
                              });
                            });
                          } else {
                            let newOrderId = [
                              ...shipmentIds,
                              ...allCheck.pushAllChooseArray,
                            ];
                            const result = [
                              ...new Set(
                                newOrderId.map((item) => JSON.stringify(item))
                              ),
                            ].map((item) => JSON.parse(item));
                            setShipmentIds(result);
                          }
                        }}
                      />
                    }
                    label="全選"
                  />
                </Grid>
              ),
              "貨物編號",
              "綠界物流編號",
              "會員姓名",
              "會員手機",
              "運送方式",
              "地址 / 超商店名",
              "出貨狀態",
              "建立日期",
              "更新日期",
              "操作",
            ]}
            columns={[
              (item) => {
                if (openChoose) {
                  const has = shipmentIds.find((item2) => item2 === item.id);
                  return (
                    <Checkbox
                      checked={Boolean(has)}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (Boolean(has)) {
                          setShipmentIds((e) => e.filter((i) => i !== item.id));
                        } else {
                          setShipmentIds((e) => [...e, item.id]);
                        }
                      }}
                    />
                  );
                } else {
                  return null;
                }
              },
              "serialNumber",
              "ecpayLogisticsId",
              (item) => item.member.fullName,
              (item) => item.member.mobile,
              (item) => shipmentMethodEnum[item.shipmentMethod],
              (item) => {
                if (item.shipmentMethod === "CONVENIENCE_STORE") {
                  return item.convenienceStore;
                } else {
                  const division = item.address;
                  return division ? (
                    <Text>
                      {divisions[division.district]["zhTW"] +
                        divisions[division.district]["subdivisions"][
                          division.subdistrict
                        ]["zhTW"] +
                        division.address}
                    </Text>
                  ) : null;
                }
              },
              (item) => shipmentStatusEnum[item.status],
              (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
              (item) => {
                return (
                  <Button
                    variant="outlined"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      _delete(item.id);
                    }}
                  >
                    刪除
                  </Button>
                );
              },
            ]}
            tableWidth={3}
            onPress={(item) => _gotoShipment(item.id)}
          />
          <PageItem
            data={pageArray}
            page={page}
            onChangePage={(e) =>
              setPage(() => {
                LocalStorage.setSearchShipmentPage(`${e}`);
                return e;
              })
            }
          />
          <Grid container item justify="center" style={{ margin: "1em" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={shipmentCount.current === 0}
              onPress={() => {
                setExcelLoading(true);
                getExcelShipments({
                  variables: {
                    shipmentMethod:
                      shipmentMethod === "ALL" ? null : shipmentMethod,
                    status: status === "ALL" ? undefined : status,
                    searchTerm: searchTerm || undefined,
                    startTime,
                    endTime,
                    productId: productId === "ALL" ? null : productId,
                    combinedDelivery,
                    latest,
                    pageSize: shipmentCount.current,
                    page: 1,
                  },
                });
              }}
            >
              下載出貨清單
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function LogisticsInfoDialog() {
  const Alert = useAlert();
  const { control, handleSubmit, reset, setValue } = useForm({
    default: {
      senderName: "",
      senderPhone: "",
      senderCellPhone: "",
    },
  });
  const [open, setOpen] = useState(false);

  const [logisticsInfo] = useLazyQuery(GET_LOGISTICSINFO, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ logisticsInfo }) {
      if (logisticsInfo) {
        setTimeout(() => {
          const { senderName, senderPhone, senderCellPhone } = logisticsInfo;
          setValue("senderName", senderName);
          setValue("senderPhone", senderPhone);
          setValue("senderCellPhone", senderCellPhone);
        }, 0);
      }
    },
  });

  useEffect(() => {
    if (open) {
      logisticsInfo();
    }
  }, [open, logisticsInfo]);

  const [saveLogisticsInfo, { loading: saveLogisticsInfoLoading }] =
    useMutation(SAVE_LOGISTICSINFO, {
      onCompleted({ saveLogisticsInfo }) {
        if (saveLogisticsInfo.success) {
          reset();
          setOpen(false);
          Alert.notice("物流資訊設定成功！");
        } else if (saveLogisticsInfo.message) {
          Alert.notice(saveLogisticsInfo.message);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  function onClose() {
    if (!saveLogisticsInfoLoading) {
      reset();
      setOpen(false);
    }
  }
  function _saveLogisticsInfo({ senderName, senderPhone, senderCellPhone }) {
    const logisticsInfoInput = {
      senderName,
      senderPhone,
      senderCellPhone,
    };
    saveLogisticsInfo({ variables: { logisticsInfoInput } });
  }
  return (
    <>
      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          color="primary"
          onPress={() => setOpen(true)}
          fullWidth
        >
          寄件人設定
        </Button>
      </Grid>
      <Dialog fullWidth open={open} onClose={onClose} maxWidth="xs">
        <LoadingModal loading={saveLogisticsInfoLoading} />
        <DialogTitle>寄件人設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="senderName"
                rules={{
                  required: "請輸入寄件人姓名",
                  validate: (e) => {
                    if (
                      e &&
                      (checkSpecialSymbolsAndNumber.test(e) ||
                        halfShapeAndFullForm(e) > 10)
                    ) {
                      return "寄件人姓名請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Typography error={error}>寄件人姓名</Typography>
                    <TextField
                      {...field}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="senderPhone"
                rules={{
                  required: "請輸入寄件人電話",
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Typography error={error}>
                      寄件人電話(建議填寫手機號碼)
                    </Typography>
                    <TextField
                      {...field}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="senderCellPhone"
                rules={{
                  required: "請輸入寄件人手機",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "請輸入正確手機號碼",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Typography error={error}>寄件人手機</Typography>
                    <TextField
                      {...field}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onPress={onClose}
          >
            關閉
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onPress={handleSubmit(_saveLogisticsInfo)}
          >
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
