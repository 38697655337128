import React, { Fragment } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller, useFormContext } from "react-hook-form";
// ANCHOR 主要組件
/**
 * @typedef renderCallback
 * @property {JSX.Element} search - 搜尋框
 * @property {JSX.Element} selected - 已選擇
 */
/**
 * @typedef ProductAutoComplateProps
 * @property {Array<{label: string, value: string | number}>} options
 * @property {string} objectName
 * @property {(callback: renderCallback) => void} render
 */
/**
 * @param {ProductAutoComplateProps} param0
 * @returns {JSX.Element}
 */
export default function MultipleAutoComplate(props) {
  const methods = useFormContext();
  const {
    // NOTE 有control
    control = methods?.control,
    name,
    render = () => {},
    rules,
    // NOTE 沒有control
    value,
    onChange,
    // NOTE 通用
    objectName,
    options = [],
    selectedOnChange,
    deletedOnChange,
    allDisabled = false,
    getOptionDisabled,
  } = props;
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({
          field: { value: productIds, onChange },
          fieldState: { error },
        }) =>
          render({
            search: (
              <Autocomplete
                multiple
                options={options}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => value.id === option.id}
                disableCloseOnSelect
                value={productIds}
                onChange={(_, n, __, k) => {
                  if (k && selectedOnChange) {
                    selectedOnChange(k.option, n);
                  }
                  onChange(n);
                }}
                disabled={allDisabled}
                noOptionsText={`找不到${objectName ?? "商品"}`}
                getOptionDisabled={getOptionDisabled}
                renderInput={({ InputProps, ...params }) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...InputProps,
                      startAdornment: null,
                      endAdornment: null,
                    }}
                    placeholder={`選擇${objectName ?? "商品"}`}
                  />
                )}
              />
            ),
            selected: (
              <Autocomplete
                freeSolo
                multiple
                options={options}
                value={productIds}
                onChange={(_, n, __, k) => {
                  if (k && deletedOnChange) {
                    deletedOnChange(k.option, n);
                  }
                  onChange(n);
                }}
                disabled={allDisabled}
                disableClearable
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    {...params}
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{
                      ...inputProps,
                      disabled: true,
                    }}
                    placeholder={`已選擇的${objectName ?? "商品"}`}
                  />
                )}
              />
            ),
          })
        }
      />
    );
  } else {
    return (
      <Fragment>
        {render({
          search: (
            <Autocomplete
              multiple
              options={options}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                value.value === option.value
              }
              disableCloseOnSelect
              value={value}
              onChange={(_, n, __, k) => {
                if (k && selectedOnChange) {
                  selectedOnChange(k.option, n);
                }
                onChange?.(n);
              }}
              disabled={allDisabled}
              noOptionsText={`找不到${objectName ?? "商品"}`}
              getOptionDisabled={getOptionDisabled}
              renderInput={({ InputProps, ...params }) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...InputProps,
                    startAdornment: null,
                    endAdornment: null,
                  }}
                  placeholder={`選擇${objectName ?? "商品"}`}
                />
              )}
            />
          ),
          selected: (
            <Autocomplete
              freeSolo
              multiple
              options={options}
              value={value}
              onChange={(_, n, __, k) => {
                if (k && deletedOnChange) {
                  deletedOnChange(k.option, n);
                }
                onChange?.(n);
              }}
              disabled={allDisabled}
              disableClearable
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={({ inputProps, ...params }) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...inputProps,
                    disabled: true,
                  }}
                  placeholder={`已選擇的${objectName ?? "商品"}`}
                />
              )}
            />
          ),
        })}
      </Fragment>
    );
  }
}
