import React, { useState } from "react";
import {
  Avatar,
  FormLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

import { Card, CardContent, CardActions } from "../component/Card";
import Grid from "../component/Grid";
import Image from "../component/Image";
import Button from "../component/Button";
import { Select, TextInput } from "../component/Form";
import Text from "../component/Text";
import Table from "../component/Table";
import { useAlert } from "../component/Alert";
import LoadingModal from "../component/LoadingModal";

import {
  orderStatusEnum,
  orderStatusList,
  orderItemStatusEnum,
  orderItemStatusList,
} from "../utils/localData";

// NOTE 訂單
const GET_ORDER = gql`
  query GetOrder($id: Int!) {
    order(id: $id) {
      id
      serialNumber
      member {
        id
        profilePicture {
          location
        }
        fullName
        # "手機號碼"
        mobile
      }
      items {
        id
        product {
          id
          name
          images {
            location
          }
        }
        # "活動"
        event {
          id
        }
        unitPrice
        amount
        status
      }
      totalPrice
      status
      note
      payments {
        id
        serialNumber
        success
        tradeNumber
        totalPrice
        paymentMethod
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

// NOTE 更新訂單狀態
const UPDATE_ORDERSTATUS = gql`
  mutation updateOrderStatus($id: Int!, $status: OrderStatus!) {
    updateOrderStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

// NOTE 更新訂單品項狀態
const UPDATE_ORDERITEMSTATUS = gql`
  mutation updateOrderItemStatus($id: Int!, $status: OrderItemStatus!) {
    updateOrderItemStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

// NOTE 將訂單設為自取訂單
const SETORDER_TOSELFPICKUPORDER = gql`
  mutation setOrderToSelfPickUpOrder($orderId: Int!) {
    setOrderToSelfPickUpOrder(orderId: $orderId) {
      success
      message
    }
  }
`;

export default function OrderPage() {
  const Alert = useAlert();
  const history = useHistory();
  const { id } = useParams();
  const [orderStatus, setOrderStatus] = useState("WAITING_FOR_PAYMENT");
  const [orderItem, setOrderItem] = useState([]);
  const { data, refetch } = useQuery(GET_ORDER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { id: parseInt(id) },
    onCompleted({ order }) {
      if (order.id) {
        setTimeout(() => {
          setOrderStatus(order.status);
          setOrderItem(order.items);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  function _save() {
    return Alert.alert("", "儲存成功", [
      {
        text: "確認",
        type: "ok",
        onPress: () => history.replace("/console/order"),
      },
    ]);
  }

  const [updateOrderStatus, { loading: updateOrderStatusLoading }] =
    useMutation(UPDATE_ORDERSTATUS, {
      onCompleted({ updateOrderStatus }) {
        if (updateOrderStatus.success) {
          refetch();
          Alert.notice("更改成功！");
          return;
        } else if (updateOrderStatus.message) {
          Alert.notice(updateOrderStatus.message);
          return;
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  const [updateOrderItemStatus, { loading: updateOrderItemStatusLoading }] =
    useMutation(UPDATE_ORDERITEMSTATUS, {
      onCompleted({ updateOrderItemStatus }) {
        if (updateOrderItemStatus.success) {
          refetch();
          Alert.notice("更改成功！");
          return;
        } else if (updateOrderItemStatus.message) {
          Alert.notice(updateOrderItemStatus.message);
          return;
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  const [
    setOrderToSelfPickUpOrder,
    { loading: setOrderToSelfPickUpOrderLoading },
  ] = useMutation(SETORDER_TOSELFPICKUPORDER, {
    onCompleted({ setOrderToSelfPickUpOrder }) {
      if (setOrderToSelfPickUpOrder.success) {
        refetch();
        Alert.notice("成功！");
        return;
      } else if (setOrderToSelfPickUpOrder.message) {
        Alert.notice(setOrderToSelfPickUpOrder.message);
        return;
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  return data ? (
    <Card>
      <CardContent>
        <LoadingModal
          loading={
            updateOrderStatusLoading ||
            updateOrderItemStatusLoading ||
            setOrderToSelfPickUpOrderLoading
          }
        />
        <Grid container spacing={1}>
          <Grid container item>
            {data.order.member.profilePicture && (
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={data.order.member.profilePicture.location} />
                </ListItemAvatar>
                <ListItemText primary={data.order.member.fullName} />
              </ListItem>
            )}
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>會員姓名</FormLabel>
              <Text>{data.order.member.fullName}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>會員手機</FormLabel>
              <Text>{data.order.member.mobile}</Text>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormLabel>訂單編號</FormLabel>
            <Text>{data.order.serialNumber}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormLabel>總金額</FormLabel>
            <Text>NT${data.order.totalPrice}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormLabel>建立日期</FormLabel>
            <Text>
              {moment(data.order.createdAt).format("YYYY/MM/DD HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormLabel>更新日期</FormLabel>
            <Text>
              {moment(data.order.updatedAt).format("YYYY/MM/DD HH:mm")}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormLabel>訂單狀態</FormLabel>
            <Select
              items={orderStatusList.filter((i) => i.value !== "ALL")}
              value={orderStatus}
              onChange={(value) => {
                Alert.alert(
                  "",
                  `確定要更改訂單狀態為${orderStatusEnum[value]}？`,
                  [
                    {
                      text: "確定",
                      onPress: () =>
                        setOrderStatus(() => {
                          updateOrderStatus({
                            variables: { id: data.order.id, status: value },
                          });
                          return value;
                        }),
                      type: "ok",
                    },
                    {
                      text: "取消",
                      type: "cancel",
                    },
                  ]
                );
              }}
              fullWidth
            />
          </Grid>
          <Grid container item>
            {false && (
              <TextInput
                label="備註"
                multiline
                value={data.order.note || ""}
                onChange={() => {}}
                fullWidth
              />
            )}
          </Grid>
          <Grid container item style={{ paddingTop: 10, paddingBottom: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onPress={() => {
                Alert.alert("", "確定要將訂單設為自取訂單？", [
                  {
                    text: "確定",
                    onPress: () =>
                      setOrderToSelfPickUpOrder({
                        variables: { orderId: data.order.id },
                      }),
                    type: "ok",
                  },
                  {
                    text: "取消",
                    type: "cancel",
                  },
                ]);
              }}
              size="small"
            >
              將訂單設為自取訂單
            </Button>
          </Grid>
          <Grid container item>
            <FormLabel>訂單品項</FormLabel>
            <Table
              data={data.order.items}
              tableWidth={2}
              header={["", "名稱", "單價", "數量", "狀態"]}
              columns={[
                (item) => (
                  <Image
                    style={{ width: 64, height: 64 }}
                    src={
                      item.product
                        ? item.product.images[0]?.location
                        : item.event
                        ? "/img/special/ticket.png"
                        : ""
                    }
                  />
                ),
                (item) =>
                  item.product ? item.product.name : item.event ? "路遙券" : "",
                (item) => `NT$${item.unitPrice}`,
                "amount",
                (item) => {
                  const orderItemsData = orderItem.find(
                    (item2) => item2.id === item.id
                  );
                  return (
                    <Select
                      items={orderItemStatusList}
                      value={orderItemsData ? orderItemsData.status : ""}
                      onChange={(value) => {
                        Alert.alert(
                          "",
                          `確定要更改訂單品項狀態為${orderItemStatusEnum[value]}？`,
                          [
                            {
                              text: "確定",
                              onPress: () =>
                                updateOrderItemStatus({
                                  variables: {
                                    id: item.id,
                                    status: value,
                                  },
                                }),
                              type: "ok",
                            },
                            {
                              text: "取消",
                              type: "cancel",
                            },
                          ]
                        );
                      }}
                      fullWidth
                    />
                  );
                },
              ]}
            />
            <CardActions style={{ width: "100%" }}>
              <FormLabel>總金額</FormLabel>
              <Text>{data.order.totalPrice}</Text>
            </CardActions>
          </Grid>
          {data.order.payments[0] && (
            <Grid container item>
              <FormLabel>付款紀錄</FormLabel>
              <Table
                data={data.order.payments}
                tableWidth={2}
                header={[
                  "訂單編號",
                  "交易編號（綠界）",
                  "付款狀態",
                  "付款方式",
                  "總金額",
                  "建立時間",
                ]}
                columns={[
                  "serialNumber",
                  "tradeNumber",
                  (item) => (item.success ? "成功" : "失敗"),
                  (item) => {
                    function getPaymentMethod() {
                      switch (item.paymentMethod) {
                        case "CREDIT_CARD":
                          return "信用卡";
                        case "ATM":
                          return "ATM";
                        case "OTHER":
                          return "其他";
                        default:
                          return "錯誤";
                      }
                    }
                    return getPaymentMethod();
                  },
                  "totalPrice",
                  (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
                ]}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      {false && (
        <CardActions style={{ width: "100%" }}>
          <Button color="primary" variant="contained" onPress={_save}>
            儲存
          </Button>
        </CardActions>
      )}
    </Card>
  ) : null;
}
