import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react";
// material-ui
import {
  Box,
  Modal,
  makeStyles,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import MusicOffIcon from "@material-ui/icons/MusicOff";
// react-hook-form
import { useFormContext } from "react-hook-form";

// component
import LoadingModal from "../LoadingModal";
import { useAlert } from "../Alert";
import { gql, useMutation } from "@apollo/client";

// SECTION apollo
// NOTE 變更蛋孵化狀態
const CHANGE_EGG_HATCHED = gql`
  mutation changeEggHatched($eggIds: [Int]!, $feedingSuccess: Boolean) {
    changeEggHatched(eggIds: $eggIds, feedingSuccess: $feedingSuccess) {
      success
      message
      # "蛋種"
      eggType {
        # "ID"
        id
        # "孵化影片(前)"
        hatchingVideoUrl
      }
      # "玩具"
      toy {
        # "ID"
        id
        # "孵化影片(後)"
        hatchingVideoUrl
      }
    }
  }
`;
// !SECTION

function androidOrIOS() {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return "android";
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "ios";
  }
}

function VideoPlayerDialogRef({ onBack = () => {}, onGoTo = () => {} }, ref) {
  const useStyles = makeStyles({
    outside: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      height: androidOrIOS() === "ios" ? window.innerHeight : "100vh",
      width: "100%",
      overflowY: "hidden",
      padding: "16px 32px",
    },
  });
  const classes = useStyles();
  const { alert, notice } = useAlert();
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [finishedPlaying, setFinishedPlaying] = useState(false);
  const [playIndex, setPlayIndex] = useState(0);
  const [playlist, setPlaylist] = useState([]);
  const [loading, setLoading] = useState(true);
  const incomingDataRef = useRef();
  const feedingFailedMessageRef = useRef("");
  const feedingRef = useRef();
  const handleFn = useRef({
    alert,
    notice,
  });
  useEffect(() => {
    handleFn.current.alert = alert;
    handleFn.current.notice = notice;
  }, [alert, notice]);
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        const newPlaylist = [];
        if (value?.feeding) {
          feedingRef.current = value.feeding;
        }
        if (value?.feeding?.video) {
          newPlaylist.push(value.feeding.video);
        }
        if (value?.hatched?.video) {
          newPlaylist.push(value.hatched.video);
        }
        if (value?.eggType?.drawLotsVideoUrl) {
          newPlaylist.push(value.eggType.drawLotsVideoUrl);
        }
        if (value?.eggType?.hatchingVideoUrl) {
          newPlaylist.push(value.eggType.hatchingVideoUrl);
        }
        if (value?.toy?.hatchingVideoUrl) {
          newPlaylist.push(value.toy.hatchingVideoUrl);
        }
        incomingDataRef.current = value;
        setPlaylist(newPlaylist);
        setOpen(true);
      },
    }),
    []
  );
  const [changeEggHatchedFn] = useMutation(CHANGE_EGG_HATCHED, {
    onCompleted({ changeEggHatched: { success, message, eggType, toy } }) {
      if (success) {
        setPlaylist([eggType?.hatchingVideoUrl, toy?.hatchingVideoUrl]);
      } else if (message) {
        handleClose();
        setValue("memberEggsRefetch", true);
        handleFn.current.notice?.(message);
      }
    },
    onError() {
      return null;
    },
  });
  const handleClose = useCallback(
    (e) => {
      if (feedingRef.current) {
        setLoading(true);
        setFinishedPlaying(false);
        setPlayIndex(0);
        setPlaylist([]);
        if (feedingRef.current.message.includes("孵化")) {
          handleFn.current.alert?.("", feedingRef.current.message, [
            {
              text: "確定",
              onPress: () => {
                feedingRef.current = null;
                setTimeout(() => {
                  setLoading(true);
                  changeEggHatchedFn({
                    variables: { eggIds: [incomingDataRef.current?.id] },
                  });
                }, 0);
              },
              type: "ok",
            },
          ]);
        } else {
          handleFn.current.alert?.("", feedingRef.current.message, [
            {
              text: "我的蛋蛋",
              onPress: () => {
                feedingRef.current = null;
                setTimeout(() => {
                  handleClose();
                }, 0);
              },
              type: "ok",
            },
          ]);
        }
        return;
      }
      if (playlist.length === 2 || playlist.length === 3) {
        if (e === "goToToys") {
          onGoTo();
        } else {
          setValue("memberEggsRefetch", true);
        }
      } else {
        onBack(incomingDataRef.current);
      }
      setTimeout(() => {
        incomingDataRef.current = null;
        setPlaylist([]);
        setPlayIndex(0);
        setFinishedPlaying(false);
        setLoading(true);
        setOpen(false);
      }, 0);
    },
    [onBack, onGoTo, playlist, setValue, changeEggHatchedFn]
  );
  /** - 當媒體加載並準備好播放時調用的回調函數 */
  const _onReady = useCallback(() => {
    setLoading(false);
  }, []);
  const _onEnded = useCallback(() => {
    if (feedingRef.current) {
      if (feedingRef.current.message.includes("孵化")) {
        handleFn.current.alert?.("", feedingRef.current.message, [
          {
            text: "確定",
            onPress: () => {
              feedingRef.current = null;
              setLoading(true);
              changeEggHatchedFn({
                variables: { eggIds: [incomingDataRef.current?.id] },
              });
            },
            type: "ok",
          },
        ]);
      } else {
        handleFn.current.alert?.("", feedingRef.current.message, [
          {
            text: "我的蛋蛋",
            onPress: () => {
              feedingRef.current = null;
              handleClose();
            },
            type: "ok",
          },
        ]);
      }
      return;
    }
    if (playlist.length === playIndex + 1) {
      if (feedingFailedMessageRef.current) {
        handleFn.current.notice?.(feedingFailedMessageRef.current);
        feedingFailedMessageRef.current = null;
      }
      setFinishedPlaying(true);
    } else {
      setLoading(true);
      setPlayIndex((e) => e + 1);
    }
  }, [playlist, playIndex, changeEggHatchedFn, handleClose]);
  return (
    <Modal open={open}>
      <Box className={classes.outside}>
        <CloseButton finishedPlaying={finishedPlaying} onClose={handleClose} />
        {finishedPlaying &&
          (playlist.length === 2 || playlist.length === 3) && (
            <GoToMyToysButton onClose={handleClose} />
          )}
        {playlist.length > 0 ? (
          <>
            <LoadingModal loading={loading} zIndex={998} />
            <EggsVideo
              url={playlist[playIndex]}
              onReady={_onReady}
              onEnded={_onEnded}
            />
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
export default forwardRef(VideoPlayerDialogRef);
// ANCHOR 關閉
function CloseButton({ finishedPlaying, onClose = () => {} }) {
  const useStyles = makeStyles({
    closeButton: {
      position: "absolute",
      top: "16px",
      right: "16px",
      zIndex: 999,
      "&:hover": {
        cursor: "pointer",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.closeButton} onClick={onClose}>
      <Typography style={{ color: "white" }}>
        {finishedPlaying ? "關閉" : "略過"}
      </Typography>
    </Box>
  );
}
// ANCHOR 前往我的玩具
function GoToMyToysButton({ onClose = () => {} }) {
  const theme = useTheme();
  const isMoblie = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    dialogButton: {
      position: "absolute",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
      height: "100%",
      paddingBottom: isMoblie ? "7rem" : "5rem",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.dialogButton}>
      <Box
        style={{
          border: "1px solid #919191",
          zIndex: 999,
          height: "24px",
          width: "120px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        size="small"
        onClick={() => onClose("goToToys")}
      >
        <img
          style={{ height: "10px", width: "10px" }}
          src={"/img/special/mark-arrow-right.png"}
          alt="arrow"
          className="arrow-icon"
        />
        <Typography style={{ color: "#4b616b", fontWeight: 700 }}>
          前往我的玩具
        </Typography>
      </Box>
    </Box>
  );
}
// ANCHOR 音樂開關
function MusicSwitchButton({ videoParentRef, onChangeMusicStatus = () => {} }) {
  const useStyles = makeStyles({
    closeButton: {
      position: "absolute",
      top: "16px",
      right: "128px",
      zIndex: 999,
      "&:hover": {
        cursor: "pointer",
      },
    },
  });
  const classes = useStyles();
  const [musicOpen, setMusicOpen] = useState(false);
  const _onOpenMusic = useCallback(() => {
    if (videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        if (musicOpen) {
          player.muted = true;
          player.setAttribute("muted", "");
          setMusicOpen(false);
          onChangeMusicStatus(false);
        } else {
          player.muted = false;
          player.setAttribute("muted", false);
          setMusicOpen(true);
          onChangeMusicStatus(true);
        }
      }
    }
  }, [musicOpen]);
  return (
    <Box className={classes.closeButton} onClick={_onOpenMusic}>
      {musicOpen ? (
        <MusicNoteIcon style={{ color: "#fff" }} />
      ) : (
        <MusicOffIcon style={{ color: "#fff" }} />
      )}
    </Box>
  );
}
// ANCHOR 影片
const EggsVideo = memo(function EggsVideo({ url, onReady, onEnded }) {
  const videoParentRef = useRef();
  const musicStatusRef = useRef(false);
  useEffect(() => {
    if (videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.autoplay = true;
        if (musicStatusRef.current) {
          player.muted = false;
        }
        player.onended = () => {
          onEnded();
        };
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise.then(() => {
              onReady();
            });
          }
        }, 0);
      }
    }
  }, [onReady, onEnded, url]);

  return (
    <>
      <MusicSwitchButton
        videoParentRef={videoParentRef}
        onChangeMusicStatus={(e) => {
          musicStatusRef.current = e;
        }}
      />
      <div
        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          id="toyVideo"
          muted
          autoplay
          playsinline
          preload="metadata"
          width="100%"
          height="100%"
        >
        <source src="${url}" type="video/mp4" />
        </video>`,
        }}
      />
    </>
  );
});
