import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress, Backdrop, makeStyles } from "@material-ui/core";
import debounce from "debounce";

export default function LoadingModal({ loading = false }) {
  const useStyles = makeStyles((theme) => ({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  }));
  const classes = useStyles();
  const [loadingModal, setLoadingModal] = useState(false);
  useEffect(() => {
    if (loading) {
      setLoadingModal(true);
    }
  }, [loading]);
  /** - 延遲寫入1 */
  const _delayedWrite1 = useMemo(
    () =>
      debounce((currentLoading) => {
        if (!currentLoading) {
          setLoadingModal(false);
        }
      }, 1000),
    []
  );
  useEffect(() => {
    _delayedWrite1(loading);
  }, [loading, _delayedWrite1]);
  return (
    <Backdrop open={loadingModal} classes={{ root: classes.Loading }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
