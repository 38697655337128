/**- 檢查特殊符號*/
export const checkSpecialSymbols = new RegExp(
  /[`~!@#$%^&*()_+<>?:"{},.;'[\]]/im
);
/**- 檢查特殊符號和數字*/
export const checkSpecialSymbolsAndNumber = new RegExp(
  /[`~!@#$%^&*()_+<>?:"{},0-9.;'[\]]/im
);
/**- 檢查手機*/
export const checkMobile = new RegExp("^09\\d{8}$");
/**- 檢查統編*/
export const checkGuiNumber = new RegExp(/^[0-9]{8}$/);
/**- 檢查自然人憑證*/
export const checkCertificateOfNaturalPerson = new RegExp(
  /^[a-zA-Z]{2}[0-9]{14}$/
);
/**- 檢查手機載具*/
export const checkCarrierNumber = new RegExp(/^\/{1}[0-9A-Z]{7}$/);
/**- 檢查身分證字號*/
export const checkIdentityNumber = new RegExp(/^[A-Z]{1}[1-2]{1}[0-9]{8}$/);
/**- 檢查信箱*/
export const checkEmail = new RegExp(
  /^([A-Za-z0-9_])+([A-Za-z0-9_])+\.([A-Za-z]{2,4})$/
);
/**- 去掉前面零*/
export const removeZeros = new RegExp("([0]*)([1-9]+[0-9]+)", "g");
