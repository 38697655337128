import create from "zustand";
import { persist } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} hatchingOrderId - hatchingOrderId
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  hatchingOrderId: null,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} setHatchingOrderId - setHatchingOrderId
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  setHatchingOrderId: (hatchingOrderId) => {
    return new Promise((resolve, reject) => {
      if (hatchingOrderId && typeof hatchingOrderId === "number") {
        set({ hatchingOrderId });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  cleanHatchingOrderId: () => {
    return new Promise((resolve) => {
      set({ hatchingOrderId: null });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useHatchingOrderIdStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@luyaoHatchingOrderId",
    }
  )
);
export default useHatchingOrderIdStore;
