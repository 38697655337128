import React from "react";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

export function Card({ className, style, children }) {
  return (
    <MuiCard className={className} style={style}>
      {children}
    </MuiCard>
  );
}
export function CardContent({ className, style, children }) {
  return (
    <MuiCardContent className={className} style={style}>
      {children}
    </MuiCardContent>
  );
}
export function CardActions({ className, style, children }) {
  const useStyles = makeStyles({
    cardActions: {
      justifyContent: "flex-end",
    },
  });
  const classes = useStyles();
  return (
    <MuiCardActions
      className={clsx(classes.cardActions, className)}
      style={style}
    >
      {children}
    </MuiCardActions>
  );
}
