import React, { useCallback, useEffect, useRef } from "react";
// material-ui
import {
  CircularProgress,
  makeStyles,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
// apollo
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import { useForm, Controller } from "react-hook-form";

import { useAlert } from "./Alert";
import Button from "./Button";
import Grid from "./Grid";
// zustand
import useMemberIdStore from "../zustand/useMemberIdStore";

const GET_LUYAOPROFILEPICTURES = gql`
  query luyaoProfilePictures(
    $memberId: Int
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    luyaoProfilePictures(
      memberId: $memberId
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      contents {
        id
        file {
          location
        }
      }
    }
  }
`;

export default function LuyaoProfilePictureFloadingLayer({
  open,
  currentLuyaoProfilePicture,
  onChangeFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const theme = useTheme();
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStylesTable = makeStyles({
    dialogPaper: {
      maxHeight: `calc(90%)`,
      maxWidth: isTableSize ? `calc(85%)` : `calc(85%)`,
      width: `calc(100%)`,
      margin: 8,
    },
    container: {
      display: "flex",
      justifyContent: "center",
    },
    avatarCard: {
      position: "relative",
      alignSelf: "center",
      margin: `0 ${theme.spacing(1)}px`,
    },
    avatar: {
      width: 160,
      height: 160,
    },
  });
  const classesTable = useStylesTable();

  const { handleSubmit, control, getValues, setValue, reset } = useForm({
    defaultValues: {
      luyaoProfilePicture: null,
      page: 1,
    },
  });

  const luyaoProfilePictureRef = useRef();

  function initialization(value, data) {
    onChangeFloatingwindowClose(value, data);
    reset({
      luyaoProfilePicture: luyaoProfilePictureRef.current,
      page: 1,
    });
  }

  function submit(data) {
    initialization("mutation", data.luyaoProfilePicture);
  }

  const [getLuyaoProfilePictures, { data, loading }] = useLazyQuery(
    GET_LUYAOPROFILEPICTURES,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ luyaoProfilePictures }) {
        if (luyaoProfilePictures) {
          setTimeout(() => {
            if (currentLuyaoProfilePicture) {
              luyaoProfilePictureRef.current = currentLuyaoProfilePicture;
              setValue("luyaoProfilePicture", currentLuyaoProfilePicture);
            }
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  useEffect(() => {
    if (open) {
      getLuyaoProfilePictures({
        variables: {
          memberId,
          latest: true,
          pageSize: 8,
          page: getValues("page"),
        },
      });
    }
  }, [open, getLuyaoProfilePictures, memberId, getValues]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={false}
      classes={{ paper: classesTable.dialogPaper }}
      onClose={initialization}
    >
      <DialogTitle classes={{ root: classesTable.container }}>
        路遙大頭貼
      </DialogTitle>
      {loading ? (
        <DialogContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: 160 }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Controller
              control={control}
              name="luyaoProfilePicture"
              rules={{
                required: "尚未選擇大頭貼",
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Grid container spacing={1}>
                  {Boolean(data) && data.luyaoProfilePictures.contents[0] ? (
                    data.luyaoProfilePictures.contents.map((item) => (
                      <Grid container item xs={12} sm={3}>
                        <CardActionArea
                          style={{
                            padding: value && value.id === item.id ? 7 : 5,
                            display: "flex",
                            border:
                              value &&
                              value.id === item.id &&
                              "2px black solid",
                            borderRadius: "5px",
                          }}
                          onClick={() => onChange(item)}
                        >
                          <CardMedia
                            component="img"
                            image={item.file.location}
                            style={{ height: 160, width: 160 }}
                          />
                        </CardActionArea>
                      </Grid>
                    ))
                  ) : (
                    <Box
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "160px",
                      }}
                    >
                      <Typography variant="body1">尚無大頭貼</Typography>
                    </Box>
                  )}
                  <Grid container item xs={12} justify="center">
                    <Pagination
                      count={
                        Boolean(data) && data.luyaoProfilePictures.pageCount
                          ? data.luyaoProfilePictures.pageCount
                          : 1
                      }
                      size="large"
                      color="primary"
                      page={getValues("page")}
                      onChange={(e, n) => {
                        getLuyaoProfilePictures({
                          variables: {
                            latest: true,
                            pageSize: 8,
                            page: n,
                            memberId,
                          },
                        });
                        setValue("page", n);
                      }}
                      showFirstButton
                      showLastButton
                    />
                  </Grid>
                  <Grid container item xs={12} justify="center">
                    <Typography style={error && { color: "red" }}>
                      {error && error.message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={handleSubmit(submit)}
                >
                  確定
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={() => initialization()}
                >
                  取消
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
