// date-fns
import {
  startOfYear,
  startOfMonth,
  startOfDay,
  startOfHour,
  startOfMinute,
  isBefore,
  isValid,
  parseISO,
} from "date-fns";

export default function dateIsBefore(date1, date2, unit = "") {
  if (unit) {
    if (!date1 || !date2) {
      throw new Error(`date1 or date2 is not defined`);
    }
    const procName = `startOf${unit[0].toUpperCase()}${unit.slice(1)}`;
    let resultDate1 = isValid(date1, "MM/dd/yyyy");
    let resultDate2 = isValid(date2, "MM/dd/yyyy");
    if (!resultDate1) {
      date1 = parseISO(date1);
    }
    if (!resultDate2) {
      date2 = parseISO(date2);
    }
    switch (procName) {
      case "startOfYear":
        date1 = startOfYear(date1);
        date2 = startOfYear(date2);
        break;
      case "startOfMonth":
        date1 = startOfMonth(date1);
        date2 = startOfMonth(date2);
        break;
      case "startOfDay":
        date1 = startOfDay(date1);
        date2 = startOfDay(date2);
        break;
      case "startOfHour":
        date1 = startOfHour(date1);
        date2 = startOfHour(date2);
        break;
      case "startOfMinute":
        date1 = startOfMinute(date1);
        date2 = startOfMinute(date2);
        break;
      default:
        throw new Error(`dateIsBefore: Unsupported unit: ${unit}`);
    }
  }
  return isBefore(date1, date2);
}
