import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  DialogActions,
  Button,
  FormHelperText,
  makeStyles,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Chip,
} from "@material-ui/core";
import { Controller, useForm, useWatch } from "react-hook-form";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { deepEqual } from "fast-equals";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import LoadingModal from "../../../component/LoadingModal";
import { AutocompleteFocusSelect } from "../../../component/Form";

// SECTION apollo
// NOTE 路遙大頭貼
/** - 路遙大頭貼 */
const GET_LUYAO_PROFILE_PICTURE = gql`
  query luyaoProfilePicture($id: Int!) {
    luyaoProfilePicture(id: $id) {
      id
      name
      allMembers
      file {
        location
      }
      assignedMembers {
        id
        fullName
      }
    }
  }
`;
// NOTE 會員列表
/** - 會員列表 */
const GET_MEMBERS = gql`
  query members($searchTerm: String, $hidden: Boolean) {
    members(searchTerm: $searchTerm, hidden: $hidden) {
      members {
        id
        fullName
      }
    }
  }
`;
// NOTE 指派路遙大頭貼給會員
/** - 指派路遙大頭貼給會員 */
const ASSIGN_LUYAO_PROFILE_PICTURE_TO_MEMBERS = gql`
  mutation assignLuyaoProfilePictureToMembers(
    $pictureId: Int!
    $memberIds: [Int!]!
    $toAllMembers: Boolean!
  ) {
    assignLuyaoProfilePictureToMembers(
      pictureId: $pictureId
      memberIds: $memberIds
      toAllMembers: $toAllMembers
    ) {
      success
      message
    }
  }
`;
// !SECTION

const useStyles = makeStyles({
  memberIdsBox: {
    display: "flex",
    flexDirection: "row",
    minHeight: 40,
    border: "1px solid #C0C0C0",
    borderRadius: "4px",
    alignItems: "center",
    flexWrap: "wrap",
    "& > div": {
      padding: 5,
    },
  },
  text: {
    flexGrow: 1,
  },
});

/** - 指派路遙大頭貼給會員`Dialog` */
function AssignLuyaoProfilePictureToMembersDialog({ onClose = () => {} }, ref) {
  const { notice } = useAlert();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermObject, setSearchTermObject] = useState({});
  const pictureIdRef = useRef();
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      toAllMembers: false,
      memberIds: [],
    },
  });
  const toAllMembers = useWatch({ control, name: "toAllMembers" });

  const [
    getLuyaoProfilePicture,
    { data: luyaoProfilePictureData, loading: luyaoProfilePictureLoading },
  ] = useLazyQuery(GET_LUYAO_PROFILE_PICTURE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ luyaoProfilePicture }) {
      if (luyaoProfilePicture) {
        setTimeout(() => {
          const newAssignedMembers = [];
          luyaoProfilePicture.assignedMembers.forEach((item) => {
            newAssignedMembers.push({ label: item.fullName, value: item.id });
          });
          setValue("toAllMembers", luyaoProfilePicture.allMembers);
          setValue("memberIds", newAssignedMembers);
        }, 0);
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      open: (pictureId) => {
        if (pictureId) {
          pictureIdRef.current = pictureId;
          getLuyaoProfilePicture({ variables: { id: pictureId } });
        }
        setTimeout(() => {
          setOpen(true);
        }, 0);
      },
      close: () => {
        setOpen(false);
      },
    }),
    [getLuyaoProfilePicture]
  );
  const handleRef = useRef({
    onClose,
  });
  useEffect(() => {
    handleRef.current.onClose = onClose;
  }, [onClose]);

  const _onClose = useCallback(
    (value) => {
      reset({
        toAllMembers: false,
        memberIds: [],
      });
      handleRef.current.onClose(value);
    },
    [reset]
  );

  const [
    assignLuyaoProfilePictureToMembersFn,
    { loading: assignLuyaoProfilePicturesToMembersLoading },
  ] = useMutation(ASSIGN_LUYAO_PROFILE_PICTURE_TO_MEMBERS, {
    onCompleted({ assignLuyaoProfilePictureToMembers: { success, message } }) {
      if (success) {
        notice("分配成功");
        _onClose("refetch");
        return;
      } else if (message) {
        notice(message);
        return;
      }
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const _assignLuyaoProfilePicturesToMembers = useCallback(
    ({ toAllMembers, memberIds }) => {
      if (toAllMembers) {
        assignLuyaoProfilePictureToMembersFn({
          variables: {
            pictureId: pictureIdRef.current,
            memberIds: [],
            toAllMembers,
          },
        });
      } else {
        assignLuyaoProfilePictureToMembersFn({
          variables: {
            pictureId: pictureIdRef.current,
            memberIds: memberIds.map((item) => item.value),
            toAllMembers,
          },
        });
      }
    },
    [assignLuyaoProfilePictureToMembersFn]
  );

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    setSearchTermObject({ label: newInputValue, value: "null" });
  };

  const [getMembers, { loading: membersLoading }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ members }) {
      if (members) {
        setTimeout(() => {
          const newMembers = [];
          for (let i = 0; i < members.members.length; i++) {
            newMembers.push({
              label: members.members[i].fullName,
              value: members.members[i].id,
            });
          }
          setOpenSelect(() => {
            setMembers(newMembers);
            return true;
          });
        }, 0);
      }
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingModal loading={assignLuyaoProfilePicturesToMembersLoading} />
      <DialogTitle>分配大頭貼</DialogTitle>
      <DialogContent
        style={{
          height: toAllMembers ? "180px" : "400px",
          display: "flex",
          justifyContent: "center",
          overflowY: 0,
        }}
      >
        {luyaoProfilePictureLoading ? (
          <Grid container justify="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            {Boolean(luyaoProfilePictureData) && (
              <Grid container item xs={12} justifyContent="center">
                <Box
                  style={{
                    width: "100%",
                    height: 100,
                  }}
                >
                  <img
                    src={
                      luyaoProfilePictureData.luyaoProfilePicture.file.location
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Box>
                <Typography>
                  {luyaoProfilePictureData.luyaoProfilePicture.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="toAllMembers"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label={"全部會員可使用"}
                      />
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Typography style={{ color: "#f44336" }}>
                          {error?.message}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              />
            </Grid>
            {!toAllMembers && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="memberIds"
                  rules={{
                    required: "必填選項",
                  }}
                  render={({
                    field: { value: memberIds, onChange },
                    fieldState: { error },
                  }) => (
                    <Grid container item direction="column" spacing={1}>
                      <Grid item>
                        <InputLabel style={error && { color: "red" }}>
                          分配名單
                        </InputLabel>
                      </Grid>
                      <Grid item>
                        <Box
                          item
                          className={classes.memberIdsBox}
                          style={error && { borderColor: "red" }}
                        >
                          {memberIds.map((item, index) => {
                            return (
                              <Grid item>
                                <Chip
                                  label={item.label}
                                  color="primary"
                                  onDelete={() => {
                                    const deleteMemberIds = memberIds.filter(
                                      (i, j) => j !== index
                                    );
                                    onChange(deleteMemberIds);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </Box>
                      </Grid>
                      {error && error.message && (
                        <Grid item>
                          <FormHelperText style={{ color: "red" }}>
                            &ensp;&ensp;{error.message}
                          </FormHelperText>
                        </Grid>
                      )}
                      <Grid container item>
                        <Grid item xs={12} sm={6}>
                          <Box display="flex">
                            <AutocompleteFocusSelect
                              open={openSelect}
                              label={"選擇會員"}
                              items={members}
                              onInputChange={handleInputChange}
                              loading={membersLoading}
                              value={
                                searchTermObject.value === "null"
                                  ? searchTermObject
                                  : null
                              }
                              onChange={(e, value) => {
                                if (value) {
                                  const hasOption = memberIds.find(
                                    (item) => item.value === value.value
                                  );
                                  if (Boolean(hasOption)) {
                                    return null;
                                  } else {
                                    const newMemberIds = [...memberIds, value];
                                    onChange(newMemberIds);
                                  }
                                } else {
                                  setSearchTerm(null);
                                  setSearchTermObject({});
                                  setOpenSelect(false);
                                }
                              }}
                              renderOption={(option) => {
                                const hasOption = memberIds.find(
                                  (item) => item.value === option.value
                                );
                                return (
                                  <React.Fragment>
                                    <div className={classes.text}>
                                      {option.label}
                                    </div>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={Boolean(hasOption)}
                                    >
                                      {hasOption ? "已加入" : "未加入"}
                                    </Button>
                                  </React.Fragment>
                                );
                              }}
                              fullWidth
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                if (openSelect) {
                                  setSearchTerm(null);
                                  setSearchTermObject({});
                                  setOpenSelect(false);
                                } else {
                                  getMembers({
                                    variables: { searchTerm, hidden: false },
                                  });
                                }
                              }}
                              disabled={!Boolean(searchTerm) || membersLoading}
                            >
                              {openSelect ? "關閉" : "搜尋"}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          {!luyaoProfilePictureLoading && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_assignLuyaoProfilePicturesToMembers)}
              >
                確定
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" color="primary" onClick={_onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default memo(
  forwardRef(AssignLuyaoProfilePictureToMembersDialog),
  deepEqual
);
