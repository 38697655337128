import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  ButtonBase,
  DialogActions,
  Button,
  FormHelperText,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { deepEqual } from "fast-equals";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import LoadingModal from "../../../component/LoadingModal";
import { CustomTextField, InputTitle } from "../../../component/Form";

// SECTION apollo
// NOTE 上傳圖片
/** - 上傳圖片 */
const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;
// NOTE 儲存路遙大頭貼
/** - 儲存路遙大頭貼 */
const SAVE_LUYAO_PROFILE_PICTURE = gql`
  mutation saveLuyaoProfilePicture(
    $luyaoProfilePictureInput: LuyaoProfilePictureInput!
  ) {
    saveLuyaoProfilePicture(
      luyaoProfilePictureInput: $luyaoProfilePictureInput
    ) {
      success
      message
    }
  }
`;
// !SECTION

/** - 儲存路遙大頭貼`Dialog` */
function SaveLuyaoProfilePictureDialog({ onClose = () => {} }, ref) {
  const { notice } = useAlert();
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      file: null,
      password: "",
    },
  });
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    }),
    []
  );
  const handleRef = useRef({
    onClose,
  });
  useEffect(() => {
    handleRef.current.onClose = onClose;
  }, [onClose]);

  const _onClose = useCallback(
    (value) => {
      reset();
      handleRef.current.onClose(value);
    },
    [reset]
  );

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  const [saveLuyaoProfilePicture, { loading: saveLuyaoProfilePictureLoading }] =
    useMutation(SAVE_LUYAO_PROFILE_PICTURE, {
      onCompleted({ saveLuyaoProfilePicture: { success, message } }) {
        if (success) {
          notice("儲存成功");
          _onClose("refetch");
          return;
        } else if (message) {
          notice(message);
          return;
        }
      },
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  const _createLuyaoProfilePicture = useCallback(
    async ({ name, file, password }) => {
      const { data } = await uploadImage({
        variables: { image: file.localPath },
      });
      if (data?.uploadImage) {
        const { __typename, ...otherUploadImages } = data.uploadImage;
        const luyaoProfilePictureInput = {
          name: name,
          file: otherUploadImages,
          password: null,
        };
        if (password) {
          luyaoProfilePictureInput.password = password;
        }
        saveLuyaoProfilePicture({
          variables: {
            luyaoProfilePictureInput,
          },
        });
      }
    },
    [saveLuyaoProfilePicture, uploadImage]
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingModal
        loading={saveLuyaoProfilePictureLoading || uploadImageLoading}
      />
      <DialogTitle>新增大頭貼</DialogTitle>
      <DialogContent
        style={{
          height: "410px",
          display: "flex",
          justifyContent: "center",
          overflowY: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              rules={{
                required: "必填",
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <InputTitle label="大頭貼名稱" />
                  <CustomTextField
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <div>
                  <InputTitle label="密碼" />
                  <CustomTextField {...field} fullWidth />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="file"
              rules={{
                required: "必填欄位",
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <ButtonBase
                    style={{
                      width: "100%",
                      height: 300,
                      border: `1px solid ${error ? "red" : "#C0C0C0"}`,
                      borderRadius: "5px",
                    }}
                    component="label"
                    htmlFor={`uploadImage`}
                  >
                    {Boolean(value) ? (
                      <img
                        src={value.location}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    ) : (
                      <CloudUploadIcon />
                    )}
                  </ButtonBase>
                  <input
                    id={`uploadImage`}
                    type="file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => {
                      onChange({
                        location: URL.createObjectURL(e.target.files[0]),
                        localPath: e.target.files[0],
                      });
                    }}
                  />
                  <Grid container xs={12}>
                    <FormHelperText style={error?.message && { color: "red" }}>
                      &ensp;&ensp;{error?.message}
                    </FormHelperText>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(_createLuyaoProfilePicture)}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={_onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default memo(forwardRef(SaveLuyaoProfilePictureDialog), deepEqual);
