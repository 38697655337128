import NotificationsIcon from "@material-ui/icons/Notifications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { useHistory } from "react-router-dom";

import { useAlert } from "./component/Alert";
import { useLocalStorage } from "./component/LocalStorageContext";
// zustand
import useTokenStore from "./zustand/useTokenStore";

export default function useLayoutSettings() {
  const Alert = useAlert();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  function signOut() {
    Alert.alert("", "確定要登出嗎？", [
      { text: "取消", type: "cancel" },
      {
        text: "確認",
        onPress: () => {
          useTokenStore.getState().cleanConsoleToken();
          LocalStorage.clearSearchProductPageSize();
          LocalStorage.clearSearchProductPage();
          LocalStorage.clearSearchMemberSearchTerm();
          LocalStorage.clearSearchMemberCountryCallingCode();
          LocalStorage.clearSearchMemberTier();
          LocalStorage.clearSearchMemberLatest();
          LocalStorage.clearSearchMemberPageSize();
          LocalStorage.clearSearchMemberPage();
          LocalStorage.clearSearchOrderStatus();
          LocalStorage.clearSearchOrderProductId();
          LocalStorage.clearSearchOrderInternational();
          LocalStorage.clearSearchOrderSearchTerm();
          LocalStorage.clearSearchOrderLatest();
          LocalStorage.clearSearchOrderPageSize();
          LocalStorage.clearSearchOrderPage();
          LocalStorage.clearSearchShipmentStatus();
          LocalStorage.clearSearchShipmentProductId();
          LocalStorage.clearSearchShipmentShipmentMethod();
          LocalStorage.clearSearchShipmentSearchTerm();
          LocalStorage.clearSearchShipmentCombinedDelivery();
          LocalStorage.clearSearchShipmentLatest();
          LocalStorage.clearSearchShipmentPageSize();
          LocalStorage.clearSearchShipmentPage();
          history.replace("/console/login");
        },
        type: "ok",
      },
    ]);
  }
  const setting = {
    navbar: {
      buttons: [
        //{title:"名稱",icon:圖標,onPress:要做的函式} 最多三個
        {
          title: "通知",
          icon: NotificationsIcon,
          onPress: () => Alert.alert("", "通知一號", [{ text: "確認" }]),
        },
        {
          title: "設定",
          icon: SettingsIcon,
          onPress: () => Alert.alert("", "設定一號", [{ text: "確認" }]),
        },
        {
          title: "登出",
          icon: ExitToAppIcon,
          onPress: signOut,
        },
      ],
    },
    drawer: {
      logo: require("./images/logo.jpg").default,
      items: [
        // title:"標題",route:"路由路線",subItems:[{同前面}]，沒有子層的話就不要給subItems，有subItems時不會有route
        // {
        //   //首頁，沒意外不用動
        //   title: "首頁",
        //   route: "/console/home",
        // },
        {
          title: "最新消息管理",
          route: "/console/article",
        },
        {
          title: "輪播圖管理",
          route: "/console/carousel",
        },
        {
          title: "商品管理",
          route: "/console/product",
        },
        {
          title: "活動管理",
          route: "/console/event",
        },
        {
          title: "育成管理",
          route: "/console/hatching",
        },
        {
          title: "路遙大頭貼管理",
          route: "/console/luyaoProfilePicture",
        },
        {
          title: "會員管理",
          route: "/console/member",
        },
        {
          title: "會員認證名單",
          route: "/console/member-directory",
        },
        {
          title: "訂單管理",
          route: "/console/order",
        },
        {
          title: "出貨管理",
          route: "/console/shipment",
        },
        {
          title: "常見問題",
          route: "/console/faq",
        },
      ],
    },
  };
  return setting;
}
