import React, { useCallback, useEffect, useState } from "react";
// material
import {
  AppBar,
  Box,
  Button,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
  ButtonBase,
  Typography,
  Hidden,
  Grid,
  Link,
  CircularProgress,
} from "@material-ui/core";
// icon
import MenuIcon from "@material-ui/icons/Menu";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
// react-router-dom
import { useHistory, useLocation } from "react-router-dom";
// apollo
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-redux
import { useDispatch } from "react-redux";

// icon
import { ReactComponent as Logo } from "./logo.svg";
// component
import { useAlert } from "./component/Alert";
// redux
import { cleanMemberTierLimit } from "./redux/member";
// zustand
import useTokenStore from "./zustand/useTokenStore";
import useMemberIdStore from "./zustand/useMemberIdStore";
// utils
import emptyArray from "./utils/emptyArray";
// zustand
import useEventTypeStore from "./zustand/useEventTypeStore";

// SECTION apollo
// NOTE 最新消息列表
const GET_ARTICLES = gql`
  query articles {
    articles {
      articles {
        id
        title
      }
    }
  }
`;
// NOTE 常見問題列表
const GET_FAQS = gql`
  query faqs {
    faqs {
      id
      question
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function WebSiteLayout({ children }) {
  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: theme.palette.grey["50"],
      position: "relative",
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="lg" disableGutters className={classes.container}>
      <Header />
      <Box flex={1}>{children}</Box>
      <Footer />
    </Container>
  );
}

// ANCHOR 菜單陣列組件
/**
 * @typedef MenuArrayMap
 * @property {(Object:{label:string, onClick:Function}) => void} component
 * @param {MenuArrayMap} param0
 */
function MenuArrayMap({ component }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const Alert = useAlert();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const menuArray = [
    { path: "/special-event", label: "育成專區" },
    { path: "/", label: "首頁" },
    { path: "/event", label: "活動商品" },
    { path: "/product/exclusive", label: "限定商品" },
    { path: "/product/preorder", label: "預購商品" },
    { path: "/product", label: "現貨商品" },
    { path: "/member", label: "會員中心", needLogin: true },
    { path: "/cart", label: "購物車" },
    { path: "/faq", label: "常見問題" },
    { path: "/article", label: "最新消息" },
    { onClick: _signOut, label: "登出", needLogin: true },
    { path: "/login", label: "登入", needLogin: false },
  ];
  function _signOut() {
    Alert.alert("", "確定登出？", [
      { text: "取消", onPress: () => {}, type: "cancel" },
      {
        text: "確定",
        onPress: () => {
          dispatch(cleanMemberTierLimit());
          useTokenStore.getState().cleanToken();
          useMemberIdStore.getState().cleanMemberId();
          //如果本來就在首頁就不重新跳轉
          if (pathname !== "/") {
            return history.replace("/");
          }
        },
        type: "ok",
      },
    ]);
  }
  return menuArray.map((item) => {
    if (
      item.needLogin === undefined ||
      (Boolean(memberToken) && item.needLogin === true) ||
      (!Boolean(memberToken) && item.needLogin === false)
    ) {
      return component({
        label: item.label,
        onClick: () =>
          item.onClick ? item.onClick() : history.push(item.path),
      });
    } else {
      return null;
    }
  });
}
// ANCHOR header
function Header() {
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    appBar: {
      top: 0,
    },
    logo: {
      width: 48,
      height: 48,
      cursor: "pointer",
    },
    toolbar: {
      display: "flex",
      height: 64,
      // padding: `0 ${theme.spacing(isTable ? 1 : 4)}px`,
      backgroundColor: theme.palette.primary.main,
    },
    toolbarArea: {
      backgroundColor: isTable ? "transparent" : "white",
      color: isTable ? "white" : "black",
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    drawerPaper: {
      width: "80%",
      maxWidth: 300,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    buttonBase: {
      height: "100%",
      padding: `0 ${theme.spacing(2)}px`,
    },
    buttonIcon: {
      height: "0.75rem",
      width: "0.75rem",
      marginRight: theme.spacing(1),
    },
  });
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (!isTable) {
      setOpenDrawer(false);
    }
  }, [isTable]);

  const headerFontStyle = { color: "white", textDecorationLine: "none" };

  return (
    <AppBar elevation={0} position="sticky" className={classes.appBar}>
      <Hidden smDown>
        <Box height={32} display="flex" justifyContent="space-between">
          <Box display="flex">
            <ButtonBase className={classes.buttonBase}>
              <PhoneIcon className={classes.buttonIcon} />
              <Typography variant="caption">
                <a style={headerFontStyle} href="tel:+886424526619">
                  04 2452 6619
                </a>
              </Typography>
            </ButtonBase>
            <ButtonBase className={classes.buttonBase}>
              <MailIcon className={classes.buttonIcon} />
              <Typography variant="caption">
                <a
                  style={headerFontStyle}
                  href="mailto:luyao.design2019@gmail.com"
                >
                  luyao.design2019@gmail.com
                </a>
              </Typography>
            </ButtonBase>
          </Box>
          <Box display="flex">
            <ButtonBase className={classes.buttonBase}>
              <FacebookIcon className={classes.buttonIcon} />
              <Typography variant="caption">
                <a
                  style={headerFontStyle}
                  href="https://www.facebook.com/luyao2019"
                >
                  Facebook
                </a>
              </Typography>
            </ButtonBase>
            <ButtonBase className={classes.buttonBase}>
              <AlternateEmailIcon className={classes.buttonIcon} />
              <Typography variant="caption">
                <a
                  style={headerFontStyle}
                  href="https://line.me/R/ti/p/@642psugn"
                >
                  LINE
                </a>
              </Typography>
            </ButtonBase>
            <ButtonBase className={classes.buttonBase}>
              <InstagramIcon className={classes.buttonIcon} />
              <Typography variant="caption">
                <a
                  style={headerFontStyle}
                  href="https://www.instagram.com/luyao.design2019"
                >
                  Instagram
                </a>
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Hidden>
      <Box className={classes.toolbar}>
        <Box className={classes.toolbarArea}>
          <Box
            width={64}
            height={64}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Logo
              className={classes.logo}
              onClick={() => {
                if (pathname === "/") {
                  return document.documentElement.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                } else {
                  return history.push("/");
                }
              }}
              fill={isTable ? "white" : "black"}
            />
          </Box>
          <Box display="flex" color={isTable ? "white" : "black"}>
            {isTable ? (
              <IconButton color="inherit" onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
              </IconButton>
            ) : (
              <MenuArrayMap
                component={({ onClick, label }) => (
                  <Button
                    key={label}
                    onClick={onClick}
                    style={
                      label === "育成專區"
                        ? { color: "#E7211A", fontWeight: 700 }
                        : {}
                    }
                  >
                    {label}
                  </Button>
                )}
              />
            )}
          </Box>
          <SwipeableDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
            anchor="right"
            classes={{ paper: classes.drawerPaper }}
          >
            <List>
              <MenuArrayMap
                component={({ onClick, label }) => (
                  <ListItem
                    button
                    key={label}
                    onClick={() => {
                      onClick();
                      setOpenDrawer(false);
                    }}
                  >
                    <ListItemText
                      primary={label}
                      style={
                        label === "育成專區"
                          ? { color: "#E7211A", fontWeight: 700 }
                          : {}
                      }
                    />
                  </ListItem>
                )}
              />
            </List>
          </SwipeableDrawer>
        </Box>
      </Box>
    </AppBar>
  );
}
// ANCHOR footer
function Footer() {
  const isTablet = useMediaQuery("(max-width:960px)");
  const history = useHistory();
  const Alert = useAlert();
  const { pathname } = useLocation();
  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: theme.palette.grey["900"],
      color: theme.palette.common.white,
      padding: theme.spacing(5),
    },
    ul: {
      listStyleType: "none",
      margin: 0,
      paddingLeft: 0,
      "& li": {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
    },
    title: {
      fontWeight: "bold",
      color: theme.palette.grey["500"],
    },
    gridItemBox: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    link: {
      color: "white",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.info.main,
      },
    },
    copyright: {
      color: theme.palette.grey["500"],
    },
  }));
  const classes = useStyles();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  function _signOut() {
    Alert.alert("", "確定登出？", [
      { text: "取消", onPress: () => {}, type: "cancel" },
      {
        text: "確定",
        onPress: () => {
          useTokenStore.getState().cleanToken();
          useMemberIdStore.getState().cleanMemberId();
          //如果本來就在首頁就不重新跳轉
          if (pathname !== "/") {
            return history.replace("/");
          }
        },
        type: "ok",
      },
    ]);
  }
  const eventList = [
    {
      label: "抽選活動",
      path: "/event",
      param: {
        eventType: "drawingLots",
      },
    },
    {
      label: "社團限定",
      path: "/event",
      param: {
        eventType: "productsOfPasswordRequired",
      },
    },
    {
      label: "團體戰活動",
      path: "/event",
      param: {
        eventType: "raid",
      },
    },
  ];
  const productList = [
    { label: "預購商品", path: "/product/preorder" },
    { label: "限定商品", path: "/product/exclusive" },
    { label: "現貨商品", path: "/product" },
  ];

  const { data, loading } = useQuery(GET_ARTICLES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.alert("通知", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });

  const { data: faqsData, loading: faqsLoading } = useQuery(GET_FAQS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.alert("通知", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });

  // 切割陣列 (點擊看更多) - 最新消息

  const [toggleMore, setToggleMore] = useState({
    newInfo: false,
    question: false,
  });

  return (
    <Box className={classes.container}>
      <Container disableGutters>
        {/* 這邊 */}
        <Grid container spacing={isTablet ? 2 : 8}>
          {loading || faqsLoading ? (
            <Box
              height="220px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <>
              {/* <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    路遙圓創
                  </Typography>
                  <ul className={classes.ul}>
                    <li>
                      <Link className={classes.link}>品牌介紹</Link>
                    </li>
                  </ul>
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    全部商品
                  </Typography>
                  <ul className={classes.ul}>
                    {productList.map((item) => (
                      <li key={item.label}>
                        <Link
                          className={classes.link}
                          onClick={() => history.push(item.path)}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    最新消息
                  </Typography>
                  <ul className={classes.ul}>
                    {(() => {
                      const arr = emptyArray(data?.articles?.articles);
                      const state = toggleMore.newInfo;
                      const slice = state ? arr.length : 3;
                      const newArr = arr.slice(0, slice);
                      return newArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className={classes.link}
                              onClick={() =>
                                history.push(`/article/${item.id}`)
                              }
                            >
                              {item.title}
                            </Link>
                          </li>
                        );
                      });
                    })()}
                    <li>
                      <Link
                        className={classes.link}
                        onClick={() => history.push("/article")}
                      >
                        更多資訊
                      </Link>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    活動頁面
                  </Typography>
                  <ul className={classes.ul}>
                    {eventList.map((item) => (
                      <li key={item.label}>
                        <Link
                          className={classes.link}
                          onClick={() => {
                            if (item.param?.eventType) {
                              useEventTypeStore
                                .getState()
                                .setEventType(item.param.eventType);
                            }
                            history.push(item.path);
                          }}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    常見問題
                  </Typography>

                  <ul className={classes.ul}>
                    {(() => {
                      const state = toggleMore.question;
                      const slice = state
                        ? emptyArray(faqsData?.faqs).length
                        : 3;
                      const newArr = emptyArray(faqsData?.faqs).slice(0, slice);
                      return newArr.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className={classes.link}
                              onClick={() => history.push("/faq")}
                            >
                              {item.question}
                            </Link>
                          </li>
                        );
                      });
                    })()}
                    {/* {faqsData.faqs.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          className={classes.link}
                          onClick={() => history.push("/faq")}
                        >
                          {item.question}
                        </Link>
                      </li>
                    );
                  })} */}

                    <li>
                      <Link
                        className={classes.link}
                        onClick={() =>
                          setToggleMore((e) => ({
                            ...e,
                            question: !e.question,
                          }))
                        }
                      >
                        {toggleMore.question ? "" : "更多資訊"}
                      </Link>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box className={classes.gridItemBox}>
                  <Typography title="h6" className={classes.title}>
                    會員中心
                  </Typography>
                  <ul className={classes.ul}>
                    {Boolean(memberToken) ? (
                      <>
                        <li>
                          <Link
                            className={classes.link}
                            onClick={() => history.push("/cart")}
                          >
                            購物車
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classes.link}
                            onClick={() =>
                              history.push("/member?type=消費紀錄")
                            }
                          >
                            購買紀錄
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classes.link}
                            onClick={() =>
                              history.push("/member?type=會員資料")
                            }
                          >
                            修改個人資料
                          </Link>
                        </li>
                        <li>
                          <Link className={classes.link} onClick={_signOut}>
                            登出
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li>
                        <Link
                          className={classes.link}
                          onClick={() => history.push("/login")}
                        >
                          登入/註冊
                        </Link>
                      </li>
                    )}
                  </ul>
                </Box>
              </Grid>
            </>
          )}
          <Grid container item alignItems="center" direction="column">
            {/* <Box display="flex" alignItems="center">
              <Link className={classes.link}>隱私權政策</Link>
              <Typography>-</Typography>
              <Link className={classes.link}>免責聲明</Link>
            </Box> */}
            <Typography variant="caption" className={classes.copyright}>
              Copyright © {new Date().getFullYear()} 路遙圓創有限公司
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
