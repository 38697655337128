import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { Box, Typography, useTheme } from "@material-ui/core";

export default function DateCountdown({
  start,
  startLabel,
  end,
  endLabel,
  onEnd = () => {},
  onEventCountdownTenMinute = () => {},
  render,
}) {
  const theme = useTheme();
  const countdownRef = useRef();
  function nowTime() {
    return moment().valueOf();
  }
  const startTime = start && start.valueOf();
  const endTime = end && end.valueOf();
  const [countTime, setCountTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const checkStart = Boolean(
    (startTime && startTime > nowTime()) || (endTime && endTime > nowTime())
  );
  const secondUnit = 60;
  const hourUnit = 60;
  const dayUnit = 24;
  const millisecondUnit = 1000;

  const _countDown = useCallback(() => {
    const dateDiff =
      ((startTime ? startTime : endTime) - nowTime()) / millisecondUnit;
    const dayValue = dateDiff % (dayUnit * secondUnit * hourUnit);
    const hourValue = dayValue % (secondUnit * hourUnit);
    const secondValue = hourValue % secondUnit;
    setCountTime({
      days: Math.floor(dateDiff / (dayUnit * secondUnit * hourUnit)),
      hours: Math.floor(dayValue / (secondUnit * hourUnit)),
      minutes: Math.floor(hourValue / secondUnit),
      seconds: Math.round(secondValue),
    });
  }, [startTime, endTime]);

  useEffect(() => {
    if (checkStart) {
      countdownRef.current = setInterval(_countDown, 1000);
    }
    return () => clearInterval(countdownRef.current);
  }, [_countDown, checkStart]);
  useEffect(() => {
    if (
      Number(countTime.days) < 0 &&
      Number(countTime.hours) < 0 &&
      Number(countTime.minutes) < 0 &&
      (Number(countTime.seconds) <= 0 || countTime.seconds === -0)
    ) {
      onEnd();
    } else if (
      countTime.days === 0 &&
      countTime.hours === 0 &&
      countTime.minutes === 9
    ) {
      onEventCountdownTenMinute();
    }
  }, [countTime, onEnd, onEventCountdownTenMinute]);
  if (render) {
    return render({
      days: countTime.days,
      hours: countTime.hours,
      minutes: countTime.minutes,
      seconds: countTime.seconds,
    });
  } else if (checkStart) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography style={{ color: theme.palette.text.hint }}>
          {start ? startLabel : null}
          {end ? endLabel : null}
        </Typography>
        <Typography style={{ color: theme.palette.text.hint }}>
          {countTime.days} 天 {countTime.hours} 時 {countTime.minutes} 分{" "}
          {countTime.seconds} 秒
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
}
