import React, { useState, useRef, useCallback } from "react";
import { Box, Grid, CircularProgress, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import { useQuery, useMutation, gql } from "@apollo/client";
import * as clipboard from "clipboard-polyfill";
// NOTE 組件
import Table from "../component/Table";
import { Card, CardContent, CardActions } from "../component/Card";
import AspectRatioBox from "../component/AspectRatioBox";
import { useAlert } from "../component/Alert";
import Button from "../component/Button";
import LoadingModal from "../component/LoadingModal";
import { Select, TextInput } from "../component/Form";
import SaveLuyaoProfilePictureDialog from "./component/LuyaoProfilePictures/SaveLuyaoProfilePictureDialog";
import AssignLuyaoProfilePictureToMembersDialog from "./component/LuyaoProfilePictures/AssignLuyaoProfilePictureToMembersDialog";
import UnassignLuyaoProfilePictureToMembersFromMemberDialog from "./component/LuyaoProfilePictures/UnassignLuyaoProfilePictureToMembersFromMemberDialog";

// SECTION apollo
// NOTE 路遙大頭貼列表
/** - 路遙大頭貼列表 */
const GET_LUYAO_PROFILE_PICTURES = gql`
  query luyaoProfilePictures(
    $searchTerm: String
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    luyaoProfilePictures(
      searchTerm: $searchTerm
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      count
      pageCount
      contents {
        id
        name
        allMembers
        file {
          location
        }
        # "密碼"
        password
        createdAt
      }
    }
  }
`;
// NOTE 刪除路遙大頭貼
/** - 刪除路遙大頭貼 */
const DELETE_LUYAO_PROFILE_PICTURE = gql`
  mutation deleteLuyaoProfilePicture($id: Int!) {
    deleteLuyaoProfilePicture(id: $id) {
      success
      message
    }
  }
`;
// !SECTION

export default function LuyaoProfilePicturesListPage() {
  const { alert, notice } = useAlert();
  const [searchTerm, setSearchTerm] = useState("");
  const [latest, setLatest] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const saveLuyaoProfilePictureRef = useRef();
  const assignLuyaoProfilePictureToMembersRef = useRef();
  const unassignLuyaoProfilePictureToMembersFromMemberRef = useRef();

  const { data, refetch, loading } = useQuery(GET_LUYAO_PROFILE_PICTURES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      searchTerm,
      latest,
      pageSize,
      page,
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [
    deleteLuyaoProfilePictureFn,
    { loading: deleteLuyaoProfilePictureLoading },
  ] = useMutation(DELETE_LUYAO_PROFILE_PICTURE, {
    onCompleted({ deleteLuyaoProfilePicture: { success, message } }) {
      if (success) {
        notice("刪除成功");
        refetch();
        return;
      } else if (message) {
        notice(message);
        return;
      }
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const _delete = useCallback(
    (value) => {
      alert("", "確定要刪除？", [
        {
          text: "確定",
          onPress: () =>
            deleteLuyaoProfilePictureFn({
              variables: { id: Number(value.id) },
            }),
          type: "ok",
        },
        { text: "取消", type: "cancel" },
      ]);
    },
    [alert, deleteLuyaoProfilePictureFn]
  );

  const _addLuyaoProfilePicture = useCallback(() => {
    saveLuyaoProfilePictureRef.current?.open();
  }, []);
  const _closeOfAddLuyaoProfilePicture = useCallback(
    (props) => {
      if (props === "refetch") {
        refetch();
      }
      saveLuyaoProfilePictureRef.current?.close();
    },
    [refetch]
  );
  const _closeOfAssignLuyaoProfilePictureToMembers = useCallback(
    (props) => {
      if (props === "refetch") {
        refetch();
      }
      assignLuyaoProfilePictureToMembersRef.current?.close();
    },
    [refetch]
  );
  const _unassignLuyaoProfilePictureToMembersFromMember = useCallback(() => {
    unassignLuyaoProfilePictureToMembersFromMemberRef.current?.open();
  }, []);
  const _closeOfUnassignLuyaoProfilePictureToMembersFromMember =
    useCallback(() => {
      unassignLuyaoProfilePictureToMembersFromMemberRef.current?.close();
    }, []);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <Card>
      <LoadingModal loading={deleteLuyaoProfilePictureLoading} />
      <SaveLuyaoProfilePictureDialog
        ref={saveLuyaoProfilePictureRef}
        onClose={_closeOfAddLuyaoProfilePicture}
      />
      <AssignLuyaoProfilePictureToMembersDialog
        ref={assignLuyaoProfilePictureToMembersRef}
        onClose={_closeOfAssignLuyaoProfilePictureToMembers}
      />
      <UnassignLuyaoProfilePictureToMembersFromMemberDialog
        ref={unassignLuyaoProfilePictureToMembersFromMemberRef}
        onClose={_closeOfUnassignLuyaoProfilePictureToMembersFromMember}
      />
      <CardContent>
        <Typography>
          路遙頭貼數量：{data && data.luyaoProfilePictures.count}
        </Typography>
      </CardContent>
      <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <TextInput
              label={"搜尋"}
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(() => {
                  setPage(1);
                  return value;
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"排序"}
              items={[
                { label: "從新到舊", value: true },
                { label: "從舊到新", value: false },
              ]}
              value={latest}
              onChange={(value) => {
                setLatest(() => {
                  setPage(1);
                  return value;
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Select
              label={"每頁顯示"}
              items={[
                { label: "10", value: 10 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              value={pageSize}
              onChange={(value) => {
                setPageSize(() => {
                  setPage(1);
                  return value;
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onPress={_addLuyaoProfilePicture}
              fullWidth
            >
              新增大頭貼
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onPress={_unassignLuyaoProfilePictureToMembersFromMember}
              fullWidth
            >
              取消大頭貼分配
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CardContent>
        <Table
          paper
          data={Boolean(data) ? data.luyaoProfilePictures.contents : []}
          header={[
            "大頭貼名稱",
            "大頭貼",
            "全部會員可使用",
            "建立時間",
            "操作",
          ]}
          tableWidth={2}
          columns={[
            "name",
            (item) => {
              if (item.file?.location) {
                return (
                  <Grid container>
                    <AspectRatioBox width={128} w={16} h={9}>
                      <img src={item.file.location} height="100%" alt="" />
                    </AspectRatioBox>
                  </Grid>
                );
              } else {
                return null;
              }
            },
            (item) => (item.allMembers ? "是" : "否"),
            (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
            (item) => {
              return (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() => {
                        assignLuyaoProfilePictureToMembersRef.current?.open(
                          item.id
                        );
                      }}
                    >
                      分配大頭貼
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ maxWidth: 120 }}
                      variant="outlined"
                      color="primary"
                      onPress={async () => {
                        const port = window.location.port
                          ? `:${window.location.port}`
                          : "";
                        const password = item.password
                          ? `?token=${item.password}`
                          : "";
                        const domain =
                          window.location.protocol +
                          "//" +
                          window.location.hostname +
                          port +
                          `/avatar/${item.id}` +
                          password;
                        clipboard.writeText(domain).then(
                          () => {
                            alert("", "複製成功", [
                              {
                                text: "前往",
                                onPress: () => {
                                  window.open(
                                    domain,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                },
                                type: "ok",
                              },
                              { text: "確認", type: "cancel" },
                            ]);
                          },
                          () => {
                            console.log("error!");
                          }
                        );
                      }}
                    >
                      {`複製大頭貼\n發放連結`}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onPress={() => _delete(item)}
                    >
                      刪除
                    </Button>
                  </Grid>
                </Grid>
              );
            },
          ]}
        />
      </CardContent>
      <CardActions>
        <Grid container justify="center">
          <Pagination
            count={
              Boolean(data)
                ? data.luyaoProfilePictures.pageCount
                  ? data.luyaoProfilePictures.pageCount
                  : 1
                : 1
            }
            size="large"
            color="primary"
            page={page}
            onChange={(e, n) => setPage(n)}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CardActions>
    </Card>
  );
}
