import React, { useCallback, useMemo, useRef } from "react";
// material
import { Box, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
// apollo
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// JoditEditor
import JoditEditor from "jodit-react";

// component
import { Card, CardActions, CardContent } from "../../component/Card";
import { FieldCache, InputTitle } from "../../component/Form";
import Grid from "../../component/Grid";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import { useAlert } from "../../component/Alert";

// SECTION apollo
// NOTE 活動內容
const GET_EVENTCONTENT = gql`
  query eventContent($eventId: Int!) {
    eventContent(eventId: $eventId)
  }
`;
// NOTE 儲存活動內文
const SAVE_EVENT_CONTENT = gql`
  mutation saveEventContent($eventId: Int!, $content: String!) {
    saveEventContent(eventId: $eventId, content: $content) {
      success
      message
    }
  }
`;
// !SECTION

const options = [
  "bold",
  "italic",
  "|",
  "ul",
  "ol",
  "|",
  "font",
  "fontsize",
  "|",
  "outdent",
  "indent",
  "align",
  "|",
  "hr",
  "|",
  "fullsize",
  "brush",
  "|",
  "table",
  "link",
  "|",
  "undo",
  "redo",
];

export default function HatchingDescription({ eventId }) {
  const { notice } = useAlert();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      content: "",
    },
  });
  const contentRef = useRef("");
  const { loading: eventContentLoading, refetch } = useQuery(GET_EVENTCONTENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onCompleted({ eventContent }) {
      if (eventContent) {
        setTimeout(() => {
          contentRef.current = eventContent || "";
          reset({
            content: eventContent,
          });
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  const [saveEventContentFn, { loading: saveEventContentLoading }] =
    useMutation(SAVE_EVENT_CONTENT, {
      variables: {
        eventId,
      },
      onCompleted({ saveEventContent: { message } }) {
        if (message) {
          notice(message);
        } else {
          refetch();
          notice("成功");
        }
      },
      onError() {
        return null;
      },
    });
  const _submit = useCallback(() => {
    saveEventContentFn({
      variables: {
        content: contentRef.current,
      },
    });
  }, [saveEventContentFn]);
  const _onCancel = useCallback(() => {
    refetch();
  }, [refetch]);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      defaultActionOnPaste: "insert_as_html",
      defaultLineHeight: 1.5,
      enter: "div",
      // options that we defined in above step.
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
    }),
    []
  );

  if (eventContentLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <LoadingModal loading={saveEventContentLoading} />
      <CardContent>
        <FieldCache
          control={control}
          name="content"
          render={(content) => (
            <div>
              <InputTitle label="遊戲說明" required />
              <JoditEditor
                value={content || ""}
                config={config}
                onChange={(htmlString) => (contentRef.current = htmlString)}
              />
            </div>
          )}
        />
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={handleSubmit(_submit)}
            >
              儲存
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onPress={_onCancel}>
              取消
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
