import { useEffect, memo } from "react";
import { useDispatch, batch } from "react-redux";

import { writeMemberAllValue } from "../redux/member";

function FetchData({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const memberValue = JSON.parse(localStorage.getItem("@LuyaoMember"));
    batch(() => {
      if (memberValue) {
        dispatch(
          writeMemberAllValue({
            memberTierLimit: memberValue["memberTierLimit"],
          })
        );
      }
    });
  }, [dispatch]);
  return children;
}
export default memo(FetchData);
