import React, { useState } from "react";

import { useAlert } from "../component/Alert";
import Grid from "../component/Grid";
import TabView from "../component/TabView";
import ArticleTable from "./component/ArticleTable";
import ArticleForm from "./component/ArticleForm";

export default function ArticlesListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["最新消息列表", "新增"],
    select: 0,
    dataObject: null,
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  return (
    <Grid>
      <TabView
        paper
        tabs={newData.tabViewData}
        index={newData.select}
        onChange={(e) => {
          if (newData.select === 2 && e !== 2) {
            Alert.alert("通知", "確定要離開編輯？", [
              {
                text: "確定",
                onPress: () => {
                  changeData("dataObject", null);
                  newData.tabViewData.splice(2, 1);
                  changeData("tabViewData", newData.tabViewData);
                  changeData("select", e, true);
                },
                type: "ok",
              },
              {
                text: "取消",
                type: "cancel",
              },
            ]);
          } else {
            changeData("select", e, true);
          }
        }}
      >
        <ArticleTable
          onEditSelect={(value) => {
            changeData("dataObject", value);
            newData.tabViewData.push("編輯");
            changeData("tabViewData", newData.tabViewData);
            changeData("select", 2, true);
          }}
        />
        <ArticleForm
          onChangeSelect={() => {
            changeData("dataObject", null);
            newData.tabViewData.splice(2, 1);
            changeData("tabViewData", newData.tabViewData);
            changeData("select", 0, true);
          }}
        />
        <ArticleForm
          id={newData.dataObject && newData.dataObject.id}
          onChangeSelect={() => {
            changeData("dataObject", null);
            newData.tabViewData.splice(2, 1);
            changeData("tabViewData", newData.tabViewData);
            changeData("select", 0, true);
          }}
        />
      </TabView>
    </Grid>
  );
}
