import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  useTheme,
  CardContent,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  Link,
  Button,
} from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";

import TitleBox from "../component/TitleBox";

export default function PaymentFlowPage() {
  const location = useLocation();
  const [openPaymentFlow, setOpenPaymentFlow] = useState(true);
  const theme = useTheme();
  const useStyles = makeStyles({
    totalBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey["50"],
      position: "sticky",
      top: 64 + theme.spacing(1),
    },
    divider: {
      backgroundColor: "white",
    },
    payButton: {
      color: "black",
      backgroundColor: "white",
      padding: theme.spacing(1),
      width: "100%",
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
  });
  const classes = useStyles();
  function testData() {
    let newArray = [];
    for (let i = 0; i < 10; i++) {
      newArray.push({ id: i });
    }
    return newArray;
  }
  function _finishPayment() {
    setOpenPaymentFlow(false);
  }
  if (location.state && location.state.paymentData) {
    return (
      <Container>
        <Box padding={`${theme.spacing(2)}px 0`}>
          {openPaymentFlow ? (
            <Box>
              <Typography>
                編號：{location.state.paymentData.serialNumber}
              </Typography>
              <Typography>
                總價：{location.state.paymentData.totalPrice}
              </Typography>
              <Button onClick={_finishPayment}>金流畫面</Button>
            </Box>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <TitleBox title="訂單內容">
                  {testData().map((item, index) => (
                    <CartItem data={item} index={index} />
                  ))}
                </TitleBox>
              </Grid>
              <Grid item xs={12} md={4} style={{ position: "relative" }}>
                <Box className={classes.totalBox}>
                  <CardContent>
                    <Box padding={1}>
                      <Grid
                        container
                        justify="space-between"
                        className={classes.label}
                      >
                        <Grid item>
                          <Typography>付款金額</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>NT.123456789</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="space-between"
                        className={classes.label}
                      >
                        <Grid item>
                          <Typography>運費</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>NT.123456789</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box padding={1}>
                      <Grid
                        container
                        justify="space-between"
                        className={classes.label}
                      >
                        <Grid item>
                          <Typography>合計</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>NT.123456789</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    );
  } else {
    return <Redirect to="/" />;
  }
}
function CartItem({ data, index }) {
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    itemBox: {
      minHeight: 128,
      backgroundColor: index % 2 === 1 && theme.palette.grey["200"],
      padding: theme.spacing(1),
    },
    image: {
      height: 128 / (isTable ? 1.5 : 1) - theme.spacing(2),
      width: 128 / (isTable ? 1.5 : 1) - theme.spacing(2),
      objectFit: "cover",
    },
    button: {
      minWidth: 32,
      minHeight: 32,
      padding: theme.spacing(0.5),
    },
    select: {
      padding: `6.5px 14px`,
    },
  });
  const classes = useStyles();
  function ItemForm() {
    return (
      <Grid container item spacing={1}>
        <Grid container item>
          <Typography>商品型號</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
          style={{ minHeight: 40 }}
        >
          <Typography>NTD $2000</Typography>
        </Grid>
        <Grid container item xs={12} sm={6} alignItems="center">
          <Typography>數量：1</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box className={classes.itemBox}>
      <Grid container spacing={1}>
        <Grid item>
          <img
            src="https://material-ui.com/static/images/cards/paella.jpg"
            className={classes.image}
            alt=""
          />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            <Link href={`/product/${data.id}`}>
              標題標題標題標題標題標題標題標題標題標題標題標題標題
            </Link>
          </Typography>
          {!isTable && <ItemForm />}
        </Grid>
        {isTable && <ItemForm />}
      </Grid>
    </Box>
  );
}
