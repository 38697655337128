import React, { useRef, useState } from "react";
import moment from "moment";
import { Grid, Box, CircularProgress, Typography } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Pagination } from "@material-ui/lab";

import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import { Select } from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import SaveBlocklistFloaingLayer from "./SaveBlocklistFloaingLayer";
import { getMemberTier } from "../../component/utils";

import { eventTypeEnum } from "../../utils/localData";

const GET_EVENTS = gql`
  query events(
    $eventTypes: [EventType]
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    events(
      eventTypes: $eventTypes
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      eventCount
      pageCount
      events {
        id
        type
        name
        product {
          id
          name
        }
        startTime
        endTime
        activated
        memberTierLimit
        createdAt
        ... on DrawingLotsEvent {
          quota
          showQuota
          reservations {
            id
          }
          results {
            id
          }
          participants {
            id
          }
          # "參加人數上限"
          maxNumberOfPeople
        }
        ... on AuctionEvent {
          bidStartAt
        }
        ... on RaidEvent {
          showQuota
        }
      }
    }
  }
`;

const HIDE_EVENT = gql`
  mutation hideEvent($id: Int!) {
    hideEvent(id: $id) {
      success
      message
    }
  }
`;

export default function EventTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [latest, setLatest] = useState(true);
  const [eventId, setEventId] = useState(undefined);
  const blocklistRef = useRef();

  const { data, refetch, loading } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      eventTypes: [
        "DRAWING_LOTS_EVENT",
        "FLASH_SALE_EVENT",
        "AUCTION_EVENT",
        "RAID_EVENT",
        "PVP_RAID_EVENT",
      ],
      latest,
      pageSize,
      page,
    },
    onError(error) {
      Alert.notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  function _delete(itemId) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        onPress: () => hideEvent({ variables: { id: Number(itemId) } }),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }

  const [hideEvent, { loading: hideEventLoading }] = useMutation(HIDE_EVENT, {
    onCompleted({ hideEvent }) {
      if (hideEvent.success) {
        refetch();
        return Alert.notice("刪除成功！");
      } else if (hideEvent.message) {
        return Alert.notice(hideEvent.message);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={hideEventLoading} />
        <CardContent>
          <Typography>活動數量：{data && data.events.eventCount}</Typography>
        </CardContent>
        <SaveBlocklistFloaingLayer
          ref={blocklistRef}
          _onClose={() => {
            setEventId(undefined);
          }}
          eventId={eventId}
        />
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Select
                label={"排序"}
                items={[
                  { label: "從新到舊", value: true },
                  { label: "從舊到新", value: false },
                ]}
                value={latest}
                onChange={(value) => setLatest(value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"每頁顯示"}
                items={[
                  { label: "10", value: 10 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                value={pageSize}
                onChange={(value) => setPageSize(value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Table
            data={data ? data.events.events : []}
            alignHeaders={Array.from({ length: 12 }).map((_) => "center")}
            header={[
              "類型",
              "名稱",
              "商品",
              "參與人數",
              "前台顯示抽選名額",
              "會員級別參加限制",
              "開始時間",
              "結束時間",
              "建立時間",
              "最後更新時間",
              "狀態",
              "操作",
            ]}
            tableWidth={4}
            columns={[
              (item) =>
                eventTypeEnum[item.type] +
                (Boolean(item.maxNumberOfPeople) ? " (限額抽選)" : ""),
              "name",
              (item) => item.product?.name,
              (item) => item.participants && item.participants.length,
              (item) => item.showQuota ?? "",
              (item) => getMemberTier(item.memberTierLimit),
              (item) => moment(item.startTime).format("YYYY/MM/DD HH:mm:ss"),
              (item) => moment(item.endTime).format("YYYY/MM/DD HH:mm:ss"),
              (item) => moment(item.createdAt).format("YYYY/MM/DD"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
              (item) => (item.activated ? "已上架" : "已下架"),
              (item) => (
                // <Grid container spacing={1}>
                //   {(item.type === "DRAWING_LOTS_EVENT" ||
                //     item.type === "RAID_EVENT" ||
                //     item.type === "PVP_RAID_EVENT") && (
                //     <Grid item>
                //       <Button
                //         variant="contained"
                //         color="primary"
                //         onPress={(event) => {
                //           event.stopPropagation();
                //           setEventId(item.id);
                //           blocklistRef.current?.open()
                //         }}
                //       >
                //         新增第二頁
                //       </Button>
                //     </Grid>
                //   )}
                //   <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={(event) => {
                    event.stopPropagation();
                    _delete(item.id);
                  }}
                >
                  刪除
                </Button>
                //   </Grid>
                // </Grid>
              ),
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        </CardContent>
        <CardActions>
          <Grid container justify="center">
            <Pagination
              count={
                Boolean(data)
                  ? data.events.pageCount
                    ? data.events.pageCount
                    : 1
                  : 1
              }
              size="large"
              color="primary"
              page={page}
              onChange={(e, n) => setPage(n)}
              showFirstButton
              showLastButton
            />
          </Grid>
        </CardActions>
      </Card>
    );
  }
}
