import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  makeStyles,
  ButtonBase,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";

import AspectRatioBox from "../component/AspectRatioBox";
import SwipeableViewsComponent from "../component/SwipeableViewsComponent";

const GET_CAROUSEL = gql`
  query carousel($section: Section!) {
    carousel(section: $section) {
      id
      contents {
        id
        image {
          filename
          mimetype
          encoding
          location
        }
        link
      }
    }
  }
`;

// ANCHOR 主要組件
export default function HomePage() {
  return (
    <Box>
      <Helmet>
        <title>路遙圓創LUYAODESIGN｜台灣設計師玩具台灣設計師玩具</title>
      </Helmet>
      <Banner />
      <GeneralEntry />
      <ProductEntry />
      <img
        src={require("../images/0124ABOUT-02.jpg").default}
        style={{
          width: "100%",
          display: "block",
        }}
        alt=""
      />
    </Box>
  );
}
// ANCHOR Banner
function Banner() {
  const { data: carouselData, loading } = useQuery(GET_CAROUSEL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      section: "HOME_PAGE",
    },
    onError() {
      return null;
    },
  });

  if (loading) {
    return (
      <Box
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          paddingTop: "45%",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            transform="none"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Fragment>
        {carouselData?.carousel?.contents &&
          carouselData.carousel.contents[0] && (
            <SwipeableViewsComponent images={carouselData.carousel.contents} />
          )}
      </Fragment>
    );
  }
}
// ANCHOR 最新消息、新品上市
function GeneralEntry() {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
    },
    tile: {
      position: "relative",
    },
  }));
  const classes = useStyles();
  const itemList = [
    {
      title: "News",
      subTitle: "最新消息",
      image: require("../images/0124ABOUT-09.jpg").default,
      onClick: () => history.push(`/article`),
    },
    {
      title: "New Products",
      subTitle: "新品上市",
      image: require("../images/0124ABOUT-10.jpg").default,
      onClick: () => (window.location = "https://luyaotoys.com/product"),
    },
  ];
  return (
    <Box className={classes.container}>
      <Grid container>
        {itemList.map((item) => (
          <Grid
            key={item.title}
            item
            xs={12}
            md={6}
            className={classes.tile}
            onClick={item.onClick}
          >
            <GeneralEntryItem data={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
// ANCHOR 一般入口物件
function GeneralEntryItem({ data }) {
  const { title, subTitle, image } = data;
  const useStyles = makeStyles((theme) => ({
    button: {
      "&:hover > div > div": {
        backgroundColor: "white",
        color: "black",
      },
    },
    image: {
      width: "100%",
      display: "block",
    },
    titleArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      top: 0,
      padding: theme.spacing(2),
    },
    title: {
      color: "white",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
    subTitleArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `1px white solid`,
      color: "white",
    },
    subTitle: {
      fontWeight: "bold",
      lineHeight: 1,
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
  }));
  const classes = useStyles();
  return (
    <ButtonBase className={classes.button}>
      <img src={image} className={classes.image} alt="" />
      <Box className={classes.titleArea}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.subTitleArea}>
          <Typography variant="h5" classes={{ root: classes.subTitle }}>
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
}
// ANCHOR 商品入口
function ProductEntry() {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    },
    gridContainer: {
      margin: `${theme.spacing(8)}px 0`,
    },
  }));
  const classes = useStyles();
  const enterList = [
    {
      title: "Exclusive Products",
      subTitle: "限　定　商　品",
      image: require("../images/0124ABOUT-06.jpg").default,
      route: "product/exclusive",
    },
    {
      title: "Preorder Products",
      subTitle: "預　購　商　品",
      image: require("../images/0124ABOUT-07.jpg").default,
      route: "product/preorder",
    },
    {
      title: "Event Products",
      subTitle: "活　動　商　品",
      image: require("../images/0124ABOUT-08.jpg").default,
      route: "event",
    },
  ];
  return (
    <Box className={classes.container}>
      <Divider />
      <Box className={classes.gridContainer}>
        <Grid container spacing={2}>
          {enterList.map((item) => (
            <Grid key={item.title} item xs={12} md={4}>
              <ProductEntryItem data={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
    </Box>
  );
}
// ANCHOR 商品入口物件
function ProductEntryItem({ data }) {
  const history = useHistory();
  const { title, subTitle, image, route } = data;
  const useStyles = makeStyles((theme) => ({
    image: {
      width: "100%",
      height: "100%",
      display: "block",
    },
    button: {
      "&:hover > div > div": {
        color: "white",
        backgroundColor: "black",
      },
      width: "100%",
      display: "block",
    },
    titleArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    subTitleArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      width: "100%",
      border: `1px black solid`,
      color: "black",
      backgroundColor: "white",
    },
    subTitle: {
      fontWeight: "bold",
      lineHeight: 1,
      margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
  }));
  const classes = useStyles();
  return (
    <ButtonBase onClick={() => history.push(route)} className={classes.button}>
      <Box className={classes.titleArea}>
        <Typography variant="h5">{title}</Typography>
        <Box className={classes.subTitleArea}>
          <Typography variant="h6" className={classes.subTitle}>
            {subTitle}
          </Typography>
        </Box>
      </Box>
      <AspectRatioBox width="100%">
        <img src={image} className={classes.image} alt="" />
      </AspectRatioBox>
    </ButtonBase>
  );
}
