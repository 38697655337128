import React, { memo, useMemo } from "react";
import Image from "../../../../component/Image";
// mui
import { Box, useMediaQuery } from "@material-ui/core";
import AdaptiveImage from "../../../../component/AdaptiveImage";

export function useGetGap() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const gap = useMemo(() => {
    if (isDownMobile) {
      return "0px";
    } else if (isMobile) {
      return "10px";
    } else if (isTable) {
      return "25px";
    } else if (isDownPC) {
      return "50px";
    } else if (isPC) {
      return "100px";
    }
    return "80px";
  }, [isPC, isDownPC, isTable, isMobile, isDownMobile]);
  return gap;
}

export function useGetIslandMarginTop() {
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const marginTop = useMemo(() => {
    if (isDownMobile) {
      return -25;
    }
    return -50;
  }, [isDownMobile]);
  return marginTop;
}

export function useGetMarginTop() {
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const marginTop = useMemo(() => {
    if (isDownMobile) {
      return -25;
    }
    return -150;
  }, [isDownMobile]);
  return marginTop;
}

export default memo(function PageIsland() {
  const marginTop = useGetIslandMarginTop();
  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <AdaptiveImage ratio="23%">
        <Image
          src={"/img/special/radish-island/島上文字.png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"contain"}
        />
      </AdaptiveImage>
      <Box
        style={{
          marginTop,
        }}
      >
        <AdaptiveImage ratio="105%">
          <Box
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={"/img/special/radish-island/島.png"}
              style={{ width: "75%", height: "75%" }}
              resizeMode={"cover"}
            />
          </Box>
        </AdaptiveImage>
      </Box>
    </Box>
  );
});
