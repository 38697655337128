import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { deepEqual } from "fast-equals";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import LoadingModal from "../../../component/LoadingModal";
import CheckboxesTags from "../../../component/MultipleAutoComplate/CheckboxesTags";

// SECTION apollo
// NOTE 商品列表
/** - 商品列表 */
const GET_PRODUCTS_ALL = gql`
  query products {
    products {
      products {
        value: id
        # "名稱"
        label: name
      }
    }
  }
`;
// NOTE 活動認證名單
/** - 活動認證名單 */
const EVENT_CERTIFICATION_LIST = gql`
  query eventCertificationList($eventId: Int!, $productIds: [Int]) {
    eventCertificationList(eventId: $eventId, productIds: $productIds) {
      # "白名單"
      whitelist {
        id
        fullName
      }
    }
  }
`;
// NOTE 儲存抽選白名單
/** - 儲存抽選白名單 */
const SAVE_WHITELIST = gql`
  mutation saveWhitelist($eventId: Int!, $memberIds: [Int!]!) {
    saveWhitelist(eventId: $eventId, memberIds: $memberIds) {
      success
      message
    }
  }
`;
// !SECTION

/** - 匯入指定商品購買過的會員 */
function MembersOfBoughtProducts(
  { eventId, existingWhitelist = [], onClose },
  ref
) {
  const { notice } = useAlert();
  const [open, setOpen] = useState(false);
  const [viewMembers, setViewMembers] = useState([]);
  const handleRef = useRef({
    onClose,
  });
  const form = useForm({
    defaultValues: { productIds: [] },
  });
  useEffect(() => {
    handleRef.current.onClose = onClose;
  }, [onClose]);
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    }),
    []
  );
  const { handleSubmit, reset, setValue } = form;
  const { data: productsAllData } = useQuery(GET_PRODUCTS_ALL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const products = useMemo(
    () => productsAllData?.products.products ?? [],
    [productsAllData]
  );
  const _onClose = useCallback(
    (param) => {
      setOpen(false);
      handleRef.current.onClose?.(param);
      setTimeout(() => {
        reset();
        setViewMembers([]);
      }, 300);
    },
    [reset]
  );
  const [
    getEventCertificationListFn,
    { loading: eventCertificationListLoading },
  ] = useLazyQuery(EVENT_CERTIFICATION_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      eventId,
    },
    onCompleted({ eventCertificationList }) {
      if (eventCertificationList) {
        setTimeout(() => {
          const idsInArray2 = new Set(existingWhitelist.map((item) => item.id));
          const resultArray = eventCertificationList.whitelist.filter(
            (item) => !idsInArray2.has(item.id)
          );
          setViewMembers(resultArray);
        }, 0);
      }
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const _getEventCertificationList = useCallback(
    ({ productIds }) => {
      const currentProductIds = productIds.map((item) => item.value);
      getEventCertificationListFn({
        variables: { eventId, productIds: currentProductIds },
      });
    },
    [eventId, getEventCertificationListFn]
  );
  const [saveWhitelistFn, { loading: saveWhitelistLoading }] = useMutation(
    SAVE_WHITELIST,
    {
      onCompleted({ saveWhitelist: { success, message } }) {
        if (success) {
          notice("匯入成功");
          setTimeout(() => {
            _onClose("refetch");
          }, 0);
        } else if (message) {
          notice(message);
        }
      },
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const _saveWhitelist = useCallback(() => {
    saveWhitelistFn({
      variables: { eventId, memberIds: viewMembers.map((item) => item.id) },
    });
  }, [eventId, saveWhitelistFn, viewMembers]);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingModal loading={saveWhitelistLoading} />
      <DialogTitle>匯入購買特定商品的會員</DialogTitle>
      <FormProvider {...form}>
        <DialogContent
          style={{
            minHeight: "56px",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CheckboxesTags
                name="productIds"
                noOptionsText="找不到商品"
                textFieldLabel="購買特定商品的會員"
                textFieldPlaceholder="選擇購買特定商品的會員"
                options={products}
                onChange={(_, value) => {
                  setValue("productIds", value);
                }}
              />
            </Grid>
            <Grid item xs={12} spacing={1}>
              <Grid container item direction="row" spacing={1}>
                {viewMembers.map((item, index) => (
                  <Grid key={"viewMember" + index} item>
                    <Chip label={item.fullName} color="primary" disabled />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_getEventCertificationList)}
                disabled={eventCertificationListLoading || saveWhitelistLoading}
              >
                {eventCertificationListLoading && (
                  <CircularProgress color="inherit" size={20} />
                )}
                預覽名單
              </Button>
            </Grid>
            {viewMembers.length > 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(_saveWhitelist)}
                  disabled={
                    eventCertificationListLoading || saveWhitelistLoading
                  }
                >
                  確定匯入
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="outlined" color="primary" onClick={_onClose}>
                取消
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
export default memo(forwardRef(MembersOfBoughtProducts), deepEqual);
