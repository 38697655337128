import React, { useState } from "react";
import { Grid, TextField, Button, Select, MenuItem } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { useAlert } from "./Alert";
import LoadingModal from "./LoadingModal";
import countryCodes from "../countryCodes.json";

const SEND_VERIFICATIONCODE = gql`
  mutation sendVerificationCode(
    $countryCallingCode: String!
    $mobile: String!
    $needCheck: Boolean
  ) {
    sendVerificationCode(
      countryCallingCode: $countryCallingCode
      mobile: $mobile
      needCheck: $needCheck
    )
  }
`;

export default function PhoneVerify({
  forget,
  mobile,
  setMobile = () => {},
  countryCallingCode,
  setCountryCallingCode = () => {},
  verificationCode,
  setVerificationCode = () => {},
}) {
  const Alert = useAlert();
  const [disabledMobile, setDisabledMobile] = useState(false);
  function getVerificationCode() {
    setDisabledMobile(true);
    _sendVerificationCode();
  }
  const [_sendVerificationCode, { loading: sendVerificationCodeLoading }] =
    useMutation(SEND_VERIFICATIONCODE, {
      variables: {
        countryCallingCode,
        mobile,
        needCheck: forget ? true : false,
      },
      onCompleted({ sendVerificationCode }) {
        if (sendVerificationCode) {
          Alert.alert("通知", "已發送驗證碼。", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("通知", "發送驗證碼失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.alert("通知", `${error.message.replace("GraphQL error: ", "")}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    });
  return (
    <Grid item container spacing={1}>
      <LoadingModal loading={sendVerificationCodeLoading} />
      <Grid item xs={12} sm={3}>
        <Select
          value={countryCallingCode}
          onChange={(e) => setCountryCallingCode(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value={""} disabled>
            選擇國碼
          </MenuItem>
          {countryCodes.map((item) => (
            <MenuItem key={item.countryCode} value={item.phoneCode}>
              {`${item.countryName}\u2002(${item.phoneCode})`}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="手機／同登入帳號"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          fullWidth
          disabled={disabledMobile}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          style={{ height: "100%" }}
          variant="contained"
          color="primary"
          onClick={getVerificationCode}
          fullWidth
          disabled={
            mobile.length < 8 || !Boolean(countryCallingCode) || disabledMobile
          }
        >
          取得驗證碼
        </Button>
      </Grid>
      <Grid item container>
        <TextField
          label="手機驗證碼"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
