// mui
import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import AdaptiveImage from "../../../component/AdaptiveImage";
import Image from "../../../component/Image";
import CopyrightRender from "./component/CopyrightRender";
import PageIsland from "./component/PageIsland";
import SwitchNavTabs from "./component/SwitchNavTabs";

// ANCHOR 主要組件
export default function IllustratePage() {
  return (
    <BackgroundContainer>
      <PageIsland />
      <IllustrateContent />
    </BackgroundContainer>
  );
}

// ANCHOR 內容
function IllustrateContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Grid container spacing={3} style={{ marginTop: "40px" }}>
        <Grid container item xs={12} justifyContent="center">
          <Image
            src={"/img/special/parallel-universe/育成系統台灣數量.png"}
            style={{ width: isMobile ? "75%" : "50%", height: "100%" }}
            resizeMode={"contain"}
          />
        </Grid>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/parallel-universe/圖鑑.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
      </Grid>
      <CopyrightRender />

      <SwitchNavTabs />
    </Box>
  );
}
