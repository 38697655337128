import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
// material-ui
import {
  Box,
  Container,
  Grid,
  useTheme,
  CardContent,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  ButtonBase,
  ButtonGroup,
  Link,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// apollo
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-router-dom
import { useHistory } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";
// react-hook-form
import { useForm, FormProvider } from "react-hook-form";

// component
import TitleBox from "../component/TitleBox";
import { useAlert } from "../component/Alert";
import { useLocalStorage } from "../component/LocalStorageContext";
import LoadingFloating from "../component/LoadingFloating";
import CreateOrderFloatingLayer from "../component/CreateOrderFloatingLayer";
// utils
import emptyArray from "../utils/emptyArray";
import { paymentMethodEnum } from "../utils/localData";
// zustand
import useTokenStore from "../zustand/useTokenStore";

const Get_Cart = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          id
          name
          images {
            filename
            mimetype
            encoding
            location
          }
          stock
          deliveryFee
          maxAmountPerOrder
          # "付款方式"
          paymentMethods
        }
        unitPrice
        amount
      }
      totalPrice
    }
  }
`;

const Create_Order = gql`
  mutation createOrder($cartId: Int!, $shipmentInput: ShipmentInput) {
    createOrder(cartId: $cartId, shipmentInput: $shipmentInput) {
      id
      items {
        id
        product {
          id
          name
          images {
            filename
            mimetype
            encoding
            location
          }
        }
        unitPrice
        amount
      }
      totalPrice
      paymentUrl
    }
  }
`;

const Create_Cart = gql`
  mutation createCart {
    createCart {
      id
    }
  }
`;

const Remove_ItemFromCart = gql`
  mutation removeItemFromCart($id: Int!, $cartItemId: Int!) {
    removeItemFromCart(id: $id, cartItemId: $cartItemId) {
      success
    }
  }
`;

const Update_CartItemAmount = gql`
  mutation updateCartItemAmount($cartItemId: Int!, $amount: Int!) {
    updateCartItemAmount(cartItemId: $cartItemId, amount: $amount) {
      success
    }
  }
`;

export default function CartPage() {
  const Alert = useAlert();
  const history = useHistory();
  const theme = useTheme();
  const LocalStorage = useLocalStorage();
  const useStyles = makeStyles({
    totalBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey["50"],
      position: "sticky",
      top: 64 + theme.spacing(1),
    },
    divider: {
      backgroundColor: "white",
    },
    payButton: {
      color: "black",
      backgroundColor: "white",
      padding: theme.spacing(1),
      width: "100%",
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
  });
  const classes = useStyles();
  const form = useForm({
    defaultValues: {},
  });
  const [open, setOpen] = useState(false);
  const contentRef = useRef("");
  const paymentMethodRef = useRef("");
  const [goldFlowButtonDisable, setGoldFlowButtonDisable] = useState(false);
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  function _buy(items) {
    if (memberToken) {
      contentRef.current = `您確定要訂購${items
        .map((item) => `【${item.product.name}】× ${item.amount}`)
        .join("、")}？即將進入付款流程。`;
      return setOpen(true);
    } else {
      return Alert.alert("通知", "尚未登入，請先登入才可付款！", [
        {
          text: "登入",
          onPress: () => history.push("/login"),
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    }
  }
  function thousandsSeparator(number) {
    return number.toLocaleString("en-US");
  }
  const [getCart, { data, loading: cartLoading, refetch }] = useLazyQuery(
    Get_Cart,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        id: Number(LocalStorage.getCartId()),
      },
      onCompleted(cart) {
        setTimeout(() => {
          if (cart) {
            const luyaoCartSelectStoreRandomCode = localStorage.getItem(
              "@luyaoCartSelectStoreRandomCode"
            );
            if (luyaoCartSelectStoreRandomCode) {
              setOpen(true);
            }
          } else {
            localStorage.removeItem("@luyaoCartId");
          }
        }, 0);
      },
      onError(error) {
        localStorage.removeItem("@luyaoCartId");
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  useEffect(() => {
    getCart();
  }, [getCart]);
  const [createCart] = useMutation(Create_Cart, {
    onCompleted({ createCart }) {
      LocalStorage.setCartId(createCart.id);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const [createOrder, { loading: createOrderLoading }] = useMutation(
    Create_Order,
    {
      onCompleted({ createOrder }) {
        if (createOrder) {
          if (
            process.env.NODE_ENV === "production" &&
            window.location.host.search(".s3-") === -1
          ) {
            window.fbq("track", "InitiateCheckout");
          }
          createCart();
          window.location =
            createOrder.paymentUrl +
            "&paymentMethod=" +
            paymentMethodRef.current;
        } else {
          setGoldFlowButtonDisable(false);
          return Alert.alert("", "請先登入後再操作。", [
            {
              text: "回首頁",
              onPress: () => history.replace("/"),
              type: "ok",
            },
            {
              text: "登入",
              onPress: () => history.replace("/login"),
              type: "cancel",
            },
          ]);
        }
      },
      onError(error) {
        setGoldFlowButtonDisable(false);
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  // NOTE 付款方式
  const paymentMethods = useMemo(() => {
    const allPaymentMethods = [];
    emptyArray(data?.cart.items).forEach((item) => {
      emptyArray(item.product?.paymentMethods).forEach((item2) => {
        allPaymentMethods.push({
          label: paymentMethodEnum[item2],
          value: item2 === "CREDIT_CARD" ? "Credit" : item2,
        });
      });
    });
    const uniqueArray = allPaymentMethods.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.value === obj.value)
    );
    return uniqueArray;
  }, [data]);

  let highestDeliveryFee = 0;
  if (data) {
    for (let item of data.cart.items) {
      highestDeliveryFee =
        highestDeliveryFee > item.product.deliveryFee
          ? highestDeliveryFee
          : item.product.deliveryFee;
    }
  }

  return (
    <Container>
      <FormProvider {...form}>
        <CreateOrderFloatingLayer
          open={open}
          content={contentRef.current}
          type="Cart"
          onChangeFloatingwindowClose={(
            value,
            paymentMethod,
            shipmentInput
          ) => {
            contentRef.current = "";
            setOpen(false);
            if (value === "mutation") {
              paymentMethodRef.current = paymentMethod;
              setGoldFlowButtonDisable(true);
              createOrder({
                variables: {
                  cartId: Number(LocalStorage.getCartId()),
                  shipmentInput,
                },
              });
            }
          }}
          paymentMethods={paymentMethods}
        />
        <Helmet>
          <title>購物車｜路遙圓創</title>
        </Helmet>
        <LoadingFloating loading={createOrderLoading} />
        {cartLoading ? (
          <Box
            display="flex"
            height={`calc(100vh - 64px)`}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box padding={`${theme.spacing(2)}px 0`}>
            {Boolean(data) && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <TitleBox title="購物車">
                      {data.cart.items &&
                        data.cart.items.map((item, index) => (
                          <CartItem
                            data={item}
                            index={index}
                            getCart={getCart}
                            refetch={refetch}
                          />
                        ))}
                    </TitleBox>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ position: "relative" }}>
                    <Box className={classes.totalBox}>
                      <CardContent>
                        <Box padding={1}>
                          <Grid
                            container
                            justify="space-between"
                            className={classes.label}
                          >
                            <Grid item>
                              <Typography>付款金額</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                NT ${thousandsSeparator(data.cart.totalPrice)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justify="space-between"
                            className={classes.label}
                          >
                            <Grid item>
                              <Typography>運費</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>{highestDeliveryFee}</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider className={classes.divider} />
                        <Box padding={1}>
                          <Grid
                            container
                            justify="space-between"
                            className={classes.label}
                          >
                            <Grid item>
                              <Typography>合計</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>
                                NT $
                                {thousandsSeparator(
                                  data.cart.totalPrice + highestDeliveryFee
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        {Boolean(data.cart.items[0]) && (
                          <ButtonBase
                            className={classes.payButton}
                            onClick={() => _buy(data.cart.items)}
                            disabled={goldFlowButtonDisable}
                          >
                            付款
                          </ButtonBase>
                        )}
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        )}
      </FormProvider>
    </Container>
  );
}
function CartItem({ data, index, getCart = () => {}, refetch }) {
  const Alert = useAlert();
  const theme = useTheme();
  const LocalStorage = useLocalStorage();
  const [count, setCount] = useState(data.amount);
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    itemBox: {
      minHeight: 128,
      backgroundColor: index % 2 === 1 && theme.palette.grey["200"],
      padding: theme.spacing(1),
    },
    image: {
      height: 128 / (isTable ? 1.5 : 1) - theme.spacing(2),
      width: 128 / (isTable ? 1.5 : 1) - theme.spacing(2),
      objectFit: "cover",
    },
    button: {
      minWidth: 32,
      minHeight: 32,
      padding: theme.spacing(0.5),
    },
    select: {
      padding: `6.5px 14px`,
    },
  });
  const classes = useStyles();
  function _delete(value) {
    Alert.alert("", "確定刪除此購物車商品？", [
      {
        text: "確認",
        onPress: () =>
          removeItemFromCart({
            variables: {
              id: Number(LocalStorage.getCartId()),
              cartItemId: value.id,
            },
          }),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }
  const [removeItemFromCart, { loading: removeItemFromCartLoading }] =
    useMutation(Remove_ItemFromCart, {
      onCompleted({ removeItemFromCart }) {
        if (removeItemFromCart.success) {
          return Alert.notice("刪除商品成功！", getCart);
        } else {
          return Alert.notice("刪除商品失敗！");
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });
  const [updateCartItemAmount, { loading: updateCartItemAmountLoading }] =
    useMutation(Update_CartItemAmount, {
      onCompleted({ updateCartItemAmount }) {
        if (updateCartItemAmount.success) {
          refetch();
        } else {
          return Alert.notice("購物車商品數量更改失敗！");
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });
  function ItemForm(value) {
    return (
      <Grid container item spacing={1}>
        <Grid
          container
          item
          xs={12}
          sm={6}
          alignItems="center"
          style={{ minHeight: 40 }}
        >
          <Typography>NTD ${data.unitPrice * count}</Typography>
        </Grid>
        <Grid container item xs={12} sm={6} alignItems="center">
          <Button
            onClick={() => _delete(value)}
            variant="contained"
            color="primary"
            style={{ minWidth: 0, marginRight: theme.spacing(2) }}
            className={classes.button}
          >
            <DeleteIcon />
          </Button>
          <ButtonGroup style={{ display: "flex", flex: 1 }}>
            <Button
              onClick={() =>
                setCount((e) => {
                  if (e > 1) {
                    updateCartItemAmount({
                      variables: { cartItemId: value.id, amount: e - 1 },
                    });
                    return e - 1;
                  } else {
                    return e;
                  }
                })
              }
              className={classes.button}
              disabled
            >
              <RemoveIcon />
            </Button>
            <Button style={{ flex: 1 }} className={classes.button} disabled>
              {count}
            </Button>
            <Button
              onClick={() =>
                setCount((e) => {
                  if (
                    e < data.product.stock &&
                    e < (data.product.maxAmountPerOrder || Number.MAX_VALUE)
                  ) {
                    updateCartItemAmount({
                      variables: { cartItemId: value.id, amount: e + 1 },
                    });
                    return e + 1;
                  } else {
                    return e;
                  }
                })
              }
              className={classes.button}
              disabled
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box className={classes.itemBox}>
      <LoadingFloating
        loading={removeItemFromCartLoading || updateCartItemAmountLoading}
      />
      <Grid container spacing={1}>
        <Grid item>
          <img
            src={data.product.images[0].location}
            className={classes.image}
            alt=""
          />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            <Link href={`/product/${data.product.id}`}>
              {data.product.name}
            </Link>
          </Typography>
          {!isTable && <ItemForm id={data.id} />}
        </Grid>
        {isTable && <ItemForm id={data.id} />}
      </Grid>
    </Box>
  );
}
