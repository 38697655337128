/**
 * @template T
 * @param {T} params 要處裡的資料
 * @returns {T} 非陣列資料則回傳空陣列
 */
export default function emptyArray(params) {
  if (Array.isArray(params)) {
    return params;
  } else {
    return [];
  }
}
