import {
  memo,
  useEffect,
  useRef,
  useState,
  Fragment,
  createElement,
  useMemo,
} from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { batch, useDispatch, useSelector } from "react-redux";
import "./index.css";

import {
  setLoadInitialValue,
  setRemainingTime,
  restRaidSetting,
  setStatus,
  setNumberOfPlayer,
  setEndTime,
  setStartTime,
  setCurrentHealthPoint,
  setAffiliationTeam,
  setScore,
  setJoined,
  setShowBoard,
  setWinningTeam,
} from "../../redux/raid";
import { useAlert } from "../../component/Alert";

//團體戰Query
const GET_EVENT_RAID = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      ... on RaidEvent {
        monster
        maxHealthPoint
        damagePerAttack
        quota
        joined
        players {
          id
        }
        startTime
        endTime
        status
        winningTeam
      }
    }
  }
`;

// 團體戰隸屬隊伍
const GET_RAID_TEAM = gql`
  query raid($id: Int!) {
    raid(eventId: $id) {
      # "隊伍"
      team
    }
  }
`;

// 團體戰遊戲中內容
const GET_RAID = gql`
  query raid($id: Int!) {
    raid(eventId: $id) {
      numberOfPlayer
      score
      status
      currentHealthPoint
    }
  }
`;

// 發動攻擊
const PLAYER_ATTACK = gql`
  mutation launchAttack($eventId: Int!, $attackPoint: Int!) {
    launchAttack(eventId: $eventId, attackPoint: $attackPoint) {
      success
      message
    }
  }
`;

const useTextStyles = makeStyles({
  serifText: {
    fontFamily: "Noto Serif TC",
  },
});

// ANCHOR 主要組件
export default function GameUI() {
  return (
    <>
      <FetchGameInitialValue />
      <GameLayout>
        <Box
          display="flex"
          flex={1}
          color="white"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <GameContentSwitch />
        </Box>
        <Box style={{ height: 35 }}>
          <img
            src={require("./assets/LUYAODESIGN.png").default}
            style={{
              width: "100%",
              objectFit: "contain",
              height: "100%",
            }}
          />
        </Box>
      </GameLayout>
    </>
  );
}
// ANCHOR 遊戲外框
function GameLayout({ children }) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    outside: {
      display: "flex",
      justifyContent: "center",
    },
    bg: {
      width: "100%",
      display: "block",
      objectFit: "cover",
      height: "100vh",
    },
    inside: {
      position: "relative",
      flex: 1,
      maxWidth: 600,
    },
    children: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      color: "white",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`,
      "& > *": {
        margin: `${theme.spacing(2.5)}px 0px`,
      },
    },
  }));
  const classes = useStyles();
  const affiliationTeam = useSelector((state) => state.raid.affiliationTeam);
  const getBackgroundImage = useMemo(() => {
    if (affiliationTeam === "PIRATE") {
      return require("./assets/pirate_background.jpg").default;
    } else if (affiliationTeam === "ADVENTURER") {
      return require("./assets/adventurer_background.jpg").default;
    }
    return require("./assets/background.jpg").default;
  }, [affiliationTeam]);
  useEffect(() => {
    return () => dispatch(restRaidSetting());
  }, []);
  return (
    <Box className={`${classes.outside} game-area`}>
      <Box className={classes.inside}>
        <Box component="img" src={getBackgroundImage} className={classes.bg} />
        <Box className={classes.children}>{children}</Box>
      </Box>
    </Box>
  );
}
// ANCHOR 獲得遊戲內容
function FetchGameInitialValue() {
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  // NOTE 只抓第一次當預設值
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          dispatch(
            setLoadInitialValue({
              joined: event.joined,
              status: event.status,
              maxHealthPoint: event.maxHealthPoint,
              numberOfPlayer: event.players ? event.players.length : 0,
              damagePerAttack: event.damagePerAttack,
              startTime: event.startTime,
              endTime: event.endTime,
              remainingTime: convertRemaining(event.endTime, event.startTime),
            })
          );
        }, 0);
      }
    },
    onError() {
      dispatch(
        setLoadInitialValue({
          status: undefined,
        })
      );
    },
  });
  const [getRaidTeam] = useLazyQuery(GET_RAID_TEAM, {
    fetchPolicy: "network-only",
    onCompleted({ raid }) {
      if (raid) {
        setTimeout(() => {
          dispatch(setAffiliationTeam(raid.team));
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
      getRaidTeam({ variables: { id } });
    }
  }, [id]);
  useEventEffect();
  useRaidEffect();
  useRemainingTimeEffect();
  return null;
}
// ANCHOR 遊戲內容切換
const GameContentSwitch = memo(() => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.raid.status);
  const loaded = useSelector((state) => state.raid.loaded);
  const joined = useSelector((state) => state.raid.joined);
  const currentHealthPoint = useSelector(
    (state) => state.raid.currentHealthPoint
  );
  const affiliationTeam = useSelector((state) => state.raid.affiliationTeam);
  const winningTeam = useSelector((state) => state.raid.winningTeam);

  const isResult = useMemo(() => {
    if (affiliationTeam && winningTeam) {
      if (affiliationTeam === winningTeam) {
        return "VICTORY";
      } else {
        return "DEFEAT";
      }
    }
  }, [affiliationTeam, winningTeam]);

  if (loaded) {
    if (typeof status === "undefined") {
      return <Typography variant="h5">找不到團體戰！</Typography>;
    }
    if (joined) {
      if (status !== "READY_TO_START" && currentHealthPoint <= 0) {
        if (status === "DURING_THE_GAME") {
          return (
            <Typography variant="h5">
              恭喜成功打敗貓錦獸，請等待對戰結果。
            </Typography>
          );
        }
        if (isResult) {
          return <ResultContent result={isResult} />;
        } else {
          return <Typography variant="h5">結算結果中！</Typography>;
        }
      }
      switch (status) {
        case "READY_TO_START":
          // 開始前
          return <PrepareScenes />;
        case "DURING_THE_GAME":
          // 進行中
          dispatch(setShowBoard(true));
          return <AttackScenes />;
        case "VICTORY":
          // 勝利
          dispatch(setShowBoard(true));
          return <ResultContent result="VICTORY" />;
        case "DEFEAT":
          // 失敗
          dispatch(setShowBoard(true));
          return <ResultContent result="DEFEAT" />;
        default:
          return <CircularProgress color="inherit" />;
      }
    } else {
      return <Typography variant="h5">請先加入團體戰！</Typography>;
    }
  } else {
    return <CircularProgress color="inherit" />;
  }
});
// ANCHOR 結果畫面
function ResultContent({ result = "DEFEAT" }) {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      // padding: theme.spacing(1),
    },
    content: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  const textClasses = useTextStyles();
  const defeatText = [
    "",
    "感謝所有馬力參與這次的團體戰，可惜沒有挑戰成功，下次再戰吧！",
  ];
  const victoryText = [
    "",
    "稍後請至\n「會員中心」>「待付款訂單」\n查看抽選結果",
    "中選者商品將直接匯入，務必於時間內完成繳款，未中選者一律不另行通知。",
  ];
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <div>
          <GetTeamLogo />
        </div>
        <div>
          {(result === "DEFEAT" ? defeatText : victoryText).map((i, index) => (
            <Fragment key={index}>
              {index === 0 ? (
                <>
                  <ShadowBox>
                    <Typography
                      variant="h3"
                      className={textClasses.serifText}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {result}
                    </Typography>
                  </ShadowBox>
                  <Box marginBottom={1}>
                    <Typography
                      align="center"
                      variant="h6"
                      className={textClasses.serifText}
                    >
                      {i}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography
                  align="center"
                  variant="subtitle1"
                  paragraph
                  className={textClasses.serifText}
                >
                  {i}
                </Typography>
              )}
            </Fragment>
          ))}
        </div>
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <TotalPeople />
          {createElement(
            Button,
            {
              variant: "contained",
              color: "default",
              size: "large",
              ...(result === "DEFEAT"
                ? { onClick: () => history.replace("/") }
                : { onClick: () => history.replace("/member") }),
            },
            <Typography
              variant="subtitle1"
              className={textClasses.serifText}
              style={{ fontWeight: "bold" }}
            >
              {result === "DEFEAT" ? "返回首頁" : "返回會員中心"}
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
// ANCHOR 影子盒
function ShadowBox({ children }) {
  const useStyles = makeStyles({
    shadow: {
      position: "absolute",
      bottom: 4,
      right: 0,
      left: 0,
      background: `linear-gradient(to right, transparent, rgba(255, 255, 255), transparent)`,
      filter: `blur(1px)`,
      height: 4,
      borderRadius: "50%",
    },
  });
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.shadow} />
      {children}
    </Box>
  );
}
// ANCHOR 開始前畫面
function PrepareScenes() {
  const dispatch = useDispatch();
  const timingRef = useRef(null);
  const minute = 60;
  const startTime = useSelector((state) => state.raid.startTime);
  const endTime = useSelector((state) => state.raid.endTime);
  const [startTimeSubtract, setStartTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (startTime) {
        setStartTimeSubtract(
          Math.max(
            moment(startTime)
              .tz("Asia/Taipei")
              .diff(moment().tz("Asia/Taipei"), "seconds"),
            0
          )
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [startTime, endTime]);
  if (startTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  } else if (startTimeSubtract <= 10) {
    //NOTE 倒數前10秒
    dispatch(setShowBoard(true));
    return <PrepareText />;
  } else if (startTimeSubtract >= minute * 10) {
    // NOTE 開始前10分鐘
    return <Typography variant="h5">團體戰尚未開始！</Typography>;
  } else {
    return <BeforeRaid />;
  }
}
// ANCHOR 團體賽開始前
function BeforeRaid() {
  const textClasses = useTextStyles();
  return (
    <Box display="flex" padding={1} height="100%">
      <Grid
        container
        spacing={1}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <div>
          <GetTeamLogo />
        </div>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            className={textClasses.serifText}
          >
            對戰即將開打
          </Typography>
        </Grid>
        <TotalPeople />
      </Grid>
    </Box>
  );
}
// ANCHOR 參加人數
const TotalPeople = memo(() => {
  const numberOfPlayer = useSelector((state) => state.raid.numberOfPlayer);
  const labelArray = String(numberOfPlayer).padStart(4, "0").split("");
  return (
    <div>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h6"
          style={{ lineHeight: 1, fontFamily: "Noto Serif TC" }}
        >
          目前參與人數
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Box padding={1}>
          <Grid container spacing={1} wrap="nowrap">
            {labelArray.map((i, index) => (
              <Grid item key={`${i}-${index}`}>
                <Typography
                  variant="h3"
                  style={{ lineHeight: 1, textDecoration: "underline" }}
                >
                  {i}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
});
// ANCHOR 攻擊畫面
function AttackScenes() {
  const attack = useAttack();
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      padding={1}
      justifyContent="space-evenly"
      alignItems="center"
    >
      <div>
        <GetTeamLogo />
      </div>
      <AttackButton onClick={attack} />
      <Grid container spacing={1} justifyContent="center">
        <TotalPeople />
      </Grid>
    </Box>
  );
}
// ANCHOR 攻擊按鈕
function AttackButton(props) {
  const useStyles = makeStyles({
    buttonBackground: {
      width: 160,
      height: 160,
    },
    button: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: 160,
      height: 160,
      opacity: 0,
      "&:active": {
        opacity: 1,
      },
    },
  });
  const classes = useStyles();
  const affiliationTeam = useSelector((state) => state.raid.affiliationTeam);
  const attackImage = useMemo(() => {
    if (affiliationTeam === "PIRATE") {
      return require("./assets/pirate_ATTACK.png").default;
    } else if (affiliationTeam === "ADVENTURER") {
      return require("./assets/adventurer_ATTACK.png").default;
    } else {
      return require("./assets/attack.png").default;
    }
  }, [affiliationTeam]);
  return (
    <Box {...props} position="relative">
      <Box
        component="img"
        src={attackImage}
        className={classes.buttonBackground}
      />
      <Box
        component="img"
        src={require("./assets/attack_active.png").default}
        className={classes.button}
      />
    </Box>
  );
}
// ANCHOR 即將開始文字
const PrepareText = memo(() => {
  const useStyles = makeStyles((theme) => ({
    prepareText: {
      fontWeight: "bold",
      animation: `$prepare 250ms ${theme.transitions.duration.standard}`,
    },
    "@keyframes prepare": {
      "0%": { color: "white" },
      "100%": { color: "red" },
    },
  }));
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.prepareText}>
      對戰即將開始
    </Typography>
  );
});

// SECTION 資料處理相關
// ANCHOR 自動刷新遊戲資料
function useEventEffect() {
  const params = useParams();
  const id = Number(params?.id);
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.raid.loaded);
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          batch(() => {
            dispatch(setStatus(event.status));
            dispatch(
              setNumberOfPlayer(event.players ? event.players.length : 0)
            );
            dispatch(setStartTime(event.startTime));
            dispatch(setEndTime(event.endTime));
            dispatch(setJoined(event.joined));
            dispatch(setWinningTeam(event.winningTeam));
          });
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (loaded) {
      if (id) {
        getEvent({ variables: { id } });
      }
    }
  }, [id, loaded]);
}
// ANCHOR 取得怪物資料
function useRaidEffect() {
  const dispatch = useDispatch();
  const { id } = useParams();
  useQuery(GET_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    variables: {
      id: Number(id),
    },
    onCompleted({ raid }) {
      if (raid) {
        setTimeout(() => {
          batch(() => {
            dispatch(setCurrentHealthPoint(raid.currentHealthPoint));
            dispatch(setScore(raid.score));
          });
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
}
// ANCHOR 取得遊戲剩餘時間
function useRemainingTimeEffect() {
  const dispatch = useDispatch();
  const endTime = useSelector((state) => state.raid.endTime);
  const status = useSelector((state) => state.raid.status);
  const currentHealthPoint = useSelector(
    (state) => state.raid.currentHealthPoint
  );
  const timerRef = useRef(null);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (currentHealthPoint > 0) {
        if (endTime && status === "DURING_THE_GAME") {
          // NOTE 血量變0時將剩餘秒數歸0
          dispatch(setRemainingTime(convertRemaining(endTime, undefined)));
        }
      } else {
        dispatch(setRemainingTime(0));
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [endTime, status, currentHealthPoint]);
}
// ANCHOR 換算遊戲剩餘時間
function convertRemaining(endTime, startTime) {
  return Math.max(
    Math.min(
      moment(endTime)
        .tz("Asia/Taipei")
        .diff(moment(startTime).tz("Asia/Taipei"), "seconds"),
      30
    ),
    0
  );
}
// ANCHOR 攻擊事件
/**
 * @param {number} sendInterval 送出間隔幾秒
 * @param {number} maxAttackTimes 每秒攻擊次數上限
 */
function useAttack(sendInterval = 2, maxAttackTimes = 8) {
  const Alert = useAlert();
  const history = useHistory();
  const seconds = 1000;
  const timerRef = useRef(null);
  const params = useParams();
  const id = Number(params?.id);
  const countRef = useRef(0);
  const damagePerAttack = useSelector((state) => state.raid.damagePerAttack);
  const [attackMonster] = useMutation(PLAYER_ATTACK, {
    onCompleted({ launchAttack }) {
      if (launchAttack.message) {
        if (launchAttack.message.search("登入") > -1) {
          // return Alert.alert("", launchAttack.message, [
          //   {
          //     text: "確定",
          //     type: "cancel",
          //   },
          //   {
          //     text: "回首頁",
          //     onPress: () => history.replace("/"),
          //     type: "ok",
          //   },
          //   {
          //     text: "登入",
          //     onPress: () => history.replace("/login"),
          //     type: "cancel",
          //   },
          // ]);
        } else {
          return Alert.notice(launchAttack.message);
        }
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (id && damagePerAttack) {
      timerRef.current = setInterval(() => {
        if (countRef.current > 0) {
          const maxCount = Math.min(
            countRef.current,
            sendInterval * maxAttackTimes
          );
          attackMonster({
            variables: {
              eventId: id,
              attackPoint: maxCount * damagePerAttack,
            },
          });
          countRef.current = countRef.current - Math.max(0, maxCount);
        }
      }, sendInterval * seconds);
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [sendInterval, maxAttackTimes, damagePerAttack, id]);
  function attack() {
    countRef.current = countRef.current + 1;
  }
  return attack;
}
// 取得隊伍圖片
function GetTeamLogo() {
  const affiliationTeam = useSelector((state) => state.raid.affiliationTeam);
  const teamImage = useMemo(() => {
    if (affiliationTeam === "PIRATE") {
      return {
        teamlogo: require("./assets/pirate.png").default,
        team: require("./assets/pirate_logo.png").default,
      };
    } else if (affiliationTeam === "ADVENTURER") {
      return {
        teamlogo: require("./assets/adventurer.png").default,
        team: require("./assets/adventurer_logo.png").default,
      };
    } else {
      return {
        teamlogo: require("./assets/LUYAODESIGN.png").default,
        team: require("./assets/sword.png").default,
      };
    }
  }, [affiliationTeam]);
  return (
    <>
      <Grid item container justifyContent="center">
        <img
          src={require("./assets/title.png").default}
          style={{ width: "50%" }}
        />
      </Grid>
      <Grid container justifyContent="center">
        <img
          src={teamImage.teamlogo}
          style={{ width: "100%", maxHeight: 116 }}
        />
      </Grid>
      <Grid container justifyContent="center">
        <img src={teamImage.team} style={{ width: "35%", maxHeight: 160 }} />
      </Grid>
    </>
  );
}
// !SECTION
