import React, { createElement, useState } from "react";
// material
import {
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  ButtonGroup,
} from "@material-ui/core";
// icon
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// react-router-dom
import { useHistory } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-redux
import { useDispatch } from "react-redux";

// component
import { useAlert } from "../component/Alert";
import TitleBox from "../component/TitleBox";
import LoadingModal from "../component/LoadingModal";
// zustand
import useTokenStore from "../zustand/useTokenStore";
import useMemberIdStore from "../zustand/useMemberIdStore";
// redux
import { setMemberAllValue } from "../redux/member";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      message
      member {
        id
        tier
        token
      }
    }
  }
`;

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { alert, notice } = useAlert();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  function _register() {
    history.push("/register");

    // if (moment().diff(moment("2021/04/08 00:00:00")) > 0) {
    //   alert(
    //     null,
    //     <div>
    //       <h3>【路遙圓創官網 | 重要公告】</h3>
    //       <p>⚠️ 4/8 00:00起 暫停網站會員註冊功能 ⚠️</p>
    //       <p>
    //         為了提供更好的服務品質給大家，我們決定暫停開放會員註冊，確保未來網站的使用者體驗可以更好！
    //       </p>
    //       <p>
    //         同時我們也將進行網站升級與優化，未來除了商品的預購外，也能透過網站進行互動，更多功能敬請期待～
    //       </p>
    //       <p>⚠️ 特別注意 ⚠️</p>
    //       <p>
    //         網站暫停註冊期間內，若有限定商品購買需提前註冊，恕無法提供個別註冊，逾期不候。敬請各位知悉，若造成不便，煩請見諒。
    //       </p>
    //       <p>再次開放註冊時將另行公告，感謝各位支持與配合。</p>
    //     </div>
    //   );
    // }
    // else {
    //   history.push("/register");
    // }
  }
  function _forgePassword() {
    history.push("/forge-password");
  }
  function _login() {
    if (!username) {
      notice("帳號未填寫！！");
      return null;
    }

    if (!password) {
      notice("密碼未填寫！！");
      return null;
    }
    login();
  }
  const [login, { loading }] = useMutation(LOGIN, {
    variables: {
      username,
      password,
    },
    onCompleted({ login }) {
      if (login.success) {
        const token = login.member.token;
        const memberId = login.member.id;
        useTokenStore.getState().setToken(token);
        useMemberIdStore.getState().setMemberId(memberId);
        if (login.message) {
          alert("", login.message, [
            {
              text: "確認",
              onPress: () => history.replace("/member"),
              type: "ok",
            },
          ]);
        }
        if (history.location.state) {
          if (history.location.state.goBackPage) {
            history.goBack();
          } else if (history.location.state.goToPage) {
            history.replace(history.location.state.goToPage);
          }
        } else {
          history.replace("/");
        }
        dispatch(setMemberAllValue({ memberTierLimit: login.member.tier }));
        return null;
      } else if (login.message) {
        return notice(login.message);
      }
    },
  });
  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>登入｜路遙圓創</title>
      </Helmet>
      <LoadingModal loading={loading} />
      <CardContent>
        <TitleBox title="登入">
          <Grid container spacing={1} direction="column">
            <Grid container item alignItems="center">
              <TextField
                label="帳號／手機號碼／信箱皆可"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid container item alignItems="center">
              <TextField
                label="密碼"
                fullWidth
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => setShowPassword((e) => !e)}
                        size="small"
                      >
                        {createElement(
                          showPassword ? VisibilityIcon : VisibilityOffIcon
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={_login}
              >
                登入
              </Button>
            </Grid>
            <Grid container item>
              <ButtonGroup
                size="small"
                fullWidth
                variant="text"
                color="primary"
              >
                <Button onClick={_register}>註冊</Button>
                <Button onClick={_forgePassword}>忘記密碼</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </TitleBox>
      </CardContent>
    </Container>
  );
}
