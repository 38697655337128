import React, { useState } from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { useAlert } from "./Alert";
import Button from "./Button";
import Text from "./Text";
import Grid from "./Grid";

export default function EventConsentFloatingLayer({
  open,
  isFlashSale,
  onChangeFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const theme = useTheme();
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStylesTable = makeStyles({
    dialogPaper: {
      maxHeight: `calc(90%)`,
      maxWidth: isTableSize ? `calc(85%)` : `calc(40%)`,
      width: `calc(100%)`,
      margin: 8,
    },
    Container: {
      display: "flex",
      justifyContent: "center",
      color: "red",
    },
    divider: {
      height: 30,
    },
    checked: {
      color: "#0072E3",
    },
  });
  const classesTable = useStylesTable();
  const [checked, setChecked] = useState(false);
  const changeName = isFlashSale ? "限額抽選" : "抽選";

  function initialization(value) {
    setChecked(false);
    onChangeFloatingwindowClose(value);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={false}
      classes={{ paper: classesTable.dialogPaper }}
    >
      <DialogTitle classes={{ root: classesTable.Container }}>
        同意書
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Text type="h6">
                您已詳閱『{changeName}注意事項』與下列注意事項，並同意
                {changeName}規則嗎？
                <br></br>
                <br></br>
                注意事項：中選會員以簡訊通知並在會員中心「待付款區」加入中選商品，為了避免遺漏，開獎後，務必在繳費截止前登入「會員中心」查看待付款區，中選者如無繳費，視同放棄並啟動帳號黑名單，帳號未來將有可能無法登入，請注意。
              </Text>
            </Box>
          </Grid>
          <Grid container item xs={12} justify="center">
            <FormControlLabel
              className={classesTable.checked}
              control={
                <Checkbox
                  checked={checked === true}
                  onChange={() => setChecked(true)}
                  style={{
                    color: "#2894FF",
                  }}
                />
              }
              label={"同意並參加"}
            />
            <FormControlLabel
              className={classesTable.checked}
              control={
                <Checkbox
                  checked={checked === false}
                  onChange={() => setChecked(false)}
                  style={{
                    color: "#2894FF",
                  }}
                />
              }
              label={"不同意"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={() => {
                if (!checked) {
                  Alert.notice(
                    `請詳閱『${changeName}注意事項』並勾選同意書即可繼續！`
                  );
                } else {
                  initialization(checked);
                }
              }}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onPress={() => initialization()}
            >
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
