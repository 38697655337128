import create from "zustand";
import { persist } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} memberToken - memberToken
 * @property {string} consoleToken - consoleToken
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  memberToken: null,
  consoleToken: null,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} setToken - 設定setToken
 * @property {() => void} cleanToken - 清除cleanToken
 * @property {() => void} setConsoleToken - 設定setConsoleToken
 * @property {() => void} cleanConsoleToken - 清除cleanConsoleToken
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set, get) => ({
  setToken: (memberToken) => {
    return new Promise((resolve, reject) => {
      if (memberToken && typeof memberToken === "string") {
        set({ memberToken });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  cleanToken: () => {
    return new Promise((resolve) => {
      set({ memberToken: null });
      resolve(true);
    });
  },
  setConsoleToken: (consoleToken) => {
    return new Promise((resolve, reject) => {
      if (consoleToken && typeof consoleToken === "string") {
        set({ consoleToken });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  cleanConsoleToken: () => {
    return new Promise((resolve) => {
      set({ consoleToken: null });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useTokenStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@luyaoMemberToken",
    }
  )
);
export default useTokenStore;
