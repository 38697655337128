import XLSX from "xlsx";

export function excelDownload(entozh, excelData, fileName) {
  return new Promise((resolve) => {
    function handleExportAll() {
      const json = excelData.map((item) => {
        return Object.keys(item).reduce((newData, key) => {
          const newKey = entozh[key] || key;
          newData[newKey] = item[key];
          return newData;
        }, {});
      });
      const sheet = XLSX.utils.json_to_sheet(json);
      const blob = sheet2blob(sheet);
      const url = URL.createObjectURL(blob);
      openDownloadDialog(url, `${fileName}.xlsx`);
      resolve("Finish");
    }

    function openDownloadDialog(url, saveName) {
      let aLink = document.createElement("a");
      aLink.href = url;
      aLink.download = saveName || "";
      let event;
      if (window.MouseEvent) event = new MouseEvent("click");
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
      }
      aLink.dispatchEvent(event);
    }

    function sheet2blob(sheet, sheetName) {
      sheetName = sheetName || "sheet1";
      let workbook = {
        SheetNames: [sheetName],
        Sheets: {},
      };
      workbook.Sheets[sheetName] = sheet;
      let wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
      };
      let wbout = XLSX.write(workbook, wopts);
      let blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      return blob;
    }

    function s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    handleExportAll();
  });
}
