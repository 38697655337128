// mui
import { Box, Grid } from "@material-ui/core";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import AreaInformation from "../../../component/special-event/AreaInformation";
import AdaptiveImage from "../../../component/AdaptiveImage";
import Image from "../../../component/Image";
import CopyrightRender from "./component/CopyrightRender";
import PageIsland from "./component/PageIsland";
import SwitchNavTabs from "./component/SwitchNavTabs";

// ANCHOR 主要組件
export default function IllustratePage() {
  return (
    <BackgroundContainer>
      <PageIsland />
      <IllustrateContent />
    </BackgroundContainer>
  );
}

// ANCHOR 內容
function IllustrateContent() {
  return (
    <Box>
      {/* <Box>
        <AreaInformation title="Illustrate & Activity 圖鑑與活動" />
      </Box> */}

      <Grid container style={{ paddingBottom: "80px" }} spacing={3}>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/event-2/Illustrate-1.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/event-2/Illustrate-2.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
        <Grid item xs={12}>
          <AdaptiveImage>
            <Image
              src={"/img/special/event-2/Illustrate-3.png"}
              style={{ width: "100%", height: "100%" }}
              resizeMode={"contain"}
            />
          </AdaptiveImage>
        </Grid>
      </Grid>
      <CopyrightRender />

      <SwitchNavTabs />
    </Box>
  );
}
