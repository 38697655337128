import FloatingIslandIllustratePage from "./FloatingIsland/IllustratePage";
import MysticIslandIllustratePage from "./MysticIsland/IllustratePage";
import DarkCityIllustratePage from "./DarkCity/IllustratePage";
import ParallelUniverseIllustratePage from "./ParallelUniverse/IllustratePage";
import RadishIslandIllustratePage from "./RadishIsland/IllustratePage";
import CrystalPalaceIllustratePage from "./CrystalPalace/IllustratePage";

export default function IllustrateMainPage() {
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";

  if (version === "FloatingIsland") {
    return <FloatingIslandIllustratePage />;
  } else if (version === "MysticIsland") {
    return <MysticIslandIllustratePage />;
  } else if (version === "DarkCity") {
    return <DarkCityIllustratePage />;
  } else if (version === "ParallelUniverse") {
    return <ParallelUniverseIllustratePage />;
  } else if (version === "RadishIsland") {
    return <RadishIslandIllustratePage />;
  } else if (version === "CrystalPalace") {
    return <CrystalPalaceIllustratePage />;
  }
  return null;
}
