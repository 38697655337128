import React, { useMemo } from "react";
import Image from "../../../../component/Image";
// mui
import { Box, useMediaQuery } from "@material-ui/core";
import AdaptiveImage from "../../../../component/AdaptiveImage";

export function useGetGap() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const gap = useMemo(() => {
    if (isDownMobile) {
      return "0px";
    } else if (isMobile) {
      return "10px";
    } else if (isTable) {
      return "25px";
    } else if (isDownPC) {
      return "50px";
    } else if (isPC) {
      return "100px";
    }
    return "80px";
  }, [isPC, isDownPC, isTable, isMobile, isDownMobile]);
  return gap;
}

export default function PageIsland() {
  const gap = useGetGap();
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap }}>
      <AdaptiveImage ratio="23%">
        <Image
          src={"/img/special/parallel-universe/育成系統4-2-白字.png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"contain"}
        />
      </AdaptiveImage>
      <AdaptiveImage ratio="23%">
        <Image
          src={"/img/special/parallel-universe/育成系統4-2-山.png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"cover"}
        />
      </AdaptiveImage>
    </Box>
  );
}
