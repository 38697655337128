import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// material
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
// apollo
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// date-fns
import { format, parseISO } from "date-fns";
// react-hook-form
import { useForm, Controller } from "react-hook-form";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent } from "../../component/Card";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import { CustomNumberInput, InputTitle } from "../../component/Form";
// utils
import emptyArray from "../../utils/emptyArray";

// SECTION
// NOTE 孵化代碼列表
const HATCHING_CODES = gql`
  query hatchingCodes($eventId: Int!) {
    hatchingCodes(eventId: $eventId, latest: true) {
      # "總數"
      count
      # "總頁數"
      pageCount
      # "孵化代碼"
      contents {
        id
        # "代碼"
        code
        # "已使用"
        used
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 建立代碼(餵食代碼帶toyId，孵化代碼什麼都不用帶)
const CREATE_CODE = gql`
  mutation createCode($eventId: Int!, $quantity: Int!) {
    createCode(eventId: $eventId, quantity: $quantity) {
      success
      message
    }
  }
`;
// !SECTION
// ANCHOR 主要組件
export default function HatchingCodeManage({ eventId }) {
  return <HatchingCodeList eventId={eventId} />;
}
// ANCHOR 列表
function HatchingCodeList({ eventId }) {
  const createHatchingCodesDialogRef = useRef();

  const {
    data: hatchingCodesData,
    loading: hatchingCodesLoading,
    refetch,
  } = useQuery(HATCHING_CODES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { eventId },
    onError() {
      return null;
    },
  });

  if (hatchingCodesLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <CreateHatchingCodesDialog
          ref={createHatchingCodesDialogRef}
          eventId={eventId}
          onRefetch={refetch}
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              孵化代碼數量：{hatchingCodesData?.hatchingCodes?.count || 0}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() => createHatchingCodesDialogRef?.current?.open()}
            >
              創建孵化代碼
            </Button>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            data={emptyArray(hatchingCodesData?.hatchingCodes?.contents)}
            header={["孵化代碼", "使用狀態", "建立時間"]}
            tableWidth={2}
            columns={[
              "code",
              (item) => (item.used ? "已使用" : "未使用"),
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
            ]}
          />
        </CardContent>
      </Card>
    );
  }
}

const CreateHatchingCodesDialog = forwardRef(function CreateHatchingCodesDialog(
  { onRefetch = () => {}, eventId },
  ref
) {
  const { notice } = useAlert();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { quantity: "" },
  });
  const [open, setOpen] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
      },
    }),
    []
  );

  const [createCodeFn, { loading: createCodeLoading }] = useMutation(
    CREATE_CODE,
    {
      variables: {
        eventId,
      },
      onCompleted({ createCode: { message } }) {
        if (message) {
          notice(message);
        } else {
          onRefetch();
          notice("成功");
          reset();
          setOpen(false);
        }
      },
      onError() {
        return null;
      },
    }
  );

  const _createCode = useCallback(
    ({ quantity }) => {
      createCodeFn({
        variables: { eventId, quantity: parseInt(quantity, 10) },
      });
    },
    [eventId, createCodeFn]
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <LoadingModal loading={createCodeLoading} />
      <DialogTitle>創建孵化代碼</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Controller
            control={control}
            name="quantity"
            rules={{
              required: "必填欄位 !",
            }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <InputTitle label="數量" required />
                <CustomNumberInput
                  {...field}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              </div>
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={handleSubmit(_createCode)}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onPress={handleClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
});
