import React from "react";
import PropTypes from "prop-types";

export default function Text({
  type = "body1",
  bold = false,
  target = "_blank",
  gutterBottom = false,
  paragraph = false,
  href,
  style = {},
  onPress = () => {},
  children,
  ...props
}) {
  const defaultFontSize = 14;
  const sizeList = {
    h1: defaultFontSize * 6,
    h2: defaultFontSize * 3.75,
    h3: defaultFontSize * 3,
    h4: defaultFontSize * 2.125,
    h5: defaultFontSize * 1.5,
    h6: defaultFontSize * 1.25,
    subtitle1: defaultFontSize * 1,
    subtitle2: defaultFontSize * 0.875,
    body1: defaultFontSize * 1,
    body2: defaultFontSize * 0.875,
    button: defaultFontSize * 0.875,
    caption: defaultFontSize * 0.75,
    overline: defaultFontSize * 0.75,
  };
  const lineHeightList = {
    h1: 1.167,
    h2: 1.2,
    h3: 1.167,
    h4: 1.235,
    h5: 1.334,
    h6: 1.6,
    subtitle1: 1.75,
    subtitle2: 1.57,
    body1: 1.5,
    body2: 1.43,
    button: 1.75,
    caption: 1.66,
    overline: 2.66,
  };
  const letterSpacingList = {
    h1: defaultFontSize * -0.01562,
    h2: defaultFontSize * -0.00833,
    h3: defaultFontSize * 0,
    h4: defaultFontSize * 0.00735,
    h5: defaultFontSize * 0,
    h6: defaultFontSize * 0.0075,
    subtitle1: defaultFontSize * 0.00938,
    subtitle2: defaultFontSize * 0.00714,
    body1: defaultFontSize * 0.00938,
    body2: defaultFontSize * 0.01071,
    button: defaultFontSize * 0.02857,
    caption: defaultFontSize * 0.03333,
    overline: defaultFontSize * 0.08333,
  };
  const boldList = ["h1", "h2", "h3", "h4", "h5", "h6"];
  const textTransformList = ["button", "overline"];
  function getTextTransform() {
    return textTransformList.find((item) => item === type)
      ? "uppercase"
      : "initial";
  }
  function getBold() {
    return boldList.find((item) => item === type || bold) ? "bold" : "normal";
  }
  return React.createElement(
    href ? "a" : "font",
    {
      style: {
        display: "block",
        fontSize: sizeList[type],
        fontWeight: getBold(),
        lineHeight: lineHeightList[type],
        letterSpacing: letterSpacingList[type],
        textTransform: getTextTransform(),
        marginBottom: (gutterBottom && 4) || (paragraph && 8),
        ...style,
      },
      rel: "noopener",
      ...props,
      target,
      href,
      onClick: onPress,
    },
    children
  );
}
Text.propTypes = {
  type: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "caption",
    "overline",
  ]),
  target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"]),
  href: PropTypes.string,
  bold: PropTypes.bool,
  style: PropTypes.object,
  onPress: PropTypes.func,
  gutterBottom: PropTypes.bool,
  paragraph: PropTypes.bool,
};
