import React, { useEffect, useState, memo, Fragment } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Button,
  ButtonBase,
  Typography,
  TextField,
  Tabs,
  Tab,
  Container,
} from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import moment from "moment-timezone";

import ContentEditor from "./ContentEditor";

import { useAlert } from "../../component/Alert";
import Grid from "../../component/Grid";
import LoadingModal from "../../component/LoadingModal";
import { Card, CardActions, CardContent } from "../../component/Card";
import { TextInput } from "../../component/Form";

const GET_ARTICLE = gql`
  query article($id: Int!) {
    article(id: $id) {
      id
      title
      imageUrl
      tags
      details {
        type
        body
      }
    }
  }
`;

const GET_ARTICLE_COMMENTS = gql`
  query article($id: Int!) {
    article(id: $id) {
      id
      comments {
        id
        member {
          id
          fullName
          nickname
        }
        comment
        createdAt
      }
    }
  }
`;

const SAVE_ARTICLE = gql`
  mutation saveArticle($articleInput: ArticleInput!) {
    saveArticle(articleInput: $articleInput) {
      success
      message
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const DELETE_AUCTIONCOMMENT = gql`
  mutation deleteAuctionComment($id: Int!) {
    deleteAuctionComment(id: $id) {
      success
      message
    }
  }
`;

export default function ArticleForm({ id, onChangeSelect = () => {} }) {
  const Alert = useAlert();
  const [select, setSelect] = useState(0);
  const [tag, setTag] = useState("");

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      title: "",
      imageUrl: undefined,
      tags: [],
      details: { details: [] },
    },
  });

  const { fields, remove, update } = useFieldArray({
    control,
    name: "tags",
  });

  const [getArticle, { loading }] = useLazyQuery(GET_ARTICLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ article }) {
      if (article) {
        setTimeout(() => {
          const newArticle = [];
          const newTags = [];
          for (let i = 0; i < article.details.length; i++) {
            const { __typename, ...otherData } = article.details[i];
            newArticle.push(otherData);
          }
          article.tags.split(",").forEach((item) => {
            newTags.push(item);
          });
          setValue("title", article.title);
          setValue("tags", newTags);
          setValue("imageUrl", article.imageUrl);
          setValue("details", { details: newArticle });
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [uploadImage, { loading: uploadImageLoading }] =
    useMutation(UPLOAD_IMAGE);

  useEffect(() => {
    if (id) {
      getArticle({ variables: { id } });
    } else {
      reset();
    }
  }, [id, getArticle, reset]);

  async function _saveArticle(data) {
    const { title, imageUrl, tags, details } = data;

    if (!tags[0]) {
      return Alert.notice("標籤未填寫！");
    }

    const articleInput = {
      title,
      imageUrl,
      tags: tags.join(),
      details: details.details,
    };

    if (imageUrl.name) {
      const newImageUrl = await uploadImage({ variables: { image: imageUrl } });
      articleInput.imageUrl = newImageUrl.data.uploadImage.location;
    }

    if (id) {
      articleInput.id = id;
    }

    saveArticle({
      variables: {
        articleInput,
      },
    });
  }

  const [saveArticle, { loading: saveArticleLoading }] = useMutation(
    SAVE_ARTICLE,
    {
      onCompleted({ saveArticle }) {
        if (saveArticle.success) {
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => onChangeSelect(),
              type: "ok",
            },
          ]);
        } else {
          Alert.notice("儲存失敗，請再次嘗試！");
        }
      },
      onError(error) {
        Alert.notice(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={saveArticleLoading || uploadImageLoading} />
        <Tabs value={select} onChange={(e, n) => setSelect(n)}>
          <Tab label="最新消息資料" />
          <Tab label="留言" />
        </Tabs>
        {Boolean(select === 0) && (
          <>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: "必填選項",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Typography style={{ color: Boolean(error) && "red" }}>
                          標題
                        </Typography>
                        <TextField
                          {...field}
                          error={error}
                          helperText={error?.message}
                          fullWidth
                        />
                      </>
                    )}
                  />
                </Grid>
                <Controller
                  control={control}
                  name="imageUrl"
                  rules={{
                    required: "必填選項",
                  }}
                  render={({
                    field: { value: imageUrl, onChange },
                    fieldState: { error },
                  }) => (
                    <Grid item sm={12}>
                      <Typography style={{ color: Boolean(error) && "red" }}>
                        封面
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ border: Boolean(error) && "1px solid red" }}
                      >
                        <ButtonBase
                          style={{ flex: 1, height: 300 }}
                          component="label"
                          htmlFor={"coverImage"}
                        >
                          {imageUrl ? (
                            <img
                              src={
                                imageUrl.name
                                  ? URL.createObjectURL(imageUrl)
                                  : imageUrl
                              }
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          ) : (
                            <CloudUploadIcon />
                          )}
                        </ButtonBase>
                        <>
                          <input
                            id={"coverImage"}
                            type="file"
                            accept="image/*"
                            onChange={(e) => onChange(e.target.files[0])}
                            style={{
                              display: "none",
                            }}
                          />
                        </>
                      </Box>
                      <Typography
                        variant="overline"
                        style={{ color: Boolean(error) && "red" }}
                      >
                        &ensp;&ensp;{error?.message}
                      </Typography>
                    </Grid>
                  )}
                />
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container spacing={1} direction="row">
                <Grid container item direction="row" spacing={1}>
                  {fields.map((item, index) => (
                    <Grid item key={item.id}>
                      <Controller
                        control={control}
                        name={`tags.${index}`}
                        render={({ field: { value } }) => (
                          <Chip
                            label={value}
                            color="primary"
                            onDelete={() => remove(index)}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container item>
                  <Box display="flex">
                    <TextInput
                      label="標籤"
                      value={tag}
                      onChange={(v) => setTag(v)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTag(() => {
                          update(fields.length, tag);
                          return "";
                        });
                      }}
                      disabled={!Boolean(tag)}
                    >
                      加入
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Controller
                control={control}
                name="details"
                rules={{
                  validate: (e) => {
                    if (!e.details[0]) {
                      return "必填選項";
                    }
                    const detailsNull = e.details.find(
                      (item) => item.body === ""
                    );
                    if (detailsNull) {
                      return "未填寫完！";
                    }
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Box
                      display="flex"
                      style={{ border: Boolean(error) && "1px solid red" }}
                      flexDirection="column"
                    >
                      <ContentEditor
                        data={value}
                        onChangeData={(e) => onChange({ details: e })}
                      />
                    </Box>
                    <Typography
                      variant="overline"
                      style={{ color: Boolean(error) && "red" }}
                    >
                      &ensp;&ensp;{error?.message}
                    </Typography>
                  </>
                )}
              />
            </CardContent>
            <CardActions>
              <Grid container item justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(_saveArticle)}
                >
                  儲存
                </Button>
              </Grid>
            </CardActions>
          </>
        )}
        {Boolean(select === 1) && <Comment id={id} />}
      </Card>
    );
  }
}

const Comment = memo(function Comment({ id }) {
  const Alert = useAlert();

  const [getArticleComments, { data, error, loading }] = useLazyQuery(
    GET_ARTICLE_COMMENTS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  useEffect(() => {
    if (Boolean(id)) {
      getArticleComments({ variables: { id: Number(id) } });
    }
  }, [id, getArticleComments]);

  return (
    <CardContent>
      <Container maxWidth="md">
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
          >
            <Typography variant="h4">無法取得資料</Typography>
          </Box>
        ) : data?.article.comments[0] ? (
          data?.article.comments.map((item, index) => (
            <CommentItem
              item={item}
              index={index}
              onRefetch={() =>
                getArticleComments({ variables: { id: Number(id) } })
              }
            />
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="450px"
          >
            <Typography variant="h4">尚無留言</Typography>
          </Box>
        )}
      </Container>
    </CardContent>
  );
});

function CommentItem({ item, index, onRefetch = () => {} }) {
  const Alert = useAlert();

  const [deleteAuctionComment, { loading: deleteAuctionCommentLoading }] =
    useMutation(DELETE_AUCTIONCOMMENT, {
      onCompleted({ deleteAuctionComment }) {
        if (deleteAuctionComment.success) {
          onRefetch();
        } else if (deleteAuctionComment.message) {
          Alert.notice(deleteAuctionComment.message);
        }
      },
    });

  return (
    <Grid
      container
      direction="row"
      style={{ borderBottom: "1px solid #C0C0C0", minHeight: "150px" }}
    >
      <LoadingModal loading={deleteAuctionCommentLoading} />
      <Grid xs={12} sm={3}>
        <Box border="1px solid #C0C0C0" padding={0.5} display="inline-block">
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            留言{index + 1}
          </Typography>
        </Box>
        <Typography variant="h6" fontWeight="bold">
          {item.member.nickname ? item.member.nickname : item.member.fullName}
        </Typography>
        <Typography variant="body2">
          {moment(item.createdAt).format("YYYY/MM/DD HH:mm")}
        </Typography>
      </Grid>
      <Grid xs={12} sm={6}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {item.comment.split("\n").map((commentItem) => (
            <Fragment key={commentItem}>
              {commentItem}
              <br />
            </Fragment>
          ))}
        </Typography>
      </Grid>
      <Grid xs={12} sm={3}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              Alert.alert("", "確定要刪除？", [
                {
                  text: "確定",
                  onPress: () =>
                    deleteAuctionComment({ variables: { id: item.id } }),
                  type: "ok",
                },
                {
                  text: "取消",
                  type: "cancel",
                },
              ])
            }
          >
            刪除留言
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
