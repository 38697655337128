import React from "react";
import Image from "../../../../component/Image";
// mui
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import AdaptiveImage from "../../../../component/AdaptiveImage";

export default function PageIsland() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box pb={isMobile ? 4 : 6}>
      <Box
        sx={{
          width: "100%",
          height: 0,
          paddingBottom: "55.04%",
          // backgroundImage: "url('/img/special/event-2/page-island.png')",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "contain",
          // backgroundPosition: "center center",
        }}
      ></Box>
      {/* <AdaptiveImage ratio="55.04%">
        <Image
          src={"/img/special/event-2/page-island.png"}
          alt="island"
          style={{
            objectFit: "contain",
          }}
        />
      </AdaptiveImage> */}
    </Box>
  );
}
