import React, { useState } from "react";
import {
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
  ButtonBase,
  makeStyles,
  Avatar,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { useForm, Controller, FormProvider } from "react-hook-form";

import PhoneVerifyHookForm from "../component/PhoneVerifyHookForm";
import { useAlert } from "../component/Alert";
import TitleBox from "../component/TitleBox";
import LoadingModal from "../component/LoadingModal";

const REGISTER = gql`
  mutation register($memberInput: MemberInput!) {
    register(memberInput: $memberInput) {
      success
      invalidFields
      member {
        id
        fullName
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

export default function RegisterPage() {
  const Alert = useAlert();
  const history = useHistory();
  const [disabledRegister, setDisabledRegister] = useState(false);
  const theme = useTheme();
  const useStyles = makeStyles({
    profilePictureButton: {
      backgroundColor: theme.palette.grey["400"],
      color: theme.palette.grey["50"],
      borderRadius: 999,
    },
    profilePicture: {
      width: 128,
      height: 128,
    },
  });
  const classes = useStyles();

  const registerForm = useForm({
    defaultValues: {
      profilePicture: undefined,
      fullName: "",
      email: "",
      username: "",
      nickname: "",
      password: "",
      rePassword: "",
      mobile: "",
      countryCallingCodeObject: "",
      verificationCode: "",
    },
  });
  const { handleSubmit, control, getValues } = registerForm;

  // useEffect(() => {
  //   if (moment().diff(moment("2021/04/08 00:00:00")) > 0) {
  //     Alert.alert(
  //       null,
  //       <div>
  //         <h3>【路遙圓創官網 | 重要公告】</h3>
  //         <p>⚠️ 4/8 00:00起 暫停網站會員註冊功能 ⚠️</p>
  //         <p>
  //           為了提供更好的服務品質給大家，我們決定暫停開放會員註冊，確保未來網站的使用者體驗可以更好！
  //         </p>
  //         <p>
  //           同時我們也將進行網站升級與優化，未來除了商品的預購外，也能透過網站進行互動，更多功能敬請期待～
  //         </p>
  //         <p>⚠️ 特別注意 ⚠️</p>
  //         <p>
  //           網站暫停註冊期間內，若有限定商品購買需提前註冊，恕無法提供個別註冊，逾期不候。敬請各位知悉，若造成不便，煩請見諒。
  //         </p>
  //         <p>再次開放註冊時將另行公告，感謝各位支持與配合。</p>
  //       </div>,
  //       [{ text: "確認", onPress: () => history.push("/") }]
  //     );
  //   }
  // }, []);

  const [uploadImage, { loading: uploadImageLoading }] =
    useMutation(UPLOAD_IMAGE);

  async function _register(data) {
    const {
      profilePicture,
      fullName,
      nickname,
      email,
      // username,
      password,
      mobile,
      countryCallingCodeObject,
      verificationCode,
    } = data;

    const memberInput = {
      fullName,
      nickname,
      email,
      // username,
      password,
      mobile,
      countryCallingCode: countryCallingCodeObject.value,
      verificationCode,
      source: "WEB",
    };

    if (Boolean(profilePicture)) {
      if (Boolean(profilePicture.localPath)) {
        const image = await uploadImage({
          variables: { image: profilePicture.localPath },
        });
        if (Boolean(image)) {
          const { uploadImage } = image.data ?? {};
          const { __typename, ...otherData } = uploadImage ?? {};
          memberInput.profilePicture = otherData;
        }
      }
    }

    setDisabledRegister(true);

    _register_({
      variables: {
        memberInput,
      },
    });
  }

  const [_register_, { loading: registerLoading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      setDisabledRegister(false);
      const hasError =
        register.invalidFields &&
        register.invalidFields.find((item) => item === "verificationCode");
      if (register.success) {
        if (
          process.env.NODE_ENV === "production" &&
          window.location.host.search(".s3-") === -1
        ) {
          window.fbq("track", "CompleteRegistration");
        }
        return Alert.notice("註冊成功，歡迎您！", () =>
          history.replace("/login")
        );
      } else if (Boolean(hasError)) {
        return Alert.notice("手機驗證碼輸入錯誤！");
      } else {
        return Alert.notice("重複註冊！");
      }
    },
    onError(error) {
      setDisabledRegister(false);
      Alert.notice("重複註冊！");
    },
  });
  return (
    <Container
      maxWidth="sm"
      style={{
        minHeight: `calc(100vh - 64px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>註冊｜路遙圓創</title>
      </Helmet>
      <LoadingModal loading={registerLoading || uploadImageLoading} />
      <CardContent>
        <TitleBox title="註冊">
          <Grid container spacing={1} direction="column">
            {false && (
              <Controller
                control={control}
                name="profilePicture"
                render={({ field: { value, onChange } }) => (
                  <Grid container item justify="center">
                    <ButtonBase
                      component="label"
                      htmlFor="profilePicture-upload"
                      className={classes.profilePictureButton}
                    >
                      {value && value.location ? (
                        <Avatar
                          src={value.location}
                          className={classes.profilePicture}
                        />
                      ) : (
                        <AddIcon className={classes.profilePicture} />
                      )}
                    </ButtonBase>
                    <input
                      style={{ display: "none" }}
                      id="profilePicture-upload"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        onChange({
                          location: URL.createObjectURL(e.target.files[0]),
                          localPath: e.target.files[0],
                        });
                      }}
                    />
                  </Grid>
                )}
              />
            )}
            <Grid item>
              <Controller
                control={control}
                name="fullName"
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="真實姓名／同收件人"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="nickname"
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="暱稱"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "必填欄位",
                  pattern: {
                    value: /^[\w\.]+@\w+((\.|-)\w+)+$/,
                    message: "格式錯誤！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="信箱"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {false && (
              <Grid item>
                <Controller
                  control={control}
                  name="username"
                  rules={{
                    required: "必填欄位",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="帳號"
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <Controller
                control={control}
                name="password"
                rules={{
                  required: "必填欄位",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/,
                    message: "密碼格式錯誤，需包含英文以及數字，且長度為八碼！",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="密碼"
                    type="password"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="rePassword"
                rules={{
                  required: "必填欄位",
                  validate: (e) => {
                    if (e !== getValues("password")) {
                      return "密碼與再次輸入密碼不符合！";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="再次輸入密碼"
                    type="password"
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <FormProvider {...registerForm}>
              <PhoneVerifyHookForm />
            </FormProvider>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit(_register)}
                disabled={disabledRegister}
              >
                註冊
              </Button>
            </Grid>
          </Grid>
        </TitleBox>
      </CardContent>
    </Container>
  );
}
