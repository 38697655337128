import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { useAlert } from "../component/Alert";
import Grid from "../component/Grid";
import TabView from "../component/TabView";
import EventTable from "./component/EventTable";
import EventContent from "./component/EventContent";
import EventForm from "./component/EventForm";
import ShootingGameForm from "./component/ShootingGameForm";

const GET_PRODUCTS = gql`
  query products {
    products {
      products {
        id
        name
      }
    }
  }
`;

export default function EventListPage() {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    tabViewData: ["活動列表", "新增", "小遊戲設定"],
    select: 0,
    dataObject: null,
    products: [],
  });

  function changeDataListener(key, v) {}
  function changeData(key, v, rerender = false) {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }

  const { loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ products }) {
      if (products) {
        setTimeout(() => {
          const newProducts = [];
          for (let i = 0; i < products.products.length; i++) {
            newProducts.push({
              label: products.products[i].name,
              value: products.products[i].id,
            });
          }
          changeData("products", newProducts, true);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid>
        <TabView
          paper
          tabs={newData.tabViewData}
          index={newData.select}
          onChange={(e) => {
            if (newData.select === 3 && e !== 3) {
              Alert.alert("通知", "確定要離開編輯？", [
                {
                  text: "確定",
                  onPress: () => {
                    if (e !== 2) {
                      changeData("dataObject", null);
                      changeData("tabViewData", [
                        "活動列表",
                        "新增",
                        "小遊戲設定",
                      ]);
                      changeData("select", e, true);
                      return null;
                    }
                    newData.tabViewData.splice(3, 1);
                    changeData("tabViewData", newData.tabViewData);
                    changeData("select", e, true);
                  },
                  type: "ok",
                },
                {
                  text: "取消",
                  type: "cancel",
                },
              ]);
            } else if (newData.select === 2 && e !== 2) {
              changeData("dataObject", null);
              newData.tabViewData.splice(2, 1);
              changeData("tabViewData", ["活動列表", "新增", "小遊戲設定"]);
              changeData("select", e, true);
            } else {
              changeData("select", e, true);
            }
          }}
        >
          <EventTable
            onEditSelect={(value) => {
              changeData("dataObject", value);
              newData.tabViewData.splice(2, 1);
              newData.tabViewData.push(`${value.name}活動內容`);
              changeData("tabViewData", newData.tabViewData);
              changeData("select", 2, true);
            }}
          />
          <EventForm
            products={newData.products}
            onChangeSelect={() => {
              changeData("dataObject", null);
              changeData("tabViewData", ["活動列表", "新增", "小遊戲設定"]);
              changeData("select", 0, true);
            }}
          />
          {newData.dataObject?.id ? (
            <EventContent
              id={newData.dataObject && newData.dataObject.id}
              onEditSelect={(value) => {
                changeData("dataObject", value);
                newData.tabViewData.push("編輯");
                changeData("tabViewData", newData.tabViewData);
                changeData("select", 3, true);
              }}
            />
          ) : (
            <ShootingGameForm />
          )}
          <EventForm
            products={newData.products}
            id={newData.dataObject && newData.dataObject.id}
            onChangeSelect={() => {
              changeData("dataObject", null);
              newData.tabViewData.splice(3, 1);
              changeData("tabViewData", ["活動列表", "新增", "小遊戲設定"]);
              changeData("select", 0, true);
            }}
          />
        </TabView>
      </Grid>
    );
  }
}
