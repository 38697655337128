import React, { useState, useEffect, memo, useReducer } from "react";
import {
  Avatar,
  FormLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Card, CardContent, CardActions } from "../component/Card";
import Grid from "../component/Grid";
import Image from "../component/Image";
import { Select } from "../component/Form";
import Text from "../component/Text";
import Table from "../component/Table";
import { useAlert } from "../component/Alert";
import LoadingModal from "../component/LoadingModal";
import Button from "../component/Button";
import { halfShapeAndFullForm, getInvitationCode } from "../component/utils";

import ShipmentFloatingLayer from "./component/ShipmentFloatingLayer";

import divisions from "../divisions.json";
import { checkSpecialSymbolsAndNumber } from "../utils/checkRegExp";

import {
  orderItemStatusEnum,
  shipmentStatusEnum,
  shipmentStatusList,
  shipmentMethodEnum,
} from "../utils/localData";

const GET_SHIPMENT = gql`
  query shipment($id: Int!) {
    shipment(id: $id) {
      id
      member {
        id
        profilePicture {
          location
        }
        fullName
        mobile
      }
      recipient
      recipientPhone
      serialNumber
      ecpayLogisticsId
      cvsPaymentNo
      cvsValidationNo
      createSuccess
      errorMessage
      logisticsRecords(admin: true) {
        id
        rtnCode
        rtnMsg
        statusUpdateTime
        createdAt
      }
      shipmentMethod
      convenienceStore
      address {
        district
        subdistrict
        address
      }
      note
      orderItems {
        id
        product {
          id
          name
          images {
            location
          }
        }
        unitPrice
        amount
        status
      }
      status
      payments {
        id
        serialNumber
        success
        tradeNumber
        totalPrice
        paymentMethod
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_SHIPMENTSTATUS = gql`
  mutation updateShipmentStatus($id: Int!, $status: ShipmentStatus!) {
    updateShipmentStatus(id: $id, status: $status) {
      success
      message
    }
  }
`;

// ANCHOR 重新建立綠界出貨單
const CREATE_NEWECPAYSHIPMENTORDER = gql`
  mutation createNewEcpayShipmentOrder($shipmentInput: ShipmentInput!) {
    createNewEcpayShipmentOrder(shipmentInput: $shipmentInput) {
      success
      message
    }
  }
`;

// ANCHOR 門市電子地圖
const GO_TO_LOGISTICSMAP = gql`
  query logisticsMap($randomCode: String!, $returnUrl: String!) {
    logisticsMap(randomCode: $randomCode, returnUrl: $returnUrl)
  }
`;

// ANCHOR 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

// ANCHOR 浮層開關
function _reducerDispatch(state, action) {
  switch (action.type) {
    case "open":
      return action.data;
    case "close":
      return null;
    default:
      return state;
  }
}
export default function ShipmentPage() {
  const Alert = useAlert();
  const { id } = useParams();
  const [totalPrice, setTotalPrice] = useState(0);
  const [shipmentStatus, setShipmentStatus] = useState("WAITING_FOR_PAYMENT");
  const [shipmentMethod, setShipmentMethod] = useState("");
  const [updateAddressDialog, updateAddressDialogDispatch] = useReducer(
    _reducerDispatch,
    null
  );
  function _closeUpdateAddressDialog() {
    updateAddressDialogDispatch({ type: "close" });
  }
  const [ecpayShipmentOrderOpen, setEcpayShipmentOrderOpen] = useState(false);

  const [getShipment, { data, loading }] = useLazyQuery(GET_SHIPMENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ shipment }) {
      if (shipment) {
        setTimeout(() => {
          let newTotalPrice = 0;
          shipment.orderItems.forEach((item) => {
            newTotalPrice += item.unitPrice * item.amount;
          });
          setTotalPrice(newTotalPrice);
          setShipmentStatus(shipment.status);
          setShipmentMethod(shipment.shipmentMethod);
          const luyaoConsoleShipmentSelectStoreRandomCode =
            localStorage.getItem("@luyaoConsoleShipmentSelectStoreRandomCode");
          if (luyaoConsoleShipmentSelectStoreRandomCode) {
            setEcpayShipmentOrderOpen(true);
          }
          const luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode =
            localStorage.getItem(
              "@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode"
            );
          const luyaoConsoleUpdateShipmentAddressSelectStoreGetValues =
            localStorage.getItem(
              `@luyaoConsoleUpdateShipmentAddressSelectStoreGetValues`
            );
          if (
            luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode &&
            luyaoConsoleUpdateShipmentAddressSelectStoreGetValues
          ) {
            updateAddressDialogDispatch({
              type: "open",
              data: JSON.parse(
                luyaoConsoleUpdateShipmentAddressSelectStoreGetValues
              ),
            });
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getShipment({ variables: { id: Number(id) } });
    }
  }, [id, getShipment]);

  const [updateShipmentStatus, { loading: updateShipmentStatusLoading }] =
    useMutation(UPDATE_SHIPMENTSTATUS, {
      onCompleted({ updateShipmentStatus }) {
        if (updateShipmentStatus.success) {
          return Alert.alert("", "更改成功！", [
            {
              text: "確定",
              onPress: getShipment({ variables: { id: Number(id) } }),
              type: "ok",
            },
          ]);
        } else {
          return Alert.alert("", "更改失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (Boolean(data)) {
    return (
      <Card>
        <CardContent>
          <LoadingModal loading={updateShipmentStatusLoading} />
          <ShipmentFloatingLayer
            clickData={updateAddressDialog}
            onChangeFloatingwindowClose={(value) => {
              _closeUpdateAddressDialog();
              if (value === "refetch") {
                getShipment({ variables: { id: Number(id) } });
              }
            }}
          />
          <Grid container spacing={1}>
            <Grid container item>
              {data.shipment.member.profilePicture && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={data.shipment.member.profilePicture.location}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={data.shipment.member.fullName} />
                </ListItem>
              )}
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>會員姓名</FormLabel>
                <Text>{data.shipment.member.fullName}</Text>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>會員手機</FormLabel>
                <Text>{data.shipment.member.mobile}</Text>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>出貨編號</FormLabel>
              <Text>{data.shipment.serialNumber}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>總金額</FormLabel>
              <Text>NT${totalPrice}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>建立日期</FormLabel>
              <Text>
                {moment(
                  data.shipment.createdAt ? data.shipment.createdAt : new Date()
                ).format("YYYY/MM/DD HH:mm")}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>更新日期</FormLabel>
              <Text>
                {moment(
                  data.shipment.updatedAt ? data.shipment.updatedAt : new Date()
                ).format("YYYY/MM/DD HH:mm")}
              </Text>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>運送方式</FormLabel>
                <Text>{shipmentMethodEnum[shipmentMethod]}</Text>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>地址 / 超商店名</FormLabel>
                <Text>
                  {shipmentMethod === "CONVENIENCE_STORE"
                    ? data.shipment.convenienceStore
                      ? data.shipment.convenienceStore
                      : ""
                    : data.shipment.address
                    ? divisions[data.shipment.address.district]["zhTW"] +
                      divisions[data.shipment.address.district]["subdivisions"][
                        data.shipment.address.subdistrict
                      ]["zhTW"] +
                      data.shipment.address.address
                    : ""}
                </Text>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>收貨人姓名</FormLabel>
                <Text>
                  {data.shipment.recipient || data.shipment.member.fullName}
                </Text>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>收貨人電話</FormLabel>
                <Text>
                  {data.shipment.recipientPhone || data.shipment.member.mobile}
                </Text>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>備註</FormLabel>
                <Text>{data.shipment.note ? data.shipment.note : "無"}</Text>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormLabel>出貨狀態</FormLabel>
              <Select
                items={shipmentStatusList.filter((i) => i.value !== "ALL")}
                value={shipmentStatus}
                onChange={(value) => {
                  Alert.alert(
                    "",
                    `確定要更改出貨狀態為${shipmentStatusEnum[value]}？`,
                    [
                      {
                        text: "確定",
                        onPress: () =>
                          updateShipmentStatus({
                            variables: { id: Number(id), status: value },
                          }),
                        type: "ok",
                      },
                      {
                        text: "取消",
                        type: "cancel",
                      },
                    ]
                  );
                }}
                fullWidth
              />
            </Grid>
            {data.shipment.status !== "SHIPPED" && (
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormLabel>操作</FormLabel>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    const {
                      id,
                      recipient,
                      recipientPhone,
                      shipmentMethod,
                      convenienceStore,
                      address,
                    } = data?.shipment ?? {};
                    updateAddressDialogDispatch({
                      type: "open",
                      data: {
                        id,
                        recipient,
                        recipientPhone,
                        shipmentMethod,
                        convenienceStore,
                        ...address,
                      },
                    });
                  }}
                  fullWidth
                >
                  更改地址
                </Button>
              </Grid>
            )}
            {data.shipment.shipmentMethod === "CONVENIENCE_STORE" && (
              <Grid container item>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormLabel>綠界物流編號</FormLabel>
                  <Text>
                    {data.shipment.ecpayLogisticsId
                      ? data.shipment.ecpayLogisticsId
                      : "無"}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormLabel>超商寄貨編號</FormLabel>
                  <Text>
                    {data.shipment.cvsPaymentNo
                      ? data.shipment.cvsPaymentNo
                      : "無"}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormLabel>超商驗證碼</FormLabel>
                  <Text>
                    {data.shipment.cvsValidationNo
                      ? data.shipment.cvsValidationNo
                      : "無"}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormLabel>物流是否建立成功</FormLabel>
                  <Text>{data.shipment.createSuccess ? "是" : "否"}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormLabel>物流錯誤訊息</FormLabel>
                  <Text>
                    {data.shipment.errorMessage
                      ? data.shipment.errorMessage
                      : "無"}
                  </Text>
                </Grid>
              </Grid>
            )}
            <Grid container item>
              <FormLabel>出貨品項</FormLabel>
              <Table
                data={data.shipment.orderItems}
                tableWidth={2}
                header={["", "名稱", "單價", "數量", "狀態"]}
                columns={[
                  (item) => (
                    <Image
                      style={{ width: 64, height: 64 }}
                      src={item.product.images[0].location}
                    />
                  ),
                  ["product", "name"],
                  (item) => `NT$${item.unitPrice}`,
                  "amount",
                  (item) => orderItemStatusEnum[item.status],
                ]}
              />
              <CardActions style={{ width: "100%" }}>
                <FormLabel>總金額</FormLabel>
                <Text>{totalPrice}</Text>
              </CardActions>
            </Grid>
            <Grid container item>
              <FormLabel>付款紀錄</FormLabel>
              <Table
                data={data.shipment.payments}
                tableWidth={2}
                header={[
                  "出貨編號",
                  "交易編號（綠界）",
                  "付款狀態",
                  "付款方式",
                  "總金額",
                  "建立時間",
                ]}
                columns={[
                  "serialNumber",
                  "tradeNumber",
                  (item) => (item.success ? "成功" : "失敗"),
                  (item) => {
                    function getPaymentMethod() {
                      switch (item.paymentMethod) {
                        case "CREDIT_CARD":
                          return "信用卡";
                        case "ATM":
                          return "ATM";
                        case "OTHER":
                          return "其他";
                        default:
                          return "錯誤";
                      }
                    }
                    return getPaymentMethod();
                  },
                  "totalPrice",
                  (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
                ]}
              />
            </Grid>
            {data.shipment.shipmentMethod === "CONVENIENCE_STORE" && (
              <>
                <Grid container item justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onPress={() => setEcpayShipmentOrderOpen(true)}
                    disabled={
                      data.shipment.createSuccess ||
                      data.shipment.shipmentMethod === "HOME_DELIVERY"
                    }
                  >
                    重新建立綠界出貨單
                  </Button>
                </Grid>
                <EcpayShipmentOrderComponent
                  open={ecpayShipmentOrderOpen}
                  onClose={(e) => {
                    if (e === "mutation") {
                      getShipment({ variables: { id: Number(id) } });
                    }
                    setEcpayShipmentOrderOpen(false);
                  }}
                  data={{
                    recipient: data.shipment.recipient,
                    recipientPhone: data.shipment.recipientPhone,
                  }}
                  shipmentId={data.shipment.id}
                />
                <Grid container item>
                  <FormLabel>物流紀錄</FormLabel>
                  <Table
                    data={data.shipment.logisticsRecords}
                    tableWidth={2}
                    header={[
                      "狀態代碼",
                      "狀態訊息",
                      "狀態更新時間",
                      "建立時間",
                    ]}
                    columns={[
                      "rtnCode",
                      "rtnMsg",
                      (item) =>
                        moment(item.statusUpdateTime).format(
                          "YYYY/MM/DD HH:mm"
                        ),
                      (item) =>
                        moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
                    ]}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  } else {
    return null;
  }
}

const EcpayShipmentOrderComponent = memo(function EcpayShipmentOrderComponent({
  open,
  onClose = () => {},
  data,
  shipmentId,
}) {
  const Alert = useAlert();
  const { location } = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles({
    input: {
      height: "40px",
      padding: "0px",
      backgroundColor: "white",
    },
    labeltext: {
      color: theme.palette.text.secondary,
      marginTop: "10px",
      marginBottom: "10px",
      fontWeight: 400,
    },
  });
  const classes = useStyles();
  const [selectStoreDisabled, setSelectStoreDisabled] = useState(false);
  const ecpayShipmentOrderForm = useForm({
    defaultValues: {
      cvsStoreId: "",
      convenienceStore: "",
      recipient: "",
      recipientPhone: "",
    },
  });
  const { control, setValue, getValues, reset, handleSubmit } =
    ecpayShipmentOrderForm;

  const [
    createNewEcpayShipmentOrder,
    { loading: createNewEcpayShipmentOrderLoading },
  ] = useMutation(CREATE_NEWECPAYSHIPMENTORDER, {
    onCompleted({ createNewEcpayShipmentOrder }) {
      if (createNewEcpayShipmentOrder.success) {
        reset();
        onClose("mutation");
      } else if (createNewEcpayShipmentOrder.message) {
        Alert.notice(createNewEcpayShipmentOrder.message);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  function _createNewEcpayShipmentOrder(data) {
    const shipmentInput = {
      id: shipmentId,
      shipmentMethod: "CONVENIENCE_STORE",
      recipient: data.recipient,
      recipientPhone: data.recipientPhone,
      shipmentSubType: "SEVEN_ELEVEN",
      cvsStoreId: data.cvsStoreId,
      convenienceStore: data.convenienceStore,
    };
    createNewEcpayShipmentOrder({
      variables: { shipmentInput },
    });
  }

  function _onClose() {
    if (!createNewEcpayShipmentOrderLoading && !selectStoreDisabled) {
      reset();
      onClose();
    }
  }

  function getOldData() {
    const luyaoSelectStoreGetValues = localStorage.getItem(
      `@luyaoConsoleSelectStoreGetValues`
    );
    if (luyaoSelectStoreGetValues) {
      const { recipient, recipientPhone } = JSON.parse(
        luyaoSelectStoreGetValues
      );
      setValue("recipient", recipient);
      setValue("recipientPhone", recipientPhone);
      setTimeout(() => {
        localStorage.removeItem(`@luyaoConsoleShipmentSelectStoreRandomCode`);
        localStorage.removeItem(`@luyaoConsoleSelectStoreGetValues`);
      }, 0);
    }
  }

  const [getLogisticsMapData] = useLazyQuery(GET_LOGISTICSMAPDATA, {
    onCompleted({ logisticsMapData }) {
      if (logisticsMapData) {
        setTimeout(() => {
          const { cvsStoreId, cvsStoreName } = logisticsMapData;
          setValue("cvsStoreId", cvsStoreId);
          setValue("convenienceStore", cvsStoreName);
          getOldData();
        }, 0);
      }
    },
    onError() {
      getOldData();
    },
  });

  useEffect(() => {
    if (open) {
      const luyaoConsoleShipmentSelectStoreRandomCode = localStorage.getItem(
        "@luyaoConsoleShipmentSelectStoreRandomCode"
      );
      if (luyaoConsoleShipmentSelectStoreRandomCode) {
        getLogisticsMapData({
          variables: { randomCode: luyaoConsoleShipmentSelectStoreRandomCode },
        });
      }
      if (data) {
        setValue("recipient", data.recipient);
        setValue("recipientPhone", data.recipientPhone);
      }
    }
  }, [open, setValue, data, getLogisticsMapData]);

  const [goToLogisticsMap] = useLazyQuery(GO_TO_LOGISTICSMAP, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ logisticsMap }) {
      if (logisticsMap) {
        setTimeout(() => {
          window.location = logisticsMap;
        }, 0);
      }
    },
  });

  // 跳轉到超商電子地圖系統選擇門市
  const chooseStore = () => {
    setSelectStoreDisabled(true);
    const randomCode = getInvitationCode(7);
    localStorage.setItem(
      `@luyaoConsoleShipmentSelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@luyaoConsoleSelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };
  return (
    <>
      <FormProvider {...ecpayShipmentOrderForm}>
        <Dialog fullWidth maxWidth="sm" open={open} onClose={_onClose}>
          <LoadingModal loading={createNewEcpayShipmentOrderLoading} />
          <DialogTitle>重新建立綠界出貨單</DialogTitle>
          <DialogContent>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography color="primary">請選擇超商名稱：</Typography>
                <Controller
                  name="convenienceStore"
                  control={control}
                  rules={{
                    required: "請選擇超商名稱",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="cvsStoreNameTextField"
                      InputProps={{
                        className: classes.input,
                      }}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                      onClick={chooseStore}
                      inputProps={{ readOnly: true }}
                    />
                  )}
                />
              </Grid>
              {/* 收件人 */}
              <Grid item xs={12}>
                <Typography color="primary">請輸入收件人：</Typography>
                <Controller
                  name="recipient"
                  control={control}
                  rules={{
                    required: "請輸入收件人",
                    validate: (e) => {
                      if (
                        e &&
                        (checkSpecialSymbolsAndNumber.test(e) ||
                          halfShapeAndFullForm(e) > 10)
                      ) {
                        return "收件人請設定為 4~10 字元(中文 2~5 個字, 英文 4~10 個字母, 不可含數字)";
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      placeholder="請輸入收件人"
                      InputProps={{
                        className: classes.input,
                      }}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {/* 收件人電話 */}
              <Grid item xs={12}>
                <Typography color="primary">請輸入收件人電話：</Typography>
                <Controller
                  name="recipientPhone"
                  control={control}
                  rules={{
                    required: "請輸入收件人電話",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "請輸入正確手機號碼",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      placeholder="請輸入收件人電話"
                      InputProps={{
                        className: classes.input,
                      }}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onPress={_onClose}
              disabled={selectStoreDisabled}
            >
              取消
            </Button>
            <Button
              variant="contained"
              color="primary"
              onPress={handleSubmit(_createNewEcpayShipmentOrder)}
              disabled={selectStoreDisabled}
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
});
