import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
// mui
import {
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
// react-hook-form
import { useFormContext, useWatch } from "react-hook-form";
// react-router-dom
import { useHistory } from "react-router-dom";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

// components
import { useAlert } from "../../../component/Alert";
import SwitchNavTabs from "../../../component/special-event/SwitchNavTabs";
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import AreaInformation from "../../../component/special-event/AreaInformation";
import Image from "../../../component/Image";
import { FieldCache } from "../../../component/Form";
import {
  CheckMyEggDialog,
  CheckMyToyDialog,
  ChangeNameDialog,
  EnterCodeDialog,
} from "../../../component/special-event/Alerts";
import ToysRandomPosition from "../../../component/special-event/ToysRandomPosition";
import AdaptiveImage from "../../../component/AdaptiveImage";
import CreateOrderFloatingLayer from "../../../component/CreateOrderFloatingLayer";
// zustand
import useTokenStore from "../../../zustand/useTokenStore";

// css
import "../../../index.css";
import VideoPlayerDialog from "../../../component/special-event/VideoPlayerDialog";
import LoadingFloating from "../../../component/LoadingFloating";

// SECTION apollo
// NOTE 建立育成訂單
const CREATE_RAISING_ORDER = gql`
  mutation createRaisingOrder(
    $memberEggId: Int!
    $shipmentInput: ShipmentInput!
  ) {
    createRaisingOrder(
      memberEggId: $memberEggId
      shipmentInput: $shipmentInput
    ) {
      success
      message
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function CultivationPage() {
  return (
    <BackgroundContainer>
      <CultivationContent />
    </BackgroundContainer>
  );
}

// ANCHOR 我的育成
function CultivationContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <CheckToken />
      <Grid container spacing={isMobile ? 2 : 6}>
        <Grid
          item
          xs={12}
          style={{ marginTop: "50px", position: "relative" }}
          id="gridContainer"
        >
          <AdaptiveImage ratio="64.87%">
            <Image
              style={{
                width: "100%",
                height: "100%",
              }}
              src={"/img/special/island.png"}
            />
          </AdaptiveImage>
          <ToysDisplay />
          <Box
            style={{
              position: "absolute",
              bottom: isMobile ? 20 : 55,
              left: 0,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid container spacing={isMobile ? 2 : 6}>
              <Grid item xs={6}>
                <MyEggArea />
              </Grid>
              <Grid item xs={6}>
                <MyToyArea />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <MyEgg />

      <Box py="52px">
        <Divider style={{ backgroundColor: "#fff" }} />
      </Box>
      <SwitchNavTabs />
    </Box>
  );
}

// ANCHOR 檢查token
function CheckToken() {
  const { notice } = useAlert();
  const history = useHistory();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  useEffect(() => {
    if (!memberToken) {
      history.push("/login");
      notice("登入時效已過，請重新登入。");
    }
  }, [memberToken, history, notice]);
  return null;
}

// ANCHOR 玩具顯示外框
function ToysDisplay() {
  const useStyles = makeStyles({
    frameBox: {
      position: "absolute",
      top: "5%",
      left: "4%",
      height: "100%",
      width: "92%",
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.frameBox}>
      <AdaptiveImage ratio="37.93%">
        <ToysRandomPositionComponent />
      </AdaptiveImage>
    </Box>
  );
}

// ANCHOR 玩具顯示
const ToysRandomPositionComponent = memo(
  function ToysRandomPositionComponent() {
    const memberToys = useWatch({ name: "memberToys" });
    return <ToysRandomPosition data={memberToys} />;
  }
);

// ANCHOR 我的玩具按鈕
const MyToyArea = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      // height: "150px",
      paddingTop: isMobile && "20px",
      cursor: "pointer",
      "& img": {
        borderRadius: isMobile ? "25px" : "28px",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="myCultivationClickButton"
        render={(myCultivationClickButton) => (
          <Image
            style={{
              width: "100%",
              height: "100%",
              border:
                myCultivationClickButton === "hatchingMyToys" && "1px solid",
            }}
            src={"/img/special/HatchingMyToys.png"}
            onPress={() => {
              if (myCultivationClickButton !== "hatchingMyToys") {
                setValue("myCultivationClickButton", "hatchingMyToys");
              }
            }}
          />
        )}
      />
    </Box>
  );
};
// ANCHOR 路遙蛋按鈕
const MyEggArea = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setValue } = useFormContext();
  const useStyles = makeStyles({
    areaContainer: {
      // height: "150px",
      paddingTop: isMobile && "20px",
      cursor: "pointer",
      "& img": {
        borderRadius: isMobile ? "25px" : "28px",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.areaContainer}>
      <FieldCache
        name="myCultivationClickButton"
        render={(myCultivationClickButton) => (
          <Image
            style={{
              width: "100%",
              height: "100%",
              border:
                myCultivationClickButton === "hatchingEggs" && "1px solid",
            }}
            src={"/img/special/HatchingMyEgg.png"}
            onPress={() => {
              if (myCultivationClickButton !== "hatchingEggs") {
                setValue("myCultivationClickButton", "hatchingEggs");
              }
            }}
          />
        )}
      />
    </Box>
  );
};

// ANCHOR 內容區域
const MyEgg = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    toyContainer: {
      display: "flex",
      flexDirection: isDownSm ? "column" : "row",
      alignItems: "center",
      "& > *+*": {
        flexShrink: 0,
      },
      "& .MuiTypography-root": {
        color: "#4D626B",
        fontSize: "20px",
        height: "40%",
        marginBottom: "2em",
      },
      "& .toyDetailButton": {
        color: "#FDFDFE",
        fontWeight: 900,
        fontSize: "18px",
        backgroundColor: "#4D626B",
        borderRadius: "5px",
        textAlign: "center",
        padding: "2px 3px",
        // width: "50px",
      },
    },
    toyImageContainer: {
      width: "100%",
      position: "relative",
      paddingBottom: "46.66%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
      "& img": {
        display: "inline-block",
        position: "absolute",
        width: "100%",
        height: "100%",
        inset: 0,
        objectFit: "contain",
      },
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const { getValues, setValue } = useFormContext();
  const checkMyEggDialogRef = useRef();
  const videoPlayerDialogRef = useRef();
  const checkMyToyDialogRef = useRef();
  const changeNameDialogRef = useRef();
  const enterCodeDialogRef = useRef();
  const createOrderDialogRef = useRef();

  useEffect(() => {
    const luyaoHatchingSelectStoreRandomCode = localStorage.getItem(
      "@luyaoHatchingSelectStoreRandomCode"
    );
    const luyaoHatchingSelectStoreMemberEggId = localStorage.getItem(
      "@luyaoHatchingSelectStoreMemberEggId"
    );
    if (
      luyaoHatchingSelectStoreRandomCode &&
      luyaoHatchingSelectStoreMemberEggId
    ) {
      createOrderDialogRef?.current?.open(
        "hatchingMyToys",
        Number(luyaoHatchingSelectStoreMemberEggId)
      );
    }
  }, []);

  return (
    <Box pt={isDownSm ? "40px" : "120px"}>
      <FieldCache
        name="myCultivationClickButton"
        render={(myCultivationClickButton) => (
          <AreaInformation
            title={
              myCultivationClickButton === "hatchingEggs"
                ? "My Egg 我的蛋蛋"
                : "My Toy 我的玩具"
            }
          />
        )}
      />
      {/* 蛋蛋孵化區域 */}
      <CheckMyEggDialog
        ref={checkMyEggDialogRef}
        onChangeName={(e) => changeNameDialogRef?.current?.open(e)}
        onFeeding={(e) => enterCodeDialogRef?.current?.open(e)}
        onIncubation={(e) => videoPlayerDialogRef?.current?.open(e)}
      />
      <VideoPlayerDialog
        ref={videoPlayerDialogRef}
        onBack={() => {
          history.push("/special-event/my-cultivation");
          setValue("memberEggsRefetch", true);
        }}
        onGoTo={() => {
          setValue("memberEggsRefetch", true);
          setValue("myCultivationClickButton", "hatchingMyToys");
        }}
      />
      <CheckMyToyDialog
        ref={checkMyToyDialogRef}
        onChangeName={(e) => changeNameDialogRef?.current?.open(e)}
        onFeeding={(e) => enterCodeDialogRef?.current?.open(e)}
        onCreateRaisingOrder={(e) =>
          createOrderDialogRef?.current?.open(null, e)
        }
      />
      <ChangeNameDialog ref={changeNameDialogRef} />
      <EnterCodeDialog
        ref={enterCodeDialogRef}
        onIncubation={(e) => videoPlayerDialogRef?.current?.open(e)}
        onFeeding={(e) => videoPlayerDialogRef?.current?.open(e)}
      />
      <CreateOrderDialog ref={createOrderDialogRef} />
      <Grid
        container
        spacing={4}
        justifyContent={isDownSm ? "center" : "space-between"}
      >
        <Grid item xs={10} sm={5}>
          <FieldCache
            name="selectItems"
            render={(selectItems) => (
              <Box className={classes.toyContainer}>
                <Box className={classes.toyImageContainer}>
                  {selectItems?.eggType?.gifUrl || selectItems?.toy?.gifUrl ? (
                    <img
                      src={
                        selectItems?.eggType?.gifUrl || selectItems?.toy?.gifUrl
                      }
                      alt="toy"
                    />
                  ) : (
                    <img
                      src={
                        require("../../../images/_luyao2019-removebg-preview.png")
                          .default
                      }
                      style={{ opacity: 0.2 }}
                      alt="logo"
                    />
                  )}
                </Box>
                {/* 名稱 & 介紹 */}
                <Box
                  width={isDownSm ? "100%" : "100px"}
                  style={{
                    display: "flex",
                    flexDirection: isDownSm ? "row" : "column",
                    alignItems: isDownSm && "center",
                    justifyContent: "space-between",
                    marginTop: isDownSm && "10px",
                  }}
                >
                  <Typography
                    style={{
                      opacity: !selectItems && 0.5,
                      margin: isDownSm && "0px 0px 0px",
                    }}
                  >
                    {selectItems?.name || "請選擇物品"}
                  </Typography>
                  <Box
                    className={"toyDetailButton"}
                    onClick={() => {
                      if (selectItems) {
                        if (
                          getValues("myCultivationClickButton") ===
                          "hatchingEggs"
                        ) {
                          checkMyEggDialogRef?.current?.open(selectItems);
                        } else {
                          checkMyToyDialogRef?.current?.open(selectItems);
                        }
                      }
                    }}
                    style={{ backgroundColor: !selectItems && "#d6e6e8" }}
                  >
                    <FieldCache
                      name="myCultivationClickButton"
                      render={(myCultivationClickButton) =>
                        myCultivationClickButton === "hatchingEggs"
                          ? "詳情 / 孵化"
                          : "詳情 / 出貨"
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
          />
        </Grid>
        {/* swiper of babies */}
        <Grid item xs={10} sm={6}>
          <Box height="100%">
            <SwiperOfBabies />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateOrderDialog = forwardRef(function CreateOrderDialog({}, ref) {
  const { notice } = useAlert();
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [memberEggId, setMemberEggId] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (type, id) => {
        if (typeof type === "string" && type === "hatchingMyToys") {
          setValue("myCultivationClickButton", "hatchingMyToys");
        }
        if (id && typeof id === "number") {
          setMemberEggId(id);
        }
        setOpen(true);
      },
    }),
    [setValue]
  );
  const [createRaisingOrderFn, { loading: createRaisingOrderLoading }] =
    useMutation(CREATE_RAISING_ORDER, {
      onCompleted({ createRaisingOrder }) {
        if (createRaisingOrder.success) {
          localStorage.removeItem("@luyaoHatchingSelectStoreMemberEggId");
          setValue("memberEggsRefetch", true);
          notice("成功");
        } else if (createRaisingOrder.message) {
          notice(createRaisingOrder.message);
        }
      },
      onError() {
        return null;
      },
    });
  return (
    <>
      <LoadingFloating loading={createRaisingOrderLoading} />
      <CreateOrderFloatingLayer
        open={open}
        memberEggId={memberEggId}
        content={"育成玩具出貨"}
        type="Hatching"
        noPaymentRequired
        onChangeFloatingwindowClose={(value, _, shipmentInput) => {
          setOpen(false);
          if (value === "mutation") {
            createRaisingOrderFn({
              variables: {
                memberEggId,
                shipmentInput,
              },
            });
          }
        }}
      />
    </>
  );
});

// ANCHOR 整理路遙蛋&玩具hook
function useGetMemberEggsOrToys() {
  const memberEggs = useWatch({ name: "memberEggs" });
  const memberToys = useWatch({ name: "memberToys" });
  // 每6個一page
  const newMemberEggs = useMemo(() => {
    const newData = [];
    if (memberEggs.length > 0) {
      for (let i = 0; i < memberEggs.length; i++) {
        if (Math.floor(memberEggs.length / 6) === i) {
          let newFakeBabyLists = [];
          const remainingAmount = memberEggs.length - i * 6;
          const notEnoughQuantity = 6 - remainingAmount;
          newFakeBabyLists = memberEggs.slice(i * 6, i * 6 + remainingAmount);
          for (let j = 0; j < notEnoughQuantity; j++) {
            newFakeBabyLists.push({
              name: "",
              imageUrl: "/img/special/dark-egg.png",
            });
          }
          newData.push(newFakeBabyLists);
          break;
        } else {
          newData.push(memberEggs.slice(i * 6, (i + 1) * 6));
        }
      }
    } else {
      const newFakeBabyLists = new Array(6).fill({ name: "" });
      newData.push(newFakeBabyLists);
    }
    return newData;
  }, [memberEggs]);
  // 每6個一page
  const newMemberToys = useMemo(() => {
    const newData = [];
    if (memberToys.length > 0) {
      for (let i = 0; i < memberToys.length; i++) {
        if (Math.floor(memberToys.length / 6) === i) {
          let newFakeBabyLists = [];
          const remainingAmount = memberToys.length - i * 6;
          const notEnoughQuantity = 6 - remainingAmount;
          newFakeBabyLists = memberToys.slice(i * 6, i * 6 + remainingAmount);
          for (let j = 0; j < notEnoughQuantity; j++) {
            newFakeBabyLists.push({
              name: "",
            });
          }
          newData.push(newFakeBabyLists);
          break;
        } else {
          newData.push(memberToys.slice(i * 6, (i + 1) * 6));
        }
      }
    } else {
      const newFakeBabyLists = new Array(6).fill({ name: "" });
      newData.push(newFakeBabyLists);
    }
    return newData;
  }, [memberToys]);
  return { memberEggs: newMemberEggs, memberToys: newMemberToys };
}

// ANCHOR 瀏覽物品區域
const SwiperOfBabies = () => {
  const { setValue } = useFormContext();
  const [pageIndex, setPageIndex] = useState(0);
  const memberEggsOrToys = useGetMemberEggsOrToys();
  const myCultivationClickButton = useWatch({
    name: "myCultivationClickButton",
  });
  const newImagesArray = useMemo(() => {
    if (myCultivationClickButton === "hatchingEggs") {
      setTimeout(() => {
        setValue(
          "selectItems",
          memberEggsOrToys.memberEggs[0][0]?.name
            ? memberEggsOrToys.memberEggs[0][0]
            : null
        );
      }, 500);
      return memberEggsOrToys.memberEggs;
    } else {
      setTimeout(() => {
        setValue(
          "selectItems",
          memberEggsOrToys.memberToys[0][0]?.name
            ? memberEggsOrToys.memberToys[0][0]
            : null
        );
      }, 500);
      return memberEggsOrToys.memberToys;
    }
  }, [memberEggsOrToys, myCultivationClickButton, setValue]);

  useEffect(() => {
    setPageIndex(0);
  }, [myCultivationClickButton]);

  const handleNext = useCallback(() => {
    if (pageIndex < newImagesArray.length - 1) {
      setPageIndex((e) => e + 1);
    } else {
      setPageIndex(0);
    }
  }, [pageIndex, newImagesArray]);
  const handlePrev = useCallback(() => {
    if (pageIndex < 1) {
      setPageIndex(newImagesArray.length - 1);
    } else {
      setPageIndex((e) => e - 1);
    }
  }, [pageIndex, newImagesArray]);

  //

  const useStyles = makeStyles({
    swiperContainer: {
      height: "100%",
      padding: "16px",
      position: "relative",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
    },
    // container 含有 select
    toyContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingBottom: "100%",
      cursor: "pointer",
      WebkitBackfaceVisibility: "hidden",
      MozBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
      WebkitPerspective: 1000,
      MozPerspective: 1000,
      perspective: 1000,
      "& img": {
        maxWidth: "100%",
        position: "absolute",
        inset: 0,
        height: "100%",
        objectFit: "contain",
      },
      "& .bottom-select": {
        position: "absolute",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        backgroundImage: 'url("/img/special/buttonSelect.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "200%",
        backgroundPosition: "center",
      },
    },
    // arrows
    leftArrowContainer: {
      position: "absolute",
      left: "-12px",
      top: "50%",
      transform: "translate(-25%,-50%)",
      width: 24,
      height: 24,
      cursor: "pointer",
      "& img": {
        maxWidth: "100%",
      },
    },
    rightArrowContainer: {
      position: "absolute",
      right: "-12px",
      top: "50%",
      transform: "translate(25%,-50%)",
      width: 24,
      height: 24,
      cursor: "pointer",
      "& img": {
        maxWidth: "100%",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box className={classes.swiperContainer}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        style={{ minHeight: "188.66px", overflow: "hidden" }}
      >
        {newImagesArray[pageIndex]?.map((item, index) => {
          return (
            <Grid item xs={4} key={item?.id || index}>
              <FieldCache
                name="selectItems"
                render={(selectItems) => {
                  const selectItem = item?.id && item?.id === selectItems?.id;
                  return (
                    <Box
                      className={
                        selectItem
                          ? `${classes.toyContainer} breathing`
                          : classes.toyContainer
                      }
                      onClick={() => {
                        if (item?.id) {
                          setValue("selectItems", item);
                        }
                      }}
                      style={{ cursor: !item?.id && "default" }}
                    >
                      <img
                        alt="toy"
                        src={
                          item?.eggType?.gifUrl ||
                          item?.toy?.gifUrl ||
                          "/img/special/dark-egg.png"
                        }
                        style={{ opacity: !item?.id && 0.3 }}
                      />
                      {/* select 框 */}
                      {selectItem && (
                        <>
                          <div className="bottom-select" />
                        </>
                      )}
                    </Box>
                  );
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Typography style={{ textAlign: "center", marginTop: "10px" }}>
        {pageIndex + 1}
      </Typography>

      {/* arrow */}
      <Box className={classes.leftArrowContainer} onClick={handlePrev}>
        <img
          src={"/img/special/mark-arrow-left.png"}
          className="button-zoom"
          alt=""
        />
      </Box>
      <Box className={classes.rightArrowContainer} onClick={handleNext}>
        <img
          src={"/img/special/mark-arrow-right-dark.png"}
          className="button-zoom"
          alt=""
        />
      </Box>
    </Box>
  );
};
