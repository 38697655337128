import React, { memo, useCallback } from "react";
// material
import { Box, makeStyles } from "@material-ui/core";
// react-router-dom
import { useHistory } from "react-router-dom";
// css
import "./special-event.css";
import { useAlert } from "../Alert";
// zustand
import useTokenStore from "../../zustand/useTokenStore";

const switchLists = [
  {
    label: "遊戲說明",
    href: "/special-event/information",
  },
  {
    label: "購買",
    href: "/special-event/purchase",
  },
  {
    label: "我的育成",
    href: "/special-event/my-cultivation",
  },
  {
    label: "圖鑑與活動",
    href: "/special-event/illustrate",
  },
];

export default memo(function SwitchNavTabs() {
  const { alert } = useAlert();
  const history = useHistory();
  const useStyles = makeStyles({
    swtichContainer: {
      display: "flex",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  return (
    <Box className={classes.swtichContainer}>
      {switchLists.map((item, index) =>
        _renderLists(item, index, history, memberToken, alert)
      )}
    </Box>
  );
});

const _renderLists = ({ label, href }, index, history, memberToken, alert) => {
  //SECTION 判斷 events 類型 決定色系
  const version =
    sessionStorage.getItem("luyao_raisingEvent_version") || "FloatingIsland";

  const defineClassName = (isCurrentPath) => {
    if (version === "FloatingIsland") {
      return isCurrentPath ? `tab-container  tab-selected` : "tab-container";
    } else if (version === "MysticIsland") {
      return isCurrentPath ? `tab-container  tab-selected` : "tab-container";
    } else if (version === "DarkCity") {
      return isCurrentPath
        ? `tab-container-dark-city  tab-selected-dark-city`
        : "tab-container-dark-city";
    } else {
      return isCurrentPath ? `tab-container  tab-selected` : "tab-container";
    }
  };

  // !SECTION END

  const isCurrentPath = window.location.pathname.startsWith(href);
  return (
    <Box
      key={index}
      className={defineClassName(isCurrentPath)}
      onClick={() => {
        if (label === "我的育成") {
          if (memberToken) {
            history.push(href);
          } else {
            return alert("通知", "尚未登入，請先登入才可查看我的育成。", [
              {
                text: "登入",
                onPress: () =>
                  history.push("/login", {
                    goToPage: "/special-event/my-cultivation",
                  }),
                type: "ok",
              },
              {
                text: "取消",
                type: "cancel",
              },
            ]);
          }
        } else {
          history.push(href);
        }
      }}
    >
      {label}
    </Box>
  );
};
