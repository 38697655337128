import React, { useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ReactApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("onerror Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("ontimeout Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    if (options.onAbortPossible) {
      options.onAbortPossible(() => {
        xhr.abort();
      });
    }

    xhr.send(options.body);
  });

const customFetch = (uri, options) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});
const cache = new InMemoryCache({ fragmentMatcher });

const link = createUploadLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://xg6tdlpp98.execute-api.ap-southeast-1.amazonaws.com/prod/graphql"
      : "https://757at7oore.execute-api.ap-southeast-1.amazonaws.com/dev/graphql",
  // : "http://localhost:4000/graphql"
  fetch: customFetch,
});

const authLink = setContext((_, { headers }) => {
  const consoleToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
    ?.state?.consoleToken;
  const memberToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
    ?.state?.memberToken;
  return {
    headers: {
      ...headers,
      authorization:
        consoleToken || memberToken
          ? `Bearer ${consoleToken || memberToken}`
          : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache,
});

export default function ApolloProvider({ children }) {
  const currentAuthLink = setContext((_, { headers }) => {
    let token;
    if (window.location.pathname.includes("console")) {
      const consoleToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
        ?.state?.consoleToken;
      if (consoleToken) {
        token = `Bearer ${consoleToken}`;
      }
    } else {
      const memberToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
        ?.state?.memberToken;
      if (memberToken) {
        token = `Bearer ${memberToken}`;
      }
    }
    return {
      headers: {
        ...headers,
        authorization: token || "",
      },
    };
  });
  const currentClient = useMemo(() => {
    return new ApolloClient({
      link: currentAuthLink.concat(link),
      cache,
    });
  }, [currentAuthLink]);
  return (
    <ReactApolloProvider client={currentClient}>{children}</ReactApolloProvider>
  );
}
