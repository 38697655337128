export default function fetch(url, { method, headers, body }, onVideoProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.upload.addEventListener("progress", (e) =>
      onVideoProgress(e.loaded, e.total, false)
    );
    xhr.upload.addEventListener("loadend", (_) =>
      onVideoProgress(undefined, undefined, true)
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve({ status: 200 });
      }
    };
    xhr.onerror = (err) => reject(err);
    Object.entries(headers).forEach(([name, value]) =>
      xhr.setRequestHeader(name, value)
    );
    xhr.send(body);
  });
}
