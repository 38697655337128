import React, { memo, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
// NOTE 工具
import { client } from "../provider/Apollo";
import stringArrayPick from "../utils/stringArrayPick";
// NOTE 組件
import { useAlert } from "../component/Alert";
import { FieldCache } from "../component/Form";
import CustomDropdown from "../component/Custom/Dropdown";
import CustomAccordion from "../component/Custom/Accordion";

// SECTION apollo
// NOTE 活動預設名單
/** - 活動預設名單 */
const EVENT_DEFAULT_LIST = gql`
  query eventDefaultList {
    eventDefaultList {
      # "白名單"
      whitelist {
        value: id
        label: fullName
      }
      # "黑名單"
      blocklist {
        value: id
        label: fullName
      }
    }
  }
`;
// NOTE 會員列
/** - 會員列 */
const GET_MEMBERS = gql`
  query members($searchTerm: String, $hidden: Boolean) {
    members(searchTerm: $searchTerm, hidden: $hidden) {
      members {
        value: id
        label: fullName
      }
    }
  }
`;
// NOTE 儲存活動預設名單
/** - 儲存活動預設名單 */
const SAVE_EVENT_DEFAULT_LIST = gql`
  mutation saveEventDefaultList($memberIds: [Int]!, $blocklisted: Boolean!) {
    saveEventDefaultList(memberIds: $memberIds, blocklisted: $blocklisted) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

/** - 會員名單頁 */
export default function MemberDirectoryPage() {
  const { notice } = useAlert();
  const form = useForm({
    defaultValues: {
      whitelist: [],
      blocklist: [],
    },
  });
  const { setValue, handleSubmit } = form;
  const typeRef = useRef();
  const { loading } = useQuery(EVENT_DEFAULT_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ eventDefaultList }) {
      if (eventDefaultList) {
        const currentWhitelist = eventDefaultList.whitelist.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        const currentBlacklist = eventDefaultList.blocklist.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setTimeout(() => {
          setValue("whitelist", currentWhitelist);
          setValue("blocklist", currentBlacklist);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  const [saveEventDefaultListFn, { loading: saveEventDefaultListLoading }] =
    useMutation(SAVE_EVENT_DEFAULT_LIST, {
      onCompleted({ saveEventDefaultList: { success, message } }) {
        if (success) {
          const text =
            typeRef.current === "whitelist"
              ? "白名單"
              : typeRef.current === "blocklist"
              ? "黑名單"
              : "";
          notice(`儲存${text}成功`);
        } else if (message) {
          notice(message);
        }
        typeRef.current = null;
      },
      onError() {
        typeRef.current = null;
        return null;
      },
    });
  const _handleWhitelist = useCallback(
    ({ whitelist }) => {
      typeRef.current = "whitelist";
      const memberIds = whitelist.map((item) => item.value);
      saveEventDefaultListFn({ variables: { memberIds, blocklisted: false } });
    },
    [saveEventDefaultListFn]
  );
  const _handleBlacklist = useCallback(
    ({ blocklist }) => {
      typeRef.current = "blocklist";
      const memberIds = blocklist.map((item) => item.value);
      saveEventDefaultListFn({ variables: { memberIds, blocklisted: true } });
    },
    [saveEventDefaultListFn]
  );
  if (loading) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <FormProvider {...form}>
      <Card>
        <CardContent>
          <Grid container style={{ gap: 16 }}>
            <Grid item xs={12}>
              <Typography>白名單</Typography>
            </Grid>
            <Grid item xs={12}>
              <WhitelistDropdown />
            </Grid>
            <Box
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_handleWhitelist)}
                disabled={
                  saveEventDefaultListLoading && typeRef.current === "whitelist"
                }
              >
                儲存
              </Button>
            </Box>
            <Divider style={{ width: "100%" }} />
            <Grid item xs={12}>
              <Typography>黑名單</Typography>
            </Grid>
            <Grid item xs={12}>
              <BlacklistDropdown />
            </Grid>
            <Box
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(_handleBlacklist)}
                disabled={
                  saveEventDefaultListLoading && typeRef.current === "blocklist"
                }
              >
                儲存
              </Button>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

/** - 抓取會員 */
const _fetchMembers = async (searchTerm) => {
  return new Promise(async (result, reject) => {
    try {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: GET_MEMBERS,
        variables: {
          searchTerm,
          hidden: false,
        },
      });
      if (data?.members) {
        result(data.members?.members ?? []);
      }
    } catch (error) {
      console.log("MembersError", error);
      reject(error);
    }
  });
};
/** - 白名單Dropdown */
const WhitelistDropdown = memo(function WhitelistDropdown() {
  const _handleClick = useCallback(async (searchTerm) => {
    return new Promise(async (result) => {
      const data = await _fetchMembers(searchTerm);
      result(data);
    });
  }, []);
  return (
    <>
      <CustomDropdown
        textInputProps={{ placeholder: "搜尋會員" }}
        handleClick={_handleClick}
        controlName="whitelist"
      />
      <div style={{ paddingTop: 8 }}>
        <FieldCache
          name="whitelist"
          render={(whitelist) => (
            <CustomAccordion
              header={`當前加入的白名單 (${whitelist.length})`}
              content={<CurrentWhitelist data={whitelist} />}
            />
          )}
        />
      </div>
    </>
  );
});

/** - 當前加入的白名單 */
const CurrentWhitelist = memo(function CurrentWhitelist({ data = [] }) {
  const { getValues, setValue } = useFormContext();
  const handleDelete = useCallback(
    (item) => {
      const whitelist = getValues("whitelist");
      setValue("whitelist", stringArrayPick(whitelist, item));
    },
    [getValues, setValue]
  );
  return (
    <List style={{ display: "flex", flex: 1, flexWrap: "wrap", gap: 8 }}>
      {data.map((item, index) => (
        <ListItem
          key={"data" + index}
          style={{
            width: 240,
            border: "1px #a2a2a2 solid",
            borderRadius: "5px",
            padding: 0,
            paddingLeft: 16,
          }}
        >
          <ListItemText primary={item.label} />
          <IconButton onClick={() => handleDelete(item)}>
            <DeleteIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
});

/** - 黑名單Dropdown */
const BlacklistDropdown = memo(function WhitelistDropdown() {
  const _handleClick = useCallback(async (searchTerm) => {
    return new Promise(async (result) => {
      const data = await _fetchMembers(searchTerm);
      result(data);
    });
  }, []);
  return (
    <>
      <CustomDropdown
        textInputProps={{ placeholder: "搜尋會員" }}
        handleClick={_handleClick}
        controlName="blocklist"
      />
      <div style={{ paddingTop: 8 }}>
        <FieldCache
          name="blocklist"
          render={(blocklist) => (
            <CustomAccordion
              header={`當前加入的黑名單 (${blocklist.length})`}
              content={<CurrentBlacklist data={blocklist} />}
            />
          )}
        />
      </div>
    </>
  );
});

/** - 當前加入的黑名單 */
const CurrentBlacklist = memo(function CurrentBlacklist({ data = [] }) {
  const { getValues, setValue } = useFormContext();
  const handleDelete = useCallback(
    (item) => {
      const blocklist = getValues("blocklist");
      setValue("blocklist", stringArrayPick(blocklist, item));
    },
    [getValues, setValue]
  );
  return (
    <List style={{ display: "flex", flex: 1, flexWrap: "wrap", gap: 8 }}>
      {data.map((item, index) => (
        <ListItem
          key={"data" + index}
          style={{
            width: 240,
            border: "1px #a2a2a2 solid",
            borderRadius: "5px",
            padding: 0,
            paddingLeft: 16,
          }}
        >
          <ListItemText primary={item.label} />
          <IconButton onClick={() => handleDelete(item)}>
            <DeleteIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
});
