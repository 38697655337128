import React, { useCallback, useEffect, useMemo, useReducer } from "react";
// material
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
// icon
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
// date-fns
import { format, parseISO } from "date-fns";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import {
  InputTitle,
  AutocompleteSelect,
  CustomNumberInput,
} from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
// utils
import emptyArray from "../../utils/emptyArray";
import { enabledList } from "../../utils/localData";

// SECTION apollo
// NOTE 選項列表
const GET_EGGTYPES_TOYS = gql`
  query Query($eventId: Int!) {
    eggTypes(eventId: $eventId, latest: true) {
      # "蛋種"
      contents {
        id
        value: id
        # "名稱"
        label: name
      }
    }
    toys(eventId: $eventId, latest: true) {
      # "玩具"
      contents {
        id
        # "商品"
        product {
          id
          # "名稱"
          name
        }
      }
    }
  }
`;
// NOTE 育成配對設定列表
const GET_RAISING_MATCH_CONFIGS = gql`
  query raisingMatchConfigs($eventId: Int!) {
    raisingMatchConfigs(eventId: $eventId, latest: true) {
      # "總數"
      count
      # "總頁數"
      pageCount
      # "餵食代碼"
      contents {
        id
        # "蛋種"
        eggType {
          id
          # "名稱"
          name
        }
        # "玩具"
        toy {
          id
          # "商品"
          product {
            id
            # "名稱"
            name
          }
        }
        # "機率"
        rate
        # "總數"
        total
        # "庫存"
        stock
        # "啟用"
        enabled
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 育成配對設定
const GET_RAISING_MATCH_CONFIG = gql`
  query raisingMatchConfig($id: Int!) {
    raisingMatchConfig(id: $id) {
      id
      # "蛋種"
      eggType {
        id
        # "名稱"
        name
      }
      # "玩具"
      toy {
        id
        # "商品"
        product {
          id
          # "名稱"
          name
        }
      }
      # "機率"
      rate
      # "總數"
      total
      # "庫存"
      stock
      # "啟用"
      enabled
    }
  }
`;
// NOTE 儲存育成配對設定
const SAVE_RAISING_MATCH_CONFIG = gql`
  mutation saveRaisingMatchConfig(
    $raisingMatchConfigInput: RaisingMatchConfigInput!
  ) {
    saveRaisingMatchConfig(raisingMatchConfigInput: $raisingMatchConfigInput) {
      success
      message
    }
  }
`;
// NOTE 刪除育成配對設定
const DELETE_RAISING_MATCH_CONFIG = gql`
  mutation deleteRaisingMatchConfig($id: Int!) {
    deleteRaisingMatchConfig(id: $id) {
      success
      message
    }
  }
`;
// NOTE 變更育成配對設定
const CHANGE_RAISING_MATCH_CONFIG_ENABLED = gql`
  mutation changeRaisingMatchConfigEnabled($id: Int!) {
    changeRaisingMatchConfigEnabled(id: $id) {
      success
      message
    }
  }
`;
// !SECTION
// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "edit":
      return action.data;
    default:
      return null;
  }
}
// ANCHOR 主要組件
export default function DrawingLotsResultManage({ eventId }) {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  const { control, setValue } = useForm({
    defaultValues: {
      listRefetch: false,
      contentRefetch: false,
    },
  });

  switch (swtichContent?.swtichType) {
    case "add":
      return (
        <DrawingLotsResultForm
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      );
    case "edit":
      return swtichContent?.id ? (
        <DrawingLotsResultForm
          swtichContent={swtichContent}
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      ) : null;
    default:
      return (
        <DrawingLotsResultList
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      );
  }
}
// ANCHOR 列表
function DrawingLotsResultList({
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const listRefetch = useWatch({
    control: formControl,
    name: "listRefetch",
  });
  const { alert, notice } = useAlert();

  const {
    data: raisingMatchConfigsData,
    loading: raisingMatchConfigsLoading,
    refetch,
  } = useQuery(GET_RAISING_MATCH_CONFIGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onCompleted({ raisingMatchConfigs }) {
      if (raisingMatchConfigs) {
        setTimeout(() => {
          formSetValue("listRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (listRefetch) {
      refetch();
    }
  }, [listRefetch, refetch]);

  const [
    deleteRaisingMatchConfigFn,
    { loading: deleteRaisingMatchConfigLoading },
  ] = useMutation(DELETE_RAISING_MATCH_CONFIG, {
    onCompleted({ deleteRaisingMatchConfig: { message } }) {
      if (message) {
        notice(message);
      } else {
        notice("刪除成功");
        refetch();
      }
    },
    onError() {
      return null;
    },
  });

  const _deleteRaisingMatchConfig = useCallback(
    ({ id }) => {
      alert("", `確定要刪除？`, [
        {
          text: "確定",
          onPress: () => {
            deleteRaisingMatchConfigFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, deleteRaisingMatchConfigFn]
  );

  const [
    changeRaisingMatchConfigEnabledFn,
    { loading: changeRaisingMatchConfigEnabledLoading },
  ] = useMutation(CHANGE_RAISING_MATCH_CONFIG_ENABLED, {
    onCompleted({ changeRaisingMatchConfigEnabled: { message } }) {
      if (message) {
        notice(message);
      } else {
        refetch();
      }
    },
    onError() {
      return null;
    },
  });

  const _changeRaisingMatchConfigEnabled = useCallback(
    ({ id, enabled }) => {
      alert("", `確定要${enabled ? "關閉" : "啟用"}？`, [
        {
          text: "確定",
          onPress: () => {
            changeRaisingMatchConfigEnabledFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, changeRaisingMatchConfigEnabledFn]
  );

  if (raisingMatchConfigsLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <LoadingModal
          loading={
            deleteRaisingMatchConfigLoading ||
            changeRaisingMatchConfigEnabledLoading
          }
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              抽選結果數量：
              {raisingMatchConfigsData?.raisingMatchConfigs?.count || 0}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() =>
                swtichContentDispatch({
                  type: "add",
                  data: {
                    swtichType: "add",
                  },
                })
              }
            >
              新增抽選結果
            </Button>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            data={emptyArray(
              raisingMatchConfigsData?.raisingMatchConfigs?.contents
            )}
            header={[
              "蛋種",
              "玩具",
              "機率",
              "總數",
              "剩餘庫存",
              "啟用狀態",
              "建立時間",
              "最後更新時間",
              "操作",
            ]}
            tableWidth={2}
            columns={[
              (item) => item.eggType?.name,
              (item) => item.toy?.product?.name,
              (item) => `${item.rate}%`,
              "total",
              "stock",
              (item) => (item.enabled ? "啟用" : "關閉"),
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.updatedAt &&
                format(parseISO(item.updatedAt), "yyyy/LL/dd HH:mm"),
              (item) => (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={(e) => {
                        e.stopPropagation();
                        _changeRaisingMatchConfigEnabled(item);
                      }}
                    >
                      {item.enabled ? "關閉" : "啟用"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onPress={(e) => {
                        e.stopPropagation();
                        _deleteRaisingMatchConfig(item);
                      }}
                    >
                      刪除
                    </Button>
                  </Grid>
                </Grid>
              ),
            ]}
            onPress={(item) => {
              swtichContentDispatch({
                type: "edit",
                data: {
                  swtichType: "edit",
                  id: item.id,
                },
              });
            }}
          />
        </CardContent>
      </Card>
    );
  }
}
// ANCHOR form
function DrawingLotsResultForm({
  swtichContent,
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const contentRefetch = useWatch({
    control: formControl,
    name: "contentRefetch",
  });
  const form = useForm({
    defaultValues: {
      eggType: "",
      toy: "",
      total: "",
      rate: "",
      enabled: {
        label: "啟用",
        value: true,
      },
    },
  });
  const { reset, setValue } = form;

  useEffect(() => {
    if (eventId) {
      setValue("eventId", eventId);
    }
  }, [eventId, setValue]);

  const { data, loading } = useQuery(GET_EGGTYPES_TOYS, {
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onError() {
      return null;
    },
  });
  const eggTypes = useMemo(() => data?.eggTypes?.contents, [data]);
  const toys = useMemo(
    () =>
      emptyArray(data?.toys?.contents).map((item) => ({
        label: item.product?.name,
        value: item.id,
      })),
    [data]
  );

  const [
    getRaisingMatchConfig,
    { loading: raisingMatchConfigLoading, refetch },
  ] = useLazyQuery(GET_RAISING_MATCH_CONFIG, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted({ raisingMatchConfig }) {
      if (raisingMatchConfig) {
        setTimeout(() => {
          Object.keys(raisingMatchConfig).forEach((item) => {
            if (item !== "__typename") {
              if (item === "eggType") {
                setValue(item, {
                  value: raisingMatchConfig[item].id,
                  label: raisingMatchConfig[item].name,
                });
              } else if (item === "toy") {
                setValue(item, {
                  value: raisingMatchConfig[item]?.id,
                  label: raisingMatchConfig[item]?.product?.name,
                });
              } else if (item === "enabled") {
                setValue(item, {
                  value: raisingMatchConfig[item] ? true : false,
                  label: raisingMatchConfig[item] ? "啟用" : "關閉",
                });
              } else {
                setValue(item, raisingMatchConfig[item]);
              }
            }
          });
          formSetValue("contentRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (contentRefetch && refetch) {
      refetch();
    }
  }, [contentRefetch, refetch]);

  useEffect(() => {
    if (swtichContent?.id) {
      getRaisingMatchConfig({ variables: { id: swtichContent.id } });
    }
  }, [swtichContent, getRaisingMatchConfig]);

  const _goBack = useCallback(() => {
    swtichContentDispatch({
      type: "list",
    });
    reset();
  }, [swtichContentDispatch, reset]);

  if (raisingMatchConfigLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <FormProvider {...form}>
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>{`${
              swtichContent?.id ? "編輯" : "新增"
            }抽選結果`}</Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`eggType`}
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="蛋種" required />
                    <AutocompleteSelect
                      {...field}
                      items={eggTypes}
                      noOptionsText={`找不到蛋種`}
                      loading={loading}
                      loadingText="載入中..."
                      renderOption={(props) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: "12px",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                        >
                          {props.label}
                        </Box>
                      )}
                      onChange={(e, value) => field.onChange(value)}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`toy`}
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="玩具" required />
                    <AutocompleteSelect
                      {...field}
                      items={toys}
                      noOptionsText={`找不到玩具`}
                      loading={loading}
                      loadingText="載入中..."
                      renderOption={(props) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: "12px",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                        >
                          {props.label}
                        </Box>
                      )}
                      onChange={(e, value) => field.onChange(value)}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`rate`}
                rules={{
                  required: "必填欄位",
                  validate: (e) => {
                    if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                      return "只允許 0～100 的整數";
                    }
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="機率" required />
                    <CustomNumberInput
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`total`}
                rules={{
                  required: "必填欄位",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="總數" required />
                    <CustomNumberInput
                      {...field}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "end",
                          paddingY: "10px",
                        },
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`enabled`}
                render={({ field }) => (
                  <div>
                    <InputTitle label="啟用狀態" required />
                    <AutocompleteSelect
                      {...field}
                      items={enabledList}
                      renderOption={(props) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: "12px",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                        >
                          {props.label}
                        </Box>
                      )}
                      onChange={(e, value) => field.onChange(value)}
                      fullWidth
                      disableClearable
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ToolButton _goBack={_goBack} formSetValue={formSetValue} />
        </CardActions>
      </FormProvider>
    </Card>
  );
}
// ANCHOR 按鈕
function ToolButton({ _goBack = () => {}, formSetValue }) {
  const { notice } = useAlert();
  const { handleSubmit } = useFormContext();
  const [saveRaisingMatchConfigFn, { loading: saveRaisingMatchConfigLoading }] =
    useMutation(SAVE_RAISING_MATCH_CONFIG, {
      onCompleted({ saveRaisingMatchConfig: { message } }) {
        if (message) {
          notice(message);
        } else {
          formSetValue("listRefetch", true);
          formSetValue("contentRefetch", true);
          notice("成功");
          _goBack();
        }
      },
      onError() {
        return null;
      },
    });
  const _submit = useCallback(
    ({ eggType, toy, rate, total, enabled, stock, ...otherDatas }) => {
      const raisingMatchConfigInput = {
        ...otherDatas,
        eggTypeId: eggType?.value,
        toyId: toy?.value,
        rate: parseFloat(rate),
        total: parseInt(total, 10),
        enabled: enabled?.value,
      };
      saveRaisingMatchConfigFn({ variables: { raisingMatchConfigInput } });
    },
    [saveRaisingMatchConfigFn]
  );
  const _onCancel = useCallback(() => {
    _goBack();
  }, [_goBack]);
  return (
    <>
      <LoadingModal loading={saveRaisingMatchConfigLoading} />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onPress={handleSubmit(_submit)}
          >
            儲存
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onPress={_onCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
