import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { useForm, FormProvider, useWatch } from "react-hook-form";

// component
import Table from "../component/Table";
import { useAlert } from "../component/Alert";
import PageItem from "../component/PageItem";
import Button from "../component/Button";
import { CardContent } from "../component/Card";
import LoadingModal from "../component/LoadingModal";
import { Select, TextInput } from "../component/Form";
import { excelDownload } from "../component/ExcelDownload";
import { useLocalStorage } from "../component/LocalStorageContext";
import MultipleAutoComplate from "../component/MultipleAutoComplate";
import CreateOrderDialog from "./component/CreateOrderDialog";
// utils
import { orderStatusEnum, orderStatusList } from "../utils/localData";
import emptyArray from "../utils/emptyArray";

// SECTION apollo
// NOTE 活動列表
/** - 活動列表 */
const GET_EVENTS = gql`
  query events($eventTypes: [EventType]) {
    events(eventTypes: $eventTypes, latest: true) {
      # "活動"
      events {
        value: id
        # "名稱"
        label: name
      }
    }
  }
`;
// NOTE 訂單列表
/** - 訂單列表 */
const GET_ORDERS = gql`
  query GetOrders(
    $eventIds: [Int]
    $status: OrderStatus
    $productId: Int
    $international: Boolean
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $hidden: Boolean
    $type: ProductType
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    orders(
      eventIds: $eventIds
      status: $status
      productId: $productId
      international: $international
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      hidden: $hidden
      type: $type
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      orderCount
      orderItemCount
      orders {
        id
        serialNumber
        member {
          id
          fullName
          mobile
        }
        totalPrice
        status
        note
        createdAt
        updatedAt
      }
    }
  }
`;
// NOTE 商品列表
/** - 商品列表 */
const GET_PRODUCTS = gql`
  query products($latest: Boolean) {
    products(latest: $latest) {
      products {
        id
        name
      }
    }
  }
`;
// NOTE 訂單列表-excel用
/** - 訂單列表-excel用 */
const GET_EXCEL_ORDERS = gql`
  query GetOrders(
    $status: OrderStatus
    $productId: Int
    $international: Boolean
    $searchTerm: String
    $startTime: Date
    $endTime: Date
    $hidden: Boolean
    $type: ProductType
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    orders(
      status: $status
      productId: $productId
      international: $international
      searchTerm: $searchTerm
      startTime: $startTime
      endTime: $endTime
      hidden: $hidden
      type: $type
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      orderCount
      orders {
        id
        serialNumber
        member {
          id
          fullName
          mobile
        }
        totalPrice
        status
        note
        createdAt
        updatedAt
      }
    }
  }
`;
// NOTE 隱藏訂單
/** - 隱藏訂單 */
const HIDE_ORDER = gql`
  mutation hideOrder($id: Int!) {
    hideOrder(id: $id) {
      success
      message
    }
  }
`;
// NOTE 批次更改訂單狀態
/** - 批次更改訂單狀態 */
const BECOMEREADY_FORSHIPMENT = gql`
  mutation becomeReadyForShipment($orderId: [Int!]!) {
    becomeReadyForShipment(orderId: $orderId) {
      success
      message
    }
  }
`;
// !SECTION

export default function OrderListPage() {
  const Alert = useAlert();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  const [status, setStatus] = useState(
    Boolean(LocalStorage.getSearchOrderStatus())
      ? LocalStorage.getSearchOrderStatus()
      : "ALL"
  );
  const [productId, setProductId] = useState(
    Boolean(LocalStorage.getSearchOrderProductId())
      ? LocalStorage.getSearchOrderProductId() === "ALL"
        ? LocalStorage.getSearchOrderProductId()
        : JSON.parse(LocalStorage.getSearchOrderProductId())
      : "ALL"
  );
  const [international, setInternational] = useState(
    Boolean(LocalStorage.getSearchOrderInternational())
      ? LocalStorage.getSearchOrderInternational() === "ALL"
        ? LocalStorage.getSearchOrderInternational()
        : JSON.parse(LocalStorage.getSearchOrderInternational())
      : "ALL"
  );
  const [productArray, setProductArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    Boolean(LocalStorage.getSearchOrderSearchTerm())
      ? LocalStorage.getSearchOrderSearchTerm()
      : ""
  );
  const [latest, setLatest] = useState(
    Boolean(LocalStorage.getSearchOrderLatest())
      ? JSON.parse(LocalStorage.getSearchOrderLatest())
      : true
  );
  const [page, setPage] = useState(
    Boolean(LocalStorage.getSearchOrderPage())
      ? JSON.parse(LocalStorage.getSearchOrderPage())
      : 1
  );
  const [pageMax, setPageMax] = useState(null);
  const [pageSize, setPageSize] = useState(
    Boolean(LocalStorage.getSearchOrderPageSize())
      ? JSON.parse(LocalStorage.getSearchOrderPageSize())
      : 10
  );
  const [pageArray, setPageArray] = useState([1]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [type, setType] = useState(
    Boolean(LocalStorage.getSearchOrderType())
      ? LocalStorage.getSearchOrderType()
      : null
  );
  const totalOrderCount = useRef(0);
  const [excelLoading, setExcelLoading] = useState(false);
  const [openChoose, setOpenChoose] = useState(false);
  const [orderId, setOrderId] = useState([]);

  const form = useForm({
    defaultValues: {
      eventIds: Boolean(LocalStorage.getSearchOrderEventIds())
        ? JSON.parse(LocalStorage.getSearchOrderEventIds())
        : [],
    },
  });
  const { control, setValue } = form;
  const createOrderDialogRef = useRef();

  const { data: eventsData, loading: eventsLoading } = useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    variables: {
      eventTypes: ["RAISING_EVENT"],
    },
    onError() {
      return null;
    },
  });

  const eventIds = useWatch({ control, name: "eventIds" });

  const { data, refetch, loading } = useQuery(GET_ORDERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      eventIds:
        eventIds.length === 0 ? undefined : eventIds.map((item) => item.value),
      status: status === "ALL" ? undefined : status,
      productId: productId === "ALL" ? undefined : productId,
      international: international === "ALL" ? undefined : international,
      searchTerm: searchTerm || undefined,
      startTime,
      endTime,
      type,
      latest,
      pageSize,
      page,
    },
    onCompleted({ orders }) {
      setTimeout(() => {
        setPageMax(orders.pageCount);
        totalOrderCount.current = orders.orderCount;
      }, 0);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const { loading: productsLoading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest: true,
    },
    onCompleted({ products }) {
      setTimeout(() => {
        const newProductArray = [
          {
            label: "全部",
            value: "ALL",
          },
        ];
        products.products.forEach((item) => {
          newProductArray.push({
            label: item.name,
            value: item.id,
          });
        });
        setProductArray(newProductArray);
      }, 0);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [getOrders] = useLazyQuery(GET_EXCEL_ORDERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ orders }) => {
      if (orders) {
        setTimeout(async () => {
          const excelData = [];
          const entozh = {
            serialNumber: "訂單編號",
            fullName: "會員姓名",
            mobile: "會員手機",
            totalPrice: "總金額",
            status: "狀態",
            note: "備註",
            createdAt: "建立日期",
            updatedAt: "更新日期",
          };
          orders.orders.forEach((item) => {
            excelData.push({
              serialNumber: item.serialNumber,
              fullName: item.member.fullName,
              mobile: item.member.mobile,
              totalPrice: item.totalPrice,
              status: orderStatusEnum[item.status],
              note: item.note,
              createdAt: moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              updatedAt: moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
            });
          });
          const excelFinish = await excelDownload(
            entozh,
            excelData,
            `${moment().format("YYYY-MM-DD")} 路遙圓創訂單清單`
          );
          if (excelFinish === "Finish") {
            setExcelLoading(false);
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);
  useEffect(() => {
    if (data) {
      if (!data.orders.orders[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  function _gotoOrder(orderId) {
    return history.push(`/console/order/${orderId}`);
  }
  function _delete(value) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        onPress: () => hideOrder({ variables: { id: Number(value.id) } }),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }

  const [hideOrder, { loading: hideOrderLoading }] = useMutation(HIDE_ORDER, {
    onCompleted({ hideOrder }) {
      if (hideOrder.success) {
        refetch();
        Alert.notice("刪除成功！");
        return;
      } else if (hideOrder.message) {
        Alert.notice(hideOrder.message);
        return;
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [becomeReadyForShipment, { loading: becomeReadyForShipmentLoading }] =
    useMutation(BECOMEREADY_FORSHIPMENT, {
      onCompleted({ becomeReadyForShipment }) {
        if (becomeReadyForShipment.success) {
          refetch();
          setOrderId([]);
          setOpenChoose((e) => !e);
          Alert.notice("選擇出貨成功！");
          return;
        } else if (becomeReadyForShipment.message) {
          Alert.notice(becomeReadyForShipment.message);
          return;
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  if (loading || eventsLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid container direction="column">
        <Grid container item>
          <LoadingModal
            loading={
              hideOrderLoading ||
              productsLoading ||
              excelLoading ||
              becomeReadyForShipmentLoading
            }
          />
          <CardContent>
            <Typography>
              <spam>訂單總數：{data && data.orders.orderCount}</spam>
              <spam style={{ marginLeft: "2vh" }}>
                商品數量：{data && data.orders.orderItemCount}
              </spam>
            </Typography>
          </CardContent>
          <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"訂單狀態"}
                  items={orderStatusList}
                  value={status}
                  onChange={(v) => {
                    LocalStorage.clearSearchOrderPage();
                    setStatus(() => {
                      LocalStorage.setSearchOrderStatus(v);
                      setPage(1);
                      return v;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"商品"}
                  items={productArray}
                  value={productId}
                  onChange={(v) => {
                    LocalStorage.clearSearchOrderPage();
                    setProductId(() => {
                      if (typeof v === "number") {
                        LocalStorage.setSearchOrderProductId(`${v}`);
                      } else {
                        LocalStorage.setSearchOrderProductId(v);
                      }
                      setPage(1);
                      return v;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"地點"}
                  items={[
                    {
                      label: "全部",
                      value: "ALL",
                    },
                    {
                      label: "國內",
                      value: false,
                    },
                    {
                      label: "國外",
                      value: true,
                    },
                  ]}
                  value={international}
                  onChange={(e) => {
                    LocalStorage.clearSearchOrderPage();
                    setInternational(() => {
                      if (typeof e === "boolean") {
                        LocalStorage.setSearchOrderInternational(`${e}`);
                      } else {
                        LocalStorage.setSearchOrderInternational(e);
                      }
                      setPage(1);
                      return e;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextInput
                  label={"搜尋"}
                  value={searchTerm}
                  onChange={(value) => {
                    LocalStorage.clearSearchOrderPage();
                    setSearchTerm(() => {
                      LocalStorage.setSearchOrderSearchTerm(value);
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"排序"}
                  items={[
                    { label: "從新到舊", value: true },
                    { label: "從舊到新", value: false },
                  ]}
                  value={latest}
                  onChange={(value) => {
                    LocalStorage.clearSearchOrderPage();
                    setLatest(() => {
                      LocalStorage.setSearchOrderLatest(`${value}`);
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  label={"每頁顯示"}
                  items={[
                    { label: "10", value: 10 },
                    { label: "50", value: 50 },
                    { label: "100", value: 100 },
                  ]}
                  value={pageSize}
                  onChange={(value) => {
                    LocalStorage.clearSearchOrderPage();
                    setPageSize(() => {
                      LocalStorage.setSearchOrderPageSize(`${value}`);
                      setPage(1);
                      return value;
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  ampm={false}
                  label={"開始時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  value={startTime}
                  onChange={(v) => setStartTime(v)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  ampm={false}
                  label={"結束時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  minDate={startTime}
                  value={endTime}
                  onChange={(v) => setEndTime(v)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ width: "100%" }}>
                  <FormProvider {...form}>
                    <MultipleAutoComplate
                      name="eventIds"
                      objectName="育成活動"
                      options={emptyArray(eventsData?.events.events)}
                      selectedOnChange={(_, value) => {
                        LocalStorage.clearSearchOrderPage();
                        LocalStorage.setSearchOrderEventIds(
                          JSON.stringify(value)
                        );
                        setValue("eventIds", value);
                        setPage(1);
                      }}
                      deletedOnChange={(_, value) => {
                        LocalStorage.clearSearchOrderPage();
                        LocalStorage.setSearchOrderEventIds(
                          JSON.stringify(value)
                        );
                        setValue("eventIds", value);
                        setPage(1);
                      }}
                      render={({ selected, search }) => (
                        <Grid item container direction="row" spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {selected}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {search}
                          </Grid>
                        </Grid>
                      )}
                    />
                  </FormProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  minWidth={"100px"}
                  alignItems={"center"}
                >
                  <Checkbox
                    checked={type === "TICKET"}
                    onChange={(e) => {
                      LocalStorage.clearSearchOrderType();
                      if (e.target.checked) {
                        setType(() => {
                          LocalStorage.setSearchOrderType("TICKET");
                          setPage(1);
                          return "TICKET";
                        });
                      } else {
                        setType(() => {
                          setPage(1);
                          return null;
                        });
                      }
                    }}
                  />
                  <Typography color="primary">僅顯示育成訂單</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    if (openChoose) {
                      setOrderId([]);
                    }
                    setOpenChoose((e) => !e);
                  }}
                  fullWidth
                >
                  {!openChoose ? "選擇出貨訂單" : "取消選擇"}
                </Button>
              </Grid>
              {openChoose && Boolean(orderId[0]) && (
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={() =>
                      becomeReadyForShipment({ variables: { orderId } })
                    }
                    fullWidth
                  >
                    確定出貨
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={2}>
                <CreateOrderDialog
                  ref={createOrderDialogRef}
                  onClose={(status) => {
                    if (status === "success") {
                      refetch?.();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => {
                    createOrderDialogRef.current?.open();
                  }}
                  fullWidth
                >
                  建立訂單
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Table
            paper
            data={data && data.orders.orders}
            tableWidth={3}
            columnWidths={[100]}
            header={[
              "",
              "訂單編號",
              "會員姓名",
              "會員手機",
              "總金額",
              "狀態",
              "備註",
              "建立日期",
              "更新日期",
              "操作",
            ]}
            columns={[
              (item) => {
                if (openChoose) {
                  const has = orderId.find((item2) => item2 === item.id);
                  return (
                    <Checkbox
                      checked={Boolean(has)}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (Boolean(has)) {
                          setOrderId((e) => e.filter((i) => i !== item.id));
                        } else {
                          setOrderId((e) => [...e, item.id]);
                        }
                      }}
                    />
                  );
                } else {
                  return null;
                }
              },
              "serialNumber",
              (item) => item.member.fullName,
              (item) => item.member.mobile,
              (item) => `NT$${item.totalPrice}`,
              (item) => orderStatusEnum[item.status],
              "note",
              (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
              (item) => {
                return (
                  <Button
                    variant="outlined"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      _delete(item);
                    }}
                  >
                    刪除
                  </Button>
                );
              },
            ]}
            onPress={(item) => _gotoOrder(item.id)}
          />
          <PageItem
            data={pageArray}
            page={page}
            onChangePage={(e) =>
              setPage(() => {
                LocalStorage.setSearchOrderPage(`${e}`);
                return e;
              })
            }
          />
          <Grid container item justify="center" style={{ margin: "1em" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={totalOrderCount.current === 0}
              onPress={() => {
                setExcelLoading(true);
                getOrders({
                  variables: {
                    status: status === "ALL" ? undefined : status,
                    productId: productId === "ALL" ? undefined : productId,
                    international:
                      international === "ALL" ? undefined : international,
                    searchTerm: searchTerm || undefined,
                    startTime,
                    endTime,
                    type: type === "TICKET" ? "TICKET" : undefined,
                    latest,
                    pageSize:
                      totalOrderCount.current === 0
                        ? 10
                        : totalOrderCount.current,
                    page: 1,
                  },
                });
              }}
            >
              下載訂單清單
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
