import React, { useEffect, useState, Fragment, memo, useCallback } from "react";
import {
  Box,
  Container,
  makeStyles,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import SwipeableViewsComponent from "../component/SwipeableViewsComponent";
import { ReactComponent as LogoIcon } from "../logo.svg";
import Events from "../component/Events";
import useEventTypeStore from "../zustand/useEventTypeStore";

const GET_CAROUSEL = gql`
  query carousel($section: Section!) {
    carousel(section: $section) {
      id
      contents {
        id
        image {
          filename
          mimetype
          encoding
          location
        }
        link
      }
    }
  }
`;

const eventArray = [
  {
    image: require("../images/EventDrawingLots.png").default,
    name: "抽 選",
    type: "drawingLots",
  },
  {
    image: require("../images/EventAuction.png").default,
    name: "社 團 限 定",
    type: "productsOfPasswordRequired",
  },
  {
    image: require("../images/EventRaid.png").default,
    name: "團 體 戰",
    type: "raid",
  },
];

export default function EventChoosePage() {
  const theme = useTheme();
  const { currentEventType, clearEventType } = useEventTypeStore(
    useCallback(
      (state) => ({
        currentEventType: state.eventType,
        clearEventType: state.clearEventType,
      }),
      []
    )
  );
  const [eventType, setEventType] = useState("drawingLots");
  useEffect(() => {
    if (currentEventType) {
      setEventType(currentEventType);
      clearEventType();
    }
  }, [currentEventType, clearEventType]);
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
    cardImage: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        "& .MuiBox-root > .MuiBox-root": {
          backgroundColor: "white !important",
          boxShadow: "#C8C8C8 5px 5px 5px",
          "& .MuiTypography-root": {
            color: "black !important",
          },
        },
        transform: "translate(5px, -5px) scale(1.01)",
        transition: "all .5s",
      },
    },
  });
  const classes = useStyles();
  const isPC = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div style={{ minHeight: 700 }}>
      <Helmet>
        <title>活動商品｜路遙圓創</title>
      </Helmet>

      {/* 輪播 */}
      <CarouselComponent />

      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={isPC ? "column" : "row"}
              justifyContent="space-between"
              padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
            >
              <Box>
                <Box display="flex" flexDirection={isPC ? "column" : "row"}>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    Luyao&nbsp;Design&nbsp;
                  </Typography>
                  <LogoIcon style={{ height: 56, width: 56 }} />
                </Box>
                <Box margin={`${theme.spacing(3)}px 0`}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    活動商品&nbsp;|&nbsp;EVENT&nbsp;PRODUCTS
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  TAIWAN
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  DESIGN TOYS
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {eventArray.map((item) => (
                <Grid
                  key={item.name}
                  item
                  xs={4}
                  onClick={() => {
                    setEventType(item.type);
                  }}
                >
                  <Box className={classes.cardImage}>
                    <Box
                      style={{
                        position: "relative",
                        width: "100%",
                        overflow: "hidden",
                        paddingTop: "100%",
                        borderRadius: "0px",
                        boxShadow: "#C8C8C8 3px 2px 6px",
                        zIndex: 2,
                      }}
                    >
                      <img
                        src={item.image}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </Box>

                    {/* 舊版按鈕 */}
                    {/* <Box
                      style={{
                        position: "absolute",
                        bottom: -40,
                        left: 0,
                        height: 65,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                      }}
                    >
                      <Box
                        id="imageBottomBox"
                        style={{
                          display: "flex",
                          height: "100%",
                          width: "86%",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          padding: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "black",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            marginTop: "25px",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "#000",
                      color: "#fff",
                      margin: "10px 0",
                      borderRadius: 0,
                    }}
                  >
                    {item.name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {eventType && <Events eventType={eventType} />}
    </div>
  );
}

const CarouselComponent = memo(function CarouselComponent() {
  const { pathname } = useLocation();

  const [getCarousel, { data: carouselData, loading: carouselLoading }] =
    useLazyQuery(GET_CAROUSEL, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    });

  useEffect(() => {
    const event = pathname === "/" || pathname.includes("event");
    if (event) {
      getCarousel({ variables: { section: "EVENT_PAGE" } });
    }
  }, [getCarousel, pathname]);

  if (carouselLoading) {
    return (
      <Box
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          paddingTop: "45%",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            transform="none"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Fragment>
        {carouselData?.carousel?.contents &&
          carouselData.carousel.contents[0] && (
            <SwipeableViewsComponent images={carouselData.carousel.contents} />
          )}
      </Fragment>
    );
  }
});
