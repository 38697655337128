import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { useAlert } from "../component/Alert";

const GET_FAQS = gql`
  query faqs {
    faqs {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

export default function FaqPage() {
  const Alert = useAlert();
  const theme = useTheme();

  const { data, loading } = useQuery(GET_FAQS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  return (
    <Container>
      <Box padding={`${theme.spacing(2)}px 0`}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h5" align="center">
              常見問題
            </Typography>
          </Grid>
          {loading ? (
            <Grid container item justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            data &&
            data.faqs.map((item) => (
              <Grid item>
                <FaqItem data={item} />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
}

function FaqItem({ data }) {
  const useStyles = makeStyles((theme) => ({
    accordion: {
      overflow: "hidden",
    },
    summary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiAccordionSummary-expandIcon": {
        color: theme.palette.primary.contrastText,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <Typography>{data.question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {data.answer.split("\n").map((item) => (
            <>
              {item}
              <br />
            </>
          ))}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
