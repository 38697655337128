import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Controller, useForm, useFormContext } from "react-hook-form";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const methods = useFormContext();
  const {
    // NOTE 有control
    name,
    rules,
    // NOTE 沒有control
    value,
    onChange,
    // NOTE 通用
    options = [],
    allDisabled = false,
    getOptionDisabled,
    noOptionsText = "",
    textFieldLabel = "",
    textFieldPlaceholder = "",
  } = props;
  const { control } = useForm({
    defaultValues: {
      [name]: [],
    },
  });
  return (
    <Controller
      control={methods?.control ?? control}
      name={name}
      rules={rules}
      render={() => (
        <Autocomplete
          multiple
          options={options}
          value={value}
          onChange={(_, n, __, k) => {
            onChange?.(k.option, n);
          }}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disabled={allDisabled}
          noOptionsText={noOptionsText}
          getOptionDisabled={getOptionDisabled}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={textFieldLabel}
              placeholder={textFieldPlaceholder}
            />
          )}
          fullWidth
        />
      )}
    />
  );
}
