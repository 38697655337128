import {
  memo,
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  BoxProps,
  Typography,
  makeStyles,
  LinearProgress,
  Divider,
  DividerProps,
  CircularProgress,
  Grid,
  Modal,
  useTheme,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { batch, useDispatch, useSelector } from "react-redux";
import "./index.css";

import {
  setLoadInitialValue,
  setRemainingTime,
  restRaidSetting,
  setStatus,
  setNumberOfPlayer,
  setEndTime,
  setStartTime,
  setCurrentHealthPoint,
  setScore,
  setPirateScore,
  setAdventurerScore,
  setWinningTeam,
  setShowBoard,
  setShowResult,
} from "../../redux/raidMonster";
import { ReactComponent as DefeatSvg } from "./assets/defeat.svg";
import Qrcode from "./components/Qrcode";

//團體戰Query
const GET_EVENT_RAID = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      ... on RaidEvent {
        monster
        maxHealthPoint
        damagePerAttack
        quota
        players {
          id
          score
          player {
            fullName
          }
        }
        startTime
        endTime
        status
        winningTeam
      }
    }
  }
`;

// 團體戰遊戲中內容(全部)
const GET_RAIDBIGSCREEN = gql`
  query raidBigScreen($eventId: Int!) {
    raidBigScreen(eventId: $eventId) {
      # "目前在線人數"
      numberOfPlayer
      # "分數"
      totalScore
      # "海盜分數"
      pirateScore
      # "探險家分數"
      adventurerScore
      # "怪物目前血量"
      currentHealthPoint
    }
  }
`;

const useTextStyles = makeStyles({
  serifText: {
    fontFamily: "Noto Serif TC",
  },
});
// ANCHOR 主要組件
export default function GameUI() {
  return (
    <>
      <FetchGameInitialValue />
      <GameLayout>
        <GameContentSwitch />
        <ResultModal />
        <InformationBoard />
      </GameLayout>
    </>
  );
}
// ANCHOR 遊戲外框
function GameLayout({ children }) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    outside: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    bg: {
      height: "100vh",
      width: "100%",
      backgroundColor: "rgba(0,0,0)",
      overflowY: "hidden",
    },
    total: {
      position: "absolute",
      alignSelf: "flex-end",
      color: "white",
    },
    children: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "white",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(4),
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    return () => dispatch(restRaidSetting());
  }, []);
  return (
    <Box className={`${classes.outside}`}>
      <Box className={classes.bg}>
        <GameBackgroundAnimation />
      </Box>
      <Box className={classes.children}>
        <Box className={classes.total}>
          <TotalPeople />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
// ANCHOR 遊戲背景影片
function GameBackgroundAnimation() {
  const dispatch = useDispatch();
  const timingRef = useRef(null);
  const minute = 60;
  const show = useSelector((state) => state.raidMonster.showResult);
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const endTime = useSelector((state) => state.raidMonster.endTime);

  const [endTimeSubtract, setEndTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (endTime) {
        setEndTimeSubtract(
          moment(endTime)
            .tz("Asia/Taipei")
            .diff(moment().tz("Asia/Taipei"), "seconds")
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [endTime]);

  function _showResult() {
    dispatch(setShowResult(true));
  }
  const winningTeam = useSelector((state) => state.raidMonster.winningTeam);

  const gameResult = useMemo(() => {
    if (status === "VICTORY") {
      return "VICTORY";
    } else if (status === "DEFEAT") {
      return "DEFEAT";
    }
  }, [status]);

  const switchArea = useCallback(() => {
    if (loaded) {
      if (gameResult === "VICTORY") {
        if (show) {
          return <MonsterVideo url={"/video/PVPRaid/victoryLOOP.mp4"} />;
        } else {
          return (
            <MonsterVideo
              url={"/video/PVPRaid/victory.mp4"}
              minute={minute}
              endTimeSubtract={endTimeSubtract}
              onEnded={_showResult}
            />
          );
        }
      }
      if (gameResult === "DEFEAT") {
        if (show) {
          return <MonsterVideo url={"/video/PVPRaid/defeatLOOP.mp4"} />;
        } else {
          return (
            <MonsterVideo
              url={"/video/PVPRaid/defeat.mp4"}
              minute={minute}
              endTimeSubtract={endTimeSubtract}
              onEnded={_showResult}
            />
          );
        }
      }
      return <div />;
    } else {
      return <div />;
    }
  }, [loaded, gameResult, show, winningTeam]);

  if (endTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  } else if (endTimeSubtract <= -5) {
    return switchArea();
  } else if (endTimeSubtract <= 30 && endTimeSubtract > -5) {
    dispatch(setShowBoard(true));
    return (
      <MonsterVideo
        url={"/video/PVPRaid/battle.mp4"}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  } else if (endTimeSubtract <= 40 && endTimeSubtract > 30) {
    //NOTE 倒數前10秒
    dispatch(setShowBoard(true));
    return (
      <MonsterVideo
        url={"/video/PVPRaid/countdown.mp4"}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  } else if (endTimeSubtract - 30 <= minute * 10 && endTimeSubtract > 40) {
    // NOTE 開始前10分鐘
    return (
      <MonsterVideo
        url={"/video/PVPRaid/10minwait.mp4"}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  } else {
    return (
      <Typography variant="h5" color="white">
        離開始時間還很久！
      </Typography>
    );
  }
}
// ANCHOR 怪物展示
/**
 * @typedef MonsterVideoProps
 * @property {string} url
 * @property {string} minute
 * @property {string} endTimeSubtract
 * @property {() => void} onEnded
 * @param {MonsterVideoProps} param0
 */
function MonsterVideo({ url, minute, endTimeSubtract, onEnded }) {
  useEffect(() => {
    function videoPlayer() {
      document.getElementById("video").muted = false;
    }
    window.addEventListener("click", videoPlayer);
    return () => window.removeEventListener("click", videoPlayer);
  }, []);

  useEffect(() => {
    function audioPlayer() {
      if (endTimeSubtract <= minute * 10 && endTimeSubtract > 40) {
        document.getElementById("audio").muted = false;
      }
    }
    window.addEventListener("click", audioPlayer);
    return () => window.removeEventListener("click", audioPlayer);
  }, [endTimeSubtract, minute]);

  const hasBackgroundAudio = useMemo(() => {
    if (endTimeSubtract <= minute * 10 && endTimeSubtract > 40) {
      return true;
    }
    return false;
  }, [endTimeSubtract, minute]);

  return (
    <>
      {hasBackgroundAudio && (
        <audio
          id="audio"
          autoPlay
          src="/audio/10minwait_bg.mp3"
          muted
          playsinline
        />
      )}
      <video
        id="video"
        autoPlay
        loop={!Boolean(onEnded)}
        width={"100%"}
        height={"100%"}
        src={url}
        muted
        playsinline
        onEnded={onEnded}
      />
    </>
  );
}
// ANCHOR 遊戲內容切換
const GameContentSwitch = memo(() => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const adventurerScore = useSelector(
    (state) => state.raidMonster.adventurerScore
  );

  const endTime = useSelector((state) => state.raidMonster.endTime);
  const timingRef = useRef(null);
  const [endTimeSubtract, setEndTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (endTime) {
        setEndTimeSubtract(
          moment(endTime)
            .tz("Asia/Taipei")
            .diff(moment().tz("Asia/Taipei"), "seconds")
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [endTime]);

  if (loaded) {
    if (typeof status === "undefined") {
      return <Typography variant="h5">找不到團體戰！</Typography>;
    } else {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignSelf="flex-start"
          height="100vh"
          width={360}
        >
          <Box
            style={{
              marginTop: 20,
              position: "relative",
              width: "57%",
              overflow: "hidden",
              paddingTop: "25%",
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              {(!status || status === "READY_TO_START") &&
              (!endTimeSubtract || endTimeSubtract > 29) ? (
                <img
                  src={require("./assets/adventurer_logo.png").default}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                  }}
                />
              ) : (
                <Typography
                  align="center"
                  style={{
                    lineHeight: 1,
                    fontWeight: "bold",
                    fontSize: "2.5rem",
                  }}
                >
                  冒險家分數
                  <Typography
                    align="center"
                    style={{
                      lineHeight: 1,
                      fontWeight: "bold",
                      letterSpacing: "5px",
                      fontSize: "2.5rem",
                    }}
                  >
                    {adventurerScore}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            {(() => {
              switch (status) {
                case "READY_TO_START":
                  // 開始前
                  dispatch(setShowBoard(false));
                  return <PrepareScenes />;
                default:
                  dispatch(setShowBoard(true));
                  return null;
              }
            })()}
          </Box>
        </Box>
      );
    }
  } else {
    return <CircularProgress color="inherit" />;
  }
});
// ANCHOR 開始前畫面
function PrepareScenes() {
  const dispatch = useDispatch();
  const timingRef = useRef(null);
  const minute = 60;
  const startTime = useSelector((state) => state.raidMonster.startTime);
  const [startTimeSubtract, setStartTimeSubtract] = useState(null);
  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (startTime) {
        setStartTimeSubtract(
          Math.max(
            moment(startTime)
              .tz("Asia/Taipei")
              .diff(moment().tz("Asia/Taipei"), "seconds"),
            0
          )
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [startTime]);
  if (startTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  } else if (startTimeSubtract <= 10 && startTimeSubtract > -1) {
    //NOTE 倒數前10秒
    dispatch(setShowBoard(true));
    return <PrepareText />;
  } else if (startTimeSubtract >= minute * 10) {
    // NOTE 時間大於開始前10分鐘
    return <Typography variant="h5">團體戰尚未開始！</Typography>;
  } else {
    return <BeforeRaid />;
  }
}
// ANCHOR 團體賽開始前
function BeforeRaid() {
  const textClasses = useTextStyles();
  const startTime = useSelector((state) => state.raidMonster.startTime);
  return (
    <Box padding={1}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            className={textClasses.serifText}
          >
            遊戲開始
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SeparateBox width="100%">
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {moment(startTime).tz("Asia/Taipei").format("HH：mm")}
            </Typography>
          </SeparateBox>
        </Grid>
        <Grid item container justifyContent="center">
          <Qrcode size={168} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={textClasses.serifText}>
            抽選團體戰活動即將開跑
          </Typography>
          <Typography align="center" className={textClasses.serifText}>
            請以打倒遊戲角色為目標
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
// ANCHOR 即將開始文字
const PrepareText = memo(() => {
  const useStyles = makeStyles((theme) => ({
    prepareText: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      fontWeight: "bold",
      animation: `$prepare 250ms ${theme.transitions.duration.standard}`,
    },
    "@keyframes prepare": {
      "0%": { color: "white" },
      "100%": { color: "red" },
    },
  }));
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.prepareText}>
      對戰即將開始
    </Typography>
  );
});
// ANCHOR 參加人數
const TotalPeople = memo(() => {
  const numberOfPlayer = useSelector(
    (state) => state.raidMonster.numberOfPlayer
  );
  const status = useSelector((state) => state.raidMonster.status);
  const labelArray = String(numberOfPlayer).padStart(4, "0").split("");
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const pirateScore = useSelector((state) => state.raidMonster.pirateScore);

  const endTime = useSelector((state) => state.raidMonster.endTime);
  const timingRef = useRef(null);
  const [endTimeSubtract, setEndTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (endTime) {
        setEndTimeSubtract(
          moment(endTime)
            .tz("Asia/Taipei")
            .diff(moment().tz("Asia/Taipei"), "seconds")
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [endTime]);

  if (loaded && typeof status !== "undefined") {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="flex-start"
        width={360}
        style={{ height: "90vh" }}
      >
        <Box
          style={{
            marginTop: 20,
            position: "relative",
            width: "50%",
            overflow: "hidden",
            paddingTop: "25%",
            alignSelf: "flex-end",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            {(!status || status === "READY_TO_START") &&
            (!endTimeSubtract || endTimeSubtract > 29) ? (
              <img
                src={require("./assets/pirate_logo.png").default}
                style={{ width: "100%", objectFit: "contain", height: "100%" }}
              />
            ) : (
              <Typography
                align="center"
                style={{
                  lineHeight: 1,
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                海盜分數
                <Typography
                  align="center"
                  style={{
                    lineHeight: 1,
                    fontWeight: "bold",
                    letterSpacing: "5px",
                    fontSize: "2.5rem",
                  }}
                >
                  {pirateScore}
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>
        {status === "READY_TO_START" && endTimeSubtract > 40 && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flex={1}
            direction="column"
            style={{ height: "100%" }}
          >
            <Grid style={{ height: 64 }}>
              <Typography
                align="center"
                style={{
                  lineHeight: 1,
                  fontFamily: "Noto Serif TC",
                  fontSize: "2.5rem",
                }}
              >
                累計參戰人數
              </Typography>
            </Grid>
            <Grid item>
              <Box width="100%">
                <Grid container spacing={1} justifyContent="space-between">
                  {labelArray.map((i, index) => (
                    <Grid item key={`${i}-${index}`}>
                      <Typography
                        style={{
                          lineHeight: 1,
                          fontSize: "6rem",
                          display: "inline-block",
                          borderBottom: "1px solid white",
                          paddingBottom: "2px",
                        }}
                      >
                        {i}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <img
                src={require("./assets/LUYAODESIGNBATTLE.png").default}
                style={{ width: "100%", objectFit: "contain", height: "60%" }}
              />
            </Grid>
            <Grid style={{ height: 64 }}>
              <Typography
                align="center"
                style={{
                  lineHeight: 1,
                  fontFamily: "Noto Serif TC",
                  fontSize: "1rem",
                }}
              >
                歡迎分享QRcode邀請朋友來參賽！
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
  return <></>;
});
// ANCHOR 結算浮層
const ResultModal = memo(() => {
  const show = useSelector((state) => state.raidMonster.showResult);
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const endTime = useSelector((state) => state.raidMonster.endTime);
  const expired =
    moment(endTime)
      .tz("Asia/Taipei")
      .diff(moment().tz("Asia/Taipei"), "seconds") <= 0;
  function switchArea() {
    if (loaded && show) {
      switch (status) {
        case "VICTORY":
          return <ResultContent result="VICTORY" />;
        case "DEFEAT":
          return <ResultContent result="DEFEAT" />;
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }
  if (loaded && typeof status === "string" && expired) {
    return (
      <Modal open={show && (status === "VICTORY" || status === "DEFEAT")}>
        {switchArea()}
      </Modal>
    );
  } else {
    return null;
  }
});
// ANCHOR 結果畫面
function ResultContent({ result = "DEFEAT" }) {
  const winningTeam = useSelector((state) => state.raidMonster.winningTeam);
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(1),
    },
    content: {
      color: "white",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  const textClasses = useTextStyles();
  const defeatText = [
    "｜請再接再厲！｜",
    "感謝所有馬力參與這次的團體戰，可惜沒有挑戰成功，下次再戰吧！",
  ];
  const victoryText = [
    "",
    "稍後請至\n「會員中心」>「待付款訂單」\n查看抽選結果",
    "中選者商品將直接匯入，務必於時間內完成繳款，未中選者一律不另行通知。",
  ];
  return (
    <Box className={classes.content}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "35%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {result === "DEFEAT" ? (
          <DefeatSvg width={128} height={128} />
        ) : (
          winningTeam && (
            <>
              <img
                src={
                  winningTeam === "ADVENTURER"
                    ? require("./assets/adventurer_logo.png").default
                    : require("./assets/pirate_logo.png").default
                }
                style={{
                  width: 128,
                  objectFit: "contain",
                  height: 128,
                }}
              />
              <Typography
                align="center"
                variant="h3"
                className={textClasses.serifText}
              >
                {winningTeam === "ADVENTURER" ? "冒險家勝利" : "海盜勝利"}
              </Typography>
            </>
          )
        )}
        {(result === "DEFEAT" ? defeatText : victoryText).map((i, index) => (
          <Fragment key={index}>
            {index === 0 ? (
              <>
                <ShadowBox>
                  <Typography
                    variant="h2"
                    style={{
                      color: result === "DEFEAT" ? "#C91733" : "white",
                    }}
                    className={textClasses.serifText}
                  >
                    {result}
                  </Typography>
                </ShadowBox>
                <Box marginBottom={3}>
                  <Typography
                    align="center"
                    variant="h4"
                    className={textClasses.serifText}
                  >
                    {i}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography
                align="center"
                variant="body2"
                className={textClasses.serifText}
              >
                {i}
              </Typography>
            )}
          </Fragment>
        ))}
        <Rankingboard />
      </Box>
    </Box>
  );
}
// ANCHOR 排行榜
function Rankingboard() {
  const useStyles = makeStyles((theme) => ({
    first: {
      ...theme.typography.h3,
      fontWeight: "bold",
      color: "#F5CE2F",
    },
    other: { ...theme.typography.h4 },
  }));
  const classes = useStyles();
  const [rankList, setRankList] = useState(undefined);
  const params = useParams();
  const id = Number(params?.id);
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          setRankList(
            event.players
              .map((item) => ({
                fullName: item.player.fullName
                  .split("")
                  .map((n, i) => (i === 1 ? "◯" : n))
                  .join(""),
                score: item.score,
              }))
              .sort((a, b) => b.score - a.score)
              .filter((_, index) => index < 5)
          );
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
    }
  }, [id]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      color="white"
    >
      <Typography variant="h2" style={{ fontWeight: "bold" }}>
        RANKING
      </Typography>
      {Array.isArray(rankList) ? (
        rankList.length > 0 ? (
          <>
            <Typography className={classes.first}>
              1.{rankList[0].fullName}---{rankList[0].score}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {rankList.map((item, index) => {
                if (index === 0) {
                  return null;
                }
                return (
                  <Typography className={classes.other}>
                    {index + 1}.{item.fullName}---{item.score}
                  </Typography>
                );
              })}
            </Box>
          </>
        ) : null
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
}
// ANCHOR 資訊板
function InformationBoard() {
  const theme = useTheme();
  const showBoard = useSelector((state) => state.raidMonster.showBoard);
  if (showBoard) {
    return (
      <Box
        position="fixed"
        bottom={theme.spacing(2)}
        padding={1}
        width="100%"
        maxWidth={1200}
      >
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid container item>
            <StatusBoard />
          </Grid>
          <Grid container item>
            <ProgressBar />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return null;
  }
}
// ANCHOR 狀態板
function StatusBoard() {
  const currentHealthPoint = useSelector(
    (state) => state.raidMonster.currentHealthPoint
  );
  const score = useSelector((state) => state.raidMonster.score);
  const remainingTime = useSelector((state) => state.raidMonster.remainingTime);
  const boardData = [
    { label: "HP", value: currentHealthPoint },
    { label: "TIME", value: `${remainingTime}s` },
    { label: "SCORE", value: String(score).padStart(5, "0") },
  ];
  return (
    <Box display="flex" width="100%">
      {boardData.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Separate orientation="vertical" />}
          <Box flex={1} flexDirection="column">
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {item.label}
            </Typography>
            <Separate variant="middle" />
            <Typography variant="h4" align="center">
              {item.value}
            </Typography>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
}
// ANCHOR 進度條
function ProgressBar() {
  const maxHealthPoint = useSelector(
    (state) => state.raidMonster.maxHealthPoint
  );
  const currentHealthPoint = useSelector(
    (state) => state.raidMonster.currentHealthPoint
  );
  const useStyles = makeStyles({
    bar: {
      width: "100%",
      height: 12,
      border: `1px solid white`,
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "white",
      },
    },
  });
  const classes = useStyles();
  return (
    <LinearProgress
      className={classes.bar}
      variant="determinate"
      color="primary"
      value={creatProgress(currentHealthPoint, maxHealthPoint)}
    />
  );
}
// ANCHOR 影子盒
function ShadowBox({ children }) {
  const useStyles = makeStyles({
    shadow: {
      position: "absolute",
      bottom: 4,
      right: 0,
      left: 0,
      background: `linear-gradient(to right, transparent, rgba(255, 255, 255), transparent)`,
      filter: `blur(1px)`,
      height: 4,
      borderRadius: "50%",
    },
  });
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.shadow} />
      {children}
    </Box>
  );
}
// ANCHOR 分隔線
/**
 * @param {Omit<DividerProps, 'className' | 'classes' | 'style' | 'flexItem'>} props
 */
function Separate(props) {
  const useStyles = makeStyles({
    hr: {
      paddingTop: 1,
      background: `linear-gradient(to ${
        props.orientation === "vertical" ? "bottom" : "right"
      }, transparent, white, transparent)`,
      filter: `blur(0.5px)`,
    },
  });
  const classes = useStyles();
  return <Divider className={classes.hr} flexItem {...props} />;
}
// ANCHOR 分隔線箱子
/**
 * @param {BoxProps} param0
 */
function SeparateBox({ children, ...props }) {
  return (
    <Box {...props}>
      <Separate />
      <Box
        marginY={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
      <Separate />
    </Box>
  );
}

// SECTION 資料處理相關
// ANCHOR 獲得遊戲內容
function FetchGameInitialValue() {
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  // NOTE 只抓第一次當預設值
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          dispatch(
            setLoadInitialValue({
              status: event.status,
              maxHealthPoint: event.maxHealthPoint,
              numberOfPlayer: event.players ? event.players.length : 0,
              damagePerAttack: event.damagePerAttack,
              startTime: event.startTime,
              endTime: event.endTime,
              remainingTime: convertRemaining(event.endTime, event.startTime),
            })
          );
        }, 0);
      }
    },
    onError() {
      dispatch(
        setLoadInitialValue({
          status: undefined,
        })
      );
    },
  });
  // ANCHOR 取得怪物資料
  const [getRaidBigScreen] = useLazyQuery(GET_RAIDBIGSCREEN, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ raidBigScreen }) {
      if (raidBigScreen) {
        setTimeout(() => {
          batch(() => {
            dispatch(setNumberOfPlayer(raidBigScreen.numberOfPlayer));
            dispatch(setCurrentHealthPoint(raidBigScreen.currentHealthPoint));
            dispatch(setScore(raidBigScreen.totalScore));
            dispatch(setPirateScore(raidBigScreen.pirateScore ?? 0));
            dispatch(setAdventurerScore(raidBigScreen.adventurerScore ?? 0));
          });
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
      getRaidBigScreen({ variables: { eventId: Number(id) } });
    }
  }, [id]);
  useEventEffect();
  useRemainingTimeEffect();
  return null;
}
// ANCHOR 建立進度條
/**
 * @param {number} molecular 分子
 * @param {number} denominator 分母
 * @returns {number}
 */
function creatProgress(molecular, denominator = 100) {
  return Math.floor((molecular / denominator) * 100);
}
// ANCHOR 自動刷新遊戲資料
function useEventEffect() {
  const params = useParams();
  const id = Number(params?.id);
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          batch(() => {
            dispatch(setStatus(event.status));
            dispatch(setStartTime(event.startTime));
            dispatch(setEndTime(event.endTime));
            dispatch(setWinningTeam(event.winningTeam));
          });
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (loaded) {
      if (id) {
        getEvent({ variables: { id } });
      }
    }
  }, [id, loaded]);
}
// ANCHOR 取得遊戲剩餘時間
function useRemainingTimeEffect() {
  const dispatch = useDispatch();
  const endTime = useSelector((state) => state.raidMonster.endTime);
  const status = useSelector((state) => state.raidMonster.status);
  const timerRef = useRef(null);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (endTime && status === "DURING_THE_GAME") {
        dispatch(setRemainingTime(convertRemaining(endTime, undefined)));
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [endTime, status]);
}
// ANCHOR 換算遊戲剩餘時間
function convertRemaining(endTime, startTime) {
  return Math.max(
    Math.min(
      moment(endTime)
        .tz("Asia/Taipei")
        .diff(moment(startTime).tz("Asia/Taipei"), "seconds"),
      30
    ),
    0
  );
}
// !SECTION
