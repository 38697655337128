import React, { createContext, useContext, useReducer } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import "./Wrap.css";

const AlertContext = createContext();
const defaultAlertSitting = {
  open: false,
  title: "通知",
  content: "",
  buttons: [{ text: "確認", onPress: () => {}, type: "ok" }],
};

export function useAlert() {
  const { alertItemDispatch } = useContext(AlertContext);
  /**
   * @param {string} title 標題
   * @param {string} content 內容
   * @param {[{text:string, onPress:() => void, type:'ok'|'cancel'|'close'}]} buttons 按鈕
   */
  function alert(title, content, buttons) {
    alertItemDispatch({
      type: "開啟",
      title,
      content,
      buttons,
    });
  }
  function notice(content, onPress, text = "確認") {
    alertItemDispatch({
      type: "開啟",
      title: "通知",
      content,
      buttons: [{ text, onPress, type: "ok" }],
    });
  }
  return { alert, notice };
}
let temporary = {
  title: defaultAlertSitting["title"],
  content: defaultAlertSitting["content"],
  buttons: defaultAlertSitting["buttons"],
}; //暫存上一筆資料，使畫面渲染符合視覺
function _alertItemDispatch(state, action) {
  const alertSetting = {
    title: action.title || defaultAlertSitting["title"],
    content: action.content || defaultAlertSitting["content"],
    buttons: action.buttons || defaultAlertSitting["buttons"],
  };
  switch (action.type) {
    case "開啟":
      temporary = {
        title: action.title,
        content: action.content,
        buttons: action.buttons,
      };
      return { open: true, ...alertSetting };
    case "關閉": //預設關閉
    default:
      return { open: false, ...alertSetting };
  }
}

export function AlertProvider({ children }) {
  const [alertItem, alertItemDispatch] = useReducer(
    _alertItemDispatch,
    defaultAlertSitting
  );
  function _alertClose() {
    alertItemDispatch({ type: "關閉" });
  }
  const buttonType = (type) => {
    switch (type) {
      case "ok":
        return "contained";
      case "cancel":
        return "outlined";
      case "close": //預設text
      default:
        return "text";
    }
  };
  return (
    <AlertContext.Provider value={{ alertItemDispatch }}>
      {children}
      <Dialog open={alertItem.open} fullWidth disableScrollLock>
        <DialogTitle>{temporary.title || alertItem.title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="msg-wrapper">
            {temporary.content || alertItem.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(temporary.buttons || alertItem.buttons).map((item, index) => (
            <Button
              key={`${index}`}
              color="primary"
              variant={buttonType(item.type)}
              onClick={() => {
                item.onPress && item.onPress();
                _alertClose();
              }}
              disableElevation
            >
              {item.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
}
