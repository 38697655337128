import React from "react";
// material
import { CircularProgress, Box, Typography } from "@material-ui/core";

function CircularProgressWithLabel({ value }) {
  return (
    <Box style={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic({ progress = 0 }) {
  return <CircularProgressWithLabel value={progress} />;
}
