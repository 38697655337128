import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  DialogActions,
  Button,
  FormHelperText,
  makeStyles,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import { Controller, useForm, useWatch } from "react-hook-form";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { deepEqual } from "fast-equals";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import LoadingModal from "../../../component/LoadingModal";
import {
  AutocompleteFocusSelect,
  CustomTextField,
} from "../../../component/Form";
import AspectRatioBox from "../../../component/AspectRatioBox";

// SECTION apollo
// NOTE 會員列表
/** - 會員列表 */
const GET_MEMBERS = gql`
  query members($searchTerm: String, $hidden: Boolean) {
    members(searchTerm: $searchTerm, hidden: $hidden) {
      members {
        id
        fullName
      }
    }
  }
`;
// NOTE 移除會員路遙大頭貼使用權限（從會員解除）
/** - 移除會員路遙大頭貼使用權限（從會員解除） */
const UNASSIGN_LUYAO_PROFILE_PICTURE_TO_MEMBERS_FROM_MEMBER = gql`
  mutation unassignLuyaoProfilePictureToMembersFromMember(
    $memberId: Int!
    $pictureIds: [Int!]!
  ) {
    unassignLuyaoProfilePictureToMembersFromMember(
      memberId: $memberId
      pictureIds: $pictureIds
    ) {
      success
      message
    }
  }
`;
// NOTE 路遙大頭貼列表
/** - 路遙大頭貼列表 */
const GET_LUYAO_PROFILE_PICTURES_FROM_MEMBERS = gql`
  query luyaoProfilePictures($memberId: Int) {
    luyaoProfilePictures(memberId: $memberId) {
      contents {
        id
        name
        file {
          location
        }
      }
    }
  }
`;
// !SECTION

const useStyles = makeStyles({
  memberIdsBox: {
    display: "flex",
    flexDirection: "row",
    minHeight: 40,
    border: "1px solid #C0C0C0",
    borderRadius: "4px",
    alignItems: "center",
    flexWrap: "wrap",
    "& > div": {
      padding: 5,
    },
  },
  text: {
    flexGrow: 1,
  },
});
function UnassignLuyaoProfilePictureToMembersFromMemberDialog(
  { onClose = () => {} },
  ref
) {
  const Alert = useAlert();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      memberObject: undefined,
      pictureIds: [],
    },
  });

  const [openSelect, setOpenSelect] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermObject, setSearchTermObject] = useState({});

  const memberObject = useWatch({ control, name: "memberObject" });

  const [hadIds, setHadIds] = useState([]);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    }),
    []
  );
  const handleRef = useRef({
    onClose,
  });
  useEffect(() => {
    handleRef.current.onClose = onClose;
  }, [onClose]);

  const _onClose = useCallback(
    (value) => {
      reset({
        memberObject: undefined,
        pictureIds: [],
      });
      setOpenSelect(false);
      setMembers([]);
      setSearchTerm("");
      setSearchTermObject({});
      setHadIds([]);
      handleRef.current.onClose(value);
    },
    [reset]
  );

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    setSearchTermObject({ label: newInputValue, value: "null" });
  };

  const [getMembers, { loading: membersLoading }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ members }) {
      if (members) {
        setTimeout(() => {
          const newMembers = [];
          for (let i = 0; i < members.members.length; i++) {
            newMembers.push({
              label: members.members[i].fullName,
              value: members.members[i].id,
            });
          }
          setOpenSelect(() => {
            setMembers(newMembers);
            return true;
          });
        }, 0);
      }
    },
  });

  const [
    unassignLuyaoProfilePictureToMembersFromMemberFn,
    { loading: unassignLuyaoProfilePictureToMembersFromMemberLoading },
  ] = useMutation(UNASSIGN_LUYAO_PROFILE_PICTURE_TO_MEMBERS_FROM_MEMBER, {
    onCompleted({ unassignLuyaoProfilePictureToMembersFromMember }) {
      if (unassignLuyaoProfilePictureToMembersFromMember.success) {
        return Alert.alert("", "移除成功！", [
          {
            text: "確定",
            onPress: _onClose,
            type: "ok",
          },
        ]);
      } else {
        return Alert.alert("", "移除失敗，請重新嘗試！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const _unassignLuyaoProfilePictureToMembersFromMember = useCallback(
    ({ memberObject, pictureIds }) => {
      unassignLuyaoProfilePictureToMembersFromMemberFn({
        variables: { memberId: memberObject.value, pictureIds },
      });
    },
    [unassignLuyaoProfilePictureToMembersFromMemberFn]
  );

  const [getLuyaoProfilePictures, { loading: luyaoProfilePicturesLoading }] =
    useLazyQuery(GET_LUYAO_PROFILE_PICTURES_FROM_MEMBERS, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ luyaoProfilePictures }) {
        if (luyaoProfilePictures) {
          setTimeout(() => {
            const newLuyaoProfilePictures = [];
            luyaoProfilePictures.contents.forEach((item) => {
              newLuyaoProfilePictures.push({
                id: item.id,
                label: item.name,
                location: item.file.location,
              });
            });
            setHadIds(newLuyaoProfilePictures);
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  useEffect(() => {
    if (Boolean(memberObject)) {
      getLuyaoProfilePictures({ variables: { memberId: memberObject.value } });
    }
  }, [memberObject, getLuyaoProfilePictures]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingModal
        loading={unassignLuyaoProfilePictureToMembersFromMemberLoading}
      />
      <DialogTitle>取消大頭貼分配</DialogTitle>
      <DialogContent
        style={{
          height: "280px",
          display: "flex",
          justifyContent: "center",
          overflowY: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="memberObject"
              rules={{
                required: "必填欄位",
              }}
              render={({
                field: { value: memberObject, onChange },
                fieldState: { error },
              }) => (
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <InputLabel style={error && { color: "red" }}>
                      會員
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      value={memberObject ? memberObject.label : ""}
                      error={error}
                      helperText={error?.message}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid container item>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <AutocompleteFocusSelect
                          open={openSelect}
                          label={"選擇會員"}
                          items={members}
                          onInputChange={handleInputChange}
                          loading={membersLoading}
                          value={
                            searchTermObject.value === "null"
                              ? searchTermObject
                              : null
                          }
                          onChange={(e, value) => {
                            onChange(value);
                            setSearchTerm(null);
                            setSearchTermObject({});
                            setOpenSelect(false);
                          }}
                          renderOption={(option) => {
                            return (
                              <React.Fragment>
                                <div className={classes.text}>
                                  {option.label}
                                </div>
                              </React.Fragment>
                            );
                          }}
                          fullWidth
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (openSelect) {
                              setSearchTerm(null);
                              setSearchTermObject({});
                              setOpenSelect(false);
                            } else {
                              getMembers({
                                variables: { searchTerm },
                              });
                            }
                          }}
                          disabled={!Boolean(searchTerm) || membersLoading}
                        >
                          {openSelect ? "關閉" : "搜尋"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="pictureIds"
              rules={{
                required: "必勾欄位",
                validate: (e) => {
                  if (e && !e[0]) {
                    return "必勾欄位";
                  }
                },
              }}
              render={({
                field: { value: pictureIds, onChange },
                fieldState: { error },
              }) => (
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <InputLabel style={error && { color: "red" }}>
                      會員擁有的大頭貼(打勾為想要移除的選項)
                    </InputLabel>
                  </Grid>
                  {luyaoProfilePicturesLoading ? (
                    <Grid container justify="center">
                      <CircularProgress color="secondary" />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      className={classes.memberIdsBox}
                      style={error && { borderColor: "red" }}
                    >
                      {hadIds.map((item) => {
                        const had =
                          pictureIds.findIndex((item2) => item2 === item.id) !==
                          -1;
                        return (
                          <Grid key={item.label} item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  <Checkbox
                                    checked={had}
                                    onChange={() => {
                                      if (had) {
                                        onChange((e) =>
                                          e.filter((i) => i !== item.id)
                                        );
                                      } else {
                                        onChange([...pictureIds, item.id]);
                                      }
                                    }}
                                  />
                                  <AspectRatioBox width={128} w={16} h={9}>
                                    <img
                                      src={item.location}
                                      height="100%"
                                      alt=""
                                    />
                                  </AspectRatioBox>
                                </>
                              }
                              label={item.label}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  <Grid item>
                    <FormHelperText style={error && { color: "red" }}>
                      &ensp;&ensp;{error && error.message}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          {!luyaoProfilePicturesLoading && !membersLoading && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(
                  _unassignLuyaoProfilePictureToMembersFromMember
                )}
              >
                確定
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" color="primary" onClick={_onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default memo(
  forwardRef(UnassignLuyaoProfilePictureToMembersFromMemberDialog),
  deepEqual
);
