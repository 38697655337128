// mui
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
// html
import parse from "html-react-parser";
// react-hook-form
import { useWatch } from "react-hook-form";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
// icons
import { ReactComponent as BannerText } from "../../../svgs/special/special-banner-text.svg";
import { ReactComponent as BannerLogo } from "../../../svgs/special/special-logo.svg";

// export
export default function SpecialEventInformation() {
  return (
    <div>
      <BackgroundContainer>
        <SpecialContents />
        <ParsingHtml />
      </BackgroundContainer>
    </div>
  );
}

// ANCHOR Banner
function SpecialContents() {
  const theme = useTheme();
  const isPc = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const useStyles = makeStyles({
    // text
    textOutSideBox: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      paddingBottom: "20.106%",
      margin: isPc ? "55px 0 32px 0 " : "24px 0",
    },
    bannerText: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    // banner logo
    bannerLogo: {
      width: isPc ? 91.98 : isTablet ? 80 : 48.98,
      height: "100%",
      objectFit: "contain",
    },
    bannerButtonBox: {
      position: "relative",
      width: "100%",
      maxWidth: "360px",
      overflow: "hidden",
      height: 0,
      paddingBottom: "15.999%",
      margin: "0 auto",
    },
    bannerButton: {
      position: "absolute",
      left: "50%",
      top: 0,
      transform: "translateX(-50%)",
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  });
  const classes = useStyles();
  return (
    <Box pb={isPc ? "100px" : "48px"}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BannerLogo className={classes.bannerLogo} />
      </Box>
      {/* </Box> */}
      <Box className={classes.textOutSideBox}>
        <BannerText className={classes.bannerText} />
      </Box>
      <Box className={classes.bannerButtonBox}>
        <img
          className={classes.bannerButton}
          src={"/img/special/special-button.png"}
          alt="special-button"
        />
      </Box>
    </Box>
  );
}

function ParsingHtml() {
  const eventContent = useWatch({ name: "eventContent" });
  return parse(eventContent || "");
}
