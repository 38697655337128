import React from "react";
import moment from "moment";
import { Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";

const GET_CAROUSELS = gql`
  query carousels {
    carousels {
      id
      section
      createdAt
      updatedAt
    }
  }
`;

export default function CarouselsListTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();

  const { data, loading } = useQuery(GET_CAROUSELS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  function getSection(value) {
    switch (value) {
      case "HOME_PAGE":
        return "首頁";
      case "EXCLUSIVE_PRODUCT_PAGE":
        return "限定商品";
      case "PREORDER_PRODUCT_PAGE":
        return "預購商品";
      case "IN_STOCK_PRODUCT_PAGE":
        return "現貨商品";
      case "EVENT_PAGE":
        return "活動";
      default:
        return "查無資料";
    }
  }

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Table
          paper
          data={data ? data.carousels : []}
          header={["區域", "建立時間", "最後更新時間"]}
          tableWidth={2}
          columns={[
            (item) => {
              return getSection(item.section);
            },
            (item) => moment(item.createdAt).format("YYYY/MM/DD"),
            (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
          ]}
          onPress={(item) => onEditSelect(item)}
        />
      </Grid>
    );
  }
}
