import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";

import { useLocalStorage } from "../component/LocalStorageContext";

const CREATE_CART = gql`
  mutation CreateCart {
    createCart {
      id
    }
  }
`;

let cartId, creating;

export function useCartId() {
  const storage = useLocalStorage();
  const [createCart] = useMutation(CREATE_CART, {
    onCompleted({ createCart }) {
      creating = false;
      storage.setCartId(createCart.id);
      cartId = createCart.id;
    },
    onError(error) {
      alert(`${error}`);
    },
  });

  useEffect(() => {
    if (!cartId) {
      cartId = storage.getCartId();
      if (!cartId && !creating) {
        creating = true;
        createCart();
      }
    }
  }, [createCart, storage]);

  return cartId || storage.getCartId();
}
