import React, { useState } from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import { useAlert } from "./Alert";
import Button from "./Button";
import Text from "./Text";
import Grid from "./Grid";

export default function MultipleAutoComplate({
  open,
  onChangeFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const useStylesTable = makeStyles({
    dialogPaper: {
      maxHeight: `calc(90% - 16px)`,
      maxWidth: `calc(85% - 16px)`,
      width: `calc(100% - 16px)`,
      margin: 8,
    },
    Container: {
      display: "flex",
      justifyContent: "center",
      color: "red",
    },
    divider: {
      height: 30,
    },
    checked: {
      color: "#0072E3",
    },
  });
  const classesTable = useStylesTable();
  const [checked, setChecked] = useState(false);

  const attentionArray = [
    {
      label: "訂購商品後請於繳費代號失效前完成繳費，如逾期未繳者視同放棄訂單。",
    },
    {
      label:
        "商品出貨時間以實際到貨日為主，無法等待者請勿預購；商品若延遲未達三個月，則不另行通知，請知悉。",
    },
    {
      label: "下單後因個人原因欲取消訂單者，將列入黑名單並無法再次於官網購物。",
    },
    {
      label:
        "商品出貨後會以簡訊通知，煩請留意手機簡訊，商若因個人因素未取貨被退回，需再次支付運費寄出。",
    },
    {
      label:
        "限時限量商品繳費時間以官網及粉絲團公告時間為主，如逾期未繳者將自動取消訂單。",
    },
    {
      label:
        "海外客戶需先繳商品金額＋60台幣運費，以順豐快遞寄出。到貨後寄出加補海外運費（貨到付款）金額。",
    },
    {
      label:
        "當您完成訂購程序後，您可以至會員中心頁面，點選 [訂單內容] 功能，查詢訂單狀態。",
    },
    {
      label:
        "預購商品須付訂金，請於預購期間完成匯款，如逾期未繳者視同放棄訂單。",
    },
    {
      label:
        "預購商品到貨後會以簡訊通知確認出貨地址，煩請至官網會員中心點選待出貨商品，勾選該項商品後確認出貨地址。若逾期未確認將以會員中心資料寄出。",
    },
    {
      label:
        "匯款需入帳時間，約40-60分鐘，若繳費後訂單狀態為已取消，請私訊粉絲團。",
    },
    {
      label: "選取超商取貨請確實填寫門市名稱，以免影響您的權益。",
    },
    {
      label:
        "為避免您久候，採用現貨商品、預購商品、限定商品分開結帳、分開出貨的方式，無法合併出貨。現貨商品可加入購物車一起結帳（運費只收一次）。如有特殊情況將公布於粉絲團。",
    },
  ];

  function initialization(value) {
    setChecked(false);
    onChangeFloatingwindowClose(value);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={false}
      style={{ marginBottom: "5em" }}
      classes={{ paper: classesTable.dialogPaper }}
    >
      <DialogTitle classes={{ root: classesTable.Container }}>
        CUSTOMER ATTENTION 消費注意事項
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <Text type="h6" color="red">
              【虛擬商品訂購須知】
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text type="h6" color="red">
              『如是電子票券商品，地址可正常填寫，但電子票券將會以email方式寄送給您，請在備註填寫email，非必要我們將不會寄送實體票券給您，會以電子票券方式寄送給您的email做領取。』
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text type="h6" color="#2894FF">
              【商品訂購及運費說明】
            </Text>
          </Grid>
          {attentionArray.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Box display="flex">
                <Text type="h6">{index + 1}.&nbsp;&nbsp;</Text>
                <Text type="h6">{item.label}</Text>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Text type="h6" color="#2894FF">
              【瑕疵換貨說明】
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Text type="h6">1.&nbsp;&nbsp;</Text>
              <Text type="h6">
                商品工廠大量出貨皆可能有小瑕疵、顏色並非圖片完全對色，與實際商品可能會有些微差距，官方認定合理瑕疵皆不退換，完美主義者請勿下單。
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Text type="h6">2.&nbsp;&nbsp;</Text>
              <Text type="h6">
                若收到瑕疵商品欲換貨，請於七天內提出，私訊粉絲團並附上照片，將有專人為你處理。
              </Text>
            </Box>
          </Grid>
          <div className={classesTable.divider} />
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <FiberManualRecord fontSize={"inherit"} />
              &nbsp;&nbsp;
              <Text type="h6">
                客服訊息回覆時間為週一至週五09:00-18:00，造成不便敬請見諒，非上班時間請先留言，待工作人員回覆，謝謝。
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <FiberManualRecord fontSize={"inherit"} />
              &nbsp;&nbsp;
              <Text type="h6">路遙圓創公司保留銷售最終解釋之權利。</Text>
            </Box>
          </Grid>
          <Grid container item xs={12} justify="center">
            <FormControlLabel
              className={classesTable.checked}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked((e) => !e)}
                  style={{
                    color: "#2894FF",
                  }}
                />
              }
              label={"我已詳閱以上內容，並同意繼續購買。"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={() => {
                if (!checked) {
                  Alert.notice("請詳閱消費注意事項並勾選同意書即可繼續！");
                } else {
                  initialization(checked);
                }
              }}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onPress={() => initialization()}
            >
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
