import React from "react";
import { makeStyles } from "@material-ui/core";

export default function SquareBox({ children, width = "100%" }) {
  const useStyles = makeStyles({
    box: {
      width,
    },
    outsideBox: {
      paddingTop: "100%",
      position: "relative",
    },
    insideBox: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.outsideBox}>
        <div className={classes.insideBox}>{children}</div>
      </div>
    </div>
  );
}
