import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

import { useAlert } from "../component/Alert";
import { Card, CardContent, CardActions } from "../component/Card";
import Grid from "../component/Grid";
import Text from "../component/Text";
import Button from "../component/Button";
import Image from "../component/Image";
import divisions from "../divisions.json";
import { Select } from "../component/Form";
import LoadingModal from "../component/LoadingModal";
import countryCodes from "../countryCodes.json";
import { thousandsSeparator } from "../component/utils";

const GET_MEMBER = gql`
  query GetMember($id: Int!) {
    member(id: $id) {
      id
      profilePicture {
        location
      }
      countryCallingCode
      fullName
      mobile
      email
      address {
        district
        subdistrict
        address
      }
      tier
      source
      totalOrderAmount
      createdAt
    }
  }
`;

const UPDATE_MEMBERTIER = gql`
  mutation updateMemberTier($id: Int!, $tier: MemberTier!) {
    updateMemberTier(id: $id, tier: $tier) {
      success
      message
    }
  }
`;

export default function MemberPage() {
  const Alert = useAlert();
  const [permissions, setPermissions] = useState([]);
  const [tier, setTier] = useState("HORSE_POWER");
  const tierRef = useRef();
  const { id } = useParams();

  const [getMember, { data, loading }] = useLazyQuery(GET_MEMBER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ member }) {
      if (member) {
        setTimeout(() => {
          setTier(member.tier);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getMember({ variables: { id: Number(id) } });
    }
  }, [id, getMember]);

  const [updateMemberTier, { loading: updateMemberTierLoading }] = useMutation(
    UPDATE_MEMBERTIER,
    {
      onCompleted({ updateMemberTier }) {
        if (updateMemberTier.success) {
          setTier(tierRef.current);
          return Alert.alert("", "更改成功！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          return Alert.alert("", "更改失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  const permissionList = [
    { lable: "後台登入", value: "CONSOLE_LOGIN" },
    { lable: "後台會員管理", value: "CONSOLE_MEMBER" },
    { lable: "後台商品管理", value: "CONSOLE_PRODUCT" },
    { lable: "後台訂單管理", value: "CONSOLE_ORDER" },
  ];
  function switchSourceText() {
    switch (data.member.source) {
      case "WEB":
        return "網頁";
      case "ANDROID":
        return "Android";
      case "IOS":
        return "iOS";
      case "OTHER":
        return "其他";
      default:
        break;
    }
  }
  function _save() {}

  function getTier(value) {
    switch (value) {
      case "HORSE_POWER":
        return "小馬力";
      case "SUPER_HORSE_POWER":
        return "超級馬力";
      case "COLLECTOR_HORSE_POWER":
        return "藏家馬力";
      default:
        return "查無資料";
    }
  }

  const memberNationality = useMemo(() => {
    let memberNationality = "暫無資料";
    if (Boolean(data)) {
      const has = countryCodes.find(
        (item) => item.phoneCode === data.member.countryCallingCode
      );
      if (has) {
        memberNationality = has.countryName;
      }
    }
    return memberNationality;
  }, [data]);

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return data ? (
      <Card>
        <LoadingModal loading={updateMemberTierLoading} />
        <CardContent>
          <Grid container spacing={1}>
            {data.member.profilePicture && (
              <Grid container item>
                <Image
                  source={data.member.profilePicture.location}
                  style={{ width: 128, height: 128, objectFit: "cover" }}
                />
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormLabel>會員等級</FormLabel>
                <Select
                  items={[
                    { label: "小馬力", value: "HORSE_POWER" },
                    { label: "超級馬力", value: "SUPER_HORSE_POWER" },
                    { label: "藏家馬力", value: "COLLECTOR_HORSE_POWER" },
                  ]}
                  value={tier}
                  onChange={(value) => {
                    Alert.alert(
                      "通知",
                      `確定更改會員等級為 "${getTier(value)}" 嗎？`,
                      [
                        {
                          text: "確定",
                          onPress: () => {
                            tierRef.current = value;
                            updateMemberTier({
                              variables: { id: Number(id), tier: value },
                            });
                          },
                          type: "ok",
                        },
                        {
                          text: "取消",
                          type: "cancel",
                        },
                      ]
                    );
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>姓名</FormLabel>
              <Text>{data.member.fullName}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>國籍</FormLabel>
              <Text>{memberNationality}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>消費總金額</FormLabel>
              <Text>{thousandsSeparator(data.member.totalOrderAmount)}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>手機號碼</FormLabel>
              <Text>{data.member.mobile}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>信箱</FormLabel>
              <Text>{data.member.email}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>地址</FormLabel>
              <Text>
                {data.member.address
                  ? divisions[data.member.address.district]["zhTW"] +
                    divisions[data.member.address.district]["subdivisions"][
                      data.member.address.subdistrict
                    ]["zhTW"] +
                    data.member.address.address
                  : null}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>來源</FormLabel>
              <Text>{switchSourceText()}</Text>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel>註冊時間</FormLabel>
              <Text>
                {moment(data.member.createdAt).format("YYYY/MM/DD HH:mm")}
              </Text>
            </Grid>
            {false && (
              <Grid container item direction="column">
                <FormLabel>權限</FormLabel>
                <List>
                  {permissionList.map((item) => {
                    const checked = permissions.indexOf(item.value) !== -1;
                    return (
                      <ListItem dense key={item.value}>
                        <ListItemIcon>
                          <Checkbox
                            checked={checked}
                            onClick={() => {
                              if (checked) {
                                return setPermissions((e) =>
                                  e.filter((item2) => item2 !== item.value)
                                );
                              } else {
                                return setPermissions((e) => [
                                  ...e,
                                  item.value,
                                ]);
                              }
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={item.lable} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {false && (
          <CardActions>
            <Button color="primary" variant="contained" onPress={_save}>
              儲存
            </Button>
          </CardActions>
        )}
      </Card>
    ) : null;
  }
}
