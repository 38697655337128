import React, { memo, useEffect } from "react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  Paper,
  CardMedia,
  Typography,
  TextField,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  useForm,
  FormProvider,
  useFormContext,
  useFieldArray,
  Controller,
} from "react-hook-form";

import { Card, CardActions } from "../../component/Card";
import { Select } from "../../component/Form";
import Grid from "../../component/Grid";
import Button from "../../component/Button";
import { useAlert } from "../../component/Alert";
import LoadingModal from "../../component/LoadingModal";
import { checkImageSize } from "../../component/utils";

const GET_CAROUSEL = gql`
  query carousel($section: Section!) {
    carousel(section: $section) {
      id
      section
      contents {
        id
        image {
          filename
          mimetype
          encoding
          location
        }
        link
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const SAVE_CAROUSEL = gql`
  mutation saveCarousel($section: Section!, $carouselInput: CarouselInput!) {
    saveCarousel(section: $section, carouselInput: $carouselInput) {
      success
      message
    }
  }
`;

const sectionArray = [
  {
    label: "首頁",
    value: "HOME_PAGE",
  },
  {
    label: "限定商品",
    value: "EXCLUSIVE_PRODUCT_PAGE",
  },
  {
    label: "預購商品",
    value: "PREORDER_PRODUCT_PAGE",
  },
  {
    label: "現貨商品",
    value: "IN_STOCK_PRODUCT_PAGE",
  },
  {
    label: "活動",
    value: "EVENT_PAGE",
  },
];

export default function CarouselsListForm({
  id,
  sectionData,
  onChangeSelect = () => [],
}) {
  const Alert = useAlert();
  const carouselForm = useForm({
    defaultValues: {
      section: "",
      carouselInput: [],
    },
  });
  const { control, setValue, handleSubmit } = carouselForm;

  const [getCarousel, { loading }] = useLazyQuery(GET_CAROUSEL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ carousel }) {
      if (carousel) {
        setTimeout(() => {
          const { contents } = carousel;
          const newImages = [];
          contents.forEach(({ image: { __typename, ...oldImage }, link }) => {
            newImages.push({ image: { ...oldImage }, link });
          });
          setValue("carouselInput", newImages);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (Boolean(sectionData)) {
      setValue("section", sectionData);
      getCarousel({ variables: { section: sectionData } });
    }
  }, [sectionData, getCarousel, setValue]);

  const [uploadImage, { loading: uploadImageLoading }] =
    useMutation(UPLOAD_IMAGE);

  async function _saveCarousel(value) {
    const { section, carouselInput } = value;
    const oldCarouselInput = [...carouselInput];
    const dataToUpload = [];
    oldCarouselInput.forEach((item, index) => {
      if (item.image.name) {
        dataToUpload.push({
          index,
          image: item.image,
          link: item.link,
        });
      }
    });
    for (let i = 0; i < dataToUpload.length; i++) {
      const element = dataToUpload[i];
      const {
        data: {
          uploadImage: { __typename, ...newImage },
        },
      } = await uploadImage({ variables: { image: element.image } });
      oldCarouselInput.fill(
        { image: newImage, link: element.link },
        element.index,
        element.index + 1
      );
    }

    saveCarousel({
      variables: { section, carouselInput: { contents: oldCarouselInput } },
    });
  }

  const [saveCarousel, { loading: saveCarouselLoading }] = useMutation(
    SAVE_CAROUSEL,
    {
      onCompleted({ saveCarousel }) {
        if (saveCarousel.success) {
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => {
                onChangeSelect();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError() {
        return null;
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={uploadImageLoading || saveCarouselLoading} />
        <FormProvider {...carouselForm}>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="section"
                  render={({ field }) => (
                    <Select
                      {...field}
                      items={sectionArray}
                      fullWidth
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CarouselComponent />
              </Grid>
            </Grid>
          </CardActions>
          <CardActions>
            <Grid container item justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onPress={handleSubmit(_saveCarousel)}
              >
                儲存
              </Button>
            </Grid>
          </CardActions>
        </FormProvider>
      </Card>
    );
  }
}

const CarouselComponent = memo(function CarouselComponent() {
  const { alert, notify } = useAlert();
  const { control } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "carouselInput",
  });

  return (
    <Box>
      <Grid container spacing={2}>
        {fields.map((item, index) => (
          <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
            <Box
              component={Paper}
              p={2}
              pt={4}
              width="100%"
              position="relative"
              display="flex"
              flexDirection="column"
            >
              {/* removeButton */}
              <IconButton
                size="small"
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  zIndex: 2,
                }}
                onClick={() =>
                  alert("", "確定要移除嗎？", [
                    {
                      text: "確定",
                      onPress: () => remove(index),
                      type: "ok",
                    },
                    {
                      text: "取消",
                      type: "cancel",
                    },
                  ])
                }
              >
                <Close />
              </IconButton>
              <Controller
                control={control}
                name={`carouselInput.${index}.image`}
                rules={{
                  required: "未選擇",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <ButtonBase
                      style={{
                        width: "100%",
                        position: "relative",
                        overflow: "hidden",
                        paddingTop: "56.25%",
                      }}
                      onClick={() => {
                        const imageIndex = document.getElementById(
                          `image${index}`
                        );
                        imageIndex.click();
                      }}
                    >
                      {value ? (
                        <CardMedia
                          component="img"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          image={
                            value.name
                              ? URL.createObjectURL(value)
                              : value.location
                          }
                          alt="product"
                        />
                      ) : (
                        <CloudUploadIcon
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                      )}
                    </ButtonBase>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ color: "#F77B72", fontWeight: "bold" }}
                    >
                      &ensp;&ensp;{error?.message}
                    </Typography>
                    <input
                      type="file"
                      id={`image${index}`}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const isUpload = e.target.files.length > 0;
                        if (isUpload) {
                          const url = e.target.files[0];
                          if (checkImageSize(url.size))
                            return notify("圖片限制 4.5 MB 以內");
                          onChange(url);
                        }
                      }}
                    />
                  </>
                )}
              />

              {/* 移動 按鈕 */}
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Controller
                    control={control}
                    name={`carouselInput.${index}.link`}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="連結"
                        error={Boolean(error)}
                        helperText={error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    disabled={index === 0}
                    variant="contained"
                    style={{ bgcolor: "primary.light" }}
                    onPress={() => {
                      move(index, index - 1);
                    }}
                  >
                    向前移動
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={index === fields.length - 1}
                    style={{ bgcolor: "primary.light" }}
                    onPress={() => move(index, index + 1)}
                  >
                    向後移動
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box
            style={{
              display: "flex",
              borderRadius: "10px",
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <ButtonBase
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "5px",
                minHeight: "100px",
              }}
              onClick={() => append({ image: "" })}
            >
              <AddIcon style={{ color: "#ccc" }} />
            </ButtonBase>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
