import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
  memo,
  useMemo,
} from "react";
// @material-ui/core
import {
  Tabs,
  Tab,
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  FormLabel,
  Chip,
  makeStyles,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
// icon
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// react-swipeable-views
import SwipeableViews from "react-swipeable-views";
// moment
import moment from "moment-timezone";
// apollo
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import { atom, useAtom, useAtomValue } from "jotai";
import { deepEqual } from "fast-equals";
import { produce } from "immer";

// component
import { Card, CardActions } from "../../component/Card";
import {
  Select,
  AutocompleteSelect,
  CustomTextField,
  CustomNumberInput,
  FieldCache,
} from "../../component/Form";
import ContentEditor from "./ContentEditor";
import Text from "../../component/Text";
import Grid from "../../component/Grid";
import Button from "../../component/Button";
import { useAlert } from "../../component/Alert";
import LoadingModal from "../../component/LoadingModal";
import MultipleAutoComplate from "../../component/MultipleAutoComplate";
// utils
import {
  paymentMethodList,
  paymentMethodEnum,
  productTypeList,
} from "../../utils/localData";
import emptyArray from "../../utils/emptyArray";
import stringToFloat from "../../utils/stringToFloat";
import withJotai from "../../jotai/withJotai";
import FormScrollToError from "../../component/FormScrollToError";

// SECTION apollo
// NOTE 商品
/** - 商品 */
const GET_PRODUCT = gql`
  query product($id: Int!) {
    product(id: $id) {
      id
      images {
        filename
        mimetype
        encoding
        location
      }
      preorder
      name
      size
      material
      limit
      limitText
      stock
      price
      depositSize
      deliveryFee
      maxAmountPerOrder
      maxAmountPerMember
      startTime
      endTime
      eta
      details {
        type
        body
      }
      enabled
      # "上架時間"
      enabledAt
      shippable
      transferExpiryDays
      memberTierLimit
      # "付款方式"
      paymentMethods
      # "限量"
      limited
      # "繳費期限(分鐘)"
      paymentMinuteLimit
      slug
      # "有無密碼"
      hasPassword
    }
  }
`;
// NOTE 商品關聯
/** - 商品關聯 */
const GET_PRODUCT_RELATION = gql`
  query productRelation($productId: Int!) {
    productRelation(productId: $productId) {
      id
      name
    }
  }
`;
// NOTE 儲存商品
/** - 儲存商品 */
const SAVE_PRODUCT = gql`
  mutation saveProduct($productInput: ProductInput!) {
    saveProduct(productInput: $productInput) {
      success
      message
    }
  }
`;
// NOTE 上傳圖片
/** - 上傳圖片 */
const UPLOAD_IMAGE = gql`
  mutation uploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;
// NOTE 儲存商品關聯
/** - 儲存商品關聯 */
const SAVE_PRODUCTRELATION = gql`
  mutation saveProductRelation($productId: Int!, $relatedProductIds: [Int]!) {
    saveProductRelation(
      productId: $productId
      relatedProductIds: $relatedProductIds
    ) {
      success
      message
    }
  }
`;
// !SECTION

const preorderSelect = [
  {
    id: 1,
    label: "是",
    value: true,
  },
  {
    id: 2,
    label: "否",
    value: false,
  },
];

const enabledSelect = [
  {
    id: 1,
    label: "是",
    value: true,
  },
  {
    id: 2,
    label: "否",
    value: false,
  },
];

const memberTierLimitSelect = [
  {
    label: "無限制",
    value: "NOT",
  },
  {
    label: "小馬力",
    value: "HORSE_POWER",
  },
  {
    label: "超級馬力",
    value: "SUPER_HORSE_POWER",
  },
  {
    label: "藏家馬力",
    value: "COLLECTOR_HORSE_POWER",
  },
];

/** - 標籤選擇 */
const tabSelectAtom = atom(0);
/** - 同系列商品 */
const relatedProductsAtom = atom([]);
/** - 主要組件 */
function ConsoleProductForm({
  data,
  onGoBackList = () => {},
  productsAllData = [],
}) {
  const Alert = useAlert();
  const form = useForm({
    defaultValues: {
      type: "NORMAL",
      images: [],
      name: "",
      slug: "",
      preorder: false,
      size: "",
      material: "",
      limit: "",
      limitText: "",
      stock: "",
      price: "",
      depositSize: 1,
      deliveryFee: 0,
      maxAmountPerOrder: "",
      maxAmountPerMember: "",
      startTime: new Date(),
      endTime: null,
      eta: null,
      details: {
        details: [
          {
            type: "HEADING",
            body: "",
          },
        ],
      },
      enabled: false,
      enabledAt: null,
      shippable: false,
      transferExpiryDays: "",
      freeShipping: false,
      memberTierLimit: "NOT",
      paymentMethods: [{ label: "信用卡", value: "CREDIT_CARD" }],
      limited: false,
      paymentMinuteLimit: "",
      password: "",
    },
  });
  const { handleSubmit, setValue, reset } = form;
  const oldRelatedProducts = useRef([]);
  const [relatedProducts, setRelatedProducts] = useAtom(relatedProductsAtom);
  /** - 商品`query` */
  const [getProduct, { data: productData, loading: productLoading }] =
    useLazyQuery(GET_PRODUCT, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ product }) {
        setTimeout(() => {
          Object.keys(product).forEach((item) => {
            const key = item;
            switch (key) {
              case "__typename":
              case "type":
                break;
              case "hasPassword":
                if (product[key]) {
                  setValue("type", "CLUB_EXCLUSIVE");
                }
                break;
              case "limited":
                if (product[key]) {
                  setValue("type", "LIMIT");
                } else {
                  setValue("type", product.type);
                }
                setValue(key, product[key]);
                break;
              case "images":
                const newImage = [];
                product[key].forEach(({ __typename, ...item2 }) => {
                  newImage.push(item2);
                });
                setValue(key, newImage);
                break;
              case "deliveryFee":
                setValue(key, product[key] === -1 ? 0 : product.deliveryFee);
                setValue("freeShipping", product[key] === -1 ? true : false);
                break;
              case "details":
                setValue(key, {
                  details: product[key].map(({ __typename, ...item }) => item),
                });
                break;
              case "memberTierLimit":
                setValue(key, product[key] ?? "NOT");
                break;
              case "paymentMethods":
                setValue(
                  key,
                  emptyArray(product[key]).length > 0
                    ? emptyArray(product[key]).map((item) => ({
                        label: paymentMethodEnum[item],
                        value: item,
                      }))
                    : [{ label: "信用卡", value: "CREDIT_CARD" }]
                );
                break;
              default:
                setValue(key, product[key]);
                break;
            }
          });
        }, 0);
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });
  useEffect(() => {
    if (data?.id) {
      getProduct({ variables: { id: data.id } });
    } else {
      reset();
    }
  }, [data, reset, getProduct]);
  const hasPassword = useMemo(
    () => Boolean(productData?.product?.hasPassword),
    [productData]
  );
  /** - 商品關聯`query` */
  const [getProductRelation, { loading: productRelationLoading }] =
    useLazyQuery(GET_PRODUCT_RELATION, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ productRelation }) {
        if (productRelation) {
          setTimeout(() => {
            oldRelatedProducts.current = productRelation;
            setRelatedProducts(productRelation);
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });
  useEffect(() => {
    if (data?.id) {
      getProductRelation({
        variables: { productId: data.id },
      });
    }
  }, [data, getProductRelation]);
  /** - 儲存商品`mutate` */
  const [saveProductFn, { loading: saveProductLoading }] = useMutation(
    SAVE_PRODUCT,
    {
      onCompleted({ saveProduct: { success, message } }) {
        if (success) {
          if (
            relatedProducts[0] &&
            !deepEqual(oldRelatedProducts.current, relatedProducts)
          ) {
            saveProductRelation({
              variables: {
                productId: Boolean(data) ? data.id : null,
                relatedProductIds: relatedProducts.map((item) => item.id),
              },
            });
          } else {
            onGoBackList();
          }
        } else if (message) {
          Alert.notice(message);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  /** - 儲存商品 */
  const _saveProduct = useCallback(
    ({
      transferExpiryDays,
      memberTierLimit,
      endTime,
      paymentMinuteLimit,
      freeShipping,
      password,
      ...data
    }) => {
      if (!Boolean(data.images[0]?.location)) {
        return Alert.alert("", "商品圖片未選擇！", [
          { text: "確定", type: "ok" },
        ]);
      }
      if (data.paymentMethods.length === 0) {
        return Alert.alert("", "至少選擇一個付款方式", [
          { text: "確定", type: "ok" },
        ]);
      }
      const detailsNull = data.details.details.find(
        (item) =>
          (item.type === "HEADING" && item.body === "") ||
          (item.type === "PARAGRAPH" && item.body === "") ||
          (item.type === "IMAGE" && item.body === "") ||
          (item.type === "VIDEO" && item.body === "")
      );
      if (detailsNull) {
        return Alert.alert("", "商品介紹未填寫完！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
      const productInput = {
        ...data,
        limit: stringToFloat(data.limit),
        stock: stringToFloat(data.stock),
        price: stringToFloat(data.price),
        depositSize: data.preorder ? stringToFloat(data.depositSize) : 1,
        deliveryFee: freeShipping ? -1 : stringToFloat(data.deliveryFee),
        maxAmountPerOrder: data.maxAmountPerOrder
          ? stringToFloat(data.maxAmountPerOrder)
          : undefined,
        maxAmountPerMember: data.maxAmountPerMember
          ? stringToFloat(data.maxAmountPerMember)
          : undefined,
        details: data.details.details,
        shippable: data.preorder ? data.shippable : true,
      };
      if (data.type === "LIMIT") {
        productInput.type = "EXCLUSIVE";
        productInput.limited = true;
      } else {
        productInput.type = data.type;
        productInput.limited = false;
      }

      if (memberTierLimit !== "NOT") {
        productInput.memberTierLimit = memberTierLimit;
      }

      if (Boolean(endTime)) {
        productInput.endTime = endTime;
      }

      if (data.preorder === true) {
        productInput.eta = data.eta;
      } else {
        productInput.eta = null;
      }

      if (transferExpiryDays) {
        productInput.transferExpiryDays = stringToFloat(transferExpiryDays);
      }
      if (data.enabledAt) {
        if (moment().valueOf() < moment(data.enabledAt).valueOf()) {
          productInput.enabled = false;
        }
        productInput.enabledAt = moment(data.enabledAt)
          .tz("Asia/Taipei")
          .second(0);
      }

      if (data.paymentMethods.length) {
        const uniqueArray = data.paymentMethods
          .filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.value === obj.value)
          )
          .map((item) => item.value);
        productInput.paymentMethods = uniqueArray;
      }

      if (paymentMinuteLimit) {
        productInput.paymentMinuteLimit = stringToFloat(paymentMinuteLimit);
      }

      if (password) {
        productInput.password = password;
      }
      saveProductFn({ variables: { productInput } });
    },
    [Alert, saveProductFn]
  );
  /** - 儲存商品關聯`mutate` */
  const [saveProductRelation, { loading: saveProductRelationLoading }] =
    useMutation(SAVE_PRODUCTRELATION, {
      onCompleted({ saveProductRelation }) {
        if (saveProductRelation.success) {
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onPress: () => {
                onGoBackList();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  if (productLoading || productRelationLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <Grid container spacing={1}>
      <FormProvider {...form}>
        <LoadingModal
          loading={saveProductLoading || saveProductRelationLoading}
        />
        <HandlePassword />
        <FormScrollToError />
        <Grid container item>
          <Card style={{ flex: 1, padding: 15 }}>
            <CardActions>
              <Grid container spacing={3}>
                <Grid container item>
                  <TabsField />
                </Grid>
                <DisplayTab
                  productsAllData={productsAllData}
                  hasPassword={hasPassword}
                />
                <Grid container item justify="flex-end">
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={handleSubmit(_saveProduct)}
                    >
                      儲存
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </FormProvider>
    </Grid>
  );
}

export default memo(withJotai(ConsoleProductForm), deepEqual);

/** - 標籤切換 */
const TabsField = memo(() => {
  const [tabSelect, setTabSelect] = useAtom(tabSelectAtom);
  return (
    <Tabs value={tabSelect} onChange={(e, n) => setTabSelect(n)}>
      <Tab label="商品資訊" />
      <Tab label="商品介紹" />
    </Tabs>
  );
});

/** - 顯示標籤 */
const DisplayTab = memo(({ productsAllData, hasPassword = false }) => {
  const tabSelect = useAtomValue(tabSelectAtom);
  const _render = useCallback(() => {
    switch (tabSelect) {
      case 1:
        return <DetailsComponent />;
      case 0:
      default:
        return (
          <FieldsComponent
            productsAllData={productsAllData}
            hasPassword={hasPassword}
          />
        );
    }
  }, [tabSelect, productsAllData, hasPassword]);
  return _render();
});

/** - 輸入欄位 */
const FieldsComponent = memo(({ productsAllData, hasPassword }) => {
  const useStyles = makeStyles({
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  });
  const classes = useStyles();
  const { setValue, getValues } = useFormContext();
  const [relatedProducts, setRelatedProducts] = useAtom(relatedProductsAtom);
  return (
    <Grid container item spacing={2}>
      <ImagesField />
      <Grid item xs={12}>
        <Controller
          name="type"
          render={({ field }) => (
            <Select
              {...field}
              label={"商品類型"}
              items={productTypeList}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="name"
          rules={{
            required: "請輸入名稱",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              label="名稱"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="slug"
          rules={{
            required: "請輸入slug",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              label="slug"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="limit"
          rules={{
            required: "請輸入限量",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomNumberInput
              {...field}
              label="限量"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="limitText"
          render={({ field }) => (
            <CustomTextField {...field} label="限量文字" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {hasPassword ? (
          <Typography color="error">已設定密碼</Typography>
        ) : (
          <Controller
            name="password"
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                {...field}
                label="密碼"
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="stock"
          rules={{
            required: "請輸入庫存",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomNumberInput
              {...field}
              label="庫存"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="price"
          rules={{
            required: "請輸入價錢",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomNumberInput
              {...field}
              label="價錢"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FieldCache
          name="preorder"
          render={(preorder) => (
            <Controller
              name="depositSize"
              render={({ field: { value, onChange } }) => (
                <CustomTextField
                  value={value ? parseFloat(value) * 100 + "%" : ""}
                  onChange={(v) => {
                    onChange((parseFloat(v.replace("%")) / 100).toString());
                  }}
                  label="訂金比例"
                  disabled={!preorder}
                  fullWidth
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="row"
            minWidth={"100px"}
            alignItems={"center"}
          >
            <Controller
              name="freeShipping"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    setValue("deliveryFee", 0, { shouldValidate: true });
                    onChange(e.target.checked);
                  }}
                />
              )}
            />
            <Typography color="primary">免運費</Typography>
          </Box>
          <FieldCache
            name="freeShipping"
            render={(freeShipping) => (
              <Controller
                name="deliveryFee"
                rules={{
                  required: "請輸入運費",
                }}
                render={({ field, fieldState: { error } }) => (
                  <CustomNumberInput
                    {...field}
                    label="運費"
                    error={Boolean(error)}
                    helperText={error?.message}
                    disabled={freeShipping}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="size"
          rules={{
            required: "請輸入尺寸",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              label="尺寸"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="material"
          rules={{
            required: "請輸入材質",
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              label="材質"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="preorder"
          render={({ field: { value, onChange } }) => (
            <Select
              label={"預購"}
              items={preorderSelect}
              value={value}
              onChange={(v) => {
                if (!v) {
                  setValue("depositSize", 1);
                }
                onChange(v);
              }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="enabled"
          render={({ field }) => (
            <Select {...field} label={"上架"} items={enabledSelect} fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="enabledAt"
          render={({ field: { value, onChange } }) => (
            <DateTimePicker
              ampm={false}
              label={"上架時間"}
              inputVariant="outlined"
              format="yyyy年MM月dd日 a hh點mm分"
              value={value}
              onChange={(v) => {
                setValue("enabled", false);
                onChange(v);
              }}
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="maxAmountPerOrder"
          render={({ field }) => (
            <CustomNumberInput
              {...field}
              label={"每筆訂單購買上限"}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="maxAmountPerMember"
          render={({ field }) => (
            <CustomNumberInput
              {...field}
              label={"每名會員購買上限"}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="startTime"
          render={({ field }) => (
            <DateTimePicker
              {...field}
              ampm={false}
              label={"開始時間"}
              inputVariant="outlined"
              format="yyyy年MM月dd日 a hh點mm分"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="endTime"
          rules={{
            validate: (e) => {
              const startTime = getValues("startTime");
              if (
                e &&
                startTime &&
                moment(e).valueOf() < moment(startTime).valueOf()
              ) {
                return "商品結束時間不可小於開始時間！";
              }
            },
          }}
          render={({ field: { value, onChange } }) => {
            if (value) {
              return (
                <Box display="flex">
                  <DateTimePicker
                    ampm={false}
                    label={"結束時間"}
                    inputVariant="outlined"
                    format="yyyy年MM月dd日 a hh點mm分"
                    value={value}
                    onChange={(v) => onChange(v)}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={() => onChange(null)}
                  >
                    移除
                  </Button>
                </Box>
              );
            } else {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => onChange(new Date())}
                  fullWidth
                >
                  新增結束時間
                </Button>
              );
            }
          }}
        />
      </Grid>
      <FieldCache
        name="preorder"
        render={(preorder) => {
          if (preorder === true) {
            return (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eta"
                    rules={{
                      required: "請選擇預計到貨時間",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <DateTimePicker
                        {...field}
                        ampm={false}
                        label={"預計到貨時間"}
                        inputVariant="outlined"
                        format="yyyy年MM月dd日"
                        error={Boolean(error)}
                        helperText={error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="shippable"
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={"預購是否已可出貨"}
                        items={preorderSelect}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            );
          }
          return null;
        }}
      />
      <Grid item xs={12} sm={6}>
        <Controller
          name="transferExpiryDays"
          render={({ field }) => (
            <CustomNumberInput {...field} label="匯款期限(天)" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="memberTierLimit"
          render={({ field }) => (
            <Select
              {...field}
              label={"會員級別購買限制"}
              items={memberTierLimitSelect}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultipleAutoComplate
          name="paymentMethods"
          objectName="付款方式"
          options={paymentMethodList}
          render={({ selected, search }) => (
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                {selected}
              </Grid>
              <Grid item xs={12} sm={6}>
                {search}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="paymentMinuteLimit"
          render={({ field }) => (
            <CustomNumberInput {...field} label="繳費期限(分鐘)" fullWidth />
          )}
        />
      </Grid>
      <FieldCache
        name="id"
        render={(id) => {
          if (id) {
            return (
              <>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12}>
                  <Grid container spacing={1} direction="row">
                    <Grid container item>
                      <FormLabel>同系列商品</FormLabel>
                    </Grid>
                    <Grid container item direction="row" spacing={1}>
                      {relatedProducts.map((item, index) => (
                        <Grid key={index} item>
                          <Chip
                            label={item.name}
                            color="primary"
                            onDelete={() => {
                              const newRelatedProducts = relatedProducts.filter(
                                (item2) => item2.id !== item.id
                              );
                              setRelatedProducts(newRelatedProducts);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteSelect
                    label={"選擇同系列商品"}
                    items={productsAllData.filter((item) => item.value !== id)}
                    value={null}
                    onChange={(e, value) => {
                      if (value) {
                        const hasOption = relatedProducts.find(
                          (item) => item.id === value.value
                        );
                        if (Boolean(hasOption)) {
                          return null;
                        } else {
                          const newRelatedProducts = produce(
                            relatedProducts,
                            (drafts) => {
                              drafts.push({
                                id: value.value,
                                name: value.label,
                              });
                            }
                          );
                          setRelatedProducts(newRelatedProducts);
                        }
                      } else {
                        setRelatedProducts([]);
                      }
                    }}
                    renderOption={(option) => {
                      const hasOption = relatedProducts.find(
                        (item) => item.id === option.value
                      );
                      return (
                        <React.Fragment>
                          <div className={classes.text}>{option.label}</div>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={Boolean(hasOption)}
                          >
                            {hasOption ? "已加入" : "未加入"}
                          </Button>
                        </React.Fragment>
                      );
                    }}
                    fullWidth
                  />
                </Grid>
              </>
            );
          }
          return null;
        }}
      />
    </Grid>
  );
});

/** - 處理密碼必填 */
const HandlePassword = memo(function HandlePassword() {
  const { errors, isSubmitted } = useFormState();
  const { setError, clearErrors } = useFormContext();
  const [type, password] = useWatch({ name: ["type", "password"] });
  useEffect(() => {
    if (isSubmitted && type === "CLUB_EXCLUSIVE") {
      if (password) {
        clearErrors("password");
      } else {
        setError("password", { message: "請輸入密碼" });
      }
    } else {
      clearErrors("password");
    }
  }, [errors, isSubmitted, type, password, setError, clearErrors]);
  return null;
});

/** - 圖片 */
const ImagesField = memo(() => {
  const { notice } = useAlert();
  const [imageIndex, setImageIndex] = useState(0);
  const { fields, append, update, remove } = useFieldArray({ name: "images" });
  const [uploadImageFn, { loading: uploadImagesLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const _uploadImage = useCallback(
    async ({ image, index }) => {
      const { data } = await uploadImageFn({ variables: { image } });
      if (data?.uploadImage) {
        const { __typename, ...items } = data.uploadImage;
        if (typeof index === "number") {
          update(index, items);
        } else {
          append(items);
        }
      }
    },
    [append, update, uploadImageFn]
  );

  return (
    <Grid container item>
      <LoadingModal loading={uploadImagesLoading} />
      <SwipeableViews
        index={imageIndex}
        onChangeIndex={(e) => setImageIndex(e)}
        enableMouseEvents
        style={{ width: "100%" }}
      >
        {fields.map((item, index) => (
          <Fragment key={item.id}>
            {item.location && (
              <IconButton onClick={() => remove(index)}>
                <HighlightOffIcon />
              </IconButton>
            )}
            <ButtonBase
              style={{ width: "100%", height: 300 }}
              component="label"
              htmlFor={`uploadImage${imageIndex}`}
            >
              {Boolean(item.location) ? (
                <img
                  src={item.location}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              ) : (
                <CloudUploadIcon />
              )}
            </ButtonBase>
          </Fragment>
        ))}
      </SwipeableViews>
      <Box padding={1} width="100%">
        <Grid container spacing={1}>
          {fields.map((item, index) => (
            <Grid
              key={item.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => setImageIndex(index)}
            >
              <Button
                style={{
                  padding: 0,
                  width: "100%",
                  height: 160,
                }}
                onClick={() => setImageIndex(index)}
              >
                <img
                  src={item.location}
                  style={{
                    width: "100%",
                    height: 160,
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </Button>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              style={{
                padding: 0,
                height: 160,
                width: "100%",
              }}
              variant="contained"
              color="primary"
              component="label"
              htmlFor="addUploadImage"
            >
              <Text style={{ color: "white" }}>新增</Text>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <>
        <input
          id={`uploadImage${imageIndex}`}
          type="file"
          accept="image/*"
          onChange={(event) => {
            const {
              target: { validity, files },
            } = event;

            if (validity.valid) {
              for (let file of files) {
                _uploadImage({ image: file, index: imageIndex });
              }
            }
          }}
          style={{
            display: "none",
          }}
        />
        <input
          id="addUploadImage"
          type="file"
          accept="image/*"
          multiple="multiple"
          onChange={(event) => {
            const {
              target: { validity, files },
            } = event;

            if (validity.valid) {
              for (let file of files) {
                _uploadImage({ image: file });
              }
            }
          }}
          style={{
            display: "none",
          }}
        />
      </>
    </Grid>
  );
});

/** - 詳細資料 */
const DetailsComponent = memo(() => {
  return (
    <Controller
      name="details"
      render={({ field: { value, onChange } }) => (
        <ContentEditor
          data={value}
          onChangeData={(e) => onChange({ details: e })}
          lockFirst
        />
      )}
    />
  );
});
