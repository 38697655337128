import React, { useEffect, useCallback, useRef } from "react";
import {
  Box,
  Container,
  makeStyles,
  Grid,
  useTheme,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ReactComponent as LogoIcon } from "../logo.svg";
import SwipeableViewsComponent from "../component/SwipeableViewsComponent";
import PasswordDialog from "../component/PasswordDialog";
import Products from "../component/Products";
// NOTE zustand
import useProductPasswordStore from "../zustand/useProductPasswordStore";

const GET_CAROUSEL = gql`
  query carousel($section: Section!) {
    carousel(section: $section) {
      id
      contents {
        id
        image {
          filename
          mimetype
          encoding
          location
        }
        link
      }
    }
  }
`;

export default function ProductListPage() {
  const history = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();
  const useStyles = makeStyles({
    container: {
      padding: theme.spacing(2),
    },
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    divider: {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  });
  const classes = useStyles();
  const passwordDialogRef = useRef();

  const [getCarousel, { data: carouselData, loading: carouselLoading }] =
    useLazyQuery(GET_CAROUSEL, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError() {
        return null;
      },
    });

  const exclusive = pathname === "/" || pathname.includes("exclusive");
  const preorder = pathname.includes("preorder");

  const checkPathname = useCallback(() => {
    if (exclusive) {
      getCarousel({ variables: { section: "EXCLUSIVE_PRODUCT_PAGE" } });
    } else if (preorder) {
      getCarousel({ variables: { section: "PREORDER_PRODUCT_PAGE" } });
    } else {
      getCarousel({ variables: { section: "IN_STOCK_PRODUCT_PAGE" } });
    }
  }, [exclusive, getCarousel, preorder]);

  useEffect(() => {
    checkPathname();
  }, [checkPathname]);

  function getProductSort() {
    if (preorder) {
      return { englishName: "Preorder Products", chineseName: "預購商品" };
    } else if (exclusive) {
      return { englishName: "Exclusive Products", chineseName: "限定商品" };
    } else {
      return { englishName: "Products", chineseName: "現貨商品" };
    }
  }
  const _handleEvent = useCallback(
    ({ slug, password }) => {
      useProductPasswordStore.getState().setProductPassword(password);
      history.push(`/product/${slug}`);
    },
    [history]
  );

  if (carouselLoading) {
    return (
      <Box
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          paddingTop: "45%",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100%"
            transform="none"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <div>
        <Helmet>
          <title>{getProductSort().chineseName}｜路遙圓創</title>
        </Helmet>
        {carouselData?.carousel?.contents &&
          carouselData.carousel.contents[0] && (
            <SwipeableViewsComponent images={carouselData.carousel.contents} />
          )}
        <PasswordDialog ref={passwordDialogRef} handleEvent={_handleEvent} />
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                padding={`${theme.spacing(3)}px ${theme.spacing(1)}px`}
              >
                <Box>
                  <Box display="flex">
                    <Typography variant="h3" style={{ fontWeight: "bold" }}>
                      LUYAO
                    </Typography>
                    <LogoIcon style={{ height: 56, width: 56 }} />
                  </Box>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    DESIGN
                  </Typography>
                </Box>
                <Box margin={`${theme.spacing(3)}px 0`}>
                  <Typography variant="h6">
                    {getProductSort().englishName}
                  </Typography>
                  <Box
                    bgcolor={theme.palette.primary.main}
                    padding={`0 ${theme.spacing(2)}px`}
                    color={theme.palette.primary.contrastText}
                  >
                    <Typography variant="h6" align="center">
                      {getProductSort().chineseName}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    TAIWAN
                  </Typography>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>
                    DESIGN TOYS
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Products />
          </Grid>
        </Container>
      </div>
    );
  }
}
