import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";

export default function ThemeProvider({ children }) {
  const theme = createTheme({
    palette: {
      primary: { main: "#000000" },
      secondary: { main: "#808080" },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiTextField: {
        variant: "outlined",
        size: "small",
      },
      MuiContainer: {
        maxWidth: "md",
      },
      MuiSelect: {
        variant: "outlined",
      },
    },
    overrides: {
      MuiSelect: {
        root: {
          padding: `10.5px 14px`,
          fontSize: 16,
        },
      },
      MuiButton: {
        root: {
          minHeight: 40,
        },
      },
      typography: {
        fontFamily: "Noto Sans TC",
      },
    },
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
