import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  ButtonBase,
  Chip,
  makeStyles,
  CircularProgress,
  Link,
} from "@material-ui/core";
import AspectRatioBox from "../component/AspectRatioBox";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

import { useAlert } from "../component/Alert";
import PageItem from "../component/PageItem";
import useSearchQuery from "../hooks/useSearchQuery";

const Get_ARTICLES = gql`
  query articles($pageSize: Int, $page: Int, $tag: String) {
    articles(pageSize: $pageSize, page: $page, tag: $tag) {
      articleCount
      pageCount
      articles {
        id
        title
        imageUrl
        details {
          type
          body
        }
        tags
        click
        createdAt
      }
    }
  }
`;

export default function ArticleListPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const searchTag = useSearchQuery().get("tag");
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(null);
  const [pageArray, setPageArray] = useState([]);
  const [getArticles, { loading, data }] = useLazyQuery(Get_ARTICLES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ articles }) {
      if (articles) {
        setTimeout(() => {
          setPageMax(articles.pageCount);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    getArticles({ variables: { tag: searchTag } });
  }, [searchTag, getArticles]);

  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);

  return (
    <Container>
      <Box padding={`${theme.spacing(2)}px 0`}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h5" align="center">
              最新消息
            </Typography>
          </Grid>
          {loading ? (
            <Grid container item justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container item>
              {Boolean(data) &&
                data.articles.articles.map((item) => (
                  <Grid container item key={item.id}>
                    <NewsListItem data={item} />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
        {Boolean(pageArray[0]) && (
          <Grid container item>
            <PageItem data={pageArray} page={page} onChangePage={setPage} />
          </Grid>
        )}
      </Box>
    </Container>
  );
}

function NewsListItem({ data }) {
  const history = useHistory();
  const useStyles = makeStyles({
    content: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      boxOrient: "vertical",
      lineClamp: 2,
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();
  function _goToProduct() {
    return history.push(`/article/${data.id}`);
  }
  function _searchTag(params) {
    return history.push(`/article?tag=${params}`);
  }

  return (
    <Box padding={1} width="100%">
      <Grid container spacing={1} justifyContent="center">
        <Grid xs={12} sm={4} item>
          <AspectRatioBox width="100%" w={16} h={9}>
            <ButtonBase
              style={{ width: "100%", height: "100%" }}
              onClick={_goToProduct}
            >
              <img
                className={classes.image}
                src={
                  data.imageUrl
                    ? data.imageUrl
                    : require("../images/@luyaoLogo-new.jpg").default
                }
                alt=""
              />
            </ButtonBase>
          </AspectRatioBox>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={8}
          direction="column"
          alignItems="flex-start"
          style={{ textAlign: "start" }}
        >
          <Link
            component="button"
            onClick={_goToProduct}
            style={{ textAlign: "start" }}
          >
            <Typography variant="h6" gutterBottom>
              {data.title}
            </Typography>
          </Link>
          <Typography className={classes.content}>
            {Boolean(data.details.find((item) => item.type === "PARAGRAPH"))
              ? data.details.find((item) => item.type === "PARAGRAPH").body
              : "無"}
          </Typography>
          <Box
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="caption">
              {moment(data.createdAt).format("YYYY/MM/DD")}
            </Typography>
            <Typography variant="caption">VIEW&nbsp;{data.click}</Typography>
          </Box>
          <Grid container spacing={1}>
            {data.tags.split(",").map((item) => (
              <Grid item key={item}>
                <Chip
                  color="primary"
                  label={item}
                  onClick={() => _searchTag(item)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
