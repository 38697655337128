import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box, Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardActions, CardContent } from "../../component/Card";
import PageItem from "../../component/PageItem";
import { TextInput, DateInput } from "../../component/Form";

const GET_ARTICLES = gql`
  query articles(
    $title: String
    $tag: String
    $startTime: Date
    $endTime: Date
    $pageSize: Int
    $page: Int
  ) {
    articles(
      title: $title
      tag: $tag
      startTime: $startTime
      endTime: $endTime
      pageSize: $pageSize
      page: $page
    ) {
      articleCount
      pageCount
      articles {
        id
        title
        tags
        createdAt
        updatedAt
      }
    }
  }
`;

export default function ArticleTable({ onEditSelect = () => {} }) {
  const Alert = useAlert();
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(null);
  const [pageArray, setPageArray] = useState([]);

  const { data, loading } = useQuery(GET_ARTICLES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      title,
      tag,
      startTime,
      endTime,
      pageSize: 10,
      page,
    },
    onCompleted({ articles }) {
      setTimeout(() => {
        setPageMax(articles.pageCount);
      }, 0);
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);

  useEffect(() => {
    if (data) {
      if (!data.articles.articles[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  if (loading) {
    return (
      <Grid container item justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <TextInput
                  label={"標題"}
                  value={title}
                  onChange={(value) => setTitle(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextInput
                  label={"標籤"}
                  value={tag}
                  onChange={(value) => setTag(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <DateInput
                  value={startTime}
                  onChange={(v) =>
                    setStartTime(
                      new Date(moment(v).format("YYYY/MM/DD 00:00:00"))
                    )
                  }
                  format="yyyy-MM-dd"
                  views={["year", "month", "date"]}
                  fullWidth
                  label={"開始時間"}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <DateInput
                  value={endTime}
                  onChange={(v) =>
                    setEndTime(
                      new Date(moment(v).format("YYYY/MM/DD 23:59:59"))
                    )
                  }
                  format="yyyy-MM-dd"
                  views={["year", "month", "date"]}
                  fullWidth
                  minDate={moment(startTime)}
                  minDateMessage={"結束時間必須大於開始時間"}
                  label={"結束時間"}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            paper
            data={data ? data.articles.articles : []}
            header={["標題", "標籤", "建立時間", "最後更新時間"]}
            tableWidth={2}
            columns={[
              "title",
              (item) => {
                const newTags =
                  item.tags.split("").length > 16
                    ? item.tags.slice(0, 16) + "..."
                    : item.tags;
                return newTags;
              },
              (item) => moment(item.createdAt).format("YYYY/MM/DD"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD"),
            ]}
            onPress={(item) => onEditSelect(item)}
          />
        </CardContent>
        <CardActions>
          <PageItem
            data={pageArray}
            page={page}
            onChangePage={(e) => setPage(e)}
          />
        </CardActions>
      </Card>
    );
  }
}
