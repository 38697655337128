import React from "react";
/**
 * @typedef AspectBoxProps
 * @property {number} w 寬比
 * @property {number} h 高比
 * @property {number | string} width 寬度
 */
/**
 * @param {AspectBoxProps} param0
 */
export default function AspectBox({ children, w = 1, h = 1, width = "100%" }) {
  return (
    <div style={{ width }}>
      <div
        style={{
          position: "relative",
          paddingTop: `${(h / w) * 100}%`,
          wdith: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
