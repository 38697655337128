import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import "./special-event.css";

export default function AreaInformation({
  icon = true,
  title,
  subtitle,
  content,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box pb={!isMobile && "40px"}>
      <Box className="area-title-container" mb="32px">
        <img
          src={"/img/special/mark-arrow-right.png"}
          alt="arrow"
          className="arrow-icon"
        />
        <Typography
          className="area-title"
          style={{ fontSize: isMobile ? "2em" : "3em" }}
        >
          {title}
        </Typography>
      </Box>
      {/* Content */}
      <Box className="area-content-container">{content}</Box>
    </Box>
  );
}
