import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
// material-ui
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  useTheme,
  Typography,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Card,
  CardContent,
  useMediaQuery,
  CardActionArea,
  CircularProgress,
  FormLabel,
  colors,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
// react-router-dom
import { useHistory, useParams } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";
// react-swipeable-views
import SwipeableViews from "react-swipeable-views";
// apollo
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// moment
import moment from "moment";
// react-redux
import { useSelector } from "react-redux";
// react-hook-form
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

// component
import SquareBox from "../component/SquareBox";
import { useAlert } from "../component/Alert";
import {
  splitArray,
  getMemberTier,
  checkMemberTierLimit,
} from "../component/utils";
import DateCountdown from "../component/DateCountdown";
import { useLocalStorage } from "../component/LocalStorageContext";
import LoadingFloating from "../component/LoadingFloating";
import CreateOrderFloatingLayer from "../component/CreateOrderFloatingLayer";
import PasswordDialog from "../component/PasswordDialog";
// utils
import emptyArray from "../utils/emptyArray";
import { paymentMethodEnum } from "../utils/localData";
// zustand
import useTokenStore from "../zustand/useTokenStore";
import useProductPasswordStore from "../zustand/useProductPasswordStore";

const GET_PRODUCT = gql`
  query product($slug: String) {
    product(slug: $slug) {
      id
      type
      images {
        filename
        mimetype
        encoding
        location
      }
      preorder
      name
      size
      material
      limit
      limitText
      stock
      price
      depositSize
      maxAmountPerOrder
      startTime
      endTime
      details {
        type
        body
      }
      memberTierLimit
      createdBy {
        id
        fullName
      }
      updatedBy {
        id
        fullName
      }
      # "付款方式"
      paymentMethods
      createdAt
      updatedAt
    }
  }
`;

const Get_Product_Stock = gql`
  query product($slug: String) {
    product(slug: $slug) {
      id
      stock
    }
  }
`;

const Add_ItemToCart = gql`
  mutation addItemToCart($id: Int!, $cartItemInput: CartItemInput!) {
    addItemToCart(id: $id, cartItemInput: $cartItemInput) {
      success
    }
  }
`;

const Create_Order = gql`
  mutation createOrder(
    $cartItemInput: CartItemInput
    $shipmentInput: ShipmentInput
  ) {
    createOrder(cartItemInput: $cartItemInput, shipmentInput: $shipmentInput) {
      id
      items {
        id
        product {
          id
          name
          images {
            filename
            mimetype
            encoding
            location
          }
        }
        unitPrice
        amount
      }
      totalPrice
      paymentUrl
    }
  }
`;
// NOTE 確認商品密碼
/** - 確認商品密碼 */
const CHECK_PRODUCT_PASSWORD = gql`
  mutation checkProductPassword($slug: String!, $password: String) {
    checkProductPassword(slug: $slug, password: $password) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  infoText: {
    color: theme.palette.text.hint,
  },
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    borderRadius: 0,
    width: "100%",
  },
  countButton: {
    minWidth: 40,
    minHeight: 40,
    padding: theme.spacing(0.5),
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  arrowIconArea: {
    display: "flex",
    height: "inherit",
    alignItems: "center",
  },
  arrowIconButton: {
    height: "100%",
  },
  arrowIcon: {
    width: 36,
    height: 36,
    color: theme.palette.text.hint,
  },
  itemButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > *": {
      width: "100%",
    },
  },
}));
export default function ProductPage() {
  const memberTierLimit = useSelector((state) => state.member.memberTierLimit);
  const Alert = useAlert();
  const history = useHistory();
  const { id } = useParams();
  const LocalStorage = useLocalStorage();
  const productForm = useForm({
    defaultValues: {
      count: 1,
    },
  });
  const [stock, setStock] = useState(0);
  const count = productForm.getValues("count");
  const [cartAvailable, setCartAvailable] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const contentRef = useRef("");
  const paymentMethodRef = useRef("");
  const passwordDialogRef = useRef();
  const startPollingRef = useRef();
  const [goldFlowButtonDisable, setGoldFlowButtonDisable] = useState(false);
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host.search(".s3-") === -1
    ) {
      window.fbq("track", "ViewContent");
    }
  }, []);

  function testData() {
    let newArray = [];
    for (let i = 0; i < 10; i++) {
      newArray.push({
        id: i,
        image: "https://material-ui.com/static/images/cards/paella.jpg",
      });
    }
    return newArray;
  }

  const [getProduct, { data, loading: productLoading }] = useLazyQuery(
    GET_PRODUCT,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        slug: isNaN(id) ? id : undefined,
      },
      onCompleted({ product }) {
        if (product) {
          setTimeout(() => {
            setCartAvailable(
              !product.preorder && !product.limit && !product.endTime
            );
            setStock(product.stock);
            const luyaoProductSelectStoreRandomCode = localStorage.getItem(
              "@luyaoProductSelectStoreRandomCode"
            );
            if (luyaoProductSelectStoreRandomCode) {
              const luyaoProductSelectStoreCount = localStorage.getItem(
                "@luyaoProductSelectStoreCount"
              );
              productForm.setValue(
                "count",
                Number(luyaoProductSelectStoreCount)
              );
              contentRef.current = `您確定要訂購【${data.product.name}】× ${luyaoProductSelectStoreCount}？即將進入付款流程。`;
              return setOpen(true);
            }
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const product = useMemo(() => data?.product ?? {}, [data]);

  const [checkProductPasswordFn, { loading: checkProductPasswordLoading }] =
    useMutation(CHECK_PRODUCT_PASSWORD, {
      variables: {
        slug: isNaN(id) ? id : undefined,
      },
      onCompleted({ checkProductPassword: { success, message } }) {
        if (success) {
          useProductPasswordStore.getState().clearProductPassword();
          getProduct();
        } else if (message) {
          passwordDialogRef.current?.open(id);
        }
      },
      onError() {
        return null;
      },
    });
  const _handleEvent = useCallback(
    ({ password }) => {
      checkProductPasswordFn({ variables: { password } });
    },
    [checkProductPasswordFn]
  );
  useEffect(() => {
    const productPassword = useProductPasswordStore.getState().productPassword;
    _handleEvent({ password: productPassword });
  }, [_handleEvent]);

  const [getProductStock] = useLazyQuery(Get_Product_Stock, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      slug: isNaN(id) ? id : undefined,
    },
    onCompleted({ product }) {
      setTimeout(() => {
        setStock(product.stock);
      }, 0);
    },
    // onError(error) {
    //   Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    // },
  });

  useEffect(() => {
    if (product?.id) {
      startPollingRef.current = setInterval(() => {
        getProductStock();
      }, 1000);
    }
    return () => {
      if (startPollingRef.current) {
        clearInterval(startPollingRef.current);
      }
    };
  }, [product, getProductStock]);

  function _trackInitiateCheckout(amount) {
    const { product } = data;
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host.search(".s3-") === -1
    ) {
      window.fbq("track", "InitiateCheckout", {
        content_category: "product",
        content_ids: product["id"],
        currency: "TWD",
        value: product["price"],
        num_items: amount,
      });
    }
  }

  function _trackAddToCart() {
    const { product } = data;
    if (
      process.env.NODE_ENV === "production" &&
      window.location.host.search(".s3-") === -1
    ) {
      window.fbq("track", "AddToCart", {
        content_type: "product",
        content_ids: product["id"],
        content_name: product["name"],
        currency: "TWD",
        value: product["price"],
      });
    }
  }

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    Create_Order,
    {
      onCompleted({ createOrder }) {
        if (createOrder) {
          _trackInitiateCheckout(createOrder["items"][0]["amount"]);
          window.location =
            createOrder.paymentUrl +
            "&paymentMethod=" +
            paymentMethodRef.current;
        } else {
          setGoldFlowButtonDisable(false);
          return Alert.alert("", "請先登入後再操作。", [
            {
              text: "回首頁",
              onPress: () => history.replace("/"),
              type: "ok",
            },
            {
              text: "登入",
              onPress: () => history.replace("/login"),
              type: "cancel",
            },
          ]);
        }
      },
      onError(error) {
        setGoldFlowButtonDisable(false);
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  function _buy({ count }, product) {
    if (memberToken) {
      if (checkMemberTierLimit(memberTierLimit, data.product.memberTierLimit)) {
        return setTimeout(() => {
          Alert.notice(
            `會員等級必須達到「${getMemberTier(
              data.product.memberTierLimit
            )}」才有購買資格！`
          );
        }, 0);
      } else {
        contentRef.current = `您確定要訂購【${product.name}】× ${count}？即將進入付款流程。`;
        return setOpen(true);
      }
    } else {
      return Alert.alert("通知", "尚未登入，請先登入才可付款！", [
        {
          text: "登入",
          onPress: () => history.push("/login"),
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    }
  }

  const [addItemToCart, { loading: addItemToCartLoading }] = useMutation(
    Add_ItemToCart,
    {
      variables: {
        id: Number(LocalStorage.getCartId()),
        cartItemInput: {
          productId: Number(product?.id),
          amount: count,
        },
      },
      onCompleted({ addItemToCart }) {
        if (addItemToCart.success) {
          _trackAddToCart();
          return Alert.notice("加入購物車成功！");
        } else {
          return Alert.notice("加入購物車失敗！");
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  function _addCart({ count }, value) {
    return Alert.alert(
      "通知",
      `確定要加入 ${count} 個「${value}」到購物車嗎？`,
      [
        {
          text: "確定",
          onPress: () => {
            if (
              checkMemberTierLimit(
                memberTierLimit,
                data.product.memberTierLimit
              )
            )
              return setTimeout(() => {
                Alert.notice(
                  `會員等級必須達到「${getMemberTier(
                    data.product.memberTierLimit
                  )}」才有購買資格！`
                );
              }, 0);
            else addItemToCart();
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]
    );
  }

  // NOTE 付款方式
  const paymentMethods = useMemo(() => {
    const allPaymentMethods = [];
    emptyArray(data?.product?.paymentMethods).forEach((item) => {
      allPaymentMethods.push({
        label: paymentMethodEnum[item],
        value: item === "CREDIT_CARD" ? "Credit" : item,
      });
    });
    return allPaymentMethods;
  }, [data]);

  function switchArea() {
    if (productLoading || checkProductPasswordLoading) {
      return (
        <Box
          display="flex"
          height={`calc(100vh - 64px - ${theme.spacing(3)}px)`}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    } else {
      if (data && data.product) {
        return (
          <>
            <Grid container>
              <Helmet>
                <title>{data.product.name}｜路遙圓創</title>
              </Helmet>
              <Grid
                container
                item
                direction="column"
                justify="space-between"
                xs={12}
                sm={6}
              >
                <div>
                  <Box display="flex" padding={`${theme.spacing(1)}px 0`}>
                    <Grid container spacing={1}>
                      {Boolean(data.product.preorder) && (
                        <Grid item>
                          <Box className={classes.tag}>
                            <Typography variant="h6">預</Typography>
                          </Box>
                        </Grid>
                      )}
                      {Boolean(data.product.limit) && (
                        <Grid item>
                          <Box className={classes.tag}>
                            <Typography variant="h6">限</Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Typography variant="h5" className={classes.title}>
                    {data.product.name}
                  </Typography>
                  <Divider className={classes.divider} />
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  margin={`${theme.spacing(4)}px 0`}
                >
                  <Box>
                    <Typography className={classes.infoText}>
                      尺寸&ensp;|&ensp;{data.product.size}
                    </Typography>
                    <Typography className={classes.infoText}>
                      材質&ensp;|&ensp;{data.product.material}
                    </Typography>
                    {data.product.limitText || data.product.limit ? (
                      <Typography className={classes.infoText}>
                        限量&ensp;|&ensp;
                        {data.product.limitText ?? `${data.product.limit}個`}
                      </Typography>
                    ) : null}
                    {false && (
                      <Typography className={classes.infoText}>
                        庫存&ensp;|&ensp;{stock}
                      </Typography>
                    )}
                  </Box>
                  <PriceBrand
                    price={data.product.price}
                    depositSize={data.product.depositSize}
                  />
                </Box>
                <Box paddingBottom={1} paddingTop={1}>
                  <Grid container spacing={1}>
                    <Grid container item>
                      <Box paddingTop={1} paddingBottom={1} width="100%">
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormLabel>商品數量</FormLabel>
                            <CountAdjust
                              stock={stock}
                              maxAmountPerOrder={data.product.maxAmountPerOrder}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {(() => {
                      if (
                        (stock < 1 ||
                          moment(data.product.endTime).isBefore()) &&
                        data.product.type === "NORMAL"
                      ) {
                        return (
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              style={{
                                height: 44,
                                backgroundColor: colors.red["700"],
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                SOLD OUT
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      } else {
                        if (stock < 1) {
                          return (
                            <Grid item xs={12}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                style={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                                color={theme.palette.primary.contrastText}
                              >
                                <Typography variant="h6">已結束</Typography>
                              </Box>
                            </Grid>
                          );
                        } else if (moment(data.product.startTime).isBefore()) {
                          if (moment(data.product.endTime).isBefore()) {
                            return (
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  style={{
                                    backgroundColor: theme.palette.primary.main,
                                  }}
                                  color={theme.palette.primary.contrastText}
                                >
                                  <Typography variant="h6">已結束</Typography>
                                </Box>
                              </Grid>
                            );
                          } else {
                            return (
                              <>
                                <Grid item xs={cartAvailable ? 6 : 12}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={productForm.handleSubmit((e) =>
                                      _buy(e, data.product)
                                    )}
                                    disabled={goldFlowButtonDisable}
                                  >
                                    <Typography variant="h6">
                                      馬上購買
                                    </Typography>
                                  </Button>
                                </Grid>
                                {cartAvailable && (
                                  <Grid item xs={6}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className={classes.button}
                                      onClick={productForm.handleSubmit((e) =>
                                        _addCart(e, data.product.name)
                                      )}
                                    >
                                      <Typography variant="h6">
                                        加入購物車
                                      </Typography>
                                    </Button>
                                  </Grid>
                                )}
                                {moment(data.product.endTime).isAfter() && (
                                  <Grid item container justify="center">
                                    <DateCountdown
                                      end={
                                        Boolean(data) &&
                                        moment(data.product.endTime)
                                      }
                                      endLabel="結單倒數"
                                    />
                                  </Grid>
                                )}
                              </>
                            );
                          }
                        } else {
                          return (
                            <Grid item container justify="center">
                              <DateCountdown
                                start={
                                  Boolean(data) &&
                                  moment(data.product.startTime)
                                }
                                startLabel="開單倒數"
                              />
                            </Grid>
                          );
                        }
                      }
                    })()}
                  </Grid>
                </Box>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Box padding={1} display="flex" flex={1}>
                  <ProductImages data={data.product.images} />
                </Box>
              </Grid>
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
            <Grid container direction="column" spacing={1}>
              {data.product.details.map((item, index) => (
                <Grid container item key={index}>
                  <DetailArea data={item} />
                </Grid>
              ))}
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
            {false && (
              <Box padding={`${theme.spacing(2)}px 0`}>
                <Typography variant="h6">推薦商品</Typography>
                <RecommendedProducts data={testData()} />
              </Box>
            )}
          </>
        );
      } else {
        return (
          <>
            <PasswordDialog
              ref={passwordDialogRef}
              handleEvent={_handleEvent}
            />
            <Box
              display="flex"
              height={`calc(100vh - 64px - ${theme.spacing(3)}px)`}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6">找不到商品</Typography>
            </Box>
          </>
        );
      }
    }
  }
  return (
    <Container maxWidth="md" className={classes.container}>
      <LoadingFloating loading={addItemToCartLoading || createOrderLoading} />
      <FormProvider {...productForm}>
        <CreateOrderFloatingLayer
          open={open}
          content={contentRef.current}
          count={count}
          onChangeFloatingwindowClose={(
            value,
            paymentMethod,
            shipmentInput
          ) => {
            contentRef.current = "";
            setOpen(false);
            if (value === "mutation") {
              paymentMethodRef.current = paymentMethod;
              setGoldFlowButtonDisable(true);
              createOrder({
                variables: {
                  cartItemInput: {
                    productId: Number(product?.id),
                    amount: count,
                  },
                  shipmentInput,
                },
              });
            }
          }}
          paymentMethods={paymentMethods}
        />
        {switchArea()}
      </FormProvider>
    </Container>
  );
}
// ANCHOR 內容區域
function DetailArea({ data }) {
  switch (data.type) {
    case "HEADING": //"標題"
      return <Typography variant="h6">{data.body}</Typography>;
    case "PARAGRAPH": //"段落"
      return (
        <Typography>
          {data.body.split("\n").map((line) => (
            <>
              {line}
              <br />
            </>
          ))}
        </Typography>
      );
    case "IMAGE": //"圖片"
      return (
        <img
          src={data.body}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            alignSelf: "flex-start",
          }}
          alt=""
        />
      );
    case "YOUTUBE_VIDEO": //"影片"
      let url = data.body;
      let embedUrl = "";
      if (url) {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          if (url.search("embed") === -1) {
            if (url[8] === "w") {
              url = url.split("watch?v=")[1].split("&")[0];
            } else {
              url = url.split("youtu.be/")[1];
            }
          } else {
            embedUrl = data.body;
          }
        } else {
          return null;
        }
      }
      return (
        <iframe
          width="900"
          height="506"
          src={embedUrl || "https://www.youtube.com/embed/" + url}
          style={{ border: `0px` }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          title="youtube"
        />
      );
    default:
      return null;
  }
}
// ANCHOR 數量選擇
function CountAdjust({ stock, maxAmountPerOrder }) {
  const { control } = useFormContext();
  const classes = useStyles();
  return (
    <Controller
      control={control}
      name="count"
      render={({ field: { onChange, value } }) => (
        <ButtonGroup style={{ display: "flex", flex: 1 }}>
          <Button
            onClick={() => onChange(Math.max(1, value - 1))}
            className={classes.countButton}
          >
            <RemoveIcon />
          </Button>
          <Button style={{ flex: 1 }} className={classes.countButton}>
            {value}
          </Button>
          <Button
            onClick={() =>
              onChange(
                // TODO 要限制購買數量再改用這行
                Math.max(value, Math.min(value + 1, stock, maxAmountPerOrder))
                // value + 1
              )
            }
            className={classes.countButton}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
      )}
    />
  );
}
// ANCHOR 價格板子
function PriceBrand({ price = 0, depositSize }) {
  const { control } = useFormContext();
  const count = useWatch({ control, name: "count" });
  const theme = useTheme();
  function thousandsSeparator(number) {
    return number.toLocaleString("en-US");
  }
  return (
    <Box
      bgcolor={theme.palette.primary.main}
      padding={`0 ${theme.spacing(2)}px`}
      color={theme.palette.primary.contrastText}
      alignSelf="flex-end"
    >
      <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
        {depositSize < 1 && "訂金"}&ensp;NTD&ensp;
        {thousandsSeparator(price * depositSize * count)}
      </Typography>
    </Box>
  );
}
// ANCHOR 商品圖片
function ProductImages({ data }) {
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const newData = useMemo(() => {
    return splitArray(
      data,
      (() => {
        if (isMobile) {
          return 2;
        } else if (isTable) {
          return 3;
        } else {
          return 4;
        }
      })()
    );
  }, [data, isMobile, isTable]);
  const [imageIndex, setImageIndex] = useState(0);
  const [selectImage, setSelectImage] = useState(null);
  useEffect(() => {
    setImageIndex(0);
  }, [isTable, isMobile]);
  useEffect(() => {
    if (selectImage === null && newData[0]) {
      setSelectImage(newData[0][0].location);
    }
  }, [newData, setSelectImage, selectImage]);
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignContent="space-between">
      <Grid item xs={12}>
        <Card variant="outlined">
          <SquareBox>
            <img src={selectImage} className={classes.image} alt="" />
          </SquareBox>
        </Card>
      </Grid>
      <Grid container item>
        <SwipeableViews
          style={{ width: "100%" }}
          index={imageIndex}
          onChangeIndex={setImageIndex}
          enableMouseEvents
        >
          {newData.map((item, index) => (
            <Box padding={0.5} key={index}>
              <Grid container spacing={1}>
                {item.map((item2) => {
                  const onSelect = item2.location === selectImage;
                  return (
                    <Grid item xs={6} sm={4} md={3} key={item2.id}>
                      <Card
                        variant="outlined"
                        style={{
                          borderColor: onSelect && theme.palette.primary.main,
                          borderWidth: onSelect && 2,
                        }}
                      >
                        <CardActionArea
                          onClick={() => setSelectImage(item2.location)}
                        >
                          <SquareBox>
                            <img
                              src={item2.location}
                              className={classes.image}
                              alt=""
                            />
                          </SquareBox>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          ))}
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}
// ANCHOR 推薦商品
function RecommendedProducts({ data }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isTable = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const newData = useMemo(() => {
    splitArray(
      data,
      (() => {
        if (isMobile) {
          return 1;
        } else if (isTable) {
          return 2;
        } else {
          return 3;
        }
      })()
    );
  }, [data, isMobile, isTable]);
  const [selectIndex, setSelectIndex] = useState(0);
  useEffect(() => {
    setSelectIndex(0);
  }, [isMobile, isTable]);
  function _next() {
    setSelectIndex((e) => {
      if (e >= newData.length - 1) {
        return 0;
      } else {
        return e + 1;
      }
    });
  }
  function _prev() {
    setSelectIndex((e) => {
      if (e <= 0) {
        return newData.length - 1;
      } else {
        return e - 1;
      }
    });
  }
  function _gotoProduct(id) {
    return history.push(`/product/${id}`);
  }
  return (
    <Box display="flex" width="100%">
      <Box className={classes.arrowIconArea}>
        <ButtonBase className={classes.arrowIconButton} onClick={_prev}>
          <ArrowLeftIcon className={classes.arrowIcon} />
        </ButtonBase>
      </Box>
      <SwipeableViews
        enableMouseEvents
        index={selectIndex}
        onChangeIndex={setSelectIndex}
      >
        {newData.map((item, index) => (
          <Box padding={3} key={index}>
            <Grid container justify="space-evenly">
              {item.map((item2) => (
                <Grid item xs={12} sm={5} md={3}>
                  <ButtonBase
                    className={classes.itemButton}
                    onClick={() => _gotoProduct(item2.id)}
                  >
                    <Card variant="outlined">
                      <SquareBox>
                        <img
                          src={item2.image}
                          className={classes.image}
                          alt=""
                        />
                      </SquareBox>
                    </Card>
                    <CardContent>
                      <Typography align="center">標題</Typography>
                    </CardContent>
                    <PriceBrand price={2000} />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </SwipeableViews>
      <Box className={classes.arrowIconArea}>
        <ButtonBase className={classes.arrowIconButton} onClick={_next}>
          <ArrowRightIcon className={classes.arrowIcon} />
        </ButtonBase>
      </Box>
    </Box>
  );
}
