import React, { useEffect, useMemo } from "react";
// mui
import {
  makeStyles,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select as Select2,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
} from "@material-ui/core";
import { deepmerge } from "@material-ui/utils";
// apollo
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// hook-form
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router-dom";

// component
import LoadingModal from "../../component/LoadingModal";
import { useAlert } from "../../component/Alert";
import Button from "../../component/Button";
import Grid from "../../component/Grid";
// utils
import { countiesList, districtsList } from "../../utils/countiesDistricts";
import { shipmentMethodList } from "../../utils/localData";
import { FieldCache } from "../../component/FieldCache";
import { getInvitationCode } from "../../component/utils";

// NOTE apolloMutation
// 更改出貨地址
const UPDATE_UPDATESHIPMENTADDRESS = gql`
  mutation updateShipmentAddress(
    $id: Int!
    $shipmentMethod: ShipmentMethod!
    $convenienceStore: String
    $address: AddressInput
  ) {
    updateShipmentAddress(
      id: $id
      shipmentMethod: $shipmentMethod
      convenienceStore: $convenienceStore
      address: $address
    ) {
      success
      message
    }
  }
`;
// 重新建立綠界出貨單
const CREATE_NEWECPAYSHIPMENTORDER = gql`
  mutation createNewEcpayShipmentOrder($shipmentInput: ShipmentInput!) {
    createNewEcpayShipmentOrder(shipmentInput: $shipmentInput) {
      success
      message
    }
  }
`;
// 門市電子地圖
const GOTO_LOGISTICSMAP = gql`
  query logisticsMap($randomCode: String!, $returnUrl: String!) {
    logisticsMap(randomCode: $randomCode, returnUrl: $returnUrl)
  }
`;
// 使用隨機碼查詢門市電子地圖回傳資訊
const GET_LOGISTICSMAPDATA = gql`
  query logisticsMapData($randomCode: String!) {
    logisticsMapData(randomCode: $randomCode) {
      id
      cvsStoreId
      cvsStoreName
    }
  }
`;

export default function ShipmentFloatingLayer({
  clickData,
  onChangeFloatingwindowClose = () => {},
}) {
  const useStylesTable = makeStyles({
    dialogPaper: {
      maxHeight: `calc(90%)`,
      width: `calc(100%)`,
      margin: 8,
    },
    Container: {
      display: "flex",
      justifyContent: "center",
      color: "red",
    },
    divider: {
      height: 30,
    },
  });
  const classesTable = useStylesTable();
  const updateShipmentAddressForm = useForm({
    defaultValues: {
      shipmentMethod: "HOME_DELIVERY",
      recipient: "",
      recipientPhone: "",
      convenienceStore: "",
      district: "",
      subdistrict: "",
      address: "",
      _selectStoreDisabled: false,
    },
  });
  const { reset, getValues } = updateShipmentAddressForm;

  const [getLogisticsMapData] = useLazyQuery(GET_LOGISTICSMAPDATA, {
    onCompleted({ logisticsMapData }) {
      if (logisticsMapData) {
        setTimeout(() => {
          const { cvsStoreId, cvsStoreName } = logisticsMapData;
          const {
            __typename,
            district,
            subdistrict,
            address,
            ...otherShipments
          } = clickData;
          let newShipment = {
            ...otherShipments,
            shipmentMethod: "CONVENIENCE_STORE",
            convenienceStore: cvsStoreName,
            district: district ?? "",
            subdistrict: subdistrict ?? "",
            address: address ?? "",
            cvsStoreId,
            _selectStoreDisabled: false,
          };
          reset({ ...deepmerge(getValues(), newShipment) });
          setTimeout(() => {
            localStorage.removeItem(
              `@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode`
            );
            localStorage.removeItem(
              `@luyaoConsoleUpdateShipmentAddressSelectStoreGetValues`
            );
          }, 0);
        }, 0);
      }
    },
    onError() {
      const {
        __typename,
        convenienceStore,
        district,
        subdistrict,
        address,
        ...otherShipments
      } = clickData;
      let newShipment = {
        ...otherShipments,
        convenienceStore: convenienceStore ?? "",
        district: district ?? "",
        subdistrict: subdistrict ?? "",
        address: address ?? "",
      };
      reset({ ...deepmerge(getValues(), newShipment) });
      setTimeout(() => {
        localStorage.removeItem(
          `@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode`
        );
        localStorage.removeItem(
          `@luyaoConsoleUpdateShipmentAddressSelectStoreGetValues`
        );
      }, 0);
    },
  });

  useEffect(() => {
    if (Boolean(clickData?.id)) {
      if (clickData.shipmentMethod) {
        const luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode =
          localStorage.getItem(
            "@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode"
          );
        if (luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode) {
          getLogisticsMapData({
            variables: {
              randomCode:
                luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode,
            },
          });
        } else {
          const {
            __typename,
            convenienceStore,
            district,
            subdistrict,
            address,
            ...otherShipments
          } = clickData;
          let newShipment = {
            ...otherShipments,
            convenienceStore: convenienceStore ?? "",
            district: district ?? "",
            subdistrict: subdistrict ?? "",
            address: address ?? "",
          };
          reset({ ...deepmerge(getValues(), newShipment) });
        }
      }
    }
  }, [clickData, reset, getLogisticsMapData, getValues]);

  return (
    <Dialog
      open={Boolean(clickData)}
      fullWidth
      maxWidth={"sm"}
      classes={{ paper: classesTable.dialogPaper }}
    >
      <FormProvider {...updateShipmentAddressForm}>
        <DialogTitle classes={{ root: classesTable.Container }}>
          出貨地址更改
        </DialogTitle>
        <DialogContent>
          <ShipmentComponent />
        </DialogContent>
        <DialogActions>
          <ToolButton
            clickData={clickData}
            onClose={onChangeFloatingwindowClose}
          />
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

// ANCHOR ShipmentComponent
function ShipmentComponent() {
  const { notice } = useAlert();
  const { location } = useHistory();
  const { control, setValue, getValues } = useFormContext();
  const convenienceStoreArray = [
    {
      label: "7-11",
      value: "7-11",
    },
  ];

  const [goToLogisticsMap] = useLazyQuery(GOTO_LOGISTICSMAP, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ logisticsMap }) {
      if (logisticsMap) {
        setTimeout(() => {
          window.location = logisticsMap;
        }, 0);
      }
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  // 跳轉到超商電子地圖系統選擇門市
  const chooseStore = () => {
    setValue("_selectStoreDisabled", true);
    const randomCode = getInvitationCode(7);
    localStorage.setItem(
      `@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode`,
      String(randomCode)
    );
    localStorage.setItem(
      `@luyaoConsoleUpdateShipmentAddressSelectStoreGetValues`,
      JSON.stringify(getValues())
    );
    if (document.getElementById("cvsStoreNameTextField")) {
      document.getElementById("cvsStoreNameTextField").placeholder =
        "選擇超商轉跳中...";
    }
    goToLogisticsMap({
      variables: {
        randomCode: String(randomCode),
        returnUrl: `${window.location.protocol}//${window.location.host}${location?.pathname}`,
      },
    });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary">運送方式</Typography>
        <Controller
          control={control}
          name="shipmentMethod"
          render={({ field: { value, onChange } }) => (
            <FormGroup row>
              {shipmentMethodList
                .filter((i) => i.value !== "SELF_PICKUP" && i.value !== "ALL")
                .map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={item.value}
                        checked={Boolean(value === item.value)}
                        onChange={() => onChange(item.value)}
                      />
                    }
                    label={item.label}
                  />
                ))}
            </FormGroup>
          )}
        />
      </Grid>
      <Grid container item spacing={1}>
        <FieldCache
          control={control}
          name="shipmentMethod"
          render={(shipmentMethod) => {
            if (shipmentMethod === "CONVENIENCE_STORE") {
              return (
                <Grid item>
                  <Typography color="primary">收件門市</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Select2 value="7-11" fullWidth displayEmpty disabled>
                        {convenienceStoreArray.map((item) => (
                          <MenuItem value={item.value} key={item}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select2>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        control={control}
                        name="convenienceStore"
                        rules={{
                          required: "* 必填欄位",
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            id="cvsStoreNameTextField"
                            placeholder={"請輸入門市 ex: xx門市"}
                            error={error}
                            helperText={error?.message}
                            fullWidth
                            onClick={chooseStore}
                            inputProps={{ readOnly: true }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid item>
                  <Typography color="primary">收件人地址</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name="district"
                        rules={{
                          required: "* 必填欄位",
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Select2
                              {...field}
                              error={error}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setValue("subdistrict", "");
                              }}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                縣市
                              </MenuItem>
                              {countiesList("zhTW").map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select2>
                            {error && (
                              <FormHelperText error={error}>
                                {error.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FieldCache
                        control={control}
                        name="district"
                        render={(district) => (
                          <Controller
                            control={control}
                            name="subdistrict"
                            rules={{
                              required: "* 必填欄位",
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <>
                                <Select2
                                  {...field}
                                  error={error}
                                  fullWidth
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>
                                    地區
                                  </MenuItem>
                                  {districtsList(district).map((item) => (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select2>
                                {error && (
                                  <FormHelperText error={error}>
                                    {error.message}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="address"
                        rules={{
                          required: "* 必填欄位",
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={error}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

// ANCHOR ToolButton
function ToolButton({ clickData, onClose = () => {} }) {
  const { alert, notice } = useAlert();
  const { handleSubmit, reset, getValues } = useFormContext();
  const isDisabled = useButtonDisabled(clickData);
  const _selectStoreDisabled = useWatch({ name: "_selectStoreDisabled" });

  const [
    createNewEcpayShipmentOrderFn,
    { loading: createNewEcpayShipmentOrderLoading },
  ] = useMutation(CREATE_NEWECPAYSHIPMENTORDER, {
    onCompleted({ createNewEcpayShipmentOrder }) {
      if (createNewEcpayShipmentOrder.success) {
        notice("更改成功。");
        reset({
          shipmentMethod: "HOME_DELIVERY",
          recipient: "",
          recipientPhone: "",
          convenienceStore: "",
          district: "",
          subdistrict: "",
          address: "",
          _selectStoreDisabled: false,
        });
        onClose("refetch");
      } else if (createNewEcpayShipmentOrder.message) {
        notice(createNewEcpayShipmentOrder.message);
      }
    },
    onError(error) {
      notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [updateShipmentAddress, { loading: updateShipmentAddressLoading }] =
    useMutation(UPDATE_UPDATESHIPMENTADDRESS, {
      onCompleted({ updateShipmentAddress }) {
        if (updateShipmentAddress.success) {
          if (getValues("shipmentMethod") === "CONVENIENCE_STORE") {
            const shipmentInput = {
              id: getValues("id"),
              shipmentMethod: "CONVENIENCE_STORE",
              recipient: getValues("recipient"),
              recipientPhone: getValues("recipientPhone"),
              shipmentSubType: "SEVEN_ELEVEN",
              cvsStoreId: getValues("cvsStoreId"),
              convenienceStore: getValues("convenienceStore"),
            };
            createNewEcpayShipmentOrderFn({
              variables: { shipmentInput },
            });
          } else {
            notice("更改成功。");
            reset({
              shipmentMethod: "HOME_DELIVERY",
              recipient: "",
              recipientPhone: "",
              convenienceStore: "",
              district: "",
              subdistrict: "",
              address: "",
              _selectStoreDisabled: false,
            });
            onClose("refetch");
          }
        } else if (updateShipmentAddress.message) {
          notice(updateShipmentAddress.message);
        }
      },
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    });

  function initialization() {
    if (!updateShipmentAddressLoading && !createNewEcpayShipmentOrderLoading) {
      reset({
        shipmentMethod: "HOME_DELIVERY",
        recipient: "",
        recipientPhone: "",
        convenienceStore: "",
        district: "",
        subdistrict: "",
        address: "",
        _selectStoreDisabled: false,
      });
      onClose();
      setTimeout(() => {
        localStorage.removeItem(
          `@luyaoConsoleUpdateShipmentAddressSelectStoreRandomCode`
        );
        localStorage.removeItem(
          `@luyaoConsoleUpdateShipmentAddressSelectStoreGetValues`
        );
      }, 0);
    }
  }

  function _updateShipmentAddress({
    id,
    shipmentMethod,
    convenienceStore,
    district,
    subdistrict,
    address,
  }) {
    const sendInput = {
      id,
      shipmentMethod,
    };
    if (shipmentMethod === "CONVENIENCE_STORE") {
      alert("通知", "確定要更改超商門市？此操作會重新建立物流單。", [
        {
          text: "確定",
          onPress: () => {
            sendInput.convenienceStore = convenienceStore;
            updateShipmentAddress({
              variables: { ...sendInput },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
      return null;
    } else {
      sendInput.address = {
        district,
        subdistrict,
        address,
      };
    }
    updateShipmentAddress({
      variables: { ...sendInput },
    });
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <LoadingModal
        loading={
          updateShipmentAddressLoading || createNewEcpayShipmentOrderLoading
        }
      />
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onPress={handleSubmit(_updateShipmentAddress)}
          disabled={isDisabled || _selectStoreDisabled}
        >
          確定
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onPress={initialization}
          disabled={_selectStoreDisabled}
        >
          取消
        </Button>
      </Grid>
    </Grid>
  );
}

// ANCHOR buttonDisabled
function useButtonDisabled(clickData) {
  const watchAllData = useWatch();
  const isDisabled = useMemo(() => {
    if (clickData?.id) {
      let resultBoolean = true;
      for (const key in clickData) {
        for (const keyOfwatch in watchAllData) {
          if (
            key === keyOfwatch &&
            clickData[key] &&
            clickData[key] !== watchAllData[key]
          ) {
            resultBoolean = false;
          }
        }
      }
      return resultBoolean;
    }
    return true;
  }, [clickData, watchAllData]);
  return isDisabled;
}
