import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

/**
 * @typedef FieldCacheProps
 * @property {import('react-hook-form').Control} control
 * @property {string} name
 * @property {(callback: string) => void} render
 */
/**
 * @param {FieldCacheProps} param0
 */
export function FieldCache(props) {
  const methods = useFormContext();
  const { control = methods.control, name, render } = props;
  const value = useWatch({ control, name });
  return useMemo(() => render?.(value) || null, [value, render]);
}
