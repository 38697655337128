import Upload from "@material-ui/icons/CloudUpload";
import Download from "@material-ui/icons/CloudDownload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MuiAddIcon from "@material-ui/icons/Add";
import MuiCloseIcon from "@material-ui/icons/Close";
import { IconButton, Box } from "@material-ui/core";
const CustomClickStyles = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};
export const DownloadIcon = ({
  onClick = () => {},
  style,
  color = "error",
  disableIconButton,
  ...props
}) => {
  if (disableIconButton) {
    return (
      <Box
        style={{ ...CustomClickStyles, ...style }}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <Download color={color} />
      </Box>
    );
  }
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={style}
      {...props}
    >
      <Download color={color} />
    </IconButton>
  );
};
export const UploadIcon = ({
  onClick = () => {},
  style,
  color = "secondary",
  disableIconButton,
  ...props
}) => {
  if (disableIconButton) {
    return (
      <Box
        style={{ ...CustomClickStyles, ...style }}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <Upload color={color} />
      </Box>
    );
  }
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={style}
      {...props}
    >
      <Upload color={color} />
    </IconButton>
  );
};
export const DeleteIcon = ({
  onClick = () => {},
  style,
  color = "grey[100]",
  disableIconButton,
  ...props
}) => {
  if (disableIconButton) {
    return (
      <Box
        style={{ ...CustomClickStyles, ...style }}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <HighlightOffIcon color={color} />
      </Box>
    );
  }
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={style}
      {...props}
    >
      <HighlightOffIcon color={color} />
    </IconButton>
  );
};
export const AddIcon = ({
  onClick = () => {},
  style,
  color = "grey[100]",
  disableIconButton,
  ...props
}) => {
  if (disableIconButton) {
    return (
      <Box
        style={{ ...CustomClickStyles, ...style }}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <MuiAddIcon color={color} />
      </Box>
    );
  }
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={style}
      {...props}
    >
      <MuiAddIcon color={color} />
    </IconButton>
  );
};
export const CloseIcon = ({
  onClick = () => {},
  style,
  color = "grey[100]",
  disableIconButton,
  ...props
}) => {
  if (disableIconButton) {
    return (
      <Box
        style={{ ...CustomClickStyles, ...style }}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        <MuiCloseIcon color={color} />
      </Box>
    );
  }
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={style}
      {...props}
    >
      <MuiCloseIcon color={color} />
    </IconButton>
  );
};
