import React, { useCallback, useEffect, useMemo, useReducer } from "react";
// material
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from "@material-ui/core";
// icon
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// apollo
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
// date-fns
import { format, parseISO } from "date-fns";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import { InputTitle, AutocompleteSelect } from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
// utils
import emptyArray from "../../utils/emptyArray";

// SECTION apollo
// NOTE 玩具列表
const GET_TOYS = gql`
  query toys($eventId: Int!) {
    toys(eventId: $eventId, latest: true) {
      # "玩具"
      contents {
        id
        # "商品"
        product {
          id
          # "名稱"
          name
        }
      }
    }
  }
`;
// NOTE 餵食代碼列表
const GET_FEEDINGCODES = gql`
  query feedingCodes($eventId: Int!) {
    feedingCodes(eventId: $eventId, latest: true) {
      # "總數"
      count
      # "總頁數"
      pageCount
      # "餵食代碼"
      contents {
        id
        # "代碼"
        code
        # "玩具"
        toy {
          id
          # "商品"
          product {
            id
            # "名稱"
            name
          }
        }
        # "已使用"
        used
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 建立代碼(餵食代碼帶toyId，孵化代碼什麼都不用帶)
const CREATE_CODE = gql`
  mutation createCode($eventId: Int!, $toyId: Int, $quantity: Int!) {
    createCode(eventId: $eventId, toyId: $toyId, quantity: $quantity) {
      success
      message
    }
  }
`;
// !SECTION
// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "edit":
      return action.data;
    default:
      return null;
  }
}
// ANCHOR 主要組件
export default function FeedCodeManage({ eventId }) {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  const { control, setValue } = useForm({
    defaultValues: {
      listRefetch: false,
    },
  });

  switch (swtichContent?.swtichType) {
    case "add":
      return (
        <FeedCodeForm
          swtichContentDispatch={swtichContentDispatch}
          listFormSetValue={setValue}
          eventId={eventId}
        />
      );
    default:
      return (
        <FeedCodeList
          swtichContentDispatch={swtichContentDispatch}
          listFormControl={control}
          listFormSetValue={setValue}
          eventId={eventId}
        />
      );
  }
}
// ANCHOR 列表
function FeedCodeList({
  swtichContentDispatch = () => {},
  listFormControl,
  listFormSetValue,
  eventId,
}) {
  const listRefetch = useWatch({
    control: listFormControl,
    name: "listRefetch",
  });

  const {
    data: feedingCodesData,
    loading: feedingCodesLoading,
    refetch,
  } = useQuery(GET_FEEDINGCODES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onCompleted({ feedingCodes }) {
      if (feedingCodes) {
        setTimeout(() => {
          listFormSetValue("listRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (listRefetch) {
      refetch();
    }
  }, [listRefetch, refetch]);

  if (feedingCodesLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              進化代碼數量：{feedingCodesData?.feedingCodes?.count || 0}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() =>
                swtichContentDispatch({
                  type: "add",
                  data: {
                    swtichType: "add",
                  },
                })
              }
            >
              新增進化代碼
            </Button>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            data={emptyArray(feedingCodesData?.feedingCodes?.contents)}
            header={["進化代碼", "玩具", "使用狀態", "建立時間"]}
            tableWidth={2}
            columns={[
              "code",
              (item) => item.toy?.product?.name,
              (item) => (item.used ? "已使用" : "未使用"),
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
            ]}
          />
        </CardContent>
      </Card>
    );
  }
}
// ANCHOR form
function FeedCodeForm({
  swtichContentDispatch = () => {},
  listFormSetValue,
  eventId,
}) {
  const form = useForm({
    defaultValues: {
      toy: "",
    },
  });
  const { reset, setValue } = form;

  useEffect(() => {
    if (eventId) {
      setValue("eventId", eventId);
    }
  }, [eventId, setValue]);

  const { data: toysData, loading: toysLoading } = useQuery(GET_TOYS, {
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onError() {
      return null;
    },
  });
  const toys = useMemo(
    () =>
      emptyArray(toysData?.toys?.contents).map((item) => ({
        label: item.product.name,
        value: item.id,
      })),
    [toysData]
  );
  const _goBack = useCallback(() => {
    swtichContentDispatch({
      type: "list",
    });
    reset();
  }, [swtichContentDispatch, reset]);
  return (
    <Card>
      <FormProvider {...form}>
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>
              {"新增餵食代碼"}
            </Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="toy"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="玩具" required />
                    <AutocompleteSelect
                      {...field}
                      items={toys}
                      noOptionsText={`找不到玩具`}
                      loading={toysLoading}
                      loadingText="載入中..."
                      renderOption={(props) => (
                        <Box
                          component="li"
                          sx={{
                            fontSize: "12px",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                        >
                          {props.label}
                        </Box>
                      )}
                      onChange={(e, value) => field.onChange(value)}
                      error={error}
                      helperText={error?.message}
                      fullWidth
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ToolButton _goBack={_goBack} listFormSetValue={listFormSetValue} />
        </CardActions>
      </FormProvider>
    </Card>
  );
}
// ANCHOR 按鈕
function ToolButton({ _goBack = () => {}, listFormSetValue }) {
  const { notice } = useAlert();
  const { handleSubmit } = useFormContext();
  const [createCodeFn, { loading: createCodeLoading }] = useMutation(
    CREATE_CODE,
    {
      onCompleted({ createCode: { message } }) {
        if (message) {
          notice(message);
        } else {
          listFormSetValue("listRefetch", true);
          notice("成功");
          _goBack();
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _submit = useCallback(
    ({ eventId, toy }) => {
      createCodeFn({
        variables: {
          eventId,
          toyId: toy?.value,
          quantity: 1,
        },
      });
    },
    [createCodeFn]
  );
  const _onCancel = useCallback(() => {
    _goBack();
  }, [_goBack]);
  return (
    <>
      <LoadingModal loading={createCodeLoading} />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onPress={handleSubmit(_submit)}
          >
            儲存
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onPress={_onCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
