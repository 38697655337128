import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  useTheme,
  Typography,
  Box,
  Chip,
  CircularProgress,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { Controller, useForm } from "react-hook-form";

import { useAlert } from "../component/Alert";
import LoadingModal from "../component/LoadingModal";
import Image from "../component/Image";

const GET_ARTICLE = gql`
  query article($id: Int!) {
    article(id: $id) {
      id
      title
      tags
      details {
        type
        body
      }
    }
  }
`;

const GET_ARTICLE_COMMENTS = gql`
  query article($id: Int!) {
    article(id: $id) {
      id
      comments {
        id
        member {
          id
          currentLuyaoProfilePicture {
            id
            file {
              location
            }
          }
          fullName
          nickname
        }
        comment
        createdAt
      }
    }
  }
`;

const SAVE_AUCTIONCOMMENT = gql`
  mutation saveAuctionComment($id: Int, $articleId: Int!, $comment: String!) {
    saveAuctionComment(id: $id, articleId: $articleId, comment: $comment) {
      success
      message
    }
  }
`;

const INCREMENT_ARTICLECLICK = gql`
  mutation incrementArticleClick($id: Int!) {
    incrementArticleClick(id: $id) {
      success
      message
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `0 ${theme.spacing(1)}px`,
    height: 32,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  box: {
    "& > *+*": {
      marginTop: 25,
    },
    "&:last-child": {
      marginBottom: 25,
    },
  },
}));

export default function ArticlePage() {
  const Alert = useAlert();
  const theme = useTheme();
  const classes = useStyles();
  const { id } = useParams();
  const [tags, setTags] = useState([]);

  const [getArticle, { data, loading }] = useLazyQuery(GET_ARTICLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ article }) {
      if (article) {
        setTimeout(() => {
          const newTags = [];
          article.tags.split(",").forEach((item) => {
            newTags.push(item);
          });
          setTags(newTags);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getArticle({ variables: { id: Number(id) } });
    }
  }, [id, getArticle]);

  function switchArea() {
    if (loading) {
      return (
        <Box
          display="flex"
          height={`calc(100vh - 64px - ${theme.spacing(3)}px)`}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    } else {
      if (data) {
        return (
          <>
            <Grid container>
              <Helmet>
                <title>{data.article.title}｜路遙圓創</title>
              </Helmet>
            </Grid>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h5" className={classes.title}>
                  {data.article.title}
                </Typography>
              </Grid>
              <Grid container item direction="row" spacing={1}>
                {tags.map((item, index) => {
                  return (
                    <Grid item>
                      <Chip label={item} className={classes.tag} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
            <Grid container direction="column" spacing={1}>
              {data.article.details.map((item, index) => (
                <Grid container item key={index}>
                  <DetailArea data={item} />
                </Grid>
              ))}
            </Grid>
            <Divider
              className={classes.divider}
              style={{ margin: `${theme.spacing(5)}px 0` }}
            />
          </>
        );
      } else {
        return null;
      }
    }
  }
  return (
    <Container maxWidth="md" className={classes.container}>
      {switchArea()}
      <Comments />
    </Container>
  );
}

function DetailArea({ data }) {
  switch (data.type) {
    case "HEADING": //"標題"
      return <Typography variant="h6">{data.body}</Typography>;
    case "PARAGRAPH": //"段落"
      return (
        <Typography>
          {data.body.split("\n").map((line) => (
            <>
              {line}
              <br />
            </>
          ))}
        </Typography>
      );
    case "IMAGE": //"圖片"
      return (
        <img
          src={data.body}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            alignSelf: "flex-start",
          }}
          alt=""
        />
      );
    case "YOUTUBE_VIDEO": //"影片"
      let url = data.body;
      let embedUrl = "";
      if (url) {
        if (url.startsWith("http://") || url.startsWith("https://")) {
          if (url.search("embed") === -1) {
            if (url[8] === "w") {
              url = url.split("watch?v=")[1].split("&")[0];
            } else {
              url = url.split("youtu.be/")[1];
            }
          } else {
            embedUrl = data.body;
          }
        } else {
          return null;
        }
      }
      return (
        <iframe
          width="900"
          height="506"
          src={embedUrl || "https://www.youtube.com/embed/" + url}
          style={{ border: `0px` }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture fullscreen"
          title="youtube"
        />
      );
    case "HYPERLINK": //"超連結"
      return (
        <Link
          component="button"
          onClick={() => window.open(data.body)}
          style={{ textAlign: "start" }}
          underline="always"
        >
          <Typography variant="h6" gutterBottom>
            {data.body}
          </Typography>
        </Link>
      );
    default:
      return null;
  }
}

function Comments() {
  const Alert = useAlert();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const [incrementArticleClick] = useMutation(INCREMENT_ARTICLECLICK);

  useEffect(() => {
    incrementArticleClick({ variables: { id: Number(id) } });
  }, [id, incrementArticleClick]);

  const [getArticleComments, { data, error, loading }] = useLazyQuery(
    GET_ARTICLE_COMMENTS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  useEffect(() => {
    if (Boolean(id)) {
      getArticleComments({ variables: { id: Number(id) } });
    }
  }, [id, getArticleComments]);

  const [saveAuctionComment, { loading: saveAuctionCommentLoading }] =
    useMutation(SAVE_AUCTIONCOMMENT, {
      onCompleted({ saveAuctionComment }) {
        if (saveAuctionComment.success) {
          reset();
          getArticleComments({ variables: { id: Number(id) } });
        } else if (saveAuctionComment.message) {
          if (saveAuctionComment.message.search("登入") > -1) {
            return Alert.alert("", saveAuctionComment.message, [
              {
                text: "回首頁",
                onPress: () => history.replace("/"),
                type: "ok",
              },
              {
                text: "登入",
                onPress: () => history.replace("/login"),
                type: "cancel",
              },
            ]);
          } else {
            return Alert.notice(saveAuctionComment.message);
          }
        } else {
          return Alert.notice("留言失敗，請重新嘗試！");
        }
      },
      onError(error) {
        Alert.notice(error.message.replace("GraphQL error: ", ""));
      },
    });

  function _saveAuctionComment(data) {
    saveAuctionComment({
      variables: { articleId: Number(id), comment: data.comment },
    });
  }

  return (
    <Box
      display="flex"
      height="100%"
      minHeight="100px"
      width="100%"
      flexDirection="column"
      className={classes.box}
    >
      <LoadingModal loading={saveAuctionCommentLoading} />
      {loading ? (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : error ? (
        <Grid container justify="center" alignItems="center">
          <Typography variant="h4">Failed to load data</Typography>
        </Grid>
      ) : data ? (
        data.article.comments.map((item, index) => (
          <CommentItem item={item} index={index} />
        ))
      ) : null}
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Controller
            control={control}
            name="comment"
            rules={{
              required: "必填欄位",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                placeholder="請輸入留言"
                error={error}
                helperText={error?.message}
                multiline
                rows={4}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(_saveAuctionComment)}
            >
              送出
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function CommentItem({ item }) {
  return (
    <Grid
      container
      direction="row"
      style={{ borderBottom: "1px solid #C0C0C0", minHeight: "150px" }}
    >
      <Grid xs={5} sm={2}>
        <Image
          source={
            item.member.currentLuyaoProfilePicture &&
            item.member.currentLuyaoProfilePicture.file
              ? item.member.currentLuyaoProfilePicture.file.location
              : require("../images/@luyaoLogo-new.jpg").default
          }
          style={{
            maxWidth: "115px",
            maxHeight: "115px",
            width: "100%",
            height: null,
            objectFit: "cover",
            aspectRatio: 1,
          }}
        />
      </Grid>
      <Grid xs={7} sm={10}>
        <Box display="flex" justifyContent="space-between" marginBottom="10px">
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            {item.member.nickname ? item.member.nickname : item.member.fullName}
          </Typography>
          <Typography
            variant="body2"
            style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
          >
            {moment(item.createdAt).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography variant="body2">
            {item.comment.split("\n").map((commentItem, index) => (
              <Fragment key={index}>
                {commentItem}
                <br />
              </Fragment>
            ))}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
