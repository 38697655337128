import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { Card, CardActions, CardContent } from "../../component/Card";
import { TextInput } from "../../component/Form";
import Grid from "../../component/Grid";
import Button from "../../component/Button";
import { useAlert } from "../../component/Alert";
import LoadingModal from "../../component/LoadingModal";

const GET_FAQ = gql`
  query faq($id: Int!) {
    faq(id: $id) {
      id
      question
      answer
      createdAt
      updatedAt
    }
  }
`;

const SAVE_FAQ = gql`
  mutation saveFAQ($faqInput: faqInput!) {
    saveFAQ(faqInput: $faqInput) {
      success
      message
    }
  }
`;

export default function FaqForm({ id, onChangeSelect = () => {} }) {
  const Alert = useAlert();
  const [newData, setNewData] = useState({
    question: "",
    answer: "",
  });

  function changeDataListener(key, v) {}

  const changeData = useCallback((key, v, rerender = false) => {
    if (rerender) setNewData((prev) => ({ ...prev, [key]: v }));
    else setNewData((prev) => Object.assign(prev, { [key]: v }));
    if (typeof changeDataListener === "function") changeDataListener(key, v);
  }, []);

  const [getFaq, { loading }] = useLazyQuery(GET_FAQ, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ faq }) {
      if (faq) {
        setTimeout(() => {
          changeData("question", faq.question);
          changeData("answer", faq.answer, true);
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getFaq({ variables: { id } });
    } else {
      changeData("question", "");
      changeData("answer", "", true);
    }
  }, [id, getFaq, changeData]);

  function _saveFAQ() {
    const faqInput = { question: newData.question, answer: newData.answer };
    if (!Boolean(newData.question)) {
      return Alert.alert("", "問題未填寫！", [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    }

    if (!Boolean(newData.answer)) {
      return Alert.alert("", "答案未填寫！", [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    }

    if (Boolean(id)) {
      faqInput.id = id;
    }

    saveFAQ({ variables: { faqInput } });
  }

  const [saveFAQ, { loading: saveFAQLoading }] = useMutation(SAVE_FAQ, {
    onCompleted({ saveFAQ }) {
      if (saveFAQ.success) {
        return Alert.alert("", "儲存成功！", [
          {
            text: "確定",
            onPress: () => onChangeSelect(),
            type: "ok",
          },
        ]);
      } else {
        return Alert.alert("", "儲存失敗，請重新嘗試！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={1}>
        <LoadingModal loading={saveFAQLoading} />
        <Card style={{ flex: 1, padding: 15 }}>
          <CardContent>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  value={newData.question}
                  onChange={(v) => changeData("question", v)}
                  label="問題"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  value={newData.answer}
                  onChange={(v) => changeData("answer", v)}
                  label="答案"
                  multiline
                  fullWidth
                  rows={10}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container item justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onPress={() => _saveFAQ()}
              >
                儲存
              </Button>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}
