// html
import parse from "html-react-parser";
// react-hook-form
import { useWatch } from "react-hook-form";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import PageIsland from "./component/PageIsland";

// export
export default function SpecialEventInformation() {
  return (
    <div>
      <BackgroundContainer>
        <PageIsland />
        <ParsingHtml />
      </BackgroundContainer>
    </div>
  );
}

function ParsingHtml() {
  const eventContent = useWatch({ name: "eventContent" });
  return parse(eventContent || "");
}
