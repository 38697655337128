import React, { useState, createElement, useEffect } from "react";
import {
  AppBar,
  Box,
  IconButton,
  useTheme,
  makeStyles,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  Tabs,
  Tab,
} from "@material-ui/core";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import useLayoutSettings from "./useLayoutSettings";

const barHeight = 64;

export default function Layout({ children }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    content: {
      width: "inherit",
      height: `calc(100vh - ${barHeight})`,
      padding: theme.spacing(1),
    },
  });
  const classes = useStyles();
  return (
    <Box
      display="flex"
      height="100%"
      minHeight="100vh"
      width="100%"
      flexDirection="column"
      bgcolor={theme.palette.grey["100"]}
    >
      <MainBar />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}

function MainBar() {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const layoutSettings = useLayoutSettings();
  const [selectIndex, setSelectIndex] = useState(0);
  const [selectSecIndex, setSelectSecIndex] = useState(null);
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const layoutSubItems = layoutSettings.drawer.items[selectIndex].subItems;
  const useStyles1 = makeStyles({
    appBar: {
      height: barHeight,
      transition: theme.transitions.create(["height"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      transition: theme.transitions.create(["height"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: barHeight * 2,
    },
    barItemsArea: {
      display: "flex",
      flex: 1,
    },
    logoBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 48 * 3,
      height: "100%",
    },
    content: {
      width: "inherit",
      height: `calc(100vh - ${barHeight})`,
    },
    icon: { width: 24, height: 24 },
  });
  const classes1 = useStyles1();
  const logoAreaWidth = 48 * (isTableSize ? 1 : 3);
  const useStyles2 = makeStyles({
    appBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: barHeight,
      padding: `0px ${theme.spacing(2)}px`,
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
    },
    barItemsArea: {
      width: `calc(100% - ${logoAreaWidth * 2}px - ${theme.spacing(2)}px)`,
      margin: `0 ${theme.spacing(1)}px`,
    },
    barItem: {
      borderRadius: theme.shape.borderRadius,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
      color: theme.palette.primary.contrastText,
    },
    logoBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: logoAreaWidth,
      height: 48,
    },
    content: {
      width: "inherit",
      height: `calc(100vh - ${barHeight})`,
    },
    tabs: {
      color: theme.palette.primary.contrastText,
    },
    tabsIndicator: {
      backgroundColor: "transparent",
    },
    icon: { width: 24, height: 24 },
  });
  const classes2 = useStyles2();
  const useStyles3 = makeStyles((theme) => ({
    appBar: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: barHeight,
      padding: `0px ${theme.spacing(2)}px`,
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      top: layoutSubItems ? barHeight : 0,
      transition: `top ${theme.transitions.duration.leavingScreen / 1000}s`,
      zIndex: 0,
    },
  }));
  const classes3 = useStyles3();

  useEffect(() => {
    if (window.location.pathname.includes("/console")) {
      window.FB && window.FB.CustomerChat.hide();
    }
  }, []);

  useEffect(() => {
    const url = pathname + search;
    for (
      let i = 0, layoutLength = layoutSettings.drawer.items.length;
      i < layoutLength;
      i++
    ) {
      if (layoutSettings.drawer.items[i].subItems) {
        for (
          let j = 0;
          j < layoutSettings.drawer.items[i].subItems.length;
          j++
        ) {
          if (layoutSettings.drawer.items[i].subItems[j].route === url) {
            setSelectIndex(i);
            setSelectSecIndex(j);
            return;
          }
        }
      } else if (layoutSettings.drawer.items[i].route === url) {
        setSelectIndex(i);
        setSelectSecIndex(null);
        return;
      }
    }
  }, [pathname, search, layoutSettings.drawer.items]);
  return (
    <>
      <Helmet>
        <title>管理後台｜路遙圓創</title>
      </Helmet>
      <Box
        className={clsx(classes1.appBar, {
          [classes1.appBarShift]: layoutSubItems,
        })}
      />
      <AppBar
        className={clsx(classes1.appBar, {
          [classes1.appBarShift]: layoutSubItems,
        })}
      >
        <Box className={classes2.appBar}>
          <Box className={classes2.logoBox}>
            <img
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={require("./images/@luyaoLogo-new.jpg").default}
              alt=""
            />
          </Box>
          <Box className={classes2.barItemsArea}>
            <Tabs
              value={selectIndex}
              onChange={(e, n) => setSelectIndex(n)}
              classes={{
                root: classes2.tabs,
                indicator: classes2.tabsIndicator,
              }}
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {layoutSettings.drawer.items.map((item, index) => (
                <Tab
                  key={item.title}
                  label={item.title}
                  className={classes2.barItem}
                  onClick={() => {
                    if (layoutSettings.drawer.items[index].route) {
                      history.push(layoutSettings.drawer.items[index].route);
                    }
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box className={classes2.logoBox}>
            <MoreButtons />
          </Box>
        </Box>
        <Box className={classes3.appBar}>
          {layoutSubItems && (
            <Tabs
              value={selectSecIndex}
              onChange={(e, n) => setSelectSecIndex(n)}
              variant="scrollable"
              scrollButtons="auto"
              textColor="secondary"
            >
              {layoutSubItems.map((item, index) => (
                <Tab
                  label={item.title}
                  onClick={() =>
                    history.push(
                      layoutSettings.drawer.items[selectIndex].subItems[index]
                        .route
                    )
                  }
                />
              ))}
            </Tabs>
          )}
        </Box>
      </AppBar>
    </>
  );
}

function MoreButtons() {
  const layoutSettings = useLayoutSettings().navbar.buttons;
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(null);
  const showButton = layoutSettings.length > 0;
  const isTableSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    icon: {
      width: 24,
      height: 24,
      color: isTableSize
        ? theme.palette.text.primary
        : theme.palette.primary.contrastText,
    },
    moreIcon: {
      color: showButton
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
    },
  });
  const classes = useStyles();
  return (
    <Box
      minWidth={48 * (isTableSize ? 1 : 3)}
      display="flex"
      justifyContent="flex-end"
    >
      {isTableSize ? (
        <>
          {showButton ? (
            <>
              <Box display="flex">
                <IconButton
                  onClick={(e) => setOpenMenu(e.currentTarget)}
                  className={classes.moreIcon}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Menu
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={() => setOpenMenu(null)}
              >
                {layoutSettings.map((item) => (
                  <MenuItem
                    key={item.title}
                    onClick={() => {
                      setOpenMenu(null);
                      item.onClick();
                    }}
                  >
                    <ListItemIcon>
                      {createElement(item.icon, {
                        className: classes.icon,
                      })}
                    </ListItemIcon>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Box display="flex">
            {showButton
              ? layoutSettings.map((item, index) => (
                  <IconButton onClick={item.onPress} key={item.title + index}>
                    {item.icon ? (
                      createElement(item.icon, {
                        className: classes.icon,
                      })
                    ) : (
                      <Box width={24} height={24} />
                    )}
                  </IconButton>
                ))
              : null}
          </Box>
        </>
      )}
    </Box>
  );
}
