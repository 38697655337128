import React from "react";
// mui
import { Box } from "@material-ui/core";

// component
import Image from "../../../../component/Image";

export default function CopyrightRender() {
  return (
    <Box pb={3} mb={3} borderBottom="1.5px solid #fff">
      {/* logo */}

      <Box maxWidth={220} mx="auto" mb={1}>
        <Image
          src={"/img/special/event-2/Luyao-design-logo.png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"contain"}
        />
      </Box>
      {/* copyright */}
      <Box maxWidth={320} mx="auto">
        <Image
          src={"/img/special/event-2/Luyao-design .png"}
          style={{ width: "100%", height: "100%" }}
          resizeMode={"contain"}
        />
      </Box>
    </Box>
  );
}
