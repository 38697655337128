import { useMemo } from "react";
// html
import parse from "html-react-parser";
// react-hook-form
import { useWatch } from "react-hook-form";
import { Box, Grid, useMediaQuery } from "@material-ui/core";

// components
import BackgroundContainer from "../../../component/special-event/BackgroundContainer";
import PageIsland, { useGetMarginTop } from "./component/PageIsland";
import Image from "../../../component/Image";

// export
export default function SpecialEventInformation() {
  return (
    <div>
      <BackgroundContainer>
        <PageIsland />
        <ParsingHtml />
        <RightsMarkContent />
      </BackgroundContainer>
    </div>
  );
}

function ParsingHtml() {
  const marginTop = useGetMarginTop();
  const eventContent = useWatch({ name: "eventContent" });
  return <Box style={{ marginTop }}>{parse(eventContent || "")}</Box>;
}

export function useGetWidth() {
  const isTable = useMediaQuery("(max-width:1060px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const width = useMemo(() => {
    if (isDownMobile) {
      return "95%";
    } else if (isMobile) {
      return "75%";
    } else if (isTable) {
      return "50%";
    }
    return "50%";
  }, [isTable, isMobile, isDownMobile]);
  return width;
}
// ANCHOR 權利標內容
function RightsMarkContent() {
  const width = useGetWidth();
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid container item xs={12} justifyContent="center">
          <Image
            src={"/img/special/crystal-palace/權利標.png"}
            style={{
              width,
              height: "100%",
            }}
            resizeMode={"contain"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
