import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { deepEqual } from "fast-equals";
// NOTE 工具
import stringArrayPick from "../../../utils/stringArrayPick";
// NOTE 組件
import { CustomTextField } from "../../Form";

function CustomDropdown({
  textInputProps,
  buttonProps,
  buttomName = "搜尋",
  handleClick,
  controlName = "",
  value,
  onChange = () => {},
}) {
  const useStyles = makeStyles({
    text: {
      flexGrow: 1,
    },
  });
  const classes = useStyles();
  const formContext = useFormContext();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchTerm: "",
      generalData: [],
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState([]);
  const propsValueRef = useRef([]);
  const inputRef = useRef(null);
  const handleRef = useRef({
    handleClick,
  });
  useEffect(() => {
    if (!deepEqual(propsValueRef.current, value)) {
      propsValueRef.current = value;
      setValue("generalData", value);
    }
  }, [value, setValue]);
  const _handleClick = useCallback(async ({ searchTerm }) => {
    setLoading(true);
    setAnchorEl(inputRef.current);
    try {
      const data = await handleRef.current.handleClick?.(searchTerm);
      if (Array.isArray(data)) {
        setLoading(false);
        setOptions(data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Grid container spacing={1} direction="row">
        <Grid item>
          <Box display="flex">
            <Controller
              control={control}
              name="searchTerm"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  {...field}
                  inputRef={inputRef}
                  fullWidth
                  error={Boolean(error)}
                  {...textInputProps}
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(_handleClick)}
              {...buttonProps}
            >
              {buttomName}
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {loading ? (
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  <CircularProgress size={20} />
                </div>
              ) : (
                options.map((item, index) => (
                  <Controller
                    key={"option" + index}
                    control={formContext?.control ?? control}
                    name={controlName || "generalData"}
                    render={({ field }) => {
                      const selected = (field.value ?? []).some(
                        (v) => v.value === item.value
                      );
                      function _onSelect() {
                        onChange(item);
                        field.onChange(
                          stringArrayPick(field.value ?? [], item)
                        );
                      }
                      return (
                        <>
                          <div
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 15,
                              paddingBottom: 15,
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              width: 240,
                            }}
                          >
                            <div className={classes.text}>{item.label}</div>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={Boolean(selected)}
                              onClick={_onSelect}
                            >
                              {selected ? "已加入" : "未加入"}
                            </Button>
                          </div>
                          <Divider />
                        </>
                      );
                    }}
                  />
                ))
              )}
            </Popover>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(CustomDropdown, deepEqual);
