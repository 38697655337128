import React, {
  forwardRef,
  useMemo,
  memo,
  useImperativeHandle,
  useState,
} from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  TextField,
  Select as Select2,
  MenuItem,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// NOTE 組件
import { useAlert } from "../../component/Alert";
import Grid from "../../component/Grid";
import { FieldCache } from "../../component/FieldCache";
import { AutocompleteSelect, CustomNumberInput } from "../../component/Form";
import LoadingModal from "../../component/LoadingModal";
// NOTE 工具
import { countiesList, districtsList } from "../../utils/countiesDistricts";
import { orderItemStatusList, shipmentStatusList } from "../../utils/localData";

// SECTION apollo
// NOTE 商品列表
/** - 商品列表 */
const GET_PRODUCTS_ALL = gql`
  query products {
    products {
      products {
        value: id
        # "名稱"
        label: name
      }
    }
  }
`;
// NOTE 建立訂單
/** - 建立訂單 */
const CREATE_ORDER = gql`
  mutation createOrder(
    $cartItemInput: CartItemInput
    $shipmentInput: ShipmentInput
  ) {
    createOrder(cartItemInput: $cartItemInput, shipmentInput: $shipmentInput) {
      # "ID"
      id
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
function CreateOrderDialog({ onClose }, ref) {
  const { notice } = useAlert();
  const [open, setOpen] = useState(false);
  const useStylesTable = makeStyles({
    QuestionsContainer: {
      flexDirection: "column",
    },
  });
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      productId: null,
      amount: 0,
      orderItemStatus: "",
      recipient: "",
      recipientPhone: "",
      shipmentMethod: "HOME_DELIVERY",
      district: null,
      subdistrict: null,
      address: "",
      shipmentStatus: "",
      note: "",
    },
  });
  const classesTable = useStylesTable();
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
      },
    }),
    []
  );

  const { data: productsAllData } = useQuery(GET_PRODUCTS_ALL, {
    fetchPolicy: "network-only",
  });
  const products = useMemo(
    () => productsAllData?.products?.products ?? [],
    [productsAllData]
  );

  const [createOrderFn, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted({ createOrder }) {
        if (createOrder.id) {
          onClose?.("success");
          setTimeout(() => {
            notice("訂單建立成功。");
          }, 0);
        } else if (createOrder.message) {
          notice(createOrder.message);
        }
      },
      onError(error) {
        notice(`${error.message.replace("GraphQL error: ", "")}`);
        return null;
      },
    }
  );

  function initialization(value, cartItemInput, shipmentInput) {
    if (value === "mutation") {
      createOrderFn({ variables: { cartItemInput, shipmentInput } });
    } else {
      setOpen(false);
      setTimeout(() => {
        reset();
      }, 300);
    }
  }

  function _createShipment({
    productId,
    amount,
    orderItemStatus,
    recipient,
    recipientPhone,
    shipmentMethod,
    district,
    subdistrict,
    address: submitAddress,
    shipmentStatus,
    note,
  }) {
    const cartItemInput = {
      productId,
      amount: Number(amount),
      status: orderItemStatus,
    };
    const shipmentInput = {
      status: shipmentStatus,
      recipient,
      recipientPhone,
      shipmentMethod,
      note,
    };
    const address = {
      district,
      subdistrict,
      address: submitAddress,
    };
    shipmentInput.address = address;
    initialization("mutation", cartItemInput, shipmentInput);
  }

  return (
    <Dialog
      style={{ marginBottom: "5em" }}
      open={open}
      fullWidth
      disableScrollLock
    >
      <LoadingModal loading={createOrderLoading} />
      <DialogTitle>後台建立訂單</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid
            container
            item
            spacing={1}
            classes={{ root: classesTable.QuestionsContainer }}
          >
            <Controller
              control={control}
              name="productId"
              rules={{
                required: "請選擇商品",
              }}
              render={({ field, fieldState: { error } }) => {
                const realValue = products.find(
                  (item) => item.value === field.value
                );
                return (
                  <Grid item>
                    <Typography color="primary">選擇商品</Typography>
                    <AutocompleteSelect
                      items={products}
                      value={realValue || null}
                      onChange={(e, value) => {
                        if (value) {
                          field.onChange(value.value);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      fullWidth
                    />
                    {error && (
                      <FormHelperText error={error}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </Grid>
                );
              }}
            />
            <Controller
              control={control}
              name="amount"
              rules={{
                required: "必填欄位",
                validate: (e) => {
                  if (!isNaN(e) && Number(e) <= 0) {
                    return "只允許大於 0 的整數";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item>
                  <Typography color="primary">數量</Typography>
                  <CustomNumberInput
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                </Grid>
              )}
            />
            <Controller
              control={control}
              name="orderItemStatus"
              rules={{
                required: "請選擇訂單狀態",
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12}>
                  <Typography color="primary">請選擇訂單狀態</Typography>
                  <Select2 {...field} fullWidth displayEmpty error={error}>
                    <MenuItem value={null} disabled>
                      請選擇訂單狀態
                    </MenuItem>
                    {orderItemStatusList
                      .filter((i) => i.value !== "ALL")
                      .map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select2>
                  {error && (
                    <FormHelperText error={error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </Grid>
              )}
            />
            <Controller
              control={control}
              name="recipient"
              rules={{
                required: "請輸入收件人姓名",
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item>
                  <Typography color="primary">收件人姓名</Typography>
                  <TextField
                    {...field}
                    placeholder={"收件人姓名"}
                    fullWidth
                    error={error}
                    helperText={error?.message}
                  />
                </Grid>
              )}
            />
            <Controller
              control={control}
              name="recipientPhone"
              rules={{
                required: "請輸入收件人手機",
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item>
                  <Typography color="primary">收件人手機</Typography>
                  <TextField
                    {...field}
                    placeholder={"收件人手機"}
                    fullWidth
                    error={error}
                    helperText={error?.message}
                  />
                </Grid>
              )}
            />
            <Grid item>
              <Typography color="primary">收件人地址</Typography>
              <Grid container spacing={1}>
                <Controller
                  control={control}
                  name="district"
                  rules={{
                    required: "請選擇縣市",
                  }}
                  render={({
                    field: { onChange, ...otherFields },
                    fieldState: { error },
                  }) => (
                    <Grid item xs={6} sm={3}>
                      <Select2
                        {...otherFields}
                        fullWidth
                        onChange={(e) => {
                          onChange(e);
                          setValue("subdistrict", null);
                        }}
                        error={error}
                        displayEmpty
                      >
                        <MenuItem value={null} disabled>
                          請選擇縣市
                        </MenuItem>
                        {countiesList("zhTW").map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select2>
                      {error && (
                        <FormHelperText error={error}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                />
                <FieldCache
                  control={control}
                  name="district"
                  render={(district) => (
                    <Controller
                      control={control}
                      name="subdistrict"
                      rules={{
                        required: "請選擇地區",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <Grid item xs={6} sm={3}>
                          <Select2
                            {...field}
                            fullWidth
                            displayEmpty
                            error={error}
                          >
                            <MenuItem value={null} disabled>
                              請選擇地區
                            </MenuItem>
                            {districtsList(district).map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select2>
                          {error && (
                            <FormHelperText error={error}>
                              {error.message}
                            </FormHelperText>
                          )}
                        </Grid>
                      )}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="address"
                  rules={{
                    required: "請輸入地址",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...field}
                        fullWidth
                        error={error}
                        helperText={error?.message}
                      />
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              control={control}
              name="shipmentStatus"
              rules={{
                required: "請選擇出貨狀態",
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12}>
                  <Typography color="primary">請選擇出貨狀態</Typography>
                  <Select2 {...field} fullWidth displayEmpty error={error}>
                    <MenuItem value={null} disabled>
                      請選擇出貨狀態
                    </MenuItem>
                    {shipmentStatusList
                      .filter((i) => i.value !== "ALL")
                      .map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select2>
                  {error && (
                    <FormHelperText error={error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </Grid>
              )}
            />
            <Controller
              control={control}
              name="note"
              render={({ field }) => (
                <Grid item>
                  <Typography color="primary">備註</Typography>
                  <TextField {...field} fullWidth />
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(_createShipment)}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={initialization}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default memo(forwardRef(CreateOrderDialog));
